/** @jsx jsx */
import { jsx, css } from '@emotion/core';
import { Link, useParams } from 'react-router-dom';
import { useContext, useEffect, useState } from 'react';
import GlobalStyles from 'assets/GlobalStyles';
import Modal from '@material-ui/core/Modal';
import CloseTwoToneIcon from '@material-ui/icons/CloseTwoTone';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import StepContext from 'components/Steps/StepContext';
import { useQuery } from '@apollo/client';
import { GET_ADMINISTERED_DOSE_LOG } from 'utils/queries';

const boldedSpan = css`
  font-weight: bold;
`;

const paragraphStyling = css`
  color: ${GlobalStyles.navyBlue};
  font-size: 21px;
  overflow-wrap: break-word;
`;

const buttonStyling = css`
  font-weight: bolder;
  align-self: center;
  background-color: ${GlobalStyles.accentOrange};
  color: ${GlobalStyles.navyBlue};
  cursor: pointer;
  width: 90%;
  text-decoration: none;
  border-radius: 0% !important;
  &:hover {
    color: ${GlobalStyles.navyBlue};
    background-color: ${GlobalStyles.accentOrange};
  }
  height: 32px;
  margin: 10px;
`;

const closeButtonStyling = css`
  color: ${GlobalStyles.white};
  background-color: ${GlobalStyles.navyBlue};
  border-radius: 0px;
  position: absolute;
  top: 0;
  right: 0;
`;

const modalStyling = css`
  display: 'flex';
  align-items: 'center';
  justify-content: 'center';
  width: 50%;
  margin: auto;
  margin-top: 100px;
  border-radius: 0px;
`;

const messageContainerStyling = css`
  background-color: ${GlobalStyles.white};
  border: solid 7px ${GlobalStyles.navyBlue};
  padding: 50px;
`;

const titleContainerStyling = css`
  width: 100%;
  border-bottom: 5px solid ${GlobalStyles.red};
`;

const titleStyling = css`
  max-width: 80%;
  background-color: ${GlobalStyles.red};
  color: ${GlobalStyles.white};
  font-weight: bolder;
  padding: 2px 15px;
  font-size: 20px;
  margin-top: 50px;
  margin-bottom: -0.5%;
`;

const Step2UpdatedModal = ({ handleClose }) => {
  const { stepState } = useContext(StepContext);
  const { patientData } = stepState;
  const { id: patientId } = useParams();
  // Get previous and current dosage values
  const { data } = useQuery(GET_ADMINISTERED_DOSE_LOG, {
    variables: {
      id: patientId,
    },
  });

  let lastDosageUpdates = [];

  const [newestStep2DosageLog, setNewestStep2DosageLog] = useState();
  const [
    newestStep2MedicationNameLog,
    setNewestStep2MedicationNameLog,
  ] = useState();
  // need for displaying correct name in modal for new non-hospital step 2, as log differs
  let [initialStep2MedicationName, setInitialStep2MedicationName] = useState(
    ''
  );

  useEffect(() => {
    if (data) {
      // function to get current and prev step 2 dosages
      const stepChecker = () => {
        data.patientLogs.forEach((item, index) => {
          let modelId = item.model_id;
          let stepIndex = index;
          let stepInfo = {
            stepNumber: null,
            lastUpdatedIndex: null,
            fieldName: '',
            oldEntry: null,
            newEntry: null,
          };

          setNewestStep2DosageLog(
            lastDosageUpdates.find(
              ({ fieldName }) => fieldName === 'administered_dose'
            )
          );

          setNewestStep2MedicationNameLog(
            lastDosageUpdates.find(
              ({ fieldName }) => fieldName === 'medication'
            )
          );

          item.patient.action_plans.forEach((plan) => {
            plan.action_plan_steps.forEach((step) => {
              let stepId = step.id;
              let stepNum = step.step_number;

              if (stepId === modelId && stepNum === 2) {
                stepInfo = {
                  stepNumber: stepNum,
                  lastUpdatedIndex: stepIndex,
                  fieldName: item.field_name,
                  oldEntry: item.old_entry,
                  newEntry: item.new_entry,
                };

                lastDosageUpdates.push(stepInfo);

                // set initial medication name
                if (
                  item.new_entry === 'step number 2' &&
                  step &&
                  step.medication
                ) {
                  setInitialStep2MedicationName(step.medication.generic_name);
                }
              }
              return lastDosageUpdates;
            });
          });
        });
      };
      stepChecker();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const handleCancel = () => {
    handleClose();
  };

  let currentStep;
  if (stepState && stepState.step) {
    currentStep = stepState.step;
  }

  return (
    <Modal
      open={stepState.openStep2UpdatedModal}
      onClose={handleClose}
      BackdropProps={{
        style: {
          boxShadow: 'none',
          cursor: 'pointer',
        },
      }}
      css={modalStyling}
    >
      <div className="messageContainer" css={messageContainerStyling}>
        <IconButton
          onClick={handleCancel}
          aria-label="close"
          css={closeButtonStyling}
        >
          <CloseTwoToneIcon />
        </IconButton>

        <div className="titleContainer" css={titleContainerStyling}>
          <div className="warningTitle" css={titleStyling}>
            CAUTION: SEIZURE PLAN DOSAGE UPDATE REQUIRED
          </div>
        </div>

        <p css={paragraphStyling}>
          You have updated{' '}
          <span css={boldedSpan}>
            {' '}
            {patientData && patientData.patient && patientData.patient.nickname}
          </span>
          &apos;s prescribed medication in{' '}
          <span css={boldedSpan}> Step 2 </span> of their plan from{' '}
          <span css={boldedSpan}>
            {' '}
            {newestStep2MedicationNameLog &&
            stepState.rescueMedicationNameIsUpdating
              ? newestStep2MedicationNameLog.oldEntry
              : newestStep2MedicationNameLog &&
                !stepState.rescueMedicationNameIsUpdating
              ? newestStep2MedicationNameLog.newEntry
              : initialStep2MedicationName}{' '}
            at a dosage of{' '}
            {newestStep2DosageLog &&
              Number(newestStep2DosageLog.oldEntry).toFixed(2)}
            mg
          </span>{' '}
          to{' '}
          <span css={boldedSpan}>
            {currentStep &&
              currentStep.medication &&
              currentStep.medication.generic_name}{' '}
            at a dosage of{' '}
            {currentStep && Number(currentStep.administered_dose).toFixed(2)}
            mg
          </span>{' '}
          and this change has been reflected in{' '}
          <span css={boldedSpan}>Step 3</span> as well.
        </p>
        <p css={paragraphStyling}>
          If necessary, please confirm or update{' '}
          <span css={boldedSpan}>Step 3</span> of{' '}
          <span css={boldedSpan}>
            {' '}
            {patientData && patientData.patient && patientData.patient.nickname}
          </span>
          &apos;s out-of-hospital plan.
        </p>

        <div
          className="buttonsContainer"
          css={css`
            display: grid;
            grid-template-columns: repeat(2, 1fr);
          `}
        >
          <Button css={buttonStyling} onClick={handleCancel}>
            CONFIRM
          </Button>

          <Button
            css={buttonStyling}
            component={Link}
            to={`/patient/${patientId}/plan/nonhospital/edit/3`}
            onClick={handleCancel}
          >
            UPDATE
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default Step2UpdatedModal;
