import { useState, Fragment, useEffect, useRef } from 'react';
import moment from 'moment';
import { nanoid } from 'nanoid';
import { useParams, useHistory } from 'react-router-dom';
import { useMutation } from '@apollo/client';
/** @jsx jsx */
import { jsx, css } from '@emotion/core';
import {
  Paper,
  Button,
  TextField,
  Snackbar,
  IconButton,
  Grid,
  CircularProgress,
  FormControlLabel,
  Checkbox,
  useTheme,
  withStyles,
} from '@material-ui/core/';
import ErrorIcon from '@material-ui/icons/Error';
import CloseIcon from '@material-ui/icons/Close';
import DeleteIcon from '@material-ui/icons/Delete';
import styled from '@emotion/styled';
import GlobalStyles from 'assets/GlobalStyles';
import {
  UPDATE_PATIENT,
  CREATE_PATIENT,
  ADD_TEAM_MEMBER_TO_PATIENT_LIST,
} from 'utils/mutations';
import {
  GET_PATIENT_LOG_DETAILS,
  GET_PATIENTS_LIST,
  GET_PATIENT_UPDATED_WEIGHT_LOG,
} from 'utils/queries';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import UpdateWeightModal from 'components/UpdateWeightModal';
import NonRescueMedication from 'components/NonRescueMedication';
import SeizureTypesList from 'components/SeizureTypesList';
import SeizureTypesMenu from 'components/SeizureTypesMenu';

const textFieldStyle = css`
  width: 100%;

  .MuiInputBase-input {
    margin-left: 3%;
    color: ${GlobalStyles.navyBlue2};
    font-size: 20px;
    font-weight: 500;
    letter-spacing: 1.25px;
    cursor: text;
  }
  .MuiInput-underline:before {
    border-bottom: 0px;
  }
  .MuiInputBase-input.Mui-disabled {
    color: ${GlobalStyles.navyBlue2};
    -webkit-text-fill-color: ${GlobalStyles.navyBlue2};
  }
  .MuiFormHelperText-root.Mui-error {
    background-color: ${GlobalStyles.white};
    margin-bottom: -10px;
  }
`;

const textFieldStyle2 = css`
  width: 100%;

  .MuiInputBase-input {
    margin-left: 1%;
    color: ${GlobalStyles.navyBlue2};
    font-size: 20px;
    font-weight: 500;
    padding-left: 10px;
    letter-spacing: 1.25px;
  }
  .MuiInputBase-input.Mui-disabled {
    background-color: ${GlobalStyles.disabledGrey};
    width: 100%;
    margin-left: 0;
  }
  .MuiInput-underline:before {
    border-bottom: 0px;
  }
  .MuiFormHelperText-root.Mui-error {
    background-color: ${GlobalStyles.white};
  }
`;

const inputTitleStyle = css`
  background-color: ${GlobalStyles.navyBlue2};
  color: ${GlobalStyles.white};
  font-size: 14px;
  font-weight: 800;
  letter-spacing: 1px;
  padding: 5px 15px;
  margin-bottom: -1px;
`;

const requiredTitleStyle = css`
  border-bottom: 5px solid ${GlobalStyles.navyBlue2};
  .required:after {
    content: '*';
    color: ${GlobalStyles.white};
  }
`;

const dateOfBirthInputStyle = css`
  flex: auto;
  margin-top: 0;

  .MuiInputBase-input {
    margin-left: 3%;
    color: ${GlobalStyles.navyBlue2};
    font-size: 20px;
    font-weight: 500;
    letter-spacing: 1.25px;
    cursor: text;
  }
  .MuiInput-underline:before {
    border-bottom: 0px;
  }
  .MuiInputBase-input.Mui-disabled {
    color: ${GlobalStyles.navyBlue2};
    -webkit-text-fill-color: ${GlobalStyles.navyBlue2};
  }
  .MuiFormHelperText-root.Mui-error {
    background-color: ${GlobalStyles.white};
    margin-bottom: -10px;
  }
`;

const addMoreButtonStyle = css`
  background-color: transparent;
  color: ${GlobalStyles.navyBlue};
  border: none;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 1px;
  padding: 5px 15px;
  margin-bottom: 1px;
  cursor: pointer;
  &:disabled {
    cursor: none;
    pointer-events: none;
    color: ${GlobalStyles.disabledGrey};
  }
`;

const savedItemsContainerStyle = css`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const savedItemContainerStyle = css`
  display: flex;
  justify-content: space-between;
`;

const savedMedicationNameStyle = css`
  color: ${GlobalStyles.navyBlue};
  -webkit-text-fill-color: ${GlobalStyles.navyBlue2};
  font-size: 20px;
  font-weight: 500;
  letter-spacing: 1.25px;
  margin: 0px;
  padding-top: 10px;
  padding-bottom: 2%;
  cursor: default;
  width: 100%;
`;

const deleteIconStyle = {
  color: `${GlobalStyles.navyBlue}`,
  cursor: 'pointer',
  alignSelf: 'center',
  marginLeft: '10px',
  //increase clickable area
  padding: '10px',
};

const StyledCircularProgress = styled(CircularProgress)`
  .MuiCircularProgress-colorPrimary {
    color: ${GlobalStyles.navyBlue};
  }
`;

const CustomCheckbox = withStyles({
  root: {
    color: GlobalStyles.navyBlue,
    '&$checked': {
      color: GlobalStyles.navyBlue,
    },
  },
  checked: {},
})((props) => <Checkbox color="default" {...props} />);

const checkboxAndLabelStyle = css`
  color: ${GlobalStyles.navyBlue};
`;

function CustomTextField(props) {
  const theme = useTheme();

  return (
    <TextField
      fullWidth
      type="text"
      margin="normal"
      InputLabelProps={{
        shrink: true,
      }}
      css={css`
        flex: 1;
        input.Mui-disabled {
          color: ${theme.palette.text.primary};
        }
      `}
      {...props}
    />
  );
}

function EditPatientForm({
  patient,
  setWeightModal,
  weightModal,
  medicationData,
  medicationNames,
  setUpdatedWeightModal,
}) {
  const history = useHistory();
  const { id } = useParams();
  const [open, setOpen] = useState(false);
  const [dosageValue, setDosageValue] = useState([]);
  const [savingMedication, setSavingMedication] = useState(false);
  const [deletingMedication, setDeletingMedication] = useState(false);
  const [disableDosage, setDisableDosage] = useState(true);
  const [savingSeizureType, setSavingSeizureType] = useState(false);
  const [deletingSeizureType, setDeletingSeizureType] = useState(false);
  const [seizureTypeNameArray, setSeizureTypeNameArray] = useState([]);
  const [isNewCheckboxActive, setIsNewCheckboxActive] = useState(false);

  const handleNewSeizureTypeCheckbox = () => {
    setIsNewCheckboxActive(!isNewCheckboxActive);
  };

  const [level1, setLevel1] = useState('');
  const [level2, setLevel2] = useState('');
  const [level3, setLevel3] = useState('');

  const [updatePatient, updatingPatient] = useMutation(UPDATE_PATIENT, {
    refetchQueries: [
      {
        query: GET_PATIENT_UPDATED_WEIGHT_LOG,
        variables: { id },
      },
      { query: GET_PATIENT_LOG_DETAILS, variables: { id } },
      { query: GET_PATIENTS_LIST },
    ],
    awaitRefetchQueries: true,
  });

  const [createPatient, creatingPatient] = useMutation(CREATE_PATIENT, {
    refetchQueries: [{ query: GET_PATIENTS_LIST }],
    awaitRefetchQueries: true,
  });

  const [addTeamMemberToPatient] = useMutation(ADD_TEAM_MEMBER_TO_PATIENT_LIST);

  //add this to ensure if adding a new patient the 'patient' prop is set to null rather than empty object.
  // An empty object causes controlled/uncontrolled field errors
  if (id === 'new') {
    patient = null;
  }

  const [formFields, setFormFields] = useState({
    first_name: patient ? patient.first_name : '',
    last_name: patient ? patient.last_name : '',
    nickname: patient ? patient.nickname : '',
    DOB: patient ? patient.DOB : '',
    caregiver_name: patient ? patient.caregiver_name : '',
    caregiver_email: patient ? patient.caregiver_email : '',
    weight: patient ? patient.weight : '',
    unit_of_weight: patient ? patient.unit_of_weight : '',
    weight_last_updated: patient ? patient.weight_last_updated : '',
    diagnoses: patient ? patient.diagnoses.trim().replace(/^\s*\n/gm, '') : '',
    seizure_types: patient ? patient.seizure_types : [],
    medications: patient ? patient.medications : '',
    dosage: patient ? patient.dosage : '',
    allergies: patient ? patient.allergies.trim().replace(/^\s*\n/gm, '') : '',
    patient_notes: patient
      ? patient.patient_notes.trim().replace(/^\s*\n/gm, '')
      : '',
  });

  const {
    first_name,
    last_name,
    nickname,
    DOB,
    dosage,
    caregiver_name,
    caregiver_email,
    weight,
    unit_of_weight,
    weight_last_updated,
    diagnoses,
    seizure_types,
    allergies,
    patient_notes,
  } = formFields;

  //creating a date object for today
  const today = new Date();

  useEffect(() => {
    ValidatorForm.addValidationRule('weightIsZero', (value) => {
      if (value === '.' || +value === 0) {
        return false;
      }
      return true;
    });

    ValidatorForm.addValidationRule('positiveWeight', (value) => {
      if (value < 0 || value.toString().includes('-')) {
        return false;
      }
      return true;
    });

    ValidatorForm.addValidationRule('isDateInPast', (value) => {
      const dateObject = new Date(value);
      if (dateObject > today) {
        return false;
      }
      return true;
    });

    ValidatorForm.addValidationRule('isDateInAfter1900', (value) => {
      const dateObject = new Date(value);
      const year = dateObject.getFullYear();
      if (year > 1899) {
        return true;
      } else {
        return false;
      }
    });
  });

  const weightLastUpdated = new Date(weight_last_updated);
  const weightNeedsUpdate = moment().diff(weightLastUpdated, 'days') > 365;

  const weightUpdateFlag = () => {
    return (
      <div
        css={css`
          align-self: flex-end;
          color: ${GlobalStyles.warningOrange};
          overflow-wrap: break-word;
          padding-bottom: 5px;
        `}
      >
        Last updated over a year ago
      </div>
    );
  };

  const InputDisabledFlag = ({ nameOfField }) => {
    return (
      <div
        css={css`
          color: ${GlobalStyles.warningOrange};
          overflow-wrap: break-word;
          align-self: center;
          font-size: 14px;
        `}
      >
        Create Patient to Add {nameOfField}
      </div>
    );
  };

  const handleBack = async (e) => {
    e.preventDefault();
    await setFormFields({
      first_name: patient ? patient.first_name : '',
      last_name: patient ? patient.last_name : '',
      nickname: patient ? patient.nickname : '',
      DOB: patient ? patient.DOB : '',
      caregiver_name: patient ? patient.caregiver_name : '',
      caregiver_email: patient ? patient.caregiver_email : '',
      weight: patient ? patient.weight : '',
      unit_of_weight: patient ? patient.unit_of_weight : '',
      weight_last_updated: patient ? patient.weight_last_updated : '',
      diagnoses: patient ? patient.diagnoses : '',
      seizure_types: patient ? patient.seizure_types : '',
      medications: patient ? patient.medications : '',
      dosage: patient ? patient.dosage : '',
      allergies: patient ? patient.allergies : '',
      patient_notes: patient ? patient.patient_notes : '',
    });

    history.goBack();
  };

  // Function for focusing on next input field on enter.
  // Multiline inputs not targeted as enter must go to next line.
  // e.preventDefault necessary in each input as form button must stay type="submit" for validation to work

  const input1 = useRef(),
    input2 = useRef(),
    input3 = useRef(),
    input4 = useRef(),
    input5 = useRef(),
    input6 = useRef(),
    input7 = useRef(),
    input8 = useRef();

  const handleEnter = (e) => {
    if (e.keyCode === 13) {
      switch (e.target.id) {
        case 'inputId1':
          e.preventDefault();
          input2.current.focus();
          break;
        case 'inputId2':
          e.preventDefault();
          input3.current.focus();
          break;
        case 'inputId3':
          e.preventDefault();
          input4.current.focus();
          break;
        case 'inputId4':
          e.preventDefault();
          input5.current.focus();
          break;
        case 'inputId5':
          e.preventDefault();
          input6.current.focus();
          break;
        case 'inputId6':
          e.preventDefault();
          input7.current.focus();
          break;
        case 'inputId7':
          e.preventDefault();
          input8.current.focus();
          break;

        default:
          break;
      }
    }
  };
  // Weight Form Validation
  const [errorHelperText, setErrorHelperText] = useState('');

  const handleChange = (key) => ({ target: { value } }) => {
    if (key === 'dosage') {
      const newDosage = value;

      setDosageValue(newDosage);
    } else if (key === 'weight') {
      const currentTime = moment().format().toString();
      const validNum = /^-?[0-9]*\.?[0-9]*$/.test(value);

      if (value === '-') {
        setErrorHelperText('This field cannot be negative');
      } else if (value > 0) {
        setErrorHelperText('');
      } else if (!validNum) {
        return;
      } else {
        setErrorHelperText('');
      }

      let newChangeFormFields = {
        ...formFields,
        weight_last_updated: currentTime.toString(),
        [key]: value,
      };
      newChangeFormFields[key] = value;

      setFormFields(newChangeFormFields);
    } else if (key === 'medications') {
      setFormFields({ ...formFields, [key]: value });
      setDisableDosage(false);
    } else {
      setFormFields({ ...formFields, [key]: value });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    let weightChange = false;
    if (!nickname) {
      formFields.nickname = first_name;
    }

    if (patient && formFields.weight !== patient.weight) {
      weightChange = true;
    }

    if (formFields.weight > 0) {
      let weight_float = parseFloat(formFields.weight);
      formFields.weight = weight_float;
    }

    if (formFields.medications !== undefined && dosageValue.length > 0) {
      const medication = medicationData.medications.find((medication) => {
        return medication.generic_name === formFields.medications;
      });

      if (medication) {
        const patientMedication = patient.medications.map(
          (medication) => medication.id
        );

        formFields.medications = [...patientMedication, medication.id];
      }

      formFields.dosage = {
        ...patient.dosage,
        [JSON.stringify(`${medication.id}`)]: dosageValue,
      };
    } else if (patient && patient.dosage) {
      formFields.dosage = { ...patient.dosage };

      const patientMedication = patient.medications.map(
        (medication) => medication.id
      );

      formFields.medications = [...patientMedication];
    } else {
      formFields.dosage = { ...formFields.dosage };
      formFields.medications = [...formFields.medications];
    }

    //level 1
    Object.entries(SeizureTypesList).forEach(([key, value]) => {
      if (key === level1) {
        setSeizureTypeNameArray([]);
        setSeizureTypeNameArray(seizureTypeNameArray.push(value.name));
      }
    });
    //level 2
    Object.entries(level1.length && SeizureTypesList[level1]).forEach(
      ([key, value]) => {
        if (key === level2) {
          setSeizureTypeNameArray(seizureTypeNameArray.push(value.name));
        }
      }
    );
    //level 3
    Object.entries(level2.length && SeizureTypesList[level1][level2]).forEach(
      ([key, value]) => {
        if (key === level3) {
          setSeizureTypeNameArray(seizureTypeNameArray.push(value.name));
        }
      }
    );

    if (
      formFields.seizure_types !== undefined &&
      seizureTypeNameArray.length > 0
    ) {
      if (patient !== null) {
        //generate unique id
        const nanoId = nanoid();

        const previousSeizureTypes = patient.seizure_types
          ? [...patient.seizure_types]
          : [];

        formFields.seizure_types = [
          ...previousSeizureTypes,
          {
            id: nanoId,
            name: seizureTypeNameArray,
            checkboxActive: isNewCheckboxActive,
          },
        ];
      }
    }

    if (id === 'new') {
      const currentTime = moment().format().toString();
      let newlyCreatedId;
      const newFormFields = {
        ...formFields,
        weight_last_updated: currentTime.toString(),
        unit_of_weight: 'kg',
      };
      await createPatient({
        variables: { data: newFormFields },
      })
        .then((response) => {
          newlyCreatedId = response.data.createPatient.patient.id;

          addTeamMemberToPatient({
            variables: {
              id: newlyCreatedId,
              data: localStorage.getItem('user_id'),
            },
          });
        })
        .catch((error) => {
          console.log('error', error);
        });

      history.push(`/patient/${newlyCreatedId}/details`);
    } else {
      await updatePatient({
        variables: { id, data: formFields },
      })
        .then(() => {
          if (weightChange) setUpdatedWeightModal(true);
        })
        .catch((error) => {
          console.log('ERROR', error);
        });

      history.push(`/patient/${id}/details`);
    }
  };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  const isLoading = updatingPatient.loading || creatingPatient.loading;

  const addMedicationRow = async (e) => {
    e.preventDefault();

    if (formFields.medications !== undefined && dosageValue.length > 0) {
      setDisableDosage(true);
      setSavingMedication(true);
      const medication = medicationData.medications.find((medication) => {
        return medication.generic_name === formFields.medications;
      });

      if (patient !== null) {
        if (medication) {
          const patientMedication = patient.medications.map(
            (medication) => medication.id
          );

          formFields.medications = [...patientMedication, medication.id];
        }

        formFields.dosage = {
          ...patient.dosage,
          [JSON.stringify(`${medication.id}`)]: dosageValue,
        };

        await updatePatient({
          variables: { id, data: formFields },
        }).catch((error) => {
          console.log('ERROR', error);
        });
      }

      setDosageValue('');
      setFormFields({
        ...formFields,
        medications: '',
      });
      setSavingMedication(false);
    }
  };

  const addSeizureTypeRow = async (e) => {
    setSeizureTypeNameArray([]);
    e.preventDefault();

    //level 1
    Object.entries(SeizureTypesList).forEach(([key, value]) => {
      if (key === level1) {
        setSeizureTypeNameArray([]);
        setSeizureTypeNameArray(seizureTypeNameArray.push(value.name));
      }
    });
    //level 2
    Object.entries(level1.length && SeizureTypesList[level1]).forEach(
      ([key, value]) => {
        if (key === level2) {
          setSeizureTypeNameArray(seizureTypeNameArray.push(value.name));
        }
      }
    );
    //level 3
    Object.entries(level2.length && SeizureTypesList[level1][level2]).forEach(
      ([key, value]) => {
        if (key === level3) {
          setSeizureTypeNameArray(seizureTypeNameArray.push(value.name));
        }
      }
    );

    if (
      formFields.seizure_types !== undefined &&
      seizureTypeNameArray.length > 0
    ) {
      setSavingSeizureType(true);

      if (patient !== null) {
        //generate unique id
        const nanoId = nanoid();

        const previousSeizureTypes = patient.seizure_types
          ? [...patient.seizure_types]
          : [];

        formFields.seizure_types = [
          ...previousSeizureTypes,
          {
            id: nanoId,
            name: seizureTypeNameArray,
            checkboxActive: isNewCheckboxActive,
          },
        ];

        await updatePatient({
          variables: {
            id,
            data: { seizure_types: formFields.seizure_types },
          },
        })
          .then(() => {
            setSeizureTypeNameArray([]);
            setIsNewCheckboxActive(false);
            handleClearDropdowns();
          })
          .catch((error) => {
            console.log('ERROR', error);
          });
      }

      setSavingSeizureType(false);
    }
  };

  const handleMedicationDelete = (e) => {
    e.preventDefault();

    setFormFields({
      ...formFields,
      medications: '',
    });
    setDisableDosage(true);
    setDosageValue('');

    if (e.target.parentElement.parentElement.children[0].id) {
      setDeletingMedication(true);
      const currentMedication =
        e.target.parentElement.parentElement.children[0].id;
      const updatedMedications = patient.medications
        .filter((patientMedication) => {
          return patientMedication.generic_name !== currentMedication;
        })
        .map((medication) => medication.id);

      const medicationId = patient.medications.find((patientMedication) => {
        return patientMedication.generic_name === currentMedication;
      }).id;

      const updatedDosage = { ...patient.dosage };

      delete updatedDosage[`"${medicationId}"`];

      updatePatient({
        variables: {
          id,
          data: {
            medications: updatedMedications,
            dosage: updatedDosage,
          },
        },
      }).then(() => {
        setDeletingMedication(false);
      });
    }
  };

  const handleClearDropdowns = () => {
    //descending order to prevent mounting errors as e.g. level 3 reads info from level 2 dropdown
    setLevel3('');
    setLevel2('');
    setLevel1('');
    setIsNewCheckboxActive(false);
  };

  const handleSeizureTypeDelete = (e) => {
    e.preventDefault();
    let itemToDelete;

    if (e.target.parentElement.parentElement.children[0].id) {
      setDeletingSeizureType(true);
      const currentSeizureTypeId =
        e.target.parentElement.parentElement.firstChild.id;

      const updatedSeizureTypes =
        patient &&
        patient.seizure_types &&
        patient.seizure_types
          .map((patientSeizureType) => {
            if (patientSeizureType.id === currentSeizureTypeId) {
              itemToDelete = patientSeizureType;
            }
            return patientSeizureType;
          })
          .filter((patientSeizureType) => {
            return patientSeizureType !== itemToDelete;
          })
          .map((seizureType) => seizureType);

      updatePatient({
        variables: {
          id,
          data: {
            seizure_types: updatedSeizureTypes,
          },
        },
      }).then(() => {
        setDeletingSeizureType(false);
        // for submit function to save updatedSeizureTypes
        setFormFields({ ...formFields, seizure_types: updatedSeizureTypes });
      });
    }
  };

  const handleCheckboxUpdate = (e) => {
    e.preventDefault();

    let savedCheckboxActiveStatus;
    let updatedCheckboxActiveTo;
    let currentCheckboxId = '';

    patient.seizure_types &&
      patient.seizure_types.forEach((savedSeizureType) => {
        currentCheckboxId = e.target.parentElement.children[0].id;

        if (savedSeizureType.id === currentCheckboxId) {
          savedCheckboxActiveStatus = savedSeizureType.checkboxActive;
          updatedCheckboxActiveTo = !savedCheckboxActiveStatus;

          const updatedSeizureType = {
            ...savedSeizureType,
            checkboxActive: updatedCheckboxActiveTo,
          };

          const allSeizureTypes = patient.seizure_types.map(
            (patientSeizureType) => {
              if (patientSeizureType.id === currentCheckboxId) {
                patientSeizureType = {
                  ...updatedSeizureType,
                };
              }
              return patientSeizureType;
            }
          );

          updatePatient({
            variables: {
              id,
              data: { seizure_types: allSeizureTypes },
            },
          })
            .then(
              setFormFields({ ...formFields, seizure_types: allSeizureTypes })
            )
            .catch((error) => {
              console.log('ERROR', error);
            });
        }
      });
  };

  return (
    <div>
      <div>
        <Paper
          key={id}
          css={css`
            padding: 5% 10% 5% 10%;
            box-shadow: none;
            background-color: white;
          `}
        >
          <div
            className="EditPatientForm__stepTitle"
            css={css`
              display: grid;
              grid-template-columns: repeat(4, 1fr);
              column-gap: ${GlobalStyles.columnGap};
              border-bottom: 5px solid ${GlobalStyles.midBlue};
              margin-bottom: 20px;
            `}
          >
            <div
              className="EditPatientForm__stepTitle"
              css={css`
                background-color: ${GlobalStyles.midBlue};
                color: ${GlobalStyles.white};
                height: 50px;
                line-height: 50px;
                font-family: 'roboto-black';
                font-size: 22px;
                padding-left: 15px;
                margin-bottom: -1%;
              `}
            >
              {id === 'new' ? 'ADD NEW PATIENT' : 'EDIT PATIENT'}
            </div>
          </div>
          <div>
            <p
              css={css`
                background-color: ${GlobalStyles.backgroundBlue};
                color: ${GlobalStyles.textBlue};
                padding: 20px;
                line-height: 1.25;
                margin-bottom: 40px;
              `}
            >
              <b>Please Note</b>: All information entered on this page will also
              be displayed on the patient plan, which is viewable by anyone who
              has the link to this plan (i.e. patient’s family, teachers, first
              responders, etc.) All information included in this plan should
              comply with PHSA Privacy Policy and PHIPA regulations.
            </p>
          </div>
          <ValidatorForm
            key={id}
            onSubmit={handleSubmit}
            css={css`
              .MuiFormControl-marginNormal {
                margin-bottom: 0px;
              }
            `}
          >
            <Grid container spacing={2}>
              <Grid item container sm={6}>
                <Grid item container sm={12} css={requiredTitleStyle}>
                  <div className="required" css={inputTitleStyle}>
                    PATIENT FIRST NAME
                  </div>
                </Grid>
                <Grid
                  item
                  container
                  sm={12}
                  direction="column"
                  css={css`
                    background-color: ${GlobalStyles.paleOrange};
                  `}
                >
                  <TextValidator
                    type="text"
                    css={textFieldStyle}
                    value={first_name}
                    validators={['required']}
                    errorMessages={['This field is required']}
                    onChange={handleChange('first_name')}
                    margin="normal"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    id={'inputId1'}
                    inputRef={input1}
                    onKeyDown={handleEnter}
                  />
                </Grid>
              </Grid>
              <Grid item container sm={6}>
                <Grid item container sm={12} css={requiredTitleStyle}>
                  <div className="required" css={inputTitleStyle}>
                    PATIENT LAST NAME
                  </div>
                </Grid>
                <Grid
                  item
                  container
                  sm={12}
                  direction="column"
                  css={css`
                    background-color: ${GlobalStyles.paleOrange};
                  `}
                >
                  <TextValidator
                    type="text"
                    css={textFieldStyle}
                    value={last_name}
                    onChange={handleChange('last_name')}
                    validators={['required']}
                    errorMessages={['This field is required']}
                    margin="normal"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    id={'inputId2'}
                    inputRef={input2}
                    onKeyDown={handleEnter}
                  />
                </Grid>
              </Grid>
              <Grid item container sm={6}>
                <Grid
                  item
                  container
                  sm={12}
                  css={css`
                    border-bottom: 5px solid ${GlobalStyles.navyBlue2};
                  `}
                >
                  <div css={inputTitleStyle}>PREFERRED NAME/NICKNAME</div>
                </Grid>
                <Grid
                  item
                  container
                  sm={12}
                  direction="column"
                  css={css`
                    background-color: ${GlobalStyles.paleOrange};
                  `}
                >
                  <TextValidator
                    type="text"
                    css={textFieldStyle}
                    value={nickname ? nickname : ''}
                    onChange={handleChange('nickname')}
                    margin="normal"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    id={'inputId3'}
                    inputRef={input3}
                    onKeyDown={handleEnter}
                  />
                </Grid>
              </Grid>
              <Grid item container sm={6}>
                <Grid item container sm={12} css={requiredTitleStyle}>
                  <div className="required" css={inputTitleStyle}>
                    DATE OF BIRTH
                  </div>
                </Grid>
                <Grid
                  item
                  container
                  sm={12}
                  direction="column"
                  css={css`
                    background-color: ${GlobalStyles.paleOrange};
                  `}
                >
                  <TextValidator
                    InputProps={{
                      inputProps: { max: '9999-12-31' },
                    }}
                    type="date"
                    css={textFieldStyle}
                    value={DOB}
                    onChange={handleChange('DOB')}
                    validators={[
                      'required',
                      'isDateInPast',
                      'isDateInAfter1900',
                    ]}
                    errorMessages={[
                      'This field is required',
                      'DOB cannot be in the future',
                      'Invalid Date',
                    ]}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    margin="normal"
                    id={'inputId4'}
                    inputRef={input4}
                    onKeyDown={handleEnter}
                  />
                </Grid>
              </Grid>
              <Grid item container sm={12}>
                <Grid item container sm={12} css={requiredTitleStyle}>
                  <div className="required" css={inputTitleStyle}>
                    PARENT/GUARDIAN NAME
                  </div>
                </Grid>
                <Grid
                  item
                  container
                  sm={12}
                  direction="column"
                  css={css`
                    background-color: ${GlobalStyles.paleOrange};
                  `}
                >
                  <TextValidator
                    type="text"
                    css={textFieldStyle2}
                    value={caregiver_name ? caregiver_name : ''}
                    onChange={handleChange('caregiver_name')}
                    validators={['required']}
                    errorMessages={['This field is required']}
                    margin="normal"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    id={'inputId5'}
                    inputRef={input5}
                    onKeyDown={handleEnter}
                  />
                </Grid>
              </Grid>
              <Grid item container sm={12}>
                <Grid item container sm={12} css={requiredTitleStyle}>
                  <div className="required" css={inputTitleStyle}>
                    PARENT/GUARDIAN EMAIL
                  </div>
                </Grid>
                <Grid
                  item
                  container
                  sm={12}
                  direction="column"
                  css={css`
                    background-color: ${GlobalStyles.paleOrange};
                  `}
                >
                  <TextValidator
                    type="text"
                    css={textFieldStyle2}
                    value={caregiver_email ? caregiver_email : ''}
                    onChange={handleChange('caregiver_email')}
                    validators={['required', 'isEmail']}
                    errorMessages={[
                      'This field is required',
                      'Email is not valid',
                    ]}
                    margin="normal"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    id={'inputId6'}
                    inputRef={input6}
                    onKeyDown={handleEnter}
                  />
                </Grid>
              </Grid>
              <Grid item sm={12}>
                <hr
                  css={css`
                    border-top: 7px solid ${GlobalStyles.midBlue};
                    margin-bottom: 4%;
                  `}
                ></hr>
              </Grid>

              <Grid
                item
                container
                sm={6}
                css={css`
                  align-items: center;
                  ${weightNeedsUpdate &&
                  'border-bottom: 5px solid transparent;'}
                `}
              >
                <Grid
                  item
                  container
                  justify={weightNeedsUpdate ? 'space-between' : null}
                  sm={12}
                  css={css`
                    flex-wrap: nowrap;
                    border-bottom: 5px solid ${GlobalStyles.navyBlue2};
                    .required:after {
                      content: '*';
                      color: ${GlobalStyles.white};
                    }
                  `}
                >
                  <div className="required" css={inputTitleStyle}>
                    PATIENT WEIGHT
                  </div>
                  {weightNeedsUpdate && weightUpdateFlag()}
                </Grid>

                <Grid container justify="space-between">
                  <Grid
                    item
                    container
                    sm={9}
                    css={css`
                      background-color: ${GlobalStyles.paleOrange};
                    `}
                    direction="column"
                  >
                    <TextValidator
                      css={textFieldStyle}
                      value={weight}
                      onChange={handleChange('weight')}
                      margin="normal"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      validators={[
                        'required',
                        'positiveWeight',
                        'weightIsZero',
                      ]}
                      errorMessages={[
                        'This field is required',
                        'This field cannot be negative',
                        'Please enter a weight greater than zero',
                      ]}
                      error={errorHelperText.length !== 0}
                      helperText={errorHelperText}
                      id={'inputId7'}
                      inputRef={input7}
                      onKeyDown={handleEnter}
                    />
                  </Grid>
                  <Grid
                    item
                    container
                    sm={2}
                    css={css`
                      background-color: ${GlobalStyles.white};
                      padding-left: 4%;
                    `}
                    direction="column"
                  >
                    <CustomTextField
                      css={textFieldStyle}
                      disabled
                      value={unit_of_weight ? unit_of_weight : 'kg'}
                    />
                  </Grid>
                </Grid>
              </Grid>

              <Grid item container sm={6}>
                <Grid
                  item
                  container
                  sm={12}
                  css={css`
                    border-bottom: 5px solid ${GlobalStyles.navyBlue2};
                  `}
                >
                  <div css={inputTitleStyle}>WEIGHT LAST UPDATED</div>
                </Grid>
                <Grid
                  item
                  container
                  sm={12}
                  css={css`
                    align-items: center;
                    justify-content: space-between;
                    padding: 0;
                    display: grid;
                    grid-template-columns: 1fr auto;
                    @media (max-width: ${GlobalStyles.mobileBreakpoint}) {
                      display: flex;
                      flex-wrap: wrap;
                    }
                  `}
                >
                  <CustomTextField
                    css={dateOfBirthInputStyle}
                    disabled
                    value={
                      id === 'new'
                        ? '-'
                        : moment(weightLastUpdated).format(
                            'MMM D, YYYY | h:mm A'
                          )
                    }
                  />
                  {weightNeedsUpdate && (
                    <Button>
                      <ErrorIcon
                        onClick={() => setWeightModal(true)}
                        style={{ color: GlobalStyles.warningOrange }}
                      ></ErrorIcon>
                    </Button>
                  )}
                </Grid>
              </Grid>

              <Grid item container sm={12}>
                <Grid
                  item
                  container
                  sm={12}
                  css={css`
                    border-bottom: 5px solid ${GlobalStyles.navyBlue2};
                  `}
                >
                  <div css={inputTitleStyle}>DIAGNOSES</div>
                </Grid>
                <Grid
                  item
                  container
                  sm={12}
                  css={css`
                    background-color: ${GlobalStyles.paleOrange};
                  `}
                >
                  <TextField
                    type="text"
                    multiline
                    rows={3}
                    css={textFieldStyle2}
                    value={diagnoses ? diagnoses : ''}
                    onChange={handleChange('diagnoses')}
                    margin="normal"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    id={'inputId8'}
                    inputRef={input8}
                  />
                </Grid>
              </Grid>

              <Grid item container sm={12}>
                <Grid
                  item
                  container
                  sm={12}
                  css={css`
                    border-bottom: 5px solid ${GlobalStyles.navyBlue2};
                    justify-content: space-between;
                  `}
                >
                  <div css={inputTitleStyle}>SEIZURE TYPES</div>
                  {patient === null && (
                    <InputDisabledFlag nameOfField="Seizure Types" />
                  )}
                  <button
                    css={addMoreButtonStyle}
                    onClick={addSeizureTypeRow}
                    disabled={patient === null}
                  >
                    {!savingSeizureType ? (
                      '+ Add Another Seizure Type'
                    ) : (
                      <StyledCircularProgress size="26px" />
                    )}
                  </button>
                </Grid>

                {seizure_types !== null && seizure_types.length ? (
                  <div css={savedItemsContainerStyle}>
                    {patient && patient.seizure_types
                      ? patient.seizure_types.map((savedSeizureType, index) => {
                          let savedSeizureTypeDisplayName = '';
                          if (!savedSeizureType) return null;

                          savedSeizureType.name.forEach((name, index) => {
                            index === 0
                              ? (savedSeizureTypeDisplayName += name)
                              : index === 1
                              ? (savedSeizureTypeDisplayName += ' | ' + name)
                              : index === 2 &&
                                (savedSeizureTypeDisplayName += ' | ' + name);
                          });

                          return (
                            <div css={savedItemContainerStyle} key={index}>
                              <p
                                css={savedMedicationNameStyle}
                                //id is set to use in delete function
                                id={savedSeizureType.id}
                              >
                                {savedSeizureTypeDisplayName}
                              </p>

                              <FormControlLabel
                                css={checkboxAndLabelStyle}
                                control={
                                  isLoading ? (
                                    <StyledCircularProgress
                                      size="18px"
                                      css={css`
                                        margin-right: 10px;
                                      `}
                                    />
                                  ) : (
                                    <CustomCheckbox
                                      disabled={!patient}
                                      checked={savedSeizureType.checkboxActive}
                                      onChange={handleCheckboxUpdate}
                                      name="ActiveState"
                                      //id is set to use in update checkbox function
                                      id={savedSeizureType.id}
                                    />
                                  )
                                }
                                label="Active"
                              />

                              {!deletingSeizureType ? (
                                <DeleteIcon
                                  onClick={handleSeizureTypeDelete}
                                  variant="contained"
                                  color="secondary"
                                  style={deleteIconStyle}
                                />
                              ) : (
                                <button
                                  css={css`
                                    background-color: ${GlobalStyles.white};
                                    border: none;
                                  `}
                                >
                                  <StyledCircularProgress size="26px" />
                                </button>
                              )}
                            </div>
                          );
                        })
                      : null}
                  </div>
                ) : null}

                <Grid item container sm={12}>
                  <SeizureTypesMenu
                    patient={patient}
                    level1={level1}
                    level2={level2}
                    level3={level3}
                    setLevel1={setLevel1}
                    setLevel2={setLevel2}
                    setLevel3={setLevel3}
                    handleClearDropdowns={handleClearDropdowns}
                    handleNewSeizureTypeCheckbox={handleNewSeizureTypeCheckbox}
                    isNewSeizureTypeActive={isNewCheckboxActive}
                  />
                </Grid>
              </Grid>

              <Grid item container sm={12}>
                <Grid
                  item
                  container
                  sm={12}
                  css={css`
                    border-bottom: 5px solid ${GlobalStyles.navyBlue2};
                    justify-content: space-between;
                  `}
                >
                  <div css={inputTitleStyle}>ANTI-SEIZURE MEDICATIONS</div>
                  {patient === null && (
                    <InputDisabledFlag nameOfField="Anti-Seizure Medications" />
                  )}
                  <button
                    css={addMoreButtonStyle}
                    onClick={addMedicationRow}
                    disabled={patient === null}
                  >
                    {!savingMedication ? (
                      '+ Add Another Medication'
                    ) : (
                      <StyledCircularProgress size="26px" />
                    )}
                  </button>
                </Grid>

                {medicationNames.length ? (
                  <div css={savedItemsContainerStyle}>
                    {patient.dosage
                      ? Object.entries(patient.dosage).map(([medicationId]) => {
                          const medicationName = patient.medications.find(
                            (patientMedication) => {
                              return (
                                patientMedication.id ===
                                JSON.parse(medicationId)
                              );
                            }
                          ).generic_name;
                          return (
                            <div
                              css={savedItemContainerStyle}
                              key={medicationId}
                            >
                              <p
                                css={savedMedicationNameStyle}
                                id={medicationName}
                              >
                                {medicationName}
                              </p>
                              <p
                                css={css`
                                  color: ${GlobalStyles.navyBlue};
                                  -webkit-text-fill-color: ${GlobalStyles.navyBlue2};
                                  font-size: 20px;
                                  font-weight: 500;
                                  letter-spacing: 1.25px;
                                  margin: 0px;
                                  padding: 5px 0px 10px 15%;
                                  cursor: default;
                                `}
                              >
                                Dose:
                              </p>
                              <p
                                css={css`
                                  color: ${GlobalStyles.navyBlue};
                                  -webkit-text-fill-color: ${GlobalStyles.navyBlue2};
                                  font-size: 20px;
                                  font-weight: 500;
                                  letter-spacing: 1.25px;
                                  margin: 0px;
                                  padding-left: 10px;
                                  padding-top: 5px;
                                  padding-bottom: 2%;
                                  cursor: default;
                                  width: 100%;
                                  text-align: center;
                                `}
                              >
                                {dosage[medicationId] &&
                                  Object.values(dosage[medicationId].split())}
                              </p>
                              {!deletingMedication ? (
                                <DeleteIcon
                                  onClick={handleMedicationDelete}
                                  variant="contained"
                                  color="secondary"
                                  style={deleteIconStyle}
                                />
                              ) : (
                                <button
                                  css={css`
                                    background-color: ${GlobalStyles.white};
                                    border: none;
                                  `}
                                >
                                  <StyledCircularProgress size="26px" />
                                </button>
                              )}
                            </div>
                          );
                        })
                      : null}
                  </div>
                ) : null}
                <NonRescueMedication
                  medications={formFields.medications}
                  dosage={dosage}
                  handleChange={handleChange}
                  textFieldStyle2={textFieldStyle2}
                  medicationData={medicationData}
                  handleDelete={handleMedicationDelete}
                  dosageValue={dosageValue}
                  isLoading={isLoading}
                  patient={patient}
                  disableDosage={disableDosage}
                />
              </Grid>

              <Grid item container sm={12}>
                <Grid
                  item
                  container
                  sm={12}
                  css={css`
                    border-bottom: 5px solid ${GlobalStyles.navyBlue2};
                  `}
                >
                  <div css={inputTitleStyle}>ALLERGIES</div>
                </Grid>

                <Grid
                  item
                  container
                  sm={12}
                  css={css`
                    background-color: ${GlobalStyles.paleOrange};
                  `}
                >
                  <TextField
                    type="text"
                    multiline
                    rows={3}
                    css={textFieldStyle2}
                    value={allergies ? allergies : ''}
                    onChange={handleChange('allergies')}
                    margin="normal"
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </Grid>
              </Grid>
              <Grid item container sm={12}>
                <Grid
                  item
                  container
                  sm={12}
                  css={css`
                    border-bottom: 5px solid ${GlobalStyles.navyBlue2};
                  `}
                >
                  <div css={inputTitleStyle}>PATIENT NOTES</div>
                </Grid>
                <Grid
                  item
                  container
                  sm={12}
                  css={css`
                    background-color: ${GlobalStyles.paleOrange};
                  `}
                >
                  <TextField
                    type="text"
                    css={textFieldStyle2}
                    multiline
                    rows={3}
                    value={patient_notes ? patient_notes : ''}
                    onChange={handleChange('patient_notes')}
                    margin="normal"
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </Grid>
              </Grid>
              <Grid item container sm={6}>
                <div
                  css={css`
                    line-height: 1.75;
                    width: 100%;
                    height: 32px;
                    margin-top: 10px;
                  `}
                >
                  <Button
                    fullWidth
                    css={css`
                      font-weight: bolder;
                      align-self: center;
                      background-color: ${GlobalStyles.red};
                      color: ${GlobalStyles.white};
                      cursor: pointer;
                      width: 100%;
                      text-decoration: none;
                      border-radius: 0% !important;
                      &:hover {
                        color: ${GlobalStyles.navyBlue};
                        background-color: ${GlobalStyles.red};
                      }
                      height: 32px;
                    `}
                    onClick={handleBack}
                  >
                    CANCEL
                  </Button>
                </div>
              </Grid>
              <Grid item container sm={6}>
                <Snackbar
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                  }}
                  open={open}
                  autoHideDuration={6000}
                  onClose={handleClose}
                  message={'Sorry something went wrong'}
                  action={
                    <Fragment>
                      <IconButton
                        size="small"
                        aria-label="close"
                        color="inherit"
                        onClick={handleClose}
                      >
                        <CloseIcon fontSize="small" />
                      </IconButton>
                    </Fragment>
                  }
                />
                <div
                  css={css`
                    line-height: 1.75;
                    height: 32px;
                    margin-top: 10px;
                    width: 100%;
                  `}
                >
                  <Button
                    type="submit"
                    fullWidth
                    css={css`
                      font-weight: bolder;
                      align-self: center;
                      background-color: ${GlobalStyles.accentOrange};
                      color: ${GlobalStyles.white};
                      cursor: pointer;
                      width: 100%;
                      text-decoration: none;
                      border-radius: 0% !important;
                      height: 32px;
                      &:hover {
                        color: ${GlobalStyles.navyBlue};
                        background-color: ${GlobalStyles.accentOrange};
                      }
                    `}
                  >
                    {!isLoading ||
                    savingMedication ||
                    deletingMedication ||
                    savingMedication ||
                    savingSeizureType ||
                    deletingSeizureType
                      ? 'SAVE'
                      : isLoading &&
                        !savingMedication &&
                        !deletingMedication &&
                        !deletingSeizureType && (
                          <StyledCircularProgress size="26px" />
                        )}
                  </Button>
                </div>
              </Grid>
            </Grid>
            {patient && (
              <UpdateWeightModal
                open={weightModal}
                handleClose={() => setWeightModal(false)}
                weight={patient.weight}
                name={patient.nickname ? patient.nickname : patient.first_name}
                setFormFields={setFormFields}
                weightLastUpdated={weightLastUpdated}
                handleChange={handleChange}
              />
            )}
          </ValidatorForm>
        </Paper>
      </div>
    </div>
  );
}

export default EditPatientForm;
