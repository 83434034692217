/** @jsx jsx */
import { jsx, css } from '@emotion/core';
import {
  Select,
  InputLabel,
  MenuItem,
  Checkbox,
  FormControlLabel,
  withStyles,
} from '@material-ui/core/';
import DeleteIcon from '@material-ui/icons/Delete';
import GlobalStyles from 'assets/GlobalStyles';
import styled from '@emotion/styled';
import SeizureTypesList from 'components/SeizureTypesList';

// CSS
const dropDownStyle = css`
  width: 100%;
  margin: 5px 20px 0 0;
  background-color: ${GlobalStyles.paleOrange};
  height: max-content;

  .MuiInputBase-input {
    color: ${GlobalStyles.navyBlue2};
    font-size: 20px;
    font-weight: 500;
    letter-spacing: 1.25px;
    margin-left: 10px;
  }

  .MuiInputBase-input.Mui-disabled {
    background-color: ${GlobalStyles.disabledGrey};
    width: 100%;
    margin-left: 0;
  }
`;

const rowContainerStyle = css`
  display: flex;
  width: 100%;
  align-items: center;
  padding-top: 10px;
`;

const dropDownTitleStyle = css`
  color: ${GlobalStyles.navyBlue};
  font-family: 'roboto';
`;

const menuContainerStyle = css`
  text-align: center;
  width: 100%;
  padding-right: 15px;
`;

const checkboxAndLabelStyle = css`
  padding-top: 15px;
  color: ${GlobalStyles.navyBlue};
`;

const checkboxDisabledStyle = css`
  padding-top: 15px;
  color: ${GlobalStyles.disabledGrey};
`;

// custom components
const StyledMenuItem = styled(MenuItem)`
  padding-bottom: 0;
`;

const CustomCheckbox = withStyles({
  root: {
    color: GlobalStyles.navyBlue,
    '&$checked': {
      color: GlobalStyles.navyBlue,
    },
  },
  checked: {},
})((props) => <Checkbox color="default" {...props} />);

const SeizureTypesMenu = ({
  patient,
  level1,
  level2,
  level3,
  setLevel1,
  setLevel2,
  setLevel3,
  handleClearDropdowns,
  isNewSeizureTypeActive,
  handleNewSeizureTypeCheckbox,
}) => {
  // CSS that uses props
  const deleteIconStyle = {
    color:
      patient === null
        ? `${GlobalStyles.disabledGrey}`
        : `${GlobalStyles.navyBlue}`,
    cursor: 'pointer',
    alignSelf: 'center',
    marginLeft: '10px',
    paddingTop: '15px',
  };

  // Functions
  const handleLevel1Change = (event) => {
    setLevel1(event.target.value);
  };

  const handleLevel2Change = (event) => {
    setLevel2(event.target.value);
  };

  const handleLevel3Change = (event) => {
    setLevel3(event.target.value);
  };

  return (
    <div css={rowContainerStyle}>
      <div css={menuContainerStyle}>
        <InputLabel id="level1" css={dropDownTitleStyle}>
          Classification*
        </InputLabel>
        <Select
          labelId="level1"
          defaultValue=""
          value={level1}
          onChange={handleLevel1Change}
          css={dropDownStyle}
          disabled={!!level2.length || !!level3.length || patient === null}
        >
          {Object.entries(SeizureTypesList).map(([key, value]) => {
            return (
              <StyledMenuItem key={key} value={key}>
                {value.name}
              </StyledMenuItem>
            );
          })}
        </Select>
      </div>

      <div css={menuContainerStyle}>
        <InputLabel id="level2" css={dropDownTitleStyle}>
          (Optional) Sub-Classification
        </InputLabel>
        <Select
          labelId="level2"
          defaultValue=""
          value={level2 ? level2 : ''}
          onChange={handleLevel2Change}
          css={dropDownStyle}
          disabled={
            level1.length === 0 || level1 === 'unclassified' || !!level3.length
          }
        >
          {Object.entries(
            level1.length
              ? SeizureTypesList[level1]
              : SeizureTypesList.focalOnset
          ).map(([key, value]) => {
            return (
              <StyledMenuItem key={key} value={key}>
                {value.name}
              </StyledMenuItem>
            );
          })}
        </Select>
      </div>

      <div css={menuContainerStyle}>
        <InputLabel id="level3" css={dropDownTitleStyle}>
          (Optional) Type
        </InputLabel>
        <Select
          labelId="level3"
          defaultValue=""
          value={level3 ? level3 : ''}
          onChange={handleLevel3Change}
          css={dropDownStyle}
          disabled={
            level1.length === 0 ||
            level2.length === 0 ||
            level2 === 'aware' ||
            level2 === 'impairedAwareness' ||
            level1 === 'unclassified'
          }
        >
          {Object.entries(
            level2.length
              ? SeizureTypesList[level1][level2]
              : SeizureTypesList.focalOnset
          ).map(([key, value]) => {
            return (
              <StyledMenuItem key={key} value={key}>
                {value.name}
              </StyledMenuItem>
            );
          })}
        </Select>
      </div>

      <div>
        <FormControlLabel
          css={patient ? checkboxAndLabelStyle : checkboxDisabledStyle}
          control={
            <CustomCheckbox
              disabled={!patient || !level1.length}
              checked={isNewSeizureTypeActive}
              onChange={handleNewSeizureTypeCheckbox}
              name="ActiveState"
            />
          }
          label="Active"
        />
      </div>

      <DeleteIcon
        onClick={handleClearDropdowns}
        variant="contained"
        color="secondary"
        style={deleteIconStyle}
      />
    </div>
  );
};

export default SeizureTypesMenu;
