import { useState, Fragment } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { useQuery, useMutation } from '@apollo/client';
import moment from 'moment';
/** @jsx jsx */
import { jsx, css } from '@emotion/core';
import Button from '@material-ui/core/Button';
import GlobalStyles from 'assets/GlobalStyles';
import { GET_ALL_MEDICAL_TEAM_MEMBERS } from 'utils/queries';
import { DELETE_TEAM_MEMBER } from 'utils/mutations';
import TeamContainer from 'components/TeamContainer';
import TeamPageButtons from 'components/TeamPageButtons';

//Loading Skeleton
import ClinicalCareTeamSkeleton from '../components/Skeletons/ClinicalCareTeamSkeleton';

function TeamPage({ isEditing, role }) {
  const history = useHistory();
  const { data, loading } = useQuery(GET_ALL_MEDICAL_TEAM_MEMBERS);
  const [teamMemberIdsChecked, setChecked] = useState([]);
  const [deleteTeamMember, { loading: deleteLoading }] = useMutation(
    DELETE_TEAM_MEMBER,
    {
      refetchQueries: [{ query: GET_ALL_MEDICAL_TEAM_MEMBERS }],
      awaitRefetchQueries: true,
    }
  );
  const [teamMemberToDelete, setTeamMemberToDelete] = useState([]);
  const [clinicalCareTeam, setClinicalCareTeam] = useState([]);

  const isLoading = deleteLoading;

  const handleChange = (id) => () => {
    const newCheckedArray = [...teamMemberIdsChecked];

    if (newCheckedArray.includes(id)) {
      const index = newCheckedArray.indexOf(id);
      newCheckedArray.splice(index, 1);
      if (clinicalCareTeam.includes(id)) {
        setClinicalCareTeam(
          clinicalCareTeam.filter((teamMember) => teamMember.id)
        );
      }
    } else {
      newCheckedArray.push(id);
      setClinicalCareTeam([...clinicalCareTeam, id]);
    }

    setTeamMemberToDelete([...teamMemberToDelete, id]);
    setChecked(newCheckedArray);
  };

  const handleDelete = (e) => {
    e.preventDefault();
    const date = new Date().getTime();
    const formatDate = moment(date).format();

    teamMemberIdsChecked.forEach(async (checkedTeamMemberId) => {
      await deleteTeamMember({
        variables: { id: checkedTeamMemberId, data: formatDate },
      }).then(() => {
        setTeamMemberToDelete([]);
        setClinicalCareTeam([]);
        history.push(`/team`);
      });
    });
  };

  const buttonOneOnClick = () => {
    history.push(`/team`);
  };

  if (loading) {
    return <ClinicalCareTeamSkeleton />;
  } else if ((!loading && !data) || (!loading && !role)) {
    return <p>Higher permissions needed to view this page</p>;
  } else {
    return (
      <Fragment>
        <div
          className="patient-list__label"
          css={css`
            padding: 7px 18px 7px 18px;
            background-color: ${GlobalStyles.midBlue};
            color: ${GlobalStyles.white};
            font-size: 20px;
            display: inline-block;
            font-family: 'roboto-black';
          `}
        >
          CLINICAL CARE TEAM
        </div>
        <div
          css={css`
            border: ${GlobalStyles.outerContainerBorder};
            background-color: white;
          `}
        >
          {isEditing && (
            <Button
              fullWidth
              component={Link}
              to={`/team-member/new`}
              css={css`
                margin-bottom: 20px;
                background-color: ${GlobalStyles.paleOrange};
                color: ${GlobalStyles.navyBlue};

                font-weight: bold;
                text-transform: none;
                &:hover {
                  color: ${GlobalStyles.navyBlue};
                  background-color: ${GlobalStyles.accentOrange};
                }
              `}
            >
              + add team member
            </Button>
          )}

          <div
            className="patientTeam__listContainer"
            css={css`
              height: 50vh;
              overflow-y: auto;
              margin: 80px 53px 80px 53px;
              /* Scrollbar for Firefox */
              /* prettier-ignore */
              scrollbar-color: ${GlobalStyles.babyBlue} ${GlobalStyles.backgroundBlue};

              &::-webkit-scrollbar {
                width: 15px;
              }
              &::-webkit-scrollbar-thumb {
                height: 40%;
                border-radius: 10px;
                background-color: ${GlobalStyles.babyBlue};
                box-shadow: inset 0 0 10px 10px ${GlobalStyles.babyBlue};
                border: 3px solid ${GlobalStyles.backgroundBlue};
              }
              &::-webkit-scrollbar-track {
                border-radius: 10px;
                background-color: ${GlobalStyles.backgroundBlue};
              }
            `}
          >
            {data.users.map((member) => {
              return (
                <div
                  className="patientTeam__container"
                  key={member.id}
                  css={css`
                    display: grid;
                    justify-items: start;
                    grid-template-columns: 50px 100px 1fr;
                    margin: 0px 40px 48px 40px;
                  `}
                >
                  <TeamContainer
                    member={member}
                    isEditing={isEditing}
                    setTeamMemberIdsChecked={setChecked}
                    teamMemberIdsChecked={teamMemberIdsChecked}
                    handleChange={handleChange}
                    clinicalCareTeam={clinicalCareTeam}
                  />
                </div>
              );
            })}
          </div>

          {!isEditing && role === 'super_admin' && (
            <div
              css={css`
                display: flex;
                justify-content: center;
                width: 100%;
              `}
            >
              <Button
                component={Link}
                to={`/team/edit`}
                css={css`
                  background-color: ${GlobalStyles.accentOrange};
                  font-weight: bolder;
                  color: ${GlobalStyles.white};
                  width: 423px;
                  border-radius: 0%;
                  margin-bottom: 2rem;
                  height: 32px;
                  &:hover {
                    color: ${GlobalStyles.navyBlue};
                    background-color: ${GlobalStyles.accentOrange};
                  }
                `}
              >
                Add/Delete
              </Button>
            </div>
          )}

          {isEditing && role === 'super_admin' && (
            <div
              css={css`
                display: flex;
                justify-content: space-between;
                margin: 0 7.5% 67px 5%;
              `}
            >
              <TeamPageButtons
                isLoading={isLoading}
                buttonOneText={'Cancel'}
                buttonTwoText={'Delete'}
                buttonOneOnClick={buttonOneOnClick}
                buttonTwoOnClick={handleDelete}
              />
            </div>
          )}
        </div>
      </Fragment>
    );
  }
}

export default TeamPage;
