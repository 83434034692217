/** @jsx jsx */
import { jsx, css } from '@emotion/core';
import { Fragment, useState } from 'react';
import { useParams } from 'react-router-dom';
import {
  CircularProgress,
  Button,
  Grid,
  useMediaQuery,
  makeStyles,
} from '@material-ui/core';
import NonHospitalPlanBlueCard from 'components/PatientView/NonHospitalPlanBlueCard.js';
import MobileAppHeader from 'components/PatientView/MobileAppHeader';
import GlobalStyles from 'assets/GlobalStyles';
import BlueCardNoData from 'components/PatientView/BlueCardNoData.js';
import handleDownload from 'components/PatientView/utils/handleDownload.js';

//transparent and yellow button styling
const useStyles = makeStyles(() => ({
  root: {
    background: `${GlobalStyles.white}`,
    color: `${GlobalStyles.whiteButton}`,
    border: `2px solid ${GlobalStyles.accentOrange}`,
    fontFamily: 'poppins-semi-bold',
    fontSize: '18px',
    padding: '1% 5%',
    borderRadius: '12px',
    marginRight: '3%',
    marginBottom: '2%',
    minWidth: '43%',
    '&:hover': {
      background: `${GlobalStyles.darkWhite}`,
    },
  },
  yellowButton: {
    color: `${GlobalStyles.whiteButton}`,
    backgroundColor: GlobalStyles.accentOrange,
    border: ` 2px solid ${GlobalStyles.accentOrange}`,
    fontFamily: 'poppins-semi-bold',
    fontSize: '18px',
    padding: '1% 4%',
    borderRadius: '12px',
    '&:hover': {
      background: `${GlobalStyles.mutedOrange}`,
    },
    minWidth: '26vw',
  },
}));

function NonHospitalPlan({ patient, planSteps }) {
  const isMobile = useMediaQuery(
    `(max-width:${GlobalStyles.mobileBreakpoint})`
  );
  const classes = useStyles();
  const { step: stepNumber } = useParams();

  let mobileStepTitle = stepNumber ? 'STEP ' + stepNumber : null;
  if (stepNumber) {
    if (stepNumber === '4') {
      mobileStepTitle = 'Additional notes';
    } else {
      mobileStepTitle = 'STEP ' + stepNumber;
    }
  }

  const [pngLoading, setPNGLoading] = useState(false);
  const [pdfLoading, setPDFLoading] = useState(false);

  const mobileStep =
    planSteps && stepNumber
      ? planSteps.filter((step) => step.step_number.toString() === stepNumber)
      : null;

  return (
    <div
      className="Out-of-hospital Plan"
      css={css`
        margin-top: 10%;
        padding: 0 6%;
        overflow: hidden;
        @media (max-width: ${GlobalStyles.mobileBreakpoint}) {
          padding: 0 0 0 10%;
          margin-top: 0;
        }
      `}
    >
      {isMobile && <MobileAppHeader patient={patient} />}
      <div
        css={css`
          font-family: 'roboto-bold';
          font-size: 30px;
          line-height: 35px;
          color: ${GlobalStyles.darkBlue};
          margin-bottom: 3%;
          text-align: center;
          @media (max-width: ${GlobalStyles.mobileBreakpoint}) {
            margin-left: 20%;
            text-align: left;
            font-size: 18px;
            line-height: 21px;
            position: relative;
            z-index: 0;
          }
        `}
      >
        {isMobile ? (
          'Out-of-Hospital Plan'
        ) : (
          <p>
            {patient.nickname}'s{' '}
            <span
              css={css`
                color: ${GlobalStyles.midBlue};
              `}
            >
              Out-of-Hospital Plan
            </span>
          </p>
        )}
      </div>
      <Fragment>
        {planSteps === undefined || planSteps === null || !planSteps.length ? (
          isMobile ? (
            //Mobile NO DATA CARD
            <Fragment>
              <Grid
                item
                xs={12}
                css={css`
                  margin-top: 10%;
                  overflow: hidden;
                  position: relative;
                  z-index: 0;
                  margin-bottom: 10%;
                `}
              >
                <BlueCardNoData
                  nickname={patient.nickname}
                  title={'NO OUT-OF-HOSPITAL PLAN'}
                  plan={'an out-of-hospital plan'}
                />
              </Grid>
            </Fragment>
          ) : (
            //Desktop NO DATA CARD
            <Grid
              item
              xs={12}
              css={css`
                margin-top: 10%;
                overflow: hidden;
                position: relative;
                z-index: 0;
              `}
            >
              <div
                css={css`
                  display: flex;
                  justify-content: center;
                `}
              >
                <BlueCardNoData
                  nickname={patient.nickname}
                  title={'NO OUT-OF-HOSPITAL PLAN'}
                  plan={'an out-of-hospital plan'}
                />
              </div>
            </Grid>
          )
        ) : !isMobile ? (
          <Fragment>
            {planSteps && (
              <div
                className="NonHospitalPlan__steps-container"
                css={css`
                  margin-bottom: 10%;
                  padding: 0%;
                  @media (max-width: ${GlobalStyles.mobileBreakpoint}) {
                    padding: 5% 6% 0% 6%;
                  }
                `}
              >
                <div
                  className="NonHospitalPlan__blueboxes"
                  css={css`
                    display: flex;
                    justify-content: center;
                  `}
                >
                  {planSteps.map((step) => {
                    const { step_number } = step;

                    const stepTitle =
                      step_number === 4
                        ? 'Additional notes'
                        : 'STEP ' + step_number;
                    return step_number === 4 ? (
                      ''
                    ) : (
                      <div key={step_number}>
                        <Grid
                          className="NonHospitalPlan__steps-container_Grid"
                          container
                          css={css`
                            flex-wrap: nowrap;
                          `}
                        >
                          <Grid
                            className="NHPS_step-container-2ndGrid"
                            item
                            md={4}
                            css={css`
                              margin-top: 5%;
                              margin-right: 20px;
                            `}
                          >
                            <NonHospitalPlanBlueCard
                              title={stepTitle}
                              step={step}
                              totalSteps={planSteps.length}
                              planSteps={planSteps ? planSteps : null}
                            />
                          </Grid>
                        </Grid>
                      </div>
                    );
                  })}
                </div>
                {planSteps[planSteps.length - 1].step_number === 4 &&
                  planSteps[planSteps.length - 1].notes
                    .trim()
                    .replace(/^\s*\n/gm, '') !== '' && ( // render step 4 in new separate grid and only if it is not empty
                    <Grid
                      key={4}
                      className="NonHospitalPlan__steps-container_Grid"
                      container
                      css={css`
                        flex-wrap: nowrap;
                        width: 33%;
                        margin-left: 19%;
                      `}
                    >
                      <Grid
                        className="NHPS_step-container-2ndGrid"
                        item
                        md={4}
                        css={css`
                          margin-top: 0;
                          margin-right: 20px;
                        `}
                      >
                        <NonHospitalPlanBlueCard
                          css={css`
                            width: 10%;
                          `}
                          title={'Additional notes'}
                          step={planSteps[planSteps.length - 1]}
                          totalSteps={planSteps.length}
                          planSteps={planSteps ? planSteps : null}
                        />
                      </Grid>
                    </Grid>
                  )}
                <Grid
                  container
                  css={css`
                    padding: 4% 0;
                  `}
                  justify={'space-between'}
                >
                  <Grid item container md={7}>
                    <Button
                      className={classes.root}
                      onClick={handleDownload(
                        'pdf',
                        patient,
                        setPDFLoading,
                        setPNGLoading
                      )}
                    >
                      {!pdfLoading ? (
                        'Download PDF'
                      ) : (
                        <CircularProgress
                          size="28px"
                          css={css`
                            .MuiCircularProgress-colorPrimary {
                              color: ${GlobalStyles.navyBlue};
                            }
                          `}
                        />
                      )}
                    </Button>
                    <Button
                      className={classes.root}
                      onClick={handleDownload(
                        'png',
                        patient,
                        setPDFLoading,
                        setPNGLoading
                      )}
                    >
                      {!pngLoading ? (
                        'Download PNG'
                      ) : (
                        <CircularProgress
                          size="28px"
                          css={css`
                            .MuiCircularProgress-colorPrimary {
                              color: ${GlobalStyles.navyBlue};
                            }
                          `}
                        />
                      )}
                    </Button>
                  </Grid>
                  <a
                    href="#PVHospitalPlan"
                    css={css`
                      text-decoration: none;
                    `}
                  >
                    <Button className={classes.yellowButton}>
                      {`Go to ${patient.nickname}'s Hospital Plan`}
                    </Button>
                  </a>
                </Grid>
              </div>
            )}
          </Fragment>
        ) : (
          <NonHospitalPlanBlueCard
            title={mobileStepTitle}
            step={mobileStep[0]}
            totalSteps={planSteps.length}
            isMobile
            planSteps={planSteps ? planSteps : null}
          />
        )}
      </Fragment>
    </div>
  );
}

export default NonHospitalPlan;
