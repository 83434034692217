import { ApolloClient, HttpLink, InMemoryCache } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';

const customFetch = (_uri, options) => {
  return fetch(
    `${process.env.REACT_APP_API_URL}/graphql?user=${localStorage.getItem(
      'user_id'
    )}`,
    options
  );
};
export const httpLink = new HttpLink({
  uri: `${process.env.REACT_APP_API_URL}/graphql?user=${localStorage.getItem(
    'user_id'
  )}`,
  fetch: customFetch,
});

export const authLink = setContext((_, previousContext) => {
  const { headers } = previousContext;
  const token = localStorage.getItem('token');
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : '',
    },
  };
});

export const client = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache({
    typePolicies: {
      Query: {
        fields: {
          users: {
            merge(existing, incoming) {
              return incoming;
            },
          },
          patients: {
            merge(existing, incoming) {
              return incoming;
            },
          },
          teamMembers: {
            merge(existing, incoming) {
              return incoming;
            },
          },
          medications: {
            merge(existing, incoming) {
              return incoming;
            },
            medication: {
              images: {
                merge(existing, incoming) {
                  return incoming;
                },
              },
            },
          },
        },
      },
      Medication: {
        fields: {
          images: {
            merge(existing, incoming) {
              return incoming;
            },
          },
        },
      },
    },
  }),
});
