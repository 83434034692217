/** @jsx jsx */
import { jsx, css } from '@emotion/core';
import GlobalStyles from 'assets/GlobalStyles';
import { Link } from 'react-router-dom';

function PrivacyPolicyLink() {
  return (
    <Link
      to="/privacy-policy"
      css={css`
        text-decoration: none;
        margin-left: auto;
        margin-right: calc(100vw / 12);
        line-height: 56px;
        color: ${GlobalStyles.white};
      `}
    >
      PRIVACY POLICY
    </Link>
  );
}

export default PrivacyPolicyLink;
