import { Fragment } from 'react';
/** @jsx jsx */
import { jsx, css } from '@emotion/core';
import GlobalStyles from 'assets/GlobalStyles';

function PrivacyPolicyPage() {
  return (
    <Fragment>
      <div
        className="patient-list__label"
        css={css`
          padding: 7px 18px 7px 18px;
          background-color: ${GlobalStyles.midBlue};
          color: ${GlobalStyles.white};
          font-size: 20px;
          display: inline-block;
          font-family: 'roboto-black';
        `}
      >
        PRIVACY POLICY
      </div>
      <div
        css={css`
          border: ${GlobalStyles.outerContainerBorder};
          background-color: white;
        `}
      >
        <div
          className="patientTeam__listContainer"
          css={css`
            height: 50vh;
            word-break: break-word;
            overflow-y: auto;
            margin: 80px 53px 80px 53px;
            padding-right: 20px;
            /* Scrollbar for Firefox */
            /* prettier-ignore */
            scrollbar-color: ${GlobalStyles.babyBlue} ${GlobalStyles.backgroundBlue};

            &::-webkit-scrollbar {
              width: 15px;
            }
            &::-webkit-scrollbar-thumb {
              height: 40%;
              border-radius: 10px;
              background-color: ${GlobalStyles.babyBlue};
              box-shadow: inset 0 0 10px 10px ${GlobalStyles.babyBlue};
              border: 3px solid ${GlobalStyles.backgroundBlue};
            }
            &::-webkit-scrollbar-track {
              border-radius: 10px;
              background-color: ${GlobalStyles.backgroundBlue};
            }
            h1,
            h2,
            h3,
            h4,
            h5 {
              color: ${GlobalStyles.babyBlue};
            }
            h2 {
              margin: 3.5rem 0 1rem;
            }
            h3,
            h4,
            h5 {
              margin: 2rem 0 1rem;
            }
            h5 {
              font-size: 1rem;
            }
            p {
              margin: 0;
            }
          `}
        >
          <p>
            Collection, use, disclosure and retention of personal information is
            subject to, and must comply with, the provisions of the{' '}
            <a
              href="http://www.bclaws.ca/EPLibraries/bclaws_new/document/ID/freeside/96165_01"
              target="_blank"
              rel="noreferrer noopener"
            >
              BC Freedom of Information and Protection of Privacy Act
            </a>{' '}
            (FIPPA). <br />
            The purpose of this privacy notice is to describe our practices
            regarding personal information use and collection through Seizure
            Action Plan (SAP). Please note that we do not disclose, give, sell
            or transfer any personal information about our website visitors
            unless required for law enforcement or otherwise provided for by
            law.
            <br />
            <br />
            We reserve the right to update this policy at any time. Users are
            responsible for reviewing this document periodically for changes.
          </p>

          <h2>What is personal information?</h2>
          <p>
            Personal information is recorded information about an identifiable
            individual other than business contact information and would
            include, but is not limited to, such things as an individual&apos;s
            name, address, birth date, email address, and phone number.
          </p>

          <h3>Collection &amp; use of information</h3>
          <p>
            Seizure Action Plan (SAP) is authorized to collect personal
            information via this website under section 26 (c) of FIPPA for the
            purposes described below. Information is collected from our
            websites, either directly from you or automatically.
          </p>

          <h4>Information collected directly from you</h4>
          <p>
            Personal information is collected through our websites in certain
            circumstances, such as when you send an email message to us or
            complete a questionnaire, form, or other online survey found on our
            websites. <br />
            <br />
            We only use the personal information you provide to us through our
            websites for purposes consistent with its collection.
            <br />
            <br />
            You should also be aware that information provided by you becomes a
            record of the organization and is subject to the access and privacy
            protection requirements set out in FIPPA. <br />
            <br />
            This information may be further collected, used and disclosed in
            accordance with that Act or as otherwise required by law. Under this
            legislation you are also entitled to access and correct any of your
            personal information that is within our custody and control. You may
            make such requests by contacting the privacy representative listed
            on this page.
          </p>
          <h4>Information collected automatically</h4>
          <p>
            Some of our web pages use &quot;cookies,&quot; which are small
            amounts of information stored by your web browser software on your
            work station. <br />
            <br />
            Cookies are designed to track browsing history on a website
            generally for the convenience of the user. They help websites
            &quot;remember&quot; what areas of a website a browser has accessed
            or used previously. Cookies can, however, be misused, and have
            become a privacy concern for some because they can be used to record
            and analyze an individual&apos;s broader browsing history.
            <br />
            <br />
            The cookies we use on our websites and what we use them for are
            described below. <br />
            <br />
            This site operates on &quot;implied consent,&quot; which means that
            by visiting and clicking through the site, we assume you agree with
            this usage. If you do not agree, then you should not use our sites,
            or you can delete the cookies after visiting our sites, or use your
            browser&apos;s anonymous usage setting (called &quot;Incognito&quot;
            in Chrome, &quot;In Private&quot; in Internet Explorer,
            &quot;Private Browsing&quot; in Firefox and Safari). <br />
            <br />
            More information on cookies, what they are used for and how to
            delete cookies after visiting this site can be found at{' '}
            <a
              href="http://www.allaboutcookies.org/cookies/session-cookies-used-for.html"
              target="_blank"
              rel="noreferrer noopener"
            >
              http://www.allaboutcookies.org/cookies/session-cookies-used-for.html
            </a>
            .<br />
            <br />
            It&apos;s important to know that cookies are plain text files and
            cannot execute files or make copies of themselves. They do not carry
            or deliver viruses and cannot scan or retrieve your personal
            information. <br />
            <br />
            There are two types of cookie - <b>Session</b> and <b>Persistent</b>
            .
          </p>

          <h5>Session cookies</h5>
          <p>
            Session cookie information is deleted as soon as you leave our
            websites. The use of temporary session cookies is solely for the
            purpose of allowing the user to interact with our websites in an
            effective manner.
          </p>

          <h5>Persistent cookies</h5>
          <p>
            In a few cases our website may use persistent cookies to store
            information for longer periods than the current session.
          </p>

          <h2>Analytics &amp; safeguards</h2>

          <h3>Analytics</h3>
          <p>
            Seizure Action Plan (SAP) uses Google Analytics, a web analytics
            service provided by Google, Inc. (Google).
            <br />
            Google Analytics uses first-party cookies to track visitor
            interactions. The information generated by the cookie about your use
            of this website (including your IP address) will be transmitted to
            and stored by Google on servers in the United States. Google will
            use this information for the purpose of evaluating your use of this
            website, compiling reports on website activity for the website
            operators and providing other services relating to website activity
            and internet usage. Google may also transfer this information to
            third parties where required to do so by law or where such third
            parties process the information on Google&apos;s behalf.
            <br />
            <br />
            You may refuse the use of cookies by selecting the appropriate
            settings on your browser - however please note that if you do this
            you may not be able to use the full functionality of this website.
            <br />
            <br />
            Seizure Action Plan (SAP) uses IP masking within Google Analytics to
            anonymize personal information collected by Google. IP masking is a
            customization which removes the last octet of the visitor&apos;s IP
            address prior to its use and storage by Google.
            <br />
            <br />
            Website visitors who do not want to be tracked by Google Analytics
            can download the Google Analytics Opt-out Browser Add-on. The add-on
            communicates with the Google Analytics JavaScript (ga.js) to
            indicate that information about the website visit should not be sent
            to Google Analytics. The Google Analytics Opt-out Browser Add-on
            does not prevent information from being sent to the website itself
            or to other web analytics services.
            <br />
            <br />
            For more information visit the{' '}
            <a
              href="https://policies.google.com/technologies/partner-sites"
              target="_blank"
              rel="noopener noreferrer"
            >
              Google Analytics Overview page
            </a>
            .
          </p>

          <h3>Safeguards</h3>
          <p>
            Seizure Action Plan (SAP) is obligated to protect your personal
            information by making reasonable security arrangements against such
            risks as unauthorized access, collection, use, disclosure, or
            disposal. Security measures have been integrated into the design,
            implementation, and day-to-day operating practices as part of
            Seizure Action Plan (SAP)&apos;s continuing commitment to the
            protection of personal information it holds.
          </p>

          <h2>Related Links</h2>
          <p>
            Seizure Action Plan (SAP) websites contains links to other websites.
            Seizure Action Plan (SAP) is not responsible for the privacy
            practices of these websites or the accuracy or reliability of any
            content found on such sites.
          </p>
        </div>
      </div>
    </Fragment>
  );
}

export default PrivacyPolicyPage;
