/** @jsx jsx */
import { jsx, css } from '@emotion/core';
import { Fragment, useContext } from 'react';
import moment from 'moment';
import StepContext from 'components/Steps/StepContext';
import GlobalStyles from 'assets/GlobalStyles';
import TextField from '@material-ui/core/TextField';

const boldTextStyling = css`
  font-weight: bolder;
`;

const paragraphTextStyling = css`
  margin-top: 0px;
  font-family: 'roboto-light';
  font-size: 21px;
`;

function ViewStepTwoAndThree() {
  const { stepState } = useContext(StepContext);
  const { stepNumber, step, formFields, patientData } = stepState;
  const {
    total_time_in_minutes,
    administered_dose,
    estimated_weight_dose_per_kg,
    medication,
    length_of_current_seizure_time_in_minutes,
    total_number_of_seizures,
    based_on_patients_weight,
    step_weight_last_updated,
  } = formFields;

  return (
    <Fragment>
      {patientData &&
        step_weight_last_updated < patientData.patient.weight_last_updated && (
          <h4
            css={css`
              margin-bottom: 12px;
              font-family: 'roboto';
              font-size: 14px;
              color: ${GlobalStyles.red};
            `}
          >
            As the patient&#39;s weight was updated, the dosage amount was
            reset. Please update the dosage amount.
          </h4>
        )}
      <h4
        css={css`
          margin-bottom: 12px;
          font-family: 'roboto-black';
          font-size: 16px;
        `}
        data-cy={`read-seizure-time-and-frequency-inputs-step-${stepNumber}`}
      >
        IF THE CHILD IS {stepNumber === 3 && 'STILL'} SEIZING AT{' '}
        {length_of_current_seizure_time_in_minutes
          ? length_of_current_seizure_time_in_minutes
          : stepNumber === 2
          ? 0
          : ''}{' '}
        MINS
        {stepNumber === 3 && ' (AND BREATHING IS STABLE)'}
        {stepNumber === 2 && (
          <span>
            {' '}
            OR HAS {total_number_of_seizures
              ? total_number_of_seizures
              : ''}{' '}
            SEIZURES IN {total_time_in_minutes ? total_time_in_minutes : ''}{' '}
            MINS
          </span>
        )}
      </h4>

      <div
        css={css`
          margin-top: 0px;
          margin-bottom: 1em;
          font-family: 'roboto-light';
          font-size: 21px;
          @media (min-width: 1070px) {
            display: flex;
            align-items: center;
          }
        `}
        data-cy={`read-administered-dose-step-${stepNumber}`}
      >
        Give {medication ? step.medication.generic_name : ''} at a dosage of{' '}
        {patientData &&
        step_weight_last_updated < patientData.patient.weight_last_updated ? (
          <TextField
            type="text"
            css={css`
              background-color: ${GlobalStyles.formInputGrey};
              width: 90px;
              margin: 0 10px;
              display: inline-block;
              color: ${GlobalStyles.textBlue};
              .MuiFormHelperText-root.Mui-error {
                background-color: ${GlobalStyles.white};
              }
            `}
            disabled={true}
            value={''}
          />
        ) : administered_dose ? (
          Number(administered_dose).toFixed(2)
        ) : (
          ''
        )}
        {}mg{' '}
      </div>
      {stepNumber === 2 && (
        <Fragment>
          <p css={paragraphTextStyling}>
            Based on{' '}
            <span css={boldTextStyling}>{`${
              patientData && patientData.patient && patientData.patient.nickname
            }'s`}</span>{' '}
            weight of{' '}
            <span css={boldTextStyling}>{based_on_patients_weight} kg</span>{' '}
            {`(on 
  ${moment(step_weight_last_updated).format('MMM D, YYYY')}) and the`}
          </p>
          <p
            data-cy={`read-est-dose-step-${stepNumber}`}
            css={paragraphTextStyling}
          >{`estimated weight dosage of ${
            estimated_weight_dose_per_kg
              ? estimated_weight_dose_per_kg.toFixed(2)
              : ''
          } mg/kg`}</p>
        </Fragment>
      )}
    </Fragment>
  );
}

export default ViewStepTwoAndThree;
