/** @jsx jsx */
import { jsx, css } from '@emotion/core';
import Skeleton from '@material-ui/lab/Skeleton';
import GlobalStyles from 'assets/GlobalStyles';
import Grid from '@material-ui/core/Grid';

const PatientTeamSkeleton = () => {
  return (
    <Grid className="patient-tab-skeleton">
      <div
        css={css`
          display: grid;
          grid-template-columns: auto 1fr;
        `}
      >
        <div
          className="patient-tab-skeleton-body"
          css={css`
            padding: 40px;
            border: ${GlobalStyles.outerContainerBorder};
            background-color: ${GlobalStyles.white};
            overflow-y: scroll;
          `}
        >
          <div
            css={css`
              min-width: 77vw;
              padding-top: 40px;
            `}
          ></div>
          {Array(7)
            .fill()
            .map((item, index) => (
              <Grid
                container
                className="clinical-team-skeleton"
                direction="row"
                justify="space-between"
                key={index}
              >
                <Skeleton height={150} width={'10vw'} />
                <Skeleton height={150} width={'55vw'} />
              </Grid>
            ))}
        </div>
      </div>
    </Grid>
  );
};

export default PatientTeamSkeleton;
