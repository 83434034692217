/** @jsx jsx */
import { jsx, css } from '@emotion/core';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from 'react-router-dom';
import { StylesProvider } from '@material-ui/core/styles';
import { useQuery } from '@apollo/client';
import { CURRENT_USER } from 'utils/queries';
import { useState, useEffect } from 'react';
import GlobalStyles from 'assets/GlobalStyles';
import NavBar from 'components/NavBar';
import Footer from 'components/Footer';
import LoginPage from 'pages/LoginPage';
import PatientList from 'pages/PatientList';
import PatientTeam from 'pages/PatientTeam';
import PatientTeamAddPage from 'pages/PatientTeamAddPage';
import PatientTeamEditPage from 'pages/PatientTeamEditPage';
import AddMedicationForm from 'components/AddMedicationForm';
import PatientDetailsPage from 'pages/PatientDetailsPage';
import MedicationList from 'pages/MedicationList';
import MedicationPage from 'pages/MedicationPage';
import TeamPage from 'pages/TeamPage';
import ResetPassword from 'pages/ResetPassword';
import PatientViewPage from 'pages/PatientViewPage';
import PatientPlanPage from 'pages/PatientPlanPage';
import PatientLogPage from 'pages/PatientLogPage';
import EditTeamMemberPage from 'pages/EditTeamMemberPage';
import NewTeamMemberPage from 'pages/NewTeamMemberPage';
import CMSWarningPage from 'pages/CMSWarningPage';
import PrivacyPolicyPage from 'pages/PrivacyPolicyPage';

function AppContainer() {
  //query for current useRole to get this on initial load - this is needed as localStorage doesn't upload app on initial login.
  const { data } = useQuery(CURRENT_USER);
  //acess localStorage to get get role even after refresh and clear cache - this is needed as above query will be wiped from the cache on refresh.
  const localStorageRole = localStorage.getItem('role');
  const role = data ? data.role : localStorageRole;
  const location = window.location.href;
  const [width, setWidth] = useState(window.innerWidth);
  // GlobalStyles won't work because it needs to be a number for window.innerWidth
  const mobileBreakpoint = 960;

  useEffect(() => {
    window.addEventListener('resize', () => setWidth(window.innerWidth));
  }, []);

  if (location.includes('patient-view')) {
    return (
      <StylesProvider injectFirst>
        <Router>
          <Route path="/patient-view/:id">
            <PatientViewPage />
          </Route>
        </Router>
      </StylesProvider>
    );
  } else
    return (
      <StylesProvider injectFirst>
        <Router>
          <div
            css={css`
              background-color: ${GlobalStyles.backgroundBlue};
              height: 100vh;
              display: grid;
              grid-template-columns: repeat(12, 1fr);
              column-gap: ${GlobalStyles.columnGap};
              grid-template-rows: 100px 1fr auto; //100px height of navbar, auto for footer
            `}
          >
            <NavBar role={role} />
            <div
              className="app-container-body"
              css={css`
                margin-top: 50px;
                grid-column: 2 / span 10;
                padding-bottom: 40px;
                background-color: ${GlobalStyles.backgroundBlue};
              `}
            >
              <Switch>
                <Route path="/" exact>
                  {width <= mobileBreakpoint ? (
                    <Redirect to="/mobile" />
                  ) : role && width > mobileBreakpoint ? (
                    <Redirect to="/patient-list" />
                  ) : (
                    !role &&
                    width > mobileBreakpoint && <Redirect to="/login" />
                  )}
                </Route>
                <Route path="/mobile">
                  <CMSWarningPage />
                </Route>
                <Route path="/login">
                  <LoginPage />
                </Route>
                <Route path="/reset-password" exact>
                  <ResetPassword />
                </Route>
                <Route path="/patient-list" exact>
                  <PatientList role={role} />
                </Route>
                <Route path="/medication-list" exact>
                  <MedicationList role={role} />
                </Route>

                <Route path="/patient/:id/details" exact>
                  <PatientDetailsPage isEditing={false} role={role} />
                </Route>
                <Route path="/patient/:id/details/edit" exact>
                  <PatientDetailsPage isEditing role={role} />
                </Route>
                <Route path="/patient/new/details/edit" exact>
                  <PatientDetailsPage isEditing role={role} />
                </Route>
                <Route path="/patient/:id/plan/nonhospital" exact>
                  <PatientPlanPage isEditing={false} role={role} />
                </Route>
                <Route path="/patient/:id/plan/hospital" exact>
                  <PatientPlanPage isEditing={false} role={role} />
                </Route>
                <Route
                  path="/patient/:id/plan/nonhospital/edit/:currentStep"
                  exact
                >
                  <PatientPlanPage isEditing role={role} />
                </Route>
                <Route
                  path="/patient/:id/plan/hospital/edit/:currentStep"
                  exact
                >
                  <PatientPlanPage isEditing role={role} />
                </Route>
                <Route path="/patient/:id/log" exact>
                  <PatientLogPage />
                </Route>
                <Route path="/patient/:id/team" exact>
                  <PatientTeam isEditing={false} />
                </Route>
                <Route path="/patient/:id/team/edit" exact>
                  <PatientTeamEditPage isEditing />
                </Route>
                <Route path="/patient/:id/team/add" exact>
                  <PatientTeamAddPage isEditing />
                </Route>

                <Route path="/medication/:id" exact>
                  <MedicationPage isEditing={false} />
                </Route>
                <Route path="/medication/:id/edit" exact>
                  <MedicationPage isEditing />
                </Route>
                <Route path="/medication-new" exact>
                  <AddMedicationForm title="Add new medication" />
                </Route>

                <Route path="/team" exact>
                  <TeamPage isEditing={false} role={role} />
                </Route>
                <Route path="/team/edit" exact>
                  <TeamPage isEditing role={role} />
                </Route>
                <Route path="/team-member/new" exact>
                  <NewTeamMemberPage />
                </Route>
                <Route path="/team-member/:id/edit" exact>
                  <EditTeamMemberPage />
                </Route>
                <Route path="/team-member/:id/" exact>
                  <EditTeamMemberPage />
                </Route>
                <Route path="/profile/:id/" exact>
                  <EditTeamMemberPage />
                </Route>
                <Route path="/privacy-policy" exact>
                  <PrivacyPolicyPage />
                </Route>
              </Switch>
            </div>
            <Footer />
          </div>
        </Router>
      </StylesProvider>
    );
}

export default AppContainer;
