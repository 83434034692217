import gql from 'graphql-tag';

//  ===== MEDICATION FRAGMENTS =====
export const MEDICATION_NAMES = gql`
  fragment MedicationNames on Medication {
    id
    generic_name
    trade_names
  }
`;

export const MEDICATION_DETAILS = gql`
  fragment MedicationDetails on Medication {
    ...MedicationNames
    type_of_medication
    maximum_dosage
    unit_of_dosage
    notes
  }
  ${MEDICATION_NAMES}
`;

export const MEDICATION_FULL = gql`
  fragment MedicationFull on Medication {
    ...MedicationDetails
    images {
      id
      formats
      url
    }
  }
  ${MEDICATION_DETAILS}
`;

export const TEAM_ROLES = gql`
  fragment RolesFields on UsersPermissionsRole {
    id
    name
    description
    type
  }
`;

export const ACTION_PLAN_STEP = gql`
  fragment ActionPlanStepFields on ActionPlanStep {
    id
    step_number
    notes
    total_time_in_minutes
    administered_dose
    estimated_weight_dose_per_kg
    calculated_dose
    # Rescue medications in non-hospital and hospital plan steps
    medication {
      ...MedicationFull
    }
    seizure_type
    bloodwork
    length_of_current_seizure_time_in_minutes
    total_number_of_seizures
    text_field
    based_on_patients_weight
    step_weight_last_updated
  }
  ${MEDICATION_FULL}
`;

export const ACTION_PLAN = gql`
  fragment ActionPlanFields on ActionPlan {
    id
    category
    show_epilepticus_plan
    action_plan_steps(sort: "step_number:asc") {
      ...ActionPlanStepFields
    }
    patient {
      id
      first_name
      last_name
    }
  }
  ${ACTION_PLAN_STEP}
`;

export const TEAM_MEMBER_WITHOUT_PATIENT_LIST = gql`
  fragment TeamMemberWithoutPatientList on UsersPermissionsUser {
    id
    username
    first_name
    last_name
    prefix
    position
    contact_info
    email
    deleted_at
    blocked
    notes
    image {
      id
      formats
      url
    }
    role {
      ...RolesFields
    }
  }
  ${TEAM_ROLES}
`;

//  ===== PATIENT FRAGMENTS =====
export const PATIENT_NAMES = gql`
  fragment PatientNames on Patient {
    id
    first_name
    last_name
    nickname
  }
`;

export const PATIENT_DETAILS = gql`
  fragment PatientDetails on Patient {
    ...PatientNames
    email
    DOB
    diagnoses
    dosage
    seizure_types
    patient_notes
    deleted_at
    allergies
    caregiver_name
    caregiver_email
    weight
    unit_of_weight
    weight_last_updated
    # Non-rescue medications in patient details
    medications {
      ...MedicationDetails
    }
  }
  ${PATIENT_NAMES}
  ${MEDICATION_DETAILS}
`;

export const PATIENT_ACTION_PLANS_IDS = gql`
  fragment PatientActionPlanIds on Patient {
    ...PatientNames
    action_plans {
      id
      action_plan_steps(sort: "step_number:asc") {
        id
        step_number
      }
    }
  }
  ${PATIENT_NAMES}
`;

export const PATIENT_ACTION_PLANS = gql`
  fragment PatientActionPlans on Patient {
    ...PatientNames
    action_plans {
      ...ActionPlanFields
    }
  }
  ${PATIENT_NAMES}
  ${ACTION_PLAN}
`;

export const PATIENT_TEAM_MEMBERS = gql`
  fragment PatientTeamMembers on Patient {
    ...PatientNames
    users(where: { deleted_at_null: true }) {
      ...TeamMemberWithoutPatientList
    }
  }
  ${PATIENT_NAMES}
  ${TEAM_MEMBER_WITHOUT_PATIENT_LIST}
`;

export const PATIENT_FULL = gql`
  fragment PatientFull on Patient {
    ...PatientDetails
    users(where: { deleted_at_null: true }) {
      ...TeamMemberWithoutPatientList
    }
    action_plans {
      ...ActionPlanFields
    }
  }
  ${PATIENT_DETAILS}
  ${TEAM_MEMBER_WITHOUT_PATIENT_LIST}
  ${ACTION_PLAN}
  ${MEDICATION_FULL}
`;

export const TEAM_MEMBER_WITH_PATIENT_LIST = gql`
  fragment TeamMemberWithPatientList on UsersPermissionsUser {
    id
    username
    first_name
    last_name
    prefix
    position
    contact_info
    deleted_at
    notes
    image {
      id
      formats
      url
    }
    patients {
      ...PatientFull
    }
    role {
      ...RolesFields
    }
  }
  ${PATIENT_FULL}
  ${TEAM_ROLES}
`;

export const PATIENT_LOG_FULL = gql`
  fragment PatientLogFull on PatientLog {
    id
    model_id
    model_name
    field_name
    updated_by_user {
      ...TeamMemberWithoutPatientList
    }
    old_entry
    new_entry
    notes
    published_at
    field_operation
    text_field
    patient {
      ...PatientFull
    }
  }
  ${PATIENT_FULL}
  ${TEAM_MEMBER_WITHOUT_PATIENT_LIST}
`;

export const PATIENT_LOG_DETAILS = gql`
  fragment PatientLogDetails on PatientLog {
    id
    model_id
    model_name
    field_name
    old_entry
    new_entry
    notes
    published_at
    field_operation
    text_field
    updated_by_user {
      first_name
      last_name
      prefix
      position
    }
    patient {
      ...PatientNames
      action_plans {
        category
        action_plan_steps(sort: "step_number:asc") {
          id
          step_number
        }
      }
    }
  }
  ${PATIENT_NAMES}
`;
