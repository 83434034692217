/** @jsx jsx */
import { Fragment } from 'react';
import { jsx, css } from '@emotion/core';
import { makeStyles } from '@material-ui/core/styles';
import { useParams } from 'react-router-dom';
import Card from '@material-ui/core/Card';
import Grid from '@material-ui/core/Grid';
import GlobalStyles from 'assets/GlobalStyles';
import BlueCard from 'components/PatientView/BlueCard.js';
import StepBreadcrumbs from 'components/PatientView/StepperDesktop';
import MobileStepper from 'components/PatientView/MobileStepper';
import MobilePlanButton from 'components/PatientView/MobilePlanButton';
import { Link } from 'react-scroll';

const paragraphStyling = css`
  color: ${GlobalStyles.darkBlue};
  font-family: 'poppins-medium';
  font-size: 24px;
  line-height: 35px;
  margin-block-start: 0;
  margin-block-start: 0;
  margin-top: 5%;
  margin-left: 7.5%;
  max-width: 21vw;
  @media (max-width: ${GlobalStyles.mobileBreakpoint}) {
    font-size: 16px;
    line-height: 27px;
    max-width: 76vw;
    margin-left: 0;
    margin-top: 0;
    letter-spacing: 1px;
  }
`;

const mobileParagraphContainerStyling = css`
  @media (max-width: ${GlobalStyles.mobileBreakpoint}) {
    margin-bottom: 20%;
  }
`;

const useStyles = makeStyles(() => ({
  root: {
    minWidth: '26vw',
    minHeight: '26rem',
    backgroundColor: `${GlobalStyles.paleBlueCardBackground}`,
    borderRadius: '30px',
    paddingTop: '20%',
    '@media (max-width: 960px)': {
      minHeight: '40vh',
    },
  },
  button: {
    borderRadius: '50%',
    minWidth: '55px',
    backgroundColor: `${GlobalStyles.midBlue}`,
    color: `${GlobalStyles.white}`,
    marginTop: '5%',
  },
  card4: {
    minWidth: '53vw',
    minHeight: '26rem',
    backgroundColor: `${GlobalStyles.paleBlueCardBackground}`,
    borderRadius: '30px',
    paddingTop: '20%',
    '@media (max-width: 960px)': {
      minHeight: '40vh',
    },
  },
}));

const whenToCall911Styling = css`
  color: ${GlobalStyles.darkBlue};
  font-family: 'poppins-medium';
  font-size: 20px;
  line-height: 1.5;
  letter-spacing: 1px;
  margin-left: 7.5%;
  overflow-wrap: break-word;
  text-decoration: underline;

  @media (max-width: ${GlobalStyles.mobileBreakpoint}) {
    font-size: 14px;
    margin-left: 0;
    letter-spacing: 0px;
  }
`;

const WhenToCall911 = () => {
  return (
    <Link
      className="whenToCall911"
      css={whenToCall911Styling}
      to="SeizureFirstAid"
      spy={true}
      offset={-200}
      smooth={true}
      duration={100}
    >
      {'When to Call 911'}
    </Link>
  );
};

export default function NonHospitalPlanBlueCard({
  title,
  step,
  totalSteps,
  isMobile,
  planSteps,
}) {
  const classes = useStyles();

  const { id: patientId } = useParams();
  const {
    step_number,
    notes,
    total_time_in_minutes,
    administered_dose,
    medication,
    length_of_current_seizure_time_in_minutes,
    total_number_of_seizures,
  } = step || {};

  let nextStep = '';

  // set last step as last one from plan (used to check if we have step 4)
  const lastStep = planSteps ? planSteps[planSteps.length - 1].step_number : 1;
  const emptyLastStep =
    lastStep === 4 &&
    planSteps[planSteps.length - 1].notes.trim().replace(/^\s*\n/gm, '') === ''
      ? true
      : false;

  // set next step to actual next step (used for mobile button navigation to steps).
  if (planSteps) {
    switch (step_number) {
      case 1:
        nextStep = planSteps[1] ? planSteps[1].step_number : 'Hospital Plan';
        break;
      case 2:
        nextStep = planSteps[2] ? planSteps[2].step_number : 'Hospital Plan';
        break;
      case 3:
        nextStep = planSteps[3] ? planSteps[3].step_number : 'Hospital Plan';
        break;
      case 4:
        nextStep = 'Hospital Plan';
        break;
      default:
        nextStep = 'Hospital Plan';
    }
    // if we have an empty step 4, set next step to hospital plan to jump over empty step on mobile devices
    if (planSteps[planSteps.length - 2]) {
      if (
        step_number === planSteps[planSteps.length - 2].step_number &&
        emptyLastStep
      ) {
        nextStep = 'Hospital Plan';
      }
    }
  }

  const medicationText = () => {
    if (medication) {
      if (medication.generic_name === 'Lorazepam - Buccal') {
        return 'Lorazepam in their cheek.';
      } else if (medication.generic_name === 'Midazolam - Buccal') {
        return 'Midazolam in their cheek.';
      } else if (medication.generic_name === 'Lorazepam - Intranasal') {
        return 'Lorazepam in their nose.';
      } else if (medication.generic_name === 'Midazolam - Intranasal') {
        return 'Midazolam in their nose.';
      } else if (medication.generic_name === 'Lorazepam - Sublingual') {
        return 'Lorazepam under their tongue.';
      } else if (medication.generic_name === 'Midazolam - Sublingual') {
        return 'Midazolam under their tongue.';
      }
    }
  };

  const stepText = () => {
    if (step_number === 1) {
      return (
        <div css={mobileParagraphContainerStyling}>
          <p css={paragraphStyling}> {notes} </p>
          {!isMobile ? <WhenToCall911 /> : null}
        </div>
      );
    } else if (step_number === 2) {
      return (
        <div css={mobileParagraphContainerStyling}>
          <p css={paragraphStyling}>
            If the child is seizing at{' '}
            <b>{length_of_current_seizure_time_in_minutes} minutes</b> or has{' '}
            <b>{total_number_of_seizures} seizures</b> in{' '}
            <b>{total_time_in_minutes} minutes</b>:
          </p>
          <p css={paragraphStyling}>
            Give <b>{administered_dose.toFixed(2)} mg</b> of{' '}
            <b>{medicationText()}</b>
          </p>
        </div>
      );
    } else if (step_number === 3) {
      return (
        <div css={mobileParagraphContainerStyling}>
          <p css={paragraphStyling}>
            If the child is still seizing at{' '}
            <b>
              {length_of_current_seizure_time_in_minutes} minutes (and breathing
              is stable)
            </b>{' '}
            or has <b>{total_number_of_seizures} seizures</b> in{' '}
            <b>{total_time_in_minutes} minutes</b>:
          </p>
          <p css={paragraphStyling}>
            Give a second dose of <b>{administered_dose.toFixed(2)} mg</b> of{' '}
            <b>{medicationText()}</b>
          </p>
        </div>
      );
    } else if (step_number === 4) {
      return (
        <div css={mobileParagraphContainerStyling}>
          <p css={paragraphStyling}> {notes} </p>
        </div>
      );
    }
  };

  return (
    <div
      css={css`
        @media (max-width: ${GlobalStyles.mobileBreakpoint}) {
          overflow: hidden;
        }
      `}
    >
      {!isMobile && (
        <div
          css={css`
            color: ${GlobalStyles.midBlue};
            font-family: 'poppins-bold';
            font-size: 16px;
            text-align: center;
            min-width: 25vw;
            padding-bottom: 4vh;
          `}
        >
          {step_number === 4
            ? ''
            : length_of_current_seizure_time_in_minutes
            ? length_of_current_seizure_time_in_minutes + ' min'
            : '0 min'}
          <Grid
            container
            direction="row"
            alignItems="center"
            css={css`
              margin-top: 4%;
            `}
          >
            {step_number === 4 ? (
              ''
            ) : (
              <StepBreadcrumbs
                totalSteps={totalSteps}
                step_number={step_number}
                lastStep={lastStep}
              />
            )}
          </Grid>
        </div>
      )}
      {isMobile ? (
        <Fragment>
          <div
            className="ContainerForBlueCardAndMobileStepButton"
            css={css`
              margin-top: 10%;
              width: 110%;
              margin-right: -10%;
              /* bottom-padding needed for iPhone5 */
              padding-bottom: 120px;
            `}
          >
            <BlueCard
              title={title}
              text={stepText()}
              isMobile
              planSteps={planSteps}
            />
            <div
              css={css`
                margin-top: 20px;
                padding-left: 25%;
                /* for iPhone hosp plan text and arrow to fit */
                @media (max-width: ${GlobalStyles.xsMobileBreakpoint}) {
                  padding-left: 12%;
                }
              `}
            >
              <MobilePlanButton
                // Set button label to proper step number, or additional notes or hospital plan, based on step 4 existence
                buttonlabel={
                  nextStep === 'Hospital Plan' || step_number === 4
                    ? nextStep
                    : nextStep === 4
                    ? 'Additional notes'
                    : `Step ${nextStep}`
                }
                to={
                  nextStep === 'Hospital Plan'
                    ? `/patient-view/${patientId}/hospital-plan/`
                    : `/patient-view/${patientId}/out-of-hospital-plan/${nextStep}`
                }
                contentdirection={'right'}
              />
            </div>
          </div>

          <MobileStepper planSteps={planSteps} />
        </Fragment>
      ) : (
        <Card className={step_number === 4 ? classes.card4 : classes.root}>
          <div
            className="NonHospitalPlanBlueCard_title-container"
            css={css`
              margin-left: 7.5%;
            `}
          >
            <h2
              css={css`
                color: ${GlobalStyles.midBlue};
                font-family: 'poppins-bold';
                font-size: 24px;
                line-height: 16px;
                margin-block-start: 0;
                margin-block-end: 0;
                @media (max-width: ${GlobalStyles.mobileBreakpoint}) {
                  font-size: 16px;
                }

                /* The styling below is for the horizontal line on the right side of the title */

                width: 100%;
                text-align: left;
                border-bottom: 3px solid ${GlobalStyles.midBlue};
                line-height: 0.1em;
                margin: 10px 0 0;

                span {
                  background: ${GlobalStyles.paleBlueCardBackground};
                  padding: 0 10px 0 0;
                }
              `}
            >
              <span>{title.toUpperCase()}</span>
            </h2>
            <h3
              css={css`
                color: ${GlobalStyles.midBlue};
                font-family: 'poppins-bold';
                font-size: 14px;
                line-height: 18px;
                margin-block-start: 0;
                margin-block-end: 0;
                margin-top: 4%;
              `}
            >
              {step_number === 4
                ? ''
                : length_of_current_seizure_time_in_minutes
                ? length_of_current_seizure_time_in_minutes + ' min'
                : '0 min'}
            </h3>
          </div>
          {stepText()}
        </Card>
      )}
    </div>
  );
}
