/** @jsx jsx */
import { jsx, css } from '@emotion/core';
import GlobalStyles from 'assets/GlobalStyles';
import MobileAppHeader from 'components/PatientView/MobileAppHeader';
import SeizureTypesBlueCard from 'components/PatientView/SeizureTypesBlueCard.js';
import BlueCard from 'components/PatientView/BlueCard.js';
import { useMediaQuery, Grid } from '@material-ui/core';
import moment from 'moment';

const PatientDetails = ({ patient }) => {
  const birthDate = moment(patient.DOB).format('DD MMMM YYYY');
  const weightLastUpdated = moment(patient.weight_last_updated).format(
    'MMMM DD, YYYY'
  );
  const isMobile = useMediaQuery(
    `(max-width:${GlobalStyles.mobileBreakpoint})`
  );

  // make a list of medications as a string to render in a card
  let medicationsText = '';
  if (patient.medications.length !== 0) {
    patient.medications.forEach((medication) => {
      medicationsText =
        medicationsText +
        `${medication.generic_name} - ${
          patient.dosage[`"${medication.id}"`]
        }\n`;
    });
  }

  // make an object from seizure types to contain an active and inactive strings to render oin new component
  const formatedSeizureTypes = {
    active: 'ACTIVE\n',
    inactive: 'INACTIVE\n',
  };

  // seisure types existance condition
  const seizureTypesExists =
    patient.seizure_types && patient.seizure_types.length !== 0 ? true : false;

  // filter seizure types array to fill acti
  if (seizureTypesExists) {
    if (patient.seizure_types.length !== 0) {
      patient.seizure_types.forEach((seizureType) => {
        if (seizureType.checkboxActive) {
          formatedSeizureTypes.active =
            formatedSeizureTypes.active + ` ● ${seizureType.name.join('-')}\n`;
        } else {
          formatedSeizureTypes.inactive =
            formatedSeizureTypes.inactive +
            ` ● ${seizureType.name.join('-')}\n`;
        }
      });
    }
  }

  return (
    <div
      className="PatientDetails"
      css={css`
        margin-top: 10%;
        padding: 0 6%;
        overflow: hidden;

        @media (max-width: ${GlobalStyles.mobileBreakpoint}) {
          padding: 0 0 3% 10%;
          margin-top: 0;
        }
      `}
    >
      {isMobile && <MobileAppHeader patient={patient} />}
      <div
        css={css`
          font-family: 'roboto-bold';
          font-size: 30px;
          line-height: 35px;
          color: ${GlobalStyles.darkBlue};
          margin-bottom: 3%;
          text-align: center;
          @media (max-width: ${GlobalStyles.mobileBreakpoint}) {
            margin-left: 20%;
            text-align: left;
            margin-bottom: 5%;
            font-size: 18px;
            line-height: 21px;
            position: relative;
            z-index: 0;
          }
        `}
      >
        {isMobile ? (
          'Patient Details'
        ) : (
          <p>
            {patient.nickname}'s{' '}
            <span
              css={css`
                color: ${GlobalStyles.midBlue};
              `}
            >
              Details
            </span>
          </p>
        )}
      </div>
      {isMobile ? (
        <Grid container justify="space-between">
          <Grid
            item
            xs={12}
            css={css`
              margin-top: 5%;
              overflow: hidden;
              position: relative;
              z-index: 0;
            `}
          >
            <BlueCard
              css={css`
                min-height: 5vh;
              `}
              title={'DATE OF BIRTH'}
              text={`${birthDate}`}
            />
          </Grid>

          <Grid
            item
            xs={12}
            css={css`
              margin-top: 2%;
              overflow: hidden;
              position: relative;
              z-index: 0;
            `}
          >
            <BlueCard
              title={'WEIGHT'}
              // Please leave line indentation as is, otherwise blank chars are detected in render
              text={`${patient.weight}${patient.unit_of_weight} 
Last updated ${weightLastUpdated}
              `}
            />
          </Grid>

          {patient.diagnoses ? (
            <Grid
              item
              xs={12}
              css={css`
                margin-top: 2%;
                overflow: hidden;
                position: relative;
                z-index: 0;
              `}
            >
              <BlueCard title={'DIAGNOSES'} text={`${patient.diagnoses}`} />
            </Grid>
          ) : null}

          {seizureTypesExists ? (
            <Grid
              item
              xs={12}
              css={css`
                margin-top: 2%;
                overflow: hidden;
                position: relative;
                z-index: 0;
              `}
            >
              <SeizureTypesBlueCard
                title={'SEIZURE TYPES'}
                text={formatedSeizureTypes}
              />
            </Grid>
          ) : null}

          {patient.medications.length !== 0 ? (
            <Grid
              item
              xs={12}
              css={css`
                margin-top: 2%;
                overflow: hidden;
                position: relative;
                z-index: 0;
              `}
            >
              <BlueCard
                title={'ANTI-SEIZURE MEDICATIONS'}
                text={medicationsText}
              />
            </Grid>
          ) : null}

          {patient.allergies ? (
            <Grid
              item
              xs={12}
              css={css`
                margin-top: 2%;
                overflow: hidden;
                position: relative;
                z-index: 0;
              `}
            >
              <BlueCard title={'ALLERGIES'} text={`${patient.allergies}`} />
            </Grid>
          ) : null}
        </Grid>
      ) : (
        <Grid container justify="space-between">
          <BlueCard
            css={css`
              min-height: 5vh;
            `}
            title={'DATE OF BIRTH'}
            text={`${birthDate}`}
          />

          <BlueCard
            title={'WEIGHT'}
            // Please leave line indentation as is, otherwise blank chars are detected in render
            text={`${patient.weight}${patient.unit_of_weight} Last updated ${weightLastUpdated}
              `}
          />

          {patient.diagnoses ? (
            <BlueCard title={'DIAGNOSES'} text={`${patient.diagnoses}`} />
          ) : null}

          {seizureTypesExists ? (
            <SeizureTypesBlueCard
              title={'SEIZURE TYPES'}
              text={formatedSeizureTypes}
            />
          ) : null}
          {patient.medications.length !== 0 ? (
            <BlueCard
              title={'ANTI-SEIZURE MEDICATIONS'}
              text={medicationsText}
            />
          ) : null}
          {patient.allergies ? (
            <BlueCard title={'ALLERGIES'} text={`${patient.allergies}`} />
          ) : null}
        </Grid>
      )}
    </div>
  );
};

export default PatientDetails;
