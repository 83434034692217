import { Fragment } from 'react';
import { useParams } from 'react-router-dom';
import { useQuery } from '@apollo/client';
// import GlobalStyles from 'assets/GlobalStyles';
import TeamMemberForm from 'components/TeamMemberForm';

/** @jsx jsx */
import { jsx } from '@emotion/core';
import { GET_TEAM_MEMBER } from 'utils/queries';

//Loading Skeleton
import EditProfileSkeleton from '../components/Skeletons/EditProfileSkeleton';

function EditTeamMemberPage() {
  const { id } = useParams();
  const { data: teamMemberData, loading, refetch } = useQuery(GET_TEAM_MEMBER, {
    variables: { id },
  });

  const teamMember = teamMemberData ? teamMemberData.user : {};

  return (
    <div>
      {loading ? (
        <EditProfileSkeleton />
      ) : (
        <Fragment>
          <TeamMemberForm
            teamMember={teamMember}
            refetchTeamMember={refetch}
            title="Edit Profile"
          />
        </Fragment>
      )}
    </div>
  );
}

export default EditTeamMemberPage;
