import TeamMemberForm from 'components/TeamMemberForm';

/** @jsx jsx */
import { jsx } from '@emotion/core';

function NewTeamMemberPage() {
  return <TeamMemberForm title="Add Profile" />;
}

export default NewTeamMemberPage;
