import { useState, Fragment, useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { useQuery, useMutation } from '@apollo/client';
/** @jsx jsx */
import { jsx, css } from '@emotion/core';
import Grid from '@material-ui/core/Grid';
import SearchIcon from '@material-ui/icons/Search';
import GlobalStyles from 'assets/GlobalStyles';
import {
  GET_PATIENT_TEAM_MEMBERS,
  GET_PATIENT_LOG_DETAILS,
  GET_ALL_MEDICAL_TEAM_MEMBERS,
} from 'utils/queries';
import { DELETE_TEAM_MEMBER_FROM_PATIENT_LIST } from 'utils/mutations';
import TeamContainer from 'components/TeamContainer';
import TeamPageButtons from 'components/TeamPageButtons';
// have to rename Link as name already exists for react-router-dom
import { Link as ScrollLink } from 'react-scroll';

//Loading Skeleton
import PatientTabSkeleton from '../components/Skeletons/PatientTabSkeleton';

const searchBarStyling = css`
  display: flex;
  border: 0;
  padding: 4px;
  width: 100%;
`;

const searchIconStyling = css`
  height: 31px;
  padding-left: 1%;
  border: ${GlobalStyles.formInputYellow};
  background-color: ${GlobalStyles.formInputYellow};
`;

const searchInputStyling = css`
  border: ${GlobalStyles.formInputYellow};
  background-color: ${GlobalStyles.formInputYellow};
  height: 31px;
  margin-right: 20px;
  width: 100%;
  position: relative;
  ::placeholder {
    color: ${GlobalStyles.navyBlue};
    position: relative;
    font-weight: 900;
    opacity: 1;
  }
  :focus {
    outline: none;
  }
  :focus::placeholder {
    color: transparent;
  }
`;

const noResults = css`
  font-size: 21px;
  font-weight: lighter;
  color: ${GlobalStyles.textBlue};
  font-family: roboto;
`;

const boldedSpan = css`
  font-weight: bold;
`;

function PatientTeam({ isEditing }) {
  const history = useHistory();
  const { id: patientId } = useParams();
  const [inputText, setInputText] = useState('');
  const [teamMemberIdsChecked, setTeamMemberIdsChecked] = useState([]);
  const [addingTeamMembers, setAddingTeamMembers] = useState(false);
  const [teamMemberToDelete, setTeamMemberToDelete] = useState([]);

  const { data: clinicalTeamMembers } = useQuery(GET_ALL_MEDICAL_TEAM_MEMBERS);

  const { data, loading } = useQuery(GET_PATIENT_TEAM_MEMBERS, {
    variables: { id: patientId },
  });

  const [deleteTeamMemberWithPatient, { loading: deleteLoading }] = useMutation(
    DELETE_TEAM_MEMBER_FROM_PATIENT_LIST,
    {
      refetchQueries: [
        { query: GET_PATIENT_LOG_DETAILS, variables: { id: patientId } },
      ],
      awaitRefetchQueries: true,
    }
  );

  const handleDelete = (e) => {
    e.preventDefault();
    const filteredPatientTeam = [];
    const currentPatientTeam = data.patient.users;
    if (teamMemberIdsChecked.length > 0) {
      currentPatientTeam.forEach((teamMember) => {
        if (!teamMemberIdsChecked.includes(teamMember.id)) {
          filteredPatientTeam.push(teamMember.id);
        }
      });

      deleteTeamMemberWithPatient({
        variables: { id: patientId, data: filteredPatientTeam },
      })
        .then(async () => {
          setTeamMemberIdsChecked([]);
          setTeamMemberToDelete([]);
          history.push(`/patient/${patientId}/team`);
        })
        .catch((error) => {
          console.log('ERROR', error);
        });
    }
  };

  const buttonOneOnClick = () => {
    setAddingTeamMembers(false);
    setTeamMemberIdsChecked([]);
    setTeamMemberToDelete([]);
    history.push(`/patient/${patientId}/team`);
  };

  // Search function
  const doSearch = (event) => {
    setInputText(event.target.value);
  };

  // User list filtered by search
  const [filteredUsers, setFilteredUsers] = useState([]);

  // Alphabet Timeline on the side
  const [availableLetters, setAvailableLetters] = useState(new Set());

  const updateLetterTimeline = (alphabets) => {
    const newAlphabets = new Set();

    alphabets.forEach((alphabet) => {
      if (alphabet.last_name[0]) {
        newAlphabets.add(alphabet.last_name[0].toUpperCase());
      }
    });

    setAvailableLetters(newAlphabets);
  };

  const AlphabetTimeline = ({ alphabets }) => {
    // adjust space between letters based on if user is filtering content by searching
    // let spacing;
    // data.patient.users.length < 10 || inputText.length > 0
    //   ? (spacing = 'flex-start')
    //   : (spacing = 'space-between');
    return (
      <div
        css={css`
          background-color: ${GlobalStyles.midBlue};
          color: ${GlobalStyles.white};
          font-size: 14px;
          font-weight: bold;
          padding-top: 7px;
          padding-bottom: 7px;
          width: 34px;
          display: flex;
          flex-direction: column;
          text-align: center;
          justify-content: flex-start;
        `}
      >
        {Array.from(alphabets).map((alphabet) => {
          return (
            <ScrollLink
              id={`${alphabet}-timeLineLetter`}
              css={css`
                padding: 3px;
                cursor: pointer;
              `}
              to={`${alphabet}-first`}
              containerId="container"
              hashSpy={true}
              spy={true}
              isDynamic={true}
              className="timeLineLetter"
              key={alphabet}
              aria-hidden={true}
              offset={-6}
              onSetActive={() => setActiveHighlight(alphabet)}
              onClick={() => {
                setActiveHighlight(alphabet);
              }}
            >
              {alphabet}
            </ScrollLink>
          );
        })}
      </div>
    );
  };

  const setActiveHighlight = (letter) => {
    document.querySelectorAll('.timeLineLetter').forEach((timeLineLetter) => {
      if (letter.startsWith(timeLineLetter.id.split('-')[0])) {
        timeLineLetter.style.backgroundColor = `${GlobalStyles.white}`;
        timeLineLetter.style.color = `${GlobalStyles.midBlue}`;
      } else {
        timeLineLetter.style.backgroundColor = `${GlobalStyles.midBlue}`;
        timeLineLetter.style.color = `${GlobalStyles.white}`;
      }
    });
  };

  useEffect(() => {
    const seenUsers = new Set();
    if (!data || !data.patient.users) return;

    const newlyFilteredUsers = data.patient.users.filter((user) => {
      const lastNameSearch = user.last_name ? user.last_name : null;
      const firstNameSearch = user.first_name ? user.first_name : null;

      if (
        (lastNameSearch &&
          lastNameSearch.toLowerCase().includes(inputText.toLowerCase())) ||
        (firstNameSearch &&
          firstNameSearch.toLowerCase().includes(inputText.toLowerCase()))
      ) {
        return user;
      } else {
        return null;
      }
    });

    //needed for sorting case-insensitively
    const collator = new Intl.Collator(['en', 'fr-ca'], {
      numeric: true,
      sensitivity: 'base',
    });

    /// needed to copy for Apollo
    const filteredUserCopy = newlyFilteredUsers.map((user) => {
      return Object.assign({}, user);
    });

    filteredUserCopy
      .sort(
        (a, b) =>
          collator.compare(a.last_name, b.last_name) ||
          collator.compare(a.first_name, b.first_name)
      )
      .forEach((user) => {
        if (user.last_name && !seenUsers.has(user.last_name[0].toLowerCase())) {
          user.lastNameAlphabeticalGroup = true;
          seenUsers.add(user.last_name[0].toLowerCase());
        }
      });

    setFilteredUsers(filteredUserCopy);
    updateLetterTimeline(filteredUserCopy);
  }, [data, inputText]);

  if (loading) {
    return <PatientTabSkeleton />;
  } else {
    return (
      <Grid role="main" className="patient-list">
        <div
          className="patient-list__label"
          css={css`
            padding: 7px 18px 7px 18px;
            background-color: ${GlobalStyles.midBlue};
            color: ${GlobalStyles.white};
            font-size: 20px;
            display: inline-block;
            font-family: 'roboto-black';
          `}
        >
          {`${data.patient.nickname.toUpperCase()}'S TEAM > EDIT CLINICAL TEAM MEMBERS`}
        </div>
        <div
          className="patient-list_column_and_body"
          css={css`
            display: grid;
            grid-template-columns: auto 1fr;
          `}
        >
          {/* Quick Label Searching Column */}

          <AlphabetTimeline
            id="alphabetTimeline"
            alphabets={availableLetters}
          />

          <div
            className="clinical-team-list__body"
            css={css`
              padding: 40px 40px 40px 60px;
              border-top: ${GlobalStyles.outerContainerBorder};
              border-right: ${GlobalStyles.outerContainerBorder};
              border-bottom: ${GlobalStyles.outerContainerBorder};
              background-color: ${GlobalStyles.white};
              overflow-y: hidden;
            `}
          >
            <div
              className="containerForSearchBarAndAddNewButton"
              css={css`
                display: flex;
                flex-direction: row;
                margin-bottom: 20px;
                align-items: center;
                padding-right: 40px;
              `}
            >
              <div className="searchBarContainer" css={searchBarStyling}>
                <SearchIcon css={searchIconStyling} />
                <input
                  css={searchInputStyling}
                  type="search"
                  placeholder="search"
                  value={inputText}
                  onChange={doSearch}
                  aria-hidden="false"
                />
              </div>
            </div>
            <Fragment>
              <div
                id="container"
                className="patientTeam__listContainer"
                css={css`
                  height: 50vh;
                  overflow-y: ${data.patient.users.length ||
                  (addingTeamMembers && clinicalTeamMembers.users.length)
                    ? 'auto'
                    : 'hidden'};
                  margin-right: 53px;
                  margin-top: 80px;
                  margin-bottom: 80px;
                  /* Scrollbar for Firefox */
                  /* prettier-ignore */
                  scrollbar-color: ${GlobalStyles.babyBlue} ${GlobalStyles.backgroundBlue};

                  &::-webkit-scrollbar {
                    width: 15px;
                  }
                  &::-webkit-scrollbar-thumb {
                    height: 40%;
                    border-radius: 10px;
                    background-color: ${GlobalStyles.babyBlue};
                    box-shadow: inset 0 0 10px 10px ${GlobalStyles.babyBlue};
                    border: 3px solid ${GlobalStyles.backgroundBlue};
                  }
                  &::-webkit-scrollbar-track {
                    border-radius: 10px;
                    background-color: ${GlobalStyles.backgroundBlue};
                  }
                `}
              >
                {!data.patient.users.length && !addingTeamMembers && (
                  <div
                    css={css`
                      display: flex;
                      justify-content: center;
                      align-items: center;
                      flex-direction: column;
                      width: 100%;
                      height: 100%;
                    `}
                  >
                    <p>
                      This patient does not yet have any medical team members
                    </p>
                  </div>
                )}

                {filteredUsers.length < 1 && inputText.length > 0 && (
                  <p css={noResults}>
                    No results found for{' '}
                    <span css={boldedSpan}>{inputText}</span>.
                  </p>
                )}

                {filteredUsers.length
                  ? filteredUsers.map((member) => {
                      return (
                        <div
                          className="patientTeam__container"
                          key={member.id}
                          css={css`
                            display: grid;
                            justify-items: start;
                            grid-template-columns: 100px 1fr;
                            margin: 0px 40px 48px 40px;
                          `}
                        >
                          <TeamContainer
                            member={member}
                            isEditing={isEditing}
                            teamMemberIdsChecked={teamMemberIdsChecked}
                            setTeamMemberIdsChecked={setTeamMemberIdsChecked}
                            teamMemberToDelete={teamMemberToDelete}
                            data={data}
                          />
                        </div>
                      );
                    })
                  : null}
              </div>
              <div
                css={css`
                  display: flex;
                  justify-content: center;
                  margin: 0 7.5% 67px 5%;
                `}
              >
                <TeamPageButtons
                  isLoading={deleteLoading}
                  buttonOneText={'Cancel'}
                  buttonTwoText={'Save'}
                  buttonOneOnClick={buttonOneOnClick}
                  buttonTwoOnClick={(e) => handleDelete(e)}
                />
              </div>
            </Fragment>
          </div>
        </div>
      </Grid>
    );
  }
}

export default PatientTeam;
