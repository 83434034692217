import gql from 'graphql-tag';
import {
  PATIENT_FULL,
  MEDICATION_FULL,
  TEAM_MEMBER_WITHOUT_PATIENT_LIST,
  ACTION_PLAN_STEP,
  PATIENT_LOG_DETAILS,
  ACTION_PLAN,
} from './fragments';

//  ===== PATIENT MUTATIONS =====
export const UPDATE_PATIENT = gql`
  ${PATIENT_FULL}
  mutation UpdatePatient($id: ID!, $data: editPatientInput!) {
    updatePatient(input: { where: { id: $id }, data: $data }) {
      patient {
        ...PatientFull
      }
    }
  }
`;

//this is soft delete so an UPDATE to deleted_at rather than a delete
export const DELETE_PATIENT = gql`
  ${PATIENT_FULL}
  mutation DeletePatient($id: ID!, $data: DateTime) {
    updatePatient(input: { where: { id: $id }, data: { deleted_at: $data } }) {
      patient {
        ...PatientFull
      }
    }
  }
`;

export const CREATE_PATIENT = gql`
  ${PATIENT_FULL}
  mutation CreatePatient($data: PatientInput!) {
    createPatient(input: { data: $data }) {
      patient {
        ...PatientFull
      }
    }
  }
`;

//  ===== MEDICATION MUTATIONS =====
export const UPDATE_MEDICATION = gql`
  ${MEDICATION_FULL}
  mutation UpdateMedication($id: ID!, $data: editMedicationInput!) {
    updateMedication(input: { where: { id: $id }, data: $data }) {
      medication {
        ...MedicationFull
      }
    }
  }
`;

export const CREATE_MEDICATION = gql`
  ${MEDICATION_FULL}
  mutation CreateMedication($data: MedicationInput!) {
    createMedication(input: { data: $data }) {
      medication {
        ...MedicationFull
      }
    }
  }
`;

//  ===== TEAM MEMBER MUTATIONS =====
export const CREATE_TEAM_MEMBER = gql`
  ${TEAM_MEMBER_WITHOUT_PATIENT_LIST}
  mutation($data: UserInput) {
    createUser(input: { data: $data }) {
      user {
        ...TeamMemberWithoutPatientList
      }
    }
  }
`;

//this is soft delete so an UPDATE to deleted_at rather than a delete
export const DELETE_TEAM_MEMBER = gql`
  ${TEAM_MEMBER_WITHOUT_PATIENT_LIST}
  mutation DeleteUser($id: ID!, $data: DateTime) {
    updateUser(
      input: { where: { id: $id }, data: { deleted_at: $data, blocked: true } }
    ) {
      user {
        ...TeamMemberWithoutPatientList
      }
    }
  }
`;

export const UPDATE_TEAM_MEMBER = gql`
  ${TEAM_MEMBER_WITHOUT_PATIENT_LIST}
  mutation($id: ID!, $data: editUserInput!) {
    updateUser(input: { where: { id: $id }, data: $data }) {
      user {
        ...TeamMemberWithoutPatientList
      }
    }
  }
`;

//  ===== PATIENT'S TEAM MEMBER MUTATIONS =====
export const DELETE_TEAM_MEMBER_FROM_PATIENT_LIST = gql`
  ${PATIENT_FULL}
  mutation($id: ID!, $data: [ID]) {
    deleteTeamMemberWithPatient: updatePatient(
      input: { where: { id: $id }, data: { users: $data } }
    ) {
      patient {
        ...PatientFull
      }
    }
  }
`;

export const ADD_TEAM_MEMBER_TO_PATIENT_LIST = gql`
  ${PATIENT_FULL}
  mutation($id: ID!, $data: [ID]) {
    addTeamMemberToPatient: updatePatient(
      input: { where: { id: $id }, data: { users: $data } }
    ) {
      patient {
        ...PatientFull
      }
    }
  }
`;

//  ===== ACTION PLAN MUTATIONS =====
export const CREATE_ACTION_PLAN_STEP = gql`
  ${ACTION_PLAN_STEP}
  mutation createActionPlanStep($data: ActionPlanStepInput!) {
    createActionPlanStep(input: { data: $data }) {
      actionPlanStep {
        ...ActionPlanStepFields
      }
    }
  }
`;

export const UPDATE_PATIENT_PLAN_STEPS = gql`
  ${ACTION_PLAN_STEP}
  mutation UpdateActionPlanStep($id: ID!, $data: editActionPlanStepInput!) {
    updateActionPlanStep(input: { where: { id: $id }, data: $data }) {
      actionPlanStep {
        ...ActionPlanStepFields
      }
    }
  }
`;

export const CREATE_ACTION_PLAN = gql`
  ${ACTION_PLAN}
  mutation createActionPlan($data: ActionPlanInput!) {
    createActionPlan(input: { data: $data }) {
      actionPlan {
        ...ActionPlanFields
      }
    }
  }
`;

export const UPDATE_ACTION_PLAN = gql`
  ${ACTION_PLAN}
  mutation UpdateActionPlan($id: ID!, $data: editActionPlanInput!) {
    updateActionPlan(input: { where: { id: $id }, data: $data }) {
      actionPlan {
        ...ActionPlanFields
      }
    }
  }
`;

//  ===== PATIENT LOGS MUTATIONS =====
export const UPDATE_PATIENT_LOG_NOTES = gql`
  ${PATIENT_LOG_DETAILS}
  mutation UpdatePatientLogNotes($id: ID!, $data: String) {
    updatePatientLog(input: { where: { id: $id }, data: { notes: $data } }) {
      patientLog {
        ...PatientLogDetails
      }
    }
  }
`;
