/** @jsx jsx */
import { jsx, css } from '@emotion/core';
import styled from '@emotion/styled';
import Skeleton from '@material-ui/lab/Skeleton';
import GlobalStyles from 'assets/GlobalStyles';

const skeletonPageContainerStyling = css`
  background-color: ${GlobalStyles.white};
  padding: 15px 0;
  min-height: 100vh;
`;

const MenuSkeleton = styled(Skeleton)`
  &.MuiSkeleton-root {
    border-top-right-radius: 40px;
    border-bottom-right-radius: 40px;
    height: 80px;
    width: 22%;
  }
`;

const pageTitleStyle = css`
  margin-left: 5%;
  margin-right: 10%;
  width: 78%;
  height: 80px;
`;

const CardSkeleton = styled(Skeleton)`
  &.MuiSkeleton-root {
    border-top-left-radius: 30px;
    border-bottom-left-radius: 30px;
    margin: 25px 0 25px 10%;
    height: 45vh;
  }
`;

const ButtonSkeleton = styled(Skeleton)`
  &.MuiSkeleton-root {
    border-top-left-radius: 30px;
    border-bottom-left-radius: 30px;
    margin: 25px 0 60px 20%;
    height: 65px;
  }
`;

const stepperBoxStyling = css`
  box-sizing: border-box;
  position: absolute;
  bottom: 0;
  left: 0;
  padding: 0 15%;
  height: 90px;
  width: 100vw;
  background-color: ${GlobalStyles.white};
  display: flex;
  justify-content: space-around;
  align-items: center;
`;

const StepperSkeleton = styled(Skeleton)`
  &.MuiSkeleton-root {
    height: 35px;
    width: 35px;
  }
`;

const indexArr = Array(4).fill();
for (let i = 4; i > 0; i--) {
  indexArr[i - 1] = i;
}

const MobileOutOfHospitalSkeleton = () => {
  return (
    <div css={skeletonPageContainerStyling}>
      <div
        css={css`
          display: flex;
          margin-bottom: 50px;
        `}
      >
        <MenuSkeleton variant="rect" />
        <Skeleton css={pageTitleStyle} />
      </div>
      <div>
        <CardSkeleton variant="rect" />
        <ButtonSkeleton variant="rect" />
        <div css={stepperBoxStyling}>
          {indexArr.map((index) => (
            <StepperSkeleton variant="circle" key={index} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default MobileOutOfHospitalSkeleton;
