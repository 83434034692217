const GlobalStyles = {
  //colors:
  red: '#D0021B',

  accentOrange: '#FFBA08',
  mutedOrange: '#FFDB80',
  warningOrange: '#FF8000',
  paleOrange: '#FFEAB3',

  babyBlue: '#0089D0',
  paleBlueCardBackground: '#CAE0F0',
  backgroundBlue: '#D8E7F3',

  footerGreen: '#136F63',

  textBlue: '#2A4A8C',
  paleBlue: '#D8E7F3',
  midBlue: '#3F88C5',
  navyBlue: '#335291',
  navyBlue2: '#2A4A8C',

  navBarBlue: '#048BA8',

  white: '#FFFFFF',
  whiteButton: '#164860',

  black: '#000000',

  disabledGrey: '#9B9B9B',

  blueBorderBottom: '#1C88CA',

  disabledOrangeButtonText: 'rgba(0, 0, 0, 0.26)',
  deleteIconContainerColor: ' rgba(0, 0, 0, 0.5)',
  formInputGrey: 'rgba(0,0,0,0.2)',
  formInputRed: 'rgba(208, 2, 27, 0.2)',
  formInputYellow: 'rgba(255, 186, 8, 0.3)',

  backgroundYellowPatientView: 'rgba(255, 255, 255, 0.8)',

  //misc
  outerContainerBorder: 'solid 7px #3F88C5', //midBlue
  columnGap: '10px',
  mobileBreakpoint: '960px',
  xsMobileBreakpoint: '400px',

  //patient view
  darkBlue: '#002B45', // for the page headers
  darkWhite: '#f5f5f5', // for non-hospital plan download buttons

  // MARGINS
  //Medication forms (add, edit, view files)
  singleInputMarginBottom: '20px',

  // FONTS
  pageTitleSize: '20px',
  inputFontSize: '20px',
};

export default GlobalStyles;
