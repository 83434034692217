/** @jsx jsx */
import { jsx, css } from '@emotion/core';
import { Button } from '@material-ui/core';
import DoneIcon from '@material-ui/icons/Done';
import { useState } from 'react';
import GlobalStyles from 'assets/GlobalStyles';
import { CopyToClipboard } from 'react-copy-to-clipboard';

const PatientViewLink = ({ id, patient, nonHospitalPlanSteps, activeTab }) => {
  const [link] = useState(`https://sap.bcchdigital.ca/patient-view/${id}`);

  const [isCopied, setIsCopied] = useState(false);

  const dataCheck =
    ((activeTab === 'non-hospital' || activeTab === 'hospital') &&
      nonHospitalPlanSteps) ||
    (!activeTab &&
      patient.action_plans[0] &&
      patient.action_plans[0].action_plan_steps);

  return (
    <div>
      <div
        className="planStepContainer__stepTitle"
        css={css`
          display: grid;
          grid-template-columns: repeat(3, 1fr);
          column-gap: ${GlobalStyles.columnGap};
          border-bottom: 5px solid ${GlobalStyles.midBlue};
          margin: 15px 30px 15px 0;
        `}
      >
        <div
          className="planStepContainer__stepTitle"
          css={css`
            width: 70%;
            background-color: ${GlobalStyles.midBlue};
            color: ${GlobalStyles.white};
            font-family: 'roboto-black';
            font-size: 14px;
            text-align: left;
            min-width: 60px;
            padding-left: 5px;
            padding-top: 1px;
            margin-bottom: -0.5%;
          `}
        >
          PATIENT PLAN LINK
        </div>
      </div>
      <div
        className="planStepContainer__stepBody"
        css={css`
          display: grid;
          grid-template-columns: 3fr;
          column-gap: ${GlobalStyles.columnGap};
          margin-right: 30px;
        `}
      >
        <input
          css={css`
            background-color: ${GlobalStyles.formInputGrey};
            color: ${GlobalStyles.navyBlue};
            display: flex;
            align-items: center;
            padding-left: 15px;
            font-size: 14px;
            border: 0;

            :focus {
              background-color: ${GlobalStyles.formInputYellow};
            }
          `}
          value={link}
          readOnly
        />

        <div
          className="NonHospitalPlanStep__ButtonsContainer"
          css={css`
            grid-column: 7 / span 2;
            display: grid;
            grid-template-columns: 1;
          `}
        >
          <CopyToClipboard text={link} onCopy={() => setIsCopied(true)}>
            <Button
              css={css`
                background: ${dataCheck
                  ? GlobalStyles.accentOrange
                  : GlobalStyles.mutedOrange};
                color: ${GlobalStyles.white};
                cursor: ${dataCheck ? 'pointer' : 'not-allowed'};
                width: 100%;
                height: 30px;
                border-radius: 0%;
                align-self: center;
                justify-self: right;
                font-family: 'roboto-black';
                font-size: 12px;
                padding: 15px 48px;
                pointer-events: ${dataCheck ? 'auto' : 'none'};

                :hover {
                  color: ${dataCheck
                    ? GlobalStyles.navyBlue
                    : GlobalStyles.white};
                  background-color: ${dataCheck
                    ? GlobalStyles.accentOrange
                    : GlobalStyles.mutedOrange};
                }
              `}
            >
              {isCopied ? (
                <div
                  css={css`
                    display: flex;
                    align-items: center;
                  `}
                >
                  <span>
                    <DoneIcon fontSize="small" />
                  </span>
                  <span>COPIED</span>
                </div>
              ) : (
                'COPY LINK'
              )}
            </Button>
          </CopyToClipboard>
        </div>
      </div>
    </div>
  );
};

export default PatientViewLink;
