/** @jsx jsx */
import { jsx, css } from '@emotion/core';
import Skeleton from '@material-ui/lab/Skeleton';
import GlobalStyles from 'assets/GlobalStyles';
import Grid from '@material-ui/core/Grid';

const EditProfileSkeleton = () => {
  return (
    <Grid className="edit-profile-skeleton">
      <div
        className="edit-profile-skeleton-title"
        css={css`
          padding: 7px 18px 7px 18px;
          background-color: ${GlobalStyles.midBlue};
          display: inline-block;
        `}
      >
        <Skeleton height={30} width={120} />
      </div>
      <div
        css={css`
          display: grid;
          grid-template-columns: auto 1fr;
        `}
      >
        <div
          className="edit-profile-skeleton-body"
          css={css`
            padding: 0 40px 40px 90px;
            border-top: ${GlobalStyles.outerContainerBorder};
            border-right: ${GlobalStyles.outerContainerBorder};
            border-bottom: ${GlobalStyles.outerContainerBorder};
            border-left: ${GlobalStyles.outerContainerBorder};
            background-color: ${GlobalStyles.white};
            overflow-y: scroll;
          `}
        >
          <div
            css={css`
              min-width: 77vw;
            `}
          ></div>
          <Grid
            container
            className="edit-profile-skeleton"
            direction="row"
            justify="space-around"
          >
            <Grid item xs={4}>
              <Skeleton height={300} width={'20vw'} />
            </Grid>
            <Grid item xs={8}>
              <div
                css={css`
                  padding-top: 48px;
                `}
              ></div>
              {Array(7)
                .fill()
                .map((item, index) => (
                  <Skeleton height={100} width={'45vw'} key={index} />
                ))}
            </Grid>
          </Grid>
        </div>
      </div>
    </Grid>
  );
};

export default EditProfileSkeleton;
