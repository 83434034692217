/** @jsx jsx */
import { jsx, css } from '@emotion/core';
import GlobalStyles from 'assets/GlobalStyles';
import { Fragment, useContext, useEffect } from 'react';
import NativeSelect from '@material-ui/core/NativeSelect';
import TextField from '@material-ui/core/TextField';
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator';

import CalculationsforStepTwoAndThree from 'components/Steps/CalculationsforStepTwoAndThree';
import { DEFAULT_VALUES } from './constants';
import StepContext from 'components/Steps/StepContext';

const h4Styling = css`
  align-self: center;
  margin: 0;
  white-space: nowrap;
  display: inline-block;
  padding: 6px 5px 7px 0px;
`;

function EditStepTwoAndThree({
  displayInputColor,
  isEditingCurrentStep,
  handleChange,
  handleDosageChange,
  displayAdministeredDosage,
  handleOpen,
}) {
  const { stepState } = useContext(StepContext);
  const {
    step,
    stepNumber,
    medicationData,
    formFields,
    patientData,
  } = stepState;
  const {
    total_time_in_minutes,
    length_of_current_seizure_time_in_minutes,
    total_number_of_seizures,
  } = formFields;

  const textFieldStyling = css`
    background-color: ${displayInputColor()};
    max-width: 50px;
    min-width: 40px;
    margin: 0 10px 5px 0px;
    max-height: -webkit-fill-available;
    color: ${GlobalStyles.navyBlue};
  `;

  const rescueMedications = () => {
    if (medicationData && medicationData.medications) {
      return medicationData.medications.filter(
        (m) =>
          m.generic_name.includes('Lorazepam') ||
          m.generic_name.includes('Midazolam')
      );
    } else {
      return null;
    }
  };

  const medicationList = rescueMedications() && [...rescueMedications()];

  // Error message if value will round to <.01
  useEffect(() => {
    ValidatorForm.addValidationRule('warningRoundingToZero', (value) => {
      if (value < 0.005) {
        return false;
      }
      return true;
    });
  });

  return (
    <Fragment>
      <div
        css={css`
          margin-bottom: 12px;
          margin-top: 20px;
          display: inline-block;
        `}
        className="stepTwoAndThree- first-line"
      >
        {patientData &&
          step &&
          step.step_weight_last_updated <
            patientData.patient.weight_last_updated && (
            <h4
              css={css`
                margin-top: 0px;
                margin-bottom: 12px;
                font-family: 'roboto';
                font-size: 14px;
                color: ${GlobalStyles.red};
              `}
            >
              As the patient&#39;s weight was updated, the dosage amount was
              reset. Please update the dosage amount.
            </h4>
          )}
        <h4 css={h4Styling}>
          IF THE CHILD IS {stepNumber === 3 && 'STILL'} SEIZING AT
        </h4>

        {stepNumber === 2 ? (
          <TextField
            type="number"
            css={textFieldStyling}
            disabled={!step && !isEditingCurrentStep()}
            onChange={handleChange('length_of_current_seizure_time_in_minutes')}
            value={
              length_of_current_seizure_time_in_minutes
                ? length_of_current_seizure_time_in_minutes
                : length_of_current_seizure_time_in_minutes === 0 && 0
            }
            onKeyDown={(event) => {
              if (event.key === 'e' || event.key === '-' || event.key === '+') {
                event.preventDefault();
              }
            }}
            onBlur={(event) => {
              if (event.target.value === '') {
                event.preventDefault();
                event.target.value = DEFAULT_VALUES.LENGTH_OF_SEIZURE_STEP_2;
              }
            }}
            placeholder={
              isEditingCurrentStep()
                ? DEFAULT_VALUES.LENGTH_OF_SEIZURE_STEP_2.toString()
                : null
            }
          />
        ) : (
          <TextField
            css={textFieldStyling}
            disabled={!step && !isEditingCurrentStep()}
            onChange={handleChange('length_of_current_seizure_time_in_minutes')}
            value={
              length_of_current_seizure_time_in_minutes
                ? length_of_current_seizure_time_in_minutes
                : ''
            }
            placeholder={
              isEditingCurrentStep()
                ? DEFAULT_VALUES.LENGTH_OF_SEIZURE_STEP_3.toString()
                : null
            }
          />
        )}
        <h4 css={h4Styling}>
          MINS {stepNumber === 3 && '(AND BREATHING IS STABLE)'}
          {stepNumber === 2 && 'OR HAS'}{' '}
        </h4>
        {stepNumber === 2 && (
          <Fragment>
            <TextField
              type="text"
              css={textFieldStyling}
              disabled={!step && !isEditingCurrentStep()}
              onChange={handleChange('total_number_of_seizures')}
              value={total_number_of_seizures ? total_number_of_seizures : ''}
              placeholder={
                isEditingCurrentStep()
                  ? DEFAULT_VALUES.TOTAL_NUMBER_OF_SEIZURES.toString()
                  : null
              }
            />
            <h4 css={h4Styling}>SEIZURES IN </h4>
            <TextField
              type="text"
              css={textFieldStyling}
              disabled={!step && !isEditingCurrentStep()}
              onChange={handleChange('total_time_in_minutes')}
              value={total_time_in_minutes ? total_time_in_minutes : ''}
              placeholder={
                isEditingCurrentStep()
                  ? DEFAULT_VALUES.TOTAL_TIME.toString()
                  : null
              }
            />
            <h4 css={h4Styling}>MINS</h4>
          </Fragment>
        )}
      </div>

      <div
        className="stepTwoAndThree-second-line"
        css={css`
          margin-bottom: 12px;
          margin-top: 20px;
          font-family: 'roboto-light';
          font-size: 21px;

          & > * {
            display: inline-block;
          }
        `}
      >
        Give
        <NativeSelect
          css={css`
            background-color: ${displayInputColor()};
            width: 35%;
            min-width: fit-content;
            margin: 5px 0 5px 5px;
            color: ${GlobalStyles.navyBlue};
            font-weight: bold;
          `}
          disabled={!step && !isEditingCurrentStep()}
          value={
            isEditingCurrentStep()
              ? stepState.formFields.medication
                ? stepState.formFields.medication
                : ''
              : ''
          }
          onChange={handleChange('medication')}
        >
          <option value="" disabled>
            {' '}
          </option>

          {medicationData &&
            medicationData.medications &&
            medicationList.map((m, index) => (
              <option
                key={index}
                value={m.id}
                css={css`
                  color: ${GlobalStyles.navyBlue};
                `}
              >
                {`${m.generic_name.split('_').join(' ')}`}
              </option>
            ))}
        </NativeSelect>{' '}
        at a dosage of
        <TextValidator
          data-cy={`edit-administered-dose-step-${stepNumber}`}
          css={css`
            background-color: ${displayInputColor('administered_dose')};
            width: 90px;
            margin: 0 10px;
            display: inline-block;
            color: ${GlobalStyles.textBlue};
            .MuiFormHelperText-root.Mui-error {
              background-color: ${GlobalStyles.white};
            }
          `}
          onBlur={(e) => handleOpen(e)}
          onChange={handleDosageChange('administered_dose')}
          disabled={
            (!step && !isEditingCurrentStep()) ||
            (isEditingCurrentStep() && !formFields.medication)
          }
          validators={['required', 'warningRoundingToZero']}
          errorMessages={[
            'This field is required',
            'Please enter a minimum value of 0.01',
          ]}
          value={displayAdministeredDosage()}
        />
        mg
        {stepNumber === 2 && (
          <CalculationsforStepTwoAndThree
            displayInputColor={displayInputColor}
            isEditingCurrentStep={isEditingCurrentStep}
            handleDosageChange={handleDosageChange}
          />
        )}
      </div>
    </Fragment>
  );
}

export default EditStepTwoAndThree;
