/** @jsx jsx */
import { jsx, css } from '@emotion/core';
import styled from '@emotion/styled';
import { Fragment } from 'react';
import GlobalStyles from 'assets/GlobalStyles';
import AppBar from '@material-ui/core/AppBar';
import { Link } from 'react-scroll';
import Logo from 'assets/images/SAP-logo-white.png';

const NavLink = styled(Link)`
  &.active {
    text-decoration: underline;
    text-underline-position: under;
  }
`;

function NavBar({ patient }) {
  return (
    <AppBar
      className="NavBar"
      position="static"
      css={css`
        grid-column: 1 / span 12;
        box-shadow: none;
        background-color: ${GlobalStyles.midBlue};
        display: grid;
        justify-content: right;
        grid-template-columns: repeat(14, 1fr);
        column-gap: ${GlobalStyles.columnGap};
        position: sticky;
        top: 0;
        z-index: 1;
      `}
    >
      <h1
        css={css`
          display: none;
        `}
      >
        Seizure Action Plan
      </h1>
      <img
        src={Logo}
        alt="navBar-logo"
        css={css`
          grid-column: 2;
          height: 60px;
          align-self: center;
        `}
      />

      <Fragment>
        <NavLink
          className="patientViewNavBar__button-care"
          activeClass="active"
          spy={true}
          offset={-200}
          smooth={true}
          to="PVDetails"
          duration={100}
          css={css`
            grid-column: 3 / span 2;
            color: ${GlobalStyles.white};
            cursor: pointer;
            align-self: center;
            justify-self: center;
            text-decoration: none;
            font-size: 90%;
            text-align: center;
            &:hover {
              text-decoration: underline;
              text-underline-position: under;
            }
          `}
        >
          {`${patient.nickname.toUpperCase()}'S DETAILS`}
        </NavLink>
        <NavLink
          className="patientViewNavBar__button-patient"
          smooth={true}
          to="PVNonHospitalPlan"
          spy={true}
          offset={-200}
          activeClass="active"
          duration={100}
          css={css`
            grid-column: 5 / span 2;
            color: ${GlobalStyles.white};
            cursor: pointer;
            align-self: center;
            justify-self: center;
            text-decoration: none;
            font-size: 90%;
            text-align: center;
            &:hover {
              text-decoration: underline;
              text-underline-position: under;
            }
          `}
        >
          {`${patient.nickname.toUpperCase()}'S OUT-OF-HOSPITAL PLAN`}
        </NavLink>
        <NavLink
          className="patientViewNavBar__button-medication"
          activeClass="active"
          spy={true}
          offset={-200}
          smooth={true}
          to="PVHospitalPlan"
          duration={100}
          css={css`
            grid-column: 7 / span 2;
            color: ${GlobalStyles.white};
            cursor: pointer;
            align-self: center;
            justify-self: center;
            text-decoration: none;
            font-size: 90%;
            text-align: center;
            &:hover {
              text-decoration: underline;
              text-underline-position: under;
            }
          `}
        >
          {`${patient.nickname.toUpperCase()}'S HOSPITAL PLAN`}
        </NavLink>
        <NavLink
          className="patientViewNavBar__button-care"
          activeClass="active"
          spy={true}
          offset={-200}
          smooth={true}
          to="PPClinicalTeam"
          duration={100}
          css={css`
            grid-column: 9 / span 2;
            color: ${GlobalStyles.white};
            cursor: pointer;
            align-self: center;
            justify-self: center;
            text-decoration: none;
            font-size: 90%;
            text-align: center;
            &:hover {
              text-decoration: underline;
              text-underline-position: under;
            }
          `}
        >
          {`${patient.nickname.toUpperCase()}'S CLINICAL CARE TEAM`}
        </NavLink>
        <NavLink
          className="patientViewNavBar__button-care"
          activeClass="active"
          spy={true}
          offset={-200}
          smooth={true}
          duration={100}
          to="PatientViewDownload"
          css={css`
            grid-column: 11 / span 2;
            color: ${GlobalStyles.white};
            cursor: pointer;
            align-self: center;
            justify-self: center;
            text-decoration: none;
            font-size: 90%;
            text-align: center;
            &:hover {
              text-decoration: underline;
              text-underline-position: under;
            }
          `}
        >
          {`SHARE/DOWNLOAD ${patient.nickname.toUpperCase()}'S PLAN`}
        </NavLink>
        <NavLink
          className="patientViewNavBar__button-care"
          activeClass="active"
          spy={true}
          offset={-200}
          smooth={true}
          to="SeizureFirstAid"
          duration={100}
          css={css`
            grid-column: 13 / span 2;
            color: ${GlobalStyles.white};
            cursor: pointer;
            align-self: center;
            justify-self: center;
            text-decoration: none;
            font-size: 90%;
            text-align: center;
            &:hover {
              text-decoration: underline;
              text-underline-position: under;
            }
          `}
        >
          {'WHEN TO CALL 9-1-1'}
        </NavLink>
      </Fragment>
    </AppBar>
  );
}

export default NavBar;
