import { Fragment } from 'react';
import { useQuery } from '@apollo/client';
import { useParams } from 'react-router-dom';
/** @jsx jsx */
import { jsx } from '@emotion/core';
import MedicationView from 'pages/MedicationView';
import { GET_MEDICATION } from 'utils/queries';
import EditMedicationForm from 'components/EditMedicationForm';

//Loading Skeleton
import EditProfileSkeleton from '../components/Skeletons/EditProfileSkeleton';

function MedicationPage({ isEditing }) {
  const { id } = useParams();
  const { data, loading, refetch } = useQuery(GET_MEDICATION, {
    variables: { id },
  });

  const medication = data ? data.medication : {};

  return (
    <div>
      {loading ? (
        <EditProfileSkeleton />
      ) : (
        <Fragment>
          {isEditing ? (
            <EditMedicationForm
              medication={medication}
              refetchMedication={refetch}
              title={'Edit medication'}
            />
          ) : (
            <div>
              <MedicationView medication={medication} isEditing={isEditing} />
            </div>
          )}
        </Fragment>
      )}
    </div>
  );
}

export default MedicationPage;
