import { useState } from 'react';
/** @jsx jsx */
import { jsx, css } from '@emotion/core';
import GlobalStyles from 'assets/GlobalStyles';
import PlanStepContainer from 'components/PlanStepContainer';

function PlanContainer({ planSteps, isEditing, planType }) {
  const stepsOrder = planType === 'non-hospital' ? [1, 2, 3, 4] : [1, 2];
  const [isErrorMessageDisplayed, setIsErrorMessageDisplayed] = useState(false);

  return (
    <div
      className="planContainer"
      css={css`
        grid-column: 2 / span 8;
        height: 500px;
        color: ${GlobalStyles.navyBlue};
        width: 108%;
      `}
    >
      {stepsOrder.map((stepNumber) => {
        const step = planSteps
          ? planSteps.find((ps) => ps.step_number === stepNumber)
          : null;

        return step ? (
          <PlanStepContainer
            key={step.step_number}
            step={step}
            stepNumber={step.step_number}
            isEditing={isEditing}
            planType={planType}
            isErrorMessageDisplayed={isErrorMessageDisplayed}
            setIsErrorMessageDisplayed={setIsErrorMessageDisplayed}
          />
        ) : (
          <PlanStepContainer
            key={stepNumber}
            stepNumber={stepNumber}
            isEditing={isEditing}
            planType={planType}
            isErrorMessageDisplayed={isErrorMessageDisplayed}
            setIsErrorMessageDisplayed={setIsErrorMessageDisplayed}
          />
        );
      })}
    </div>
  );
}

export default PlanContainer;
