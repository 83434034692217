/** @jsx jsx */
import { jsx, css } from '@emotion/core';
import GlobalStyles from 'assets/GlobalStyles';

function MobileAppHeader({ patient }) {
  return (
    <div>
      <h1
        css={css`
          font-family: 'poppins-bold';
          font-size: 28px;
          font-weight: bold;
          color: ${GlobalStyles.darkBlue};
          text-transform: uppercase;
          margin: 0 0 0 20%;
        `}
      >
        {patient.nickname}'s
      </h1>
      <h1
        css={css`
          font-family: 'poppins-bold';
          font-size: 20px;
          line-height: 19px;
          color: ${GlobalStyles.midBlue};
          text-align: left;
          margin: 0 0 5px 20%;
        `}
      >
        SEIZURE ACTION PLAN
      </h1>
    </div>
  );
}

export default MobileAppHeader;
