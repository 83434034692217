import { useState, useEffect, useRef, Fragment } from 'react';
import axios from 'axios';
import { useHistory, useParams } from 'react-router-dom';
import { useApolloClient, useMutation, useQuery } from '@apollo/client';
import GlobalStyles from 'assets/GlobalStyles';
import {
  Grid,
  Button,
  TextField,
  GridList,
  GridListTile,
  GridListTileBar,
} from '@material-ui/core';
/** @jsx jsx */
import { jsx, css } from '@emotion/core';
import { GET_ROLES, GET_ALL_MEDICAL_TEAM_MEMBERS } from 'utils/queries';
import { UPDATE_TEAM_MEMBER, CREATE_TEAM_MEMBER } from 'utils/mutations';
import { TEAM_MEMBER_WITHOUT_PATIENT_LIST } from 'utils/fragments';
import PlaceholderImage from 'assets/images/placeholder.jpg';
import DeleteIcon from '@material-ui/icons/Delete';
import {
  ValidatorForm,
  TextValidator,
  SelectValidator,
} from 'react-material-ui-form-validator';
import { Snackbar, IconButton, CircularProgress } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import MenuItem from '@material-ui/core/MenuItem';

//Loading Skeleton
import EditProfileSkeleton from '../components/Skeletons/EditProfileSkeleton';

// Styles
const textValidatorStyle = css`
  width: 100%;
  background-color: ${GlobalStyles.paleOrange};
  border-top: 3px solid ${GlobalStyles.navyBlue};
  .MuiInputBase-input {
    color: ${GlobalStyles.navyBlue2};
    font-size: 20px;
    font-weight: 500;
    letter-spacing: 1.25px;
    margin-left: 10px;
  }

  .MuiInput-underline:before {
    border-bottom: 0px;
  }

  .MuiInputBase-input.Mui-disabled {
    color: ${GlobalStyles.navyBlue2};
  }
  .MuiFormHelperText-root.Mui-error {
    background-color: ${GlobalStyles.white};
    margin-bottom: -5px;
  }
`;

const formInputLabelStyle = css`
  width: fit-content;
  background-color: ${GlobalStyles.navyBlue2};
  color: ${GlobalStyles.white};
  font-size: 14px;
  font-family: 'roboto-black';
  margin-top: 20px;
  letter-spacing: 1px;
  text-align: left;
  padding: 4px 6px 4px 6px;
`;

const buttonShawdowBoxStyle = css`
  line-height: 1.75;
  width: 100%;
  height: 32px;
  margin-top: 10px;
`;

const buttonStyle = css`
  font-family: 'poppins-medium';
  background-color: ${GlobalStyles.accentOrange};
  color: ${GlobalStyles.white};
  cursor: pointer;
  border-radius: 0%;
  &:hover {
    color: ${GlobalStyles.navyBlue};
    background-color: ${GlobalStyles.accentOrange};
  }
  transform: translate(0px, -31px);
  height: 32px;
`;

const imageUploadFormStyle = css`
  min-height: 173px;
  max-height: 237px;
  display: grid;
  margin-top: 20px;
  background-color: ${GlobalStyles.paleBlue};
`;

const imageUploadBtnStyle = css`
  font-family: 'roboto-black';
  font-size: 14px;
  letter-spacing: 1px;
  background-color: ${GlobalStyles.accentOrange};
  color: ${GlobalStyles.white};
  cursor: pointer;
  text-align: center;
  height: 28px;
  padding-top: 10px;
  &:hover {
    color: ${GlobalStyles.navyBlue};
    background-color: ${GlobalStyles.accentOrange};
  }
`;

const imagePreviewBoxStyle = css`
  display: flex;
  justify-content: center;
  height: 164px;
  margin-top: 5px;

  img {
    margin-bottom: 20px;
    object-fit: contain;
    min-height: 100px;
  }

  .MuiCircularProgress-colorPrimary {
    color: ${GlobalStyles.navyBlue};
  }
`;

const deleteIconContainerStyle = css`
  height: 30px;
  margin: auto;
  max-width: inherit;
  display: flex;
  align-items: center;
`;

const viewImages = css`
  background: ${GlobalStyles.paleBlue};
  max-height: -webkit-fill-available;
  margin-top: 0;
  padding: 0 30px 10px 30px;
  width: 200px;
  display: flex;
  flex-direction: inherit;
`;

function CustomTextField(props) {
  return (
    <TextField
      fullWidth
      type="text"
      InputLabelProps={{
        shrink: true,
      }}
      css={css`
        border-top: 3px solid ${GlobalStyles.navyBlue2};
        background-color: ${GlobalStyles.paleOrange};
        font-family: 'roboto-medium';

        /* disabled style for role during editing existing team member */
        .MuiInputBase-input.Mui-disabled {
          margin: 0px;
          padding-left: 10px;
          background-color: ${GlobalStyles.white};
        }

        .MuiInputBase-input {
          color: ${GlobalStyles.navyBlue2};
          font-size: 20px;
          margin-left: 10px;
          letter-spacing: 1.25px;
        }

        .MuiInput-underline:before {
          border-bottom: 0px;
        }

        .MuiFormHelperText-root.Mui-error {
          background-color: ${GlobalStyles.white};
          margin-bottom: -5px;
        }
      `}
      {...props}
    />
  );
}

function TeamMemberForm({ teamMember, refetchTeamMember, title }) {
  const history = useHistory();
  const { id } = useParams();
  const client = useApolloClient();
  const imageFormEl = useRef(null);
  const [open, setOpen] = useState(false);
  const [imageLoading, setImageLoading] = useState(false);
  const [loadingSubmit, setLoadingSubmit] = useState(false);
  const location = window.location.href;
  const isProfile = location.includes(`profile`);
  const [imagePreview, setImagePreview] = useState({ image: null });
  const [uniqueError, setUniqueError] = useState();

  const { data: rolesData, loading: loadingRoles } = useQuery(GET_ROLES);
  const [updateTeamMember] = useMutation(UPDATE_TEAM_MEMBER, {
    refetchQueries: [{ query: GET_ALL_MEDICAL_TEAM_MEMBERS }],
    awaitRefetchQueries: true,
  });
  const [createTeamMember] = useMutation(CREATE_TEAM_MEMBER, {
    refetchQueries: [{ query: GET_ALL_MEDICAL_TEAM_MEMBERS }],
    awaitRefetchQueries: true,
  });

  const [formFields, setFormFields] = useState(
    teamMember
      ? {
          username: teamMember.username ? teamMember.username : '',
          first_name: teamMember.first_name ? teamMember.first_name : '',
          last_name: teamMember.last_name ? teamMember.last_name : '',
          prefix: teamMember.prefix ? teamMember.prefix : '',
          position: teamMember.position ? teamMember.position : '',
          contact_info: teamMember.contact_info ? teamMember.contact_info : '',
          email: teamMember.email ? teamMember.email : '',
          notes: teamMember.notes ? teamMember.notes : '',
          role: teamMember.role.id ? teamMember.role.id : '',
        }
      : {
          username: '',
          first_name: '',
          last_name: '',
          prefix: '',
          position: '',
          contact_info: '',
          email: '',
          notes: '',
          role: '',
        }
  );

  useEffect(() => {
    if (teamMember) {
      setFormFields({
        username: teamMember.username ? teamMember.username : '',
        first_name: teamMember.first_name ? teamMember.first_name : '',
        last_name: teamMember.last_name ? teamMember.last_name : '',
        prefix: teamMember.prefix ? teamMember.prefix : '',
        position: teamMember.position ? teamMember.position : '',
        contact_info: teamMember.contact_info ? teamMember.contact_info : '',
        email: teamMember.email ? teamMember.email : '',
        notes: teamMember.notes ? teamMember.notes : '',
        role: teamMember.role.id ? teamMember.role.id : '',
      });
    }
  }, [teamMember]);

  useEffect(() => {
    ValidatorForm.addValidationRule('isUnique', () => {
      if (uniqueError) {
        return false;
      }
      return true;
    });
  });

  const handleImagePreview = async (event) => {
    //if adding a new teamMember then need to set a imagePreview as you cannot attach the image rightaway to a user that does not yet exist
    if (event.target.files && event.target.files[0]) {
      setImagePreview({ image: URL.createObjectURL(event.target.files[0]) });
    }
    setImageLoading(false);
  };

  const handleSubmitNewImage = async (e, newMemberRecord) => {
    const formElement = imageFormEl.current;
    const formData = new FormData(formElement);
    if (!formElement.files.value) {
      return;
    }
    setImageLoading(true);
    if (newMemberRecord) {
      const newMemberRecordId = newMemberRecord.id;
      formData.append('refId', newMemberRecordId);
    }

    if (id || newMemberRecord) {
      const options = {
        method: 'POST',
        body: formData,
      };

      return fetch(`${process.env.REACT_APP_API_URL}/upload`, options)
        .then((response) => response.json())
        .then(async () => {
          if (id) {
            await refetchTeamMember();
          }
          setImageLoading(false);
        })
        .catch((error) => {
          console.error('Error:', error);
        });
    } else {
      handleImagePreview(e);
    }
  };

  const handleChange = (key) => ({ target: { value } }) => {
    if (key === 'username' || key === 'email') {
      if (uniqueError) {
        setUniqueError(false);
      }
    }
    setFormFields({ ...formFields, [key]: value });
  };

  const handleChangePasswordReset = async (e) => {
    e.preventDefault();

    axios
      .post(`${process.env.REACT_APP_API_URL}/auth/forgot-password`, {
        email: teamMember.email,
      })
      .then(() => {
        console.log('Your user received an email');
      })
      .catch((error) => {
        console.log('An error occurred:', error.response);
      });
  };

  const handleEmailOrUsernameIsNotUnique = () => {
    setUniqueError(true);
    //need to set username and email to add empty space at end, then remove it to trigger change for validation error on these input to display (bit hacky!)
    setFormFields({
      ...formFields,
      username: formFields.username + ' ',
      email: formFields.email + ' ',
    });
    setFormFields({
      ...formFields,
      username: formFields.username.trim(),
      email: formFields.email.trim(),
    });
  };

  const handleSubmitNewProfile = async (e) => {
    e.preventDefault();
    setLoadingSubmit(true);
    setUniqueError(false);

    const formElementImage = imageFormEl.current;
    formFields['password'] = 'password1';

    createTeamMember({
      variables: { data: formFields },
    })
      .then(async (response) => {
        const newMemberRecord = response.data.createUser.user;

        if (formElementImage.files.value) {
          await handleSubmitNewImage(e, newMemberRecord);
        }

        //send user email to user to reset their password
        axios
          .post(`${process.env.REACT_APP_API_URL}/auth/forgot-password`, {
            email: formFields.email,
          })
          .then(() => {
            console.log('Your user received an email');
          })
          .catch((error) => {
            console.log('An error occurred:', error.response);
            setOpen(true);
          });
        setLoadingSubmit(false);
        history.push(`/team`);
      })
      .catch((error) => {
        setLoadingSubmit(false);
        console.log('error', error);
        if (
          error.message.includes(
            'Cannot return null for non-nullable field UsersPermissionsUser.id'
          )
        ) {
          handleEmailOrUsernameIsNotUnique();
        }
        setOpen(true);
      });
  };

  const handleSubmitEditProfile = async (e) => {
    e.preventDefault();
    setLoadingSubmit(true);
    updateTeamMember({
      variables: { id, data: formFields },
    })
      .then(async () => {
        setLoadingSubmit(false);
        await history.goBack();
      })
      .catch((error) => {
        setLoadingSubmit(false);
        console.log('error', error);
        if (
          error.message.includes(
            'Cannot return null for non-nullable field UsersPermissionsUser.id'
          )
        ) {
          handleEmailOrUsernameIsNotUnique();
        }
        setOpen(true);
      });
  };

  const handleImageDelete = async (e) => {
    setImageLoading(true);
    e.preventDefault();

    const options = {
      method: 'DELETE',
    };

    const imageId = teamMember.image ? teamMember.image.id : '';

    //clear any chached images saved in the form.input values
    const formElement = imageFormEl.current;
    formElement.files.value = '';

    if (!imageId) {
      setTimeout(() => {}, 500);
    } else {
      fetch(`${process.env.REACT_APP_API_URL}/upload/files/${imageId}`, options)
        .then((response) => response.json())
        .then(async () => {
          await client.writeFragment({
            id: client.cache.identify(teamMember),
            fragment: TEAM_MEMBER_WITHOUT_PATIENT_LIST,
            data: {
              image: null,
            },
            fragmentName: 'TeamMemberWithoutPatientList',
          });
          setImageLoading(false);
        })
        .catch((error) => {
          console.error('Error:', error);
        });
    }
  };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };

  // uploaded images < 157px height will be null in thumbnail, display non-optimized for such cases but use optimized thumbnails if larger
  const displayImage = () => {
    let imageToReturn;
    if (imagePreview.image) {
      imageToReturn = imagePreview.image;
    } else if (teamMember) {
      if (teamMember.image && teamMember.image.formats === null) {
        imageToReturn = teamMember.image.url;
      } else if (teamMember.image && teamMember.image.formats !== null) {
        imageToReturn = teamMember.image.formats.thumbnail.url;
      } else {
        imageToReturn = PlaceholderImage;
      }
    } else {
      imageToReturn = PlaceholderImage;
    }
    return imageToReturn;
  };

  const rolesNeeded = rolesData
    ? rolesData.roles.filter((role) => role.name !== 'Public')
    : null;

  // Error messages
  const requiredField = 'This field is required';
  const uniqueEmailOrUsernameRequired =
    'This email or username may already exist, please add a unique entry';
  const invalidEmail = 'Email is not valid';

  return (
    <div>
      {loadingRoles ? (
        <EditProfileSkeleton />
      ) : (
        <Fragment>
          <div
            className="teamMemberForm__containerHeader"
            css={css`
              font-size: ${GlobalStyles.pageTitleSize};
              padding: 10px;
              font-weight: bolder;
              background-color: ${GlobalStyles.midBlue};
              color: ${GlobalStyles.white};
              width: max-content;
              text-align: left;
              letter-spacing: 1px;
            `}
          >
            {title.toUpperCase()}
          </div>
          <div
            className="teamMemberForm__containerBody"
            css={css`
              padding: 40px 80px;
              border: ${GlobalStyles.outerContainerBorder};
              overflow-y: hidden;
              background-color: ${GlobalStyles.white};
            `}
          >
            <Grid container>
              <Grid item>
                <form ref={imageFormEl} css={imageUploadFormStyle}>
                  {imageLoading ? (
                    <div
                      css={css`
                        text-align: center;
                      `}
                    >
                      <CircularProgress
                        size={30}
                        css={css`
                          .MuiCircularProgress-colorPrimary {
                            color: ${GlobalStyles.navyBlue};
                          }
                        `}
                      />
                    </div>
                  ) : (
                    <label css={imageUploadBtnStyle}>
                      ADD IMAGE
                      <input
                        type="file"
                        id="teamMemberForm__imageInput"
                        name="files"
                        required
                        accept="image/png, image/jpg, image/jpeg"
                        onChange={handleSubmitNewImage}
                        css={css`
                          display: none;
                        `}
                      />
                    </label>
                  )}

                  <GridList
                    // Style tag is needed to override negative margin from mui
                    style={{ margin: 0 }}
                    cellHeight={150}
                    cols={1}
                    css={viewImages}
                  >
                    <GridListTile cols={1} rows={1} css={imagePreviewBoxStyle}>
                      <img
                        src={displayImage()}
                        alt={
                          teamMember
                            ? `${teamMember.prefix} ${teamMember.first_name} ${teamMember.last_name}`
                            : 'Placeholder image for new team member'
                        }
                        style={{
                          height: '150px',
                          margin: 'auto',
                          padding: '10px 0',
                        }}
                      />
                      {teamMember && teamMember.image && (
                        <GridListTileBar
                          css={deleteIconContainerStyle}
                          actionIcon={
                            <DeleteIcon
                              onClick={
                                !teamMember || !teamMember.image || imageLoading
                                  ? null
                                  : handleImageDelete
                              }
                              variant="contained"
                              color="secondary"
                              style={{
                                color:
                                  !teamMember ||
                                  !teamMember.image ||
                                  imageLoading
                                    ? 'transparent'
                                    : `${GlobalStyles.accentOrange}`,
                              }}
                              css={css`
                                cursor: pointer;
                              `}
                            />
                          }
                        />
                      )}
                    </GridListTile>
                  </GridList>

                  <input type="hidden" name="refId" value={id} />
                  <input type="hidden" name="ref" value="user" />
                  <input
                    type="hidden"
                    name="source"
                    value="users-permissions"
                  />
                  <input type="hidden" name="field" value="image" />
                </form>
              </Grid>

              {/* User Form Column */}
              <Grid
                container
                item
                xs
                css={css`
                  padding-left: 10px;
                `}
              >
                <ValidatorForm
                  className="edit-team-member__edit-info-form"
                  onError={(errors) => console.log(errors)}
                  onSubmit={
                    id ? handleSubmitEditProfile : handleSubmitNewProfile
                  }
                  css={css`
                    margin-left: 50px;
                    width: 100%;
                  `}
                >
                  <div css={formInputLabelStyle}> USERNAME*</div>
                  <TextValidator
                    fullWidth
                    type="text"
                    value={formFields.username ? formFields.username : ''}
                    onChange={handleChange('username')}
                    validators={['required', 'isUnique']}
                    aria-hidden="false"
                    errorMessages={[
                      requiredField,
                      uniqueEmailOrUsernameRequired,
                    ]}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    css={textValidatorStyle}
                  ></TextValidator>

                  <div css={formInputLabelStyle}> ROLE* </div>

                  {teamMember ? (
                    <CustomTextField
                      value={teamMember.role.name}
                      disabled={true}
                    />
                  ) : (
                    <SelectValidator
                      id="role-select"
                      css={textValidatorStyle}
                      value={formFields.role ? formFields.role : ''}
                      onChange={handleChange('role')}
                      validators={['required']}
                      errorMessages={[requiredField]}
                    >
                      {rolesNeeded.map((role) => (
                        <MenuItem
                          key={role.id}
                          value={role.id}
                        >{`${role.name}`}</MenuItem>
                      ))}
                    </SelectValidator>
                  )}

                  <div css={formInputLabelStyle}> PREFIX </div>
                  <TextField
                    type="text"
                    value={formFields.prefix ? formFields.prefix : ''}
                    onChange={handleChange('prefix')}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    css={textValidatorStyle}
                  ></TextField>

                  <div css={formInputLabelStyle}> TITLE* </div>
                  <TextValidator
                    fullWidth
                    type="text"
                    value={formFields.position ? formFields.position : ''}
                    onChange={handleChange('position')}
                    validators={['required']}
                    errorMessages={[requiredField]}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    css={textValidatorStyle}
                  ></TextValidator>

                  <Grid container direction="row" spacing={3}>
                    <Grid item xs>
                      <div css={formInputLabelStyle}> FIRST NAME* </div>
                      <TextValidator
                        fullWidth
                        type="text"
                        value={
                          formFields.first_name ? formFields.first_name : ''
                        }
                        onChange={handleChange('first_name')}
                        validators={['required']}
                        errorMessages={[requiredField]}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        css={textValidatorStyle}
                      ></TextValidator>
                    </Grid>

                    <Grid item xs>
                      <div css={formInputLabelStyle}> LAST NAME* </div>
                      <TextValidator
                        fullWidth
                        type="text"
                        value={formFields.last_name ? formFields.last_name : ''}
                        onChange={handleChange('last_name')}
                        validators={['required']}
                        errorMessages={[requiredField]}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        css={textValidatorStyle}
                      ></TextValidator>
                    </Grid>
                  </Grid>

                  <div css={formInputLabelStyle}> PHONE </div>
                  <TextField
                    type="text"
                    value={
                      formFields.contact_info ? formFields.contact_info : ''
                    }
                    onChange={handleChange('contact_info')}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    css={textValidatorStyle}
                  ></TextField>

                  <div css={formInputLabelStyle}> EMAIL* </div>
                  <TextValidator
                    fullWidth
                    type="text"
                    value={formFields.email ? formFields.email : ''}
                    onChange={handleChange('email')}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    validators={['required', 'isEmail', 'isUnique']}
                    errorMessages={[
                      requiredField,
                      invalidEmail,
                      uniqueEmailOrUsernameRequired,
                    ]}
                    css={textValidatorStyle}
                  ></TextValidator>

                  <div css={formInputLabelStyle}> NOTES </div>
                  <CustomTextField
                    multiline
                    rows={5}
                    value={formFields.notes ? formFields.notes : ''}
                    onChange={handleChange('notes')}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />

                  {isProfile && (
                    <Grid item xs>
                      <Button
                        fullWidth
                        css={css`
                          margin: 10px 0 20px 0;
                          font-weight: bolder;
                          align-self: center;
                          background-color: ${GlobalStyles.accentOrange};
                          color: ${GlobalStyles.white};
                          cursor: pointer;
                          width: 100%;
                          text-decoration: none;
                          border-radius: 0% !important;
                          &:hover {
                            color: ${GlobalStyles.navyBlue};
                            background-color: ${GlobalStyles.accentOrange};
                          }
                          height: 32px;
                        `}
                        onClick={handleChangePasswordReset}
                      >
                        SEND PASSWORD RESET LINK TO EMAIL
                      </Button>
                    </Grid>
                  )}
                  <Snackbar
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'center',
                    }}
                    open={open}
                    autoHideDuration={6000}
                    onClose={handleClose}
                    message={
                      uniqueError
                        ? 'Sorry this team member could not be saved - please check username and email are unique'
                        : 'Sorry something went wrong'
                    }
                    action={
                      <Fragment>
                        <IconButton
                          size="small"
                          aria-label="close"
                          color="inherit"
                          onClick={handleClose}
                        >
                          <CloseIcon fontSize="small" />
                        </IconButton>
                      </Fragment>
                    }
                  />

                  <Grid
                    container
                    spacing={3}
                    css={css`
                      margin-top: 3%;
                    `}
                  >
                    <Grid container item xs={6}>
                      <div css={buttonShawdowBoxStyle}></div>
                      <Button
                        fullWidth
                        css={css`
                          font-weight: bolder;
                          align-self: center;
                          background-color: ${GlobalStyles.red};
                          color: ${GlobalStyles.white};
                          cursor: pointer;
                          width: 100%;
                          text-decoration: none;
                          border-radius: 0% !important;
                          &:hover {
                            color: ${GlobalStyles.navyBlue};
                            background-color: ${GlobalStyles.red};
                          }
                          transform: translate(0px, -31px);
                          height: 32px;
                        `}
                        onClick={() => history.goBack()}
                      >
                        CANCEL
                      </Button>
                    </Grid>

                    <Grid container item xs={6}>
                      <div css={buttonShawdowBoxStyle}></div>
                      <Button fullWidth type="submit" css={buttonStyle}>
                        {!loadingSubmit ? (
                          'Save'
                        ) : (
                          <CircularProgress
                            size="28px"
                            css={css`
                              .MuiCircularProgress-colorPrimary {
                                color: ${GlobalStyles.navyBlue};
                              }
                            `}
                          />
                        )}
                      </Button>
                    </Grid>
                  </Grid>
                </ValidatorForm>
              </Grid>
            </Grid>
          </div>
        </Fragment>
      )}
    </div>
  );
}

export default TeamMemberForm;
