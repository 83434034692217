/** @jsx jsx */
import GlobalStyles from 'assets/GlobalStyles';
import Card from '@material-ui/core/Card';
import { jsx, css } from '@emotion/core';
import { makeStyles } from '@material-ui/core/styles';
import { useParams, useLocation } from 'react-router-dom';

//ROOT
const useStyles = makeStyles(() => ({
  root: {
    minHeight: '40vh',
    minWidth: '41vw',
    backgroundColor: `${GlobalStyles.paleBlueCardBackground}`,
    borderRadius: '30px',
    paddingTop: '3%',
    marginBottom: '3%',

    '@media(max-width: 960px)': {
      minHeight: 'initial',
      marginRight: '-10%',
      paddingLeft: '33px',
      paddingTop: '20px',
    },
  },
}));

const sharedStyling = css`
  color: ${GlobalStyles.darkBlue};
  font-family: 'poppins-medium';
  font-size: 20px;
  line-height: 1.5;
  padding-right: 5%;
  padding-bottom: 3%;
  margin-left: 7.5%;
  overflow-wrap: break-word;
  max-width: 41vw;
  white-space: pre-wrap;
  @media (max-width: ${GlobalStyles.mobileBreakpoint}) {
    max-width: 100vw;
    padding-right: 14%;
    padding-bottom: 20px;
    font-size: 14px;
    margin-left: 0;
  }
`;

export default function SeizureTypesBlueCard({ title, text }) {
  const classes = useStyles();
  const { step } = useParams();

  const location = useLocation();

  const generateText = () => {
    return (
      <div>
        {text.active !== 'ACTIVE\n' ? (
          <h4
            css={css`
              color: #002b45;
            `}
          >
            {text.active}
          </h4>
        ) : null}
        {text.inactive !== 'INACTIVE\n' ? (
          <h4
            css={css`
              color: #9b9b9b;
            `}
          >
            {text.inactive}
          </h4>
        ) : null}
      </div>
    );
  };

  return (
    <Card
      className={classes.root}
      css={css`
        @media (max-width: ${GlobalStyles.mobileBreakpoint}) {
          /* min height for hospital plan mobile cards */
          min-height: ${!step &&
          !location.pathname.includes('/details') &&
          '20vh'};
        }
      `}
    >
      <div
        css={css`
          margin-left: 7.5%;
          @media (max-width: ${GlobalStyles.mobileBreakpoint}) {
            margin-left: 0%;
          }
        `}
      >
        <h2
          css={css`
            color: ${GlobalStyles.midBlue};
            font-family: 'poppins-bold';
            font-size: 24px;
            line-height: 16px;
            margin-block-start: 0;
            margin-block-end: 0;
            @media (max-width: ${GlobalStyles.mobileBreakpoint}) {
              font-size: 16px;
            }

            /* The styling below is for the horizontal line on the right side of the title */

            width: 100%;
            text-align: left;
            border-bottom: 3px solid ${GlobalStyles.midBlue};
            line-height: 0.1em;
            margin: 10px 0 20px;

            span {
              background: ${GlobalStyles.paleBlueCardBackground};
              padding: 0 10px 0 0;
            }
          `}
        >
          <span>{title && title.toUpperCase()}</span>
        </h2>
      </div>
      <div css={sharedStyling}>
        <div>{generateText()}</div>
      </div>
    </Card>
  );
}
