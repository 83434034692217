import { useParams, Link } from 'react-router-dom';
/** @jsx jsx */
import { jsx, css } from '@emotion/core';
import { Paper, TextField, Grid, Button } from '@material-ui/core/';
import GlobalStyles from 'assets/GlobalStyles';
import ErrorIcon from '@material-ui/icons/Error';
import moment from 'moment';
import PatientViewLink from 'components/PatientViewLink';
import WeightUpdatedModal from './WeightUpdatedModal';

// CSS
const inputTitleStyle = css`
  background-color: ${GlobalStyles.navyBlue2};
  color: ${GlobalStyles.white};
  font-size: 14px;
  font-weight: 800;
  letter-spacing: 1px;
  padding: 5px 15px;
  margin-bottom: -1px;
`;

const savedItemsContainerStyle = css`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const savedItemContainerStyle = css`
  display: flex;
  justify-content: space-between;
`;

const savedDropdownNameStyle = css`
  color: ${GlobalStyles.navyBlue};
  -webkit-text-fill-color: ${GlobalStyles.navyBlue2};
  font-size: 20px;
  font-weight: 500;
  letter-spacing: 1.25px;
  margin: 0px;
  padding-top: 10px;
  cursor: default;
  width: 100%;
`;

const savedSeizureActiveStyle = css`
  width: fit-content;
  align-self: flex-end;
`;

const inactiveSeizureNameStyle = css`
  color: ${GlobalStyles.disabledGrey};
  -webkit-text-fill-color: ${GlobalStyles.disabledGrey};
`;

// Custom Components
function CustomTextField(props) {
  return (
    <TextField
      fullWidth
      type="text"
      margin="normal"
      InputLabelProps={{
        shrink: true,
      }}
      css={css`
        margin: 0;

        .MuiInputBase-input.Mui-disabled {
          color: ${GlobalStyles.navyBlue2};
          -webkit-text-fill-color: ${GlobalStyles.navyBlue2};
          font-size: 20px;
          font-weight: 500;
          letter-spacing: 1.25px;
          margin: 0px;
          padding-left: 10px;
          padding-bottom: 2%;
        }
        .MuiInput-underline:before {
          border-bottom: 0px;
        }
      `}
      {...props}
    />
  );
}

function PatientView({
  patient,
  setWeightModal,
  setUpdatedWeightModal,
  updatedWeightModal,
  medicationNames,
}) {
  const { id } = useParams();

  const {
    first_name,
    last_name,
    nickname,
    DOB,
    caregiver_name,
    caregiver_email,
    weight,
    unit_of_weight,
    weight_last_updated,
    diagnoses,
    seizure_types,
    allergies,
    patient_notes,
    dosage,
  } = patient;
  const weightLastUpdated = new Date(weight_last_updated);
  const weightNeedsUpdate = moment().diff(weightLastUpdated, 'days') > 365;

  const weightUpdateFlag = () => {
    return (
      <div
        css={css`
          align-self: flex-end;
          color: ${GlobalStyles.warningOrange};
          overflow-wrap: break-word;
          padding-bottom: 5px;
        `}
      >
        Last updated over a year ago
      </div>
    );
  };

  const disabled = true;

  return (
    <Paper
      className="PatientView"
      key={id}
      css={css`
        padding: 5% 10%;
        box-shadow: none;
        background-color: ${GlobalStyles.white};
      `}
    >
      <div>
        <p
          css={css`
            background-color: ${GlobalStyles.backgroundBlue};
            color: ${GlobalStyles.textBlue};
            padding: 20px;
            line-height: 1.25;
            margin-bottom: 40px;
          `}
        >
          <b>Please Note</b>: All information entered on this page will also be
          displayed on the patient plan, which is viewable by anyone who has the
          link to this plan (i.e. patient’s family, teachers, first responders,
          etc.) All information included in this plan should comply with PHSA
          Privacy Policy and PHIPA regulations.
        </p>
      </div>
      <Grid container spacing={2}>
        <Grid item container sm={6}>
          <Grid
            item
            container
            sm={12}
            css={css`
              border-bottom: 5px solid ${GlobalStyles.navyBlue2};
            `}
          >
            <div css={inputTitleStyle}>PATIENT FIRST NAME</div>
          </Grid>
          <Grid item container sm={12}>
            <CustomTextField value={first_name} disabled={disabled} />
          </Grid>
        </Grid>
        <Grid item container sm={6}>
          <Grid
            item
            container
            sm={12}
            css={css`
              border-bottom: 5px solid ${GlobalStyles.navyBlue2};
            `}
          >
            <div css={inputTitleStyle}>PATIENT LAST NAME</div>
          </Grid>
          <Grid item container sm={12}>
            <CustomTextField value={last_name} disabled={disabled} />
          </Grid>
        </Grid>
        <Grid item container sm={6}>
          <Grid
            item
            container
            sm={12}
            css={css`
              border-bottom: 5px solid ${GlobalStyles.navyBlue2};
            `}
          >
            <div css={inputTitleStyle}>PREFERRED NAME/NICKNAME</div>
          </Grid>
          <Grid item container sm={12}>
            <CustomTextField value={nickname} disabled={disabled} />
          </Grid>
        </Grid>
        <Grid item container sm={6}>
          <Grid
            item
            container
            sm={12}
            css={css`
              border-bottom: 5px solid ${GlobalStyles.navyBlue2};
            `}
          >
            <div css={inputTitleStyle}>DATE OF BIRTH</div>
          </Grid>
          <Grid item container sm={12}>
            <CustomTextField type="date" value={DOB} disabled={disabled} />{' '}
          </Grid>
        </Grid>
        <Grid item container sm={12}>
          <Grid
            item
            container
            sm={12}
            css={css`
              border-bottom: 5px solid ${GlobalStyles.navyBlue2};
            `}
          >
            <div css={inputTitleStyle}>PARENT/GUARDIAN NAME</div>
          </Grid>
          <Grid item container sm={12}>
            <CustomTextField
              value={caregiver_name ? caregiver_name : ''}
              disabled={disabled}
            />
          </Grid>
        </Grid>
        <Grid item container sm={12}>
          <Grid
            item
            container
            sm={12}
            css={css`
              border-bottom: 5px solid ${GlobalStyles.navyBlue2};
            `}
          >
            <div css={inputTitleStyle}>PARENT/GUARDIAN EMAIL</div>
          </Grid>
          <Grid item container sm={12}>
            <CustomTextField
              value={caregiver_email ? caregiver_email : ''}
              disabled={disabled}
            />
          </Grid>
        </Grid>
        <Grid item sm={12}>
          <hr
            css={css`
              border-top: 7px solid ${GlobalStyles.midBlue};
              margin-bottom: 4%;
            `}
          ></hr>
        </Grid>
        <Grid item container sm={6}>
          <Grid
            item
            container
            justify={weightNeedsUpdate ? 'space-between' : null}
            sm={12}
            css={css`
              border-bottom: 5px solid ${GlobalStyles.navyBlue2};
            `}
          >
            <div css={inputTitleStyle}>PATIENT WEIGHT</div>
            {weightNeedsUpdate && weightUpdateFlag()}
          </Grid>
          <Grid item container sm={12}>
            <CustomTextField
              value={weight + ' ' + unit_of_weight}
              disabled={disabled}
            />
          </Grid>
        </Grid>
        <Grid item container sm={6}>
          <Grid
            item
            container
            sm={12}
            css={css`
              border-bottom: 5px solid ${GlobalStyles.navyBlue2};
            `}
          >
            <div css={inputTitleStyle}>WEIGHT LAST UPDATED</div>
          </Grid>
          <Grid
            item
            container
            sm={12}
            css={css`
              align-items: center;
              justify-content: space-between;
              display: grid;
              grid-template-columns: 1fr auto;
              @media (max-width: ${GlobalStyles.mobileBreakpoint}) {
                display: flex;
                flex-wrap: wrap;
              }
            `}
          >
            <CustomTextField
              value={moment(weightLastUpdated).format('MMM D, YYYY | h:mm A')}
              disabled={disabled}
            />
            {weightNeedsUpdate && (
              <Button component={Link} to={`/patient/${id}/details/edit`}>
                <ErrorIcon
                  onClick={() => setWeightModal(true)}
                  style={{ color: GlobalStyles.warningOrange }}
                ></ErrorIcon>
              </Button>
            )}
          </Grid>
        </Grid>
        <Grid item container sm={12}>
          <Grid
            item
            container
            sm={12}
            css={css`
              border-bottom: 5px solid ${GlobalStyles.navyBlue2};
            `}
          >
            <div css={inputTitleStyle}>DIAGNOSES</div>
          </Grid>
          <Grid item container sm={12}>
            <CustomTextField
              multiline
              value={diagnoses.trim().replace(/^\s*\n/gm, '')}
              disabled={disabled}
            />
          </Grid>
        </Grid>
        <Grid item container sm={12}>
          <Grid
            item
            container
            sm={12}
            css={css`
              border-bottom: 5px solid ${GlobalStyles.navyBlue2};
            `}
          >
            <div css={inputTitleStyle}>SEIZURE TYPES</div>
          </Grid>
          {seizure_types !== null && seizure_types.length ? (
            <div css={savedItemsContainerStyle}>
              {patient.seizure_types
                ? [...patient.seizure_types]
                    .sort((a, b) => b.checkboxActive - a.checkboxActive)
                    .map((savedSeizureType, index) => {
                      let savedSeizureTypeDisplayName = '';

                      savedSeizureType.name &&
                        savedSeizureType.name.forEach((name, index) => {
                          index === 0
                            ? (savedSeizureTypeDisplayName += name)
                            : index === 1
                            ? (savedSeizureTypeDisplayName += ' | ' + name)
                            : index === 2 &&
                              (savedSeizureTypeDisplayName += ' | ' + name);
                        });

                      return (
                        <div css={savedItemContainerStyle} key={index}>
                          <p
                            css={
                              savedSeizureType.checkboxActive
                                ? savedDropdownNameStyle
                                : [
                                    savedDropdownNameStyle,
                                    inactiveSeizureNameStyle,
                                  ]
                            }
                            id={savedSeizureTypeDisplayName}
                          >
                            {savedSeizureTypeDisplayName}
                          </p>

                          {savedSeizureType.checkboxActive ? (
                            <p
                              css={[
                                savedDropdownNameStyle,
                                savedSeizureActiveStyle,
                              ]}
                            >
                              Active
                            </p>
                          ) : null}
                        </div>
                      );
                    })
                : null}
            </div>
          ) : (
            <CustomTextField disabled={disabled} />
          )}
        </Grid>
        <Grid item container sm={12}>
          <Grid
            item
            container
            sm={12}
            css={css`
              border-bottom: 5px solid ${GlobalStyles.navyBlue2};
            `}
          >
            <div css={inputTitleStyle}>ANTI-SEIZURE MEDICATIONS</div>
          </Grid>
          <div
            css={css`
              display: flex;
              justify-content: space-between;
              width: 100%;
              min-height: 50px;
              padding-top: 10px;
            `}
          >
            <Grid item container sm={6}>
              {medicationNames &&
                patient &&
                medicationNames.map((medicationName) => {
                  return (
                    <div
                      key={medicationName}
                      css={css`
                        color: ${GlobalStyles.navyBlue2};
                        -webkit-text-fill-color: ${GlobalStyles.navyBlue2};
                        font-size: 20px;
                        font-weight: 500;
                        letter-spacing: 1.25px;
                        margin: 0px;
                        width: 500px;
                        padding-bottom: 2%;
                        cursor: default;
                      `}
                    >
                      {medicationName.split()}
                    </div>
                  );
                })}
            </Grid>
            <div
              css={css`
                display: flex;
                flex-direction: column;
                width: 40%;
              `}
            >
              {dosage
                ? Object.entries(dosage).map(([medication]) => {
                    return (
                      <div
                        css={css`
                          display: flex;
                          justify-content: space-between;
                        `}
                        key={medication}
                      >
                        <p
                          css={css`
                            color: ${GlobalStyles.navyBlue};
                            -webkit-text-fill-color: ${GlobalStyles.navyBlue2};
                            font-size: 20px;
                            font-weight: 500;
                            letter-spacing: 1.25px;
                            margin: 0px;
                            padding-left: 10px;
                            padding-bottom: 2%;
                            cursor: default;
                          `}
                        >
                          {dosage ? 'Dose: ' : null}
                        </p>
                        <p
                          css={css`
                            color: ${GlobalStyles.navyBlue};
                            -webkit-text-fill-color: ${GlobalStyles.navyBlue2};
                            font-size: 20px;
                            font-weight: 500;
                            letter-spacing: 1.25px;
                            margin: 0px;
                            padding-left: 10px;
                            padding-bottom: 2%;
                            cursor: default;
                          `}
                        >
                          {dosage
                            ? Object.values(dosage[medication].split())
                            : null}
                        </p>
                      </div>
                    );
                  })
                : null}
            </div>
          </div>
        </Grid>

        <Grid item container sm={12}>
          <Grid
            item
            container
            sm={12}
            css={css`
              border-bottom: 5px solid ${GlobalStyles.navyBlue2};
            `}
          >
            <div css={inputTitleStyle}>ALLERGIES</div>
          </Grid>
          <Grid item container sm={12}>
            <CustomTextField
              multiline
              value={allergies.trim().replace(/^\s*\n/gm, '')}
              disabled={disabled}
            />
          </Grid>
        </Grid>
        <Grid item container sm={12}>
          <Grid
            item
            container
            sm={12}
            css={css`
              border-bottom: 5px solid ${GlobalStyles.navyBlue2};
            `}
          >
            <div css={inputTitleStyle}>PATIENT NOTES</div>
          </Grid>
          <Grid item container sm={12}>
            <CustomTextField
              multiline
              value={patient_notes.trim().replace(/^\s*\n/gm, '')}
              disabled={disabled}
            />
          </Grid>
          <Grid item sm={12}>
            <PatientViewLink id={id} patient={patient} />
          </Grid>
        </Grid>
        <Grid item container sm={6}>
          <div
            css={css`
              line-height: 1.75;
              height: 32px;
              margin-top: 10px;
              width: 100%;
              margin-top: 40px;
            `}
          >
            <Button
              fullWidth
              component={Link}
              to={`/patient-list`}
              type="submit"
              css={css`
                font-weight: bolder;
                align-self: center;
                background-color: ${GlobalStyles.red};
                color: ${GlobalStyles.white};
                cursor: pointer;
                width: 100%;
                text-decoration: none;
                border-radius: 0% !important;
                &:hover {
                  color: ${GlobalStyles.navyBlue};
                  background-color: ${GlobalStyles.red};
                }
                height: 32px;
              `}
            >
              Back to Database
            </Button>
          </div>
        </Grid>
        <Grid item container sm={6}>
          <div
            css={css`
              line-height: 1.75;
              width: 100%;
              height: 32px;
              margin-top: 40px;
            `}
          >
            <Button
              fullWidth
              type="submit"
              css={css`
                font-weight: bolder;
                align-self: center;
                background-color: ${GlobalStyles.accentOrange};
                color: ${GlobalStyles.white};
                cursor: pointer;
                width: 100%;
                text-decoration: none;
                border-radius: 0% !important;
                &:hover {
                  color: ${GlobalStyles.navyBlue};
                  background-color: ${GlobalStyles.accentOrange};
                }
                height: 32px;
              `}
              component={Link}
              to={`/patient/${id}/details/edit`}
            >
              Edit
            </Button>
          </div>
        </Grid>
      </Grid>

      <WeightUpdatedModal
        open={updatedWeightModal}
        handleClose={() => setUpdatedWeightModal(false)}
        name={patient.nickname ? patient.nickname : patient.first_name}
      />
    </Paper>
  );
}

export default PatientView;
