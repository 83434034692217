/** @jsx jsx */
import { Fragment } from 'react';
import { jsx, css } from '@emotion/core';
import { makeStyles } from '@material-ui/core/styles';
import GlobalStyles from 'assets/GlobalStyles';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';

const noLineStyling = css`
  border: none;
`;

const yellowLineStyling = css`
  border: 2px solid ${GlobalStyles.mutedOrange};
  background-color: ${GlobalStyles.mutedOrange};
  margin-right: -30%;
  margin-left: -15%;
`;

const stepStyling = css`
  font-family: 'roboto-black';
  color: ${GlobalStyles.white};
  font-size: 24px;
`;

const useStyles = makeStyles(() => ({
  button: {
    borderRadius: '50%',
    minWidth: '55px',
    backgroundColor: GlobalStyles.midBlue,
    color: GlobalStyles.white,
    marginTop: '5%',
  },
}));

function StepperDesktop({ totalSteps, step_number, lastStep }) {
  const classes = useStyles();
  if (totalSteps && totalSteps === 1) {
    return (
      <Fragment>
        <Grid className="yellowlineLeft" item xs={5}>
          {step_number === 1 && <hr css={noLineStyling} />}
        </Grid>

        <Grid className="stepNumber" item xs={2}>
          <Button className={classes.button} disabled color="primary">
            <span css={stepStyling}>{step_number}</span>
          </Button>
        </Grid>

        <Grid className="yellowlineRight" item xs={5}>
          {step_number === 1 && <hr css={noLineStyling} />}
        </Grid>
      </Fragment>
    );
  } else if (totalSteps && totalSteps === 2) {
    return (
      <Fragment>
        <Grid className="yellowlineLeft" item xs={5}>
          {step_number === 1 ? (
            <hr css={noLineStyling} />
          ) : (
            <hr css={yellowLineStyling} />
          )}
        </Grid>
        <Grid className="stepNumber" item xs={2}>
          <Button className={classes.button} disabled color="primary">
            <span css={stepStyling}>{step_number}</span>
          </Button>
        </Grid>
        <Grid className="yellowlineRight" item xs={5}>
          {step_number === 1 && lastStep !== 4 ? (
            <hr css={yellowLineStyling} />
          ) : (
            <hr css={noLineStyling} />
          )}
        </Grid>
      </Fragment>
    );
  } else if (totalSteps && totalSteps === 3) {
    return (
      <Fragment>
        <Grid className="yellowlineLeft" item xs={5}>
          {step_number === 1 ? (
            <hr css={noLineStyling} />
          ) : (
            <hr css={yellowLineStyling} />
          )}
        </Grid>
        <Grid className="stepNumber" item xs={2}>
          <Button className={classes.button} disabled color="primary">
            <span css={stepStyling}>{step_number}</span>
          </Button>
        </Grid>
        <Grid className="yellowlineRight" item xs={5}>
          {step_number === 3 || (step_number === 2 && lastStep === 4) ? (
            <hr css={noLineStyling} />
          ) : (
            <hr css={yellowLineStyling} />
          )}
        </Grid>
      </Fragment>
    );
  } else if (totalSteps && totalSteps === 4) {
    return (
      <Fragment>
        <Grid className="yellowlineLeft" item xs={5}>
          {step_number === 1 ? (
            <hr css={noLineStyling} />
          ) : (
            <hr css={yellowLineStyling} />
          )}
        </Grid>
        <Grid className="stepNumber" item xs={2}>
          <Button className={classes.button} disabled color="primary">
            <span css={stepStyling}>{step_number}</span>
          </Button>
        </Grid>
        <Grid className="yellowlineRight" item xs={5}>
          {step_number === 3 ? (
            <hr css={noLineStyling} />
          ) : (
            <hr css={yellowLineStyling} />
          )}
        </Grid>
      </Fragment>
    );
  } else {
    return null;
  }
}

export default StepperDesktop;
