/** @jsx jsx */
import { jsx, css } from '@emotion/core';
import GlobalStyles from 'assets/GlobalStyles';
import CloseIcon from '@material-ui/icons/Close';
import { Link, useParams } from 'react-router-dom';
import Slide from '@material-ui/core/Slide';

const StyledLogoNavLink = css`
  display: block;
  color: ${GlobalStyles.white};
  margin: 1em 0 2em 0.5em;
  font-weight: 900;
  font-size: 135%;
  letter-spacing: -1px;
  text-decoration: none;
  border-bottom: 3px solid ${GlobalStyles.white};
  padding-bottom: 5px;
  width: 245px;
`;

const StyledNavLink = css`
  display: block;
  color: ${GlobalStyles.white};
  margin: 1em 0 2em 0.5em;
  text-decoration: none;
  font-size: 110%;
  border-bottom: 1px solid ${GlobalStyles.white};
  padding-bottom: 3px;
  width: 285px;
`;

function MobileNavMenu({ setOpenToggle, patient, mobileNavOpen }) {
  const { id } = useParams();

  return (
    <Slide
      timeout={1000}
      direction="right"
      in={mobileNavOpen}
      mountOnEnter
      unmountOnExit
    >
      <div
        css={css`
          background-color: ${GlobalStyles.midBlue};
          position: fixed;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          grid-column: 1 / -1;
          grid-row: 1 / -1;
          color: ${GlobalStyles.white};
          z-index: 1;
          padding: 25px 20px 0 20px;
        `}
      >
        <CloseIcon
          fontSize={'large'}
          css={css`
            position: absolute;
            right: 0;
            margin-right: 20px;
            margin-top: 0.5em;
            font-weight: 900;
          `}
          onClick={setOpenToggle}
        />
        <Link
          css={StyledLogoNavLink}
          onClick={setOpenToggle}
          to={`/patient-view/${id}/out-of-hospital-plan/1`}
        >
          SEIZURE ACTION PLAN
        </Link>
        <Link
          css={StyledNavLink}
          onClick={setOpenToggle}
          to={`/patient-view/${id}/details`}
        >
          {`${patient.nickname.toUpperCase()}'S DETAILS`}
        </Link>
        <Link
          css={StyledNavLink}
          onClick={setOpenToggle}
          to={`/patient-view/${id}/out-of-hospital-plan/1`}
        >
          {`${patient.nickname.toUpperCase()}'S OUT-OF-HOSPITAL PLAN`}
        </Link>
        <Link
          css={StyledNavLink}
          onClick={setOpenToggle}
          to={`/patient-view/${id}/hospital-plan`}
        >
          {`${patient.nickname.toUpperCase()}'S HOSPITAL PLAN`}
        </Link>
        <Link
          css={StyledNavLink}
          onClick={setOpenToggle}
          to={`/patient-view/${id}/clinical-team`}
        >
          {`${patient.nickname.toUpperCase()}'S CLINICAL CARE TEAM`}
        </Link>
        <Link
          css={StyledNavLink}
          onClick={setOpenToggle}
          to={`/patient-view/${id}/download-plan`}
        >
          {`SHARE/DOWNLOAD ${patient.nickname.toUpperCase()}'S PLAN`}
        </Link>
        <Link
          css={StyledNavLink}
          onClick={setOpenToggle}
          to={`/patient-view/${id}/first-aid`}
        >
          {'WHEN TO CALL 9-1-1'}
        </Link>
      </div>
    </Slide>
  );
}

export default MobileNavMenu;
