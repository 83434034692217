/** @jsx jsx */
import { jsx, css } from '@emotion/core';
import { Fragment } from 'react';
import { CircularProgress, Button } from '@material-ui/core';
import GlobalStyles from 'assets/GlobalStyles';

const TeamPageButtons = ({
  buttonOneOnClick,
  buttonTwoOnClick,
  isLoading,
  buttonOneText,
  buttonTwoText,
}) => {
  return (
    <Fragment>
      <div
        css={css`
          display: flex;
          justify-content: space-between;
          width: 100%;
        `}
      >
        <Button
          onClick={buttonOneOnClick}
          css={css`
            font-weight: bolder;
            background-color: ${GlobalStyles.red};
            color: ${GlobalStyles.white};
            width: 48%;
            border-radius: 0%;
            &:hover {
              color: ${GlobalStyles.navyBlue};
              background-color: ${GlobalStyles.red};
            }
            height: 32px;
          `}
        >
          {buttonOneText}
        </Button>

        <Button
          onClick={buttonTwoOnClick}
          css={css`
            font-weight: bolder;
            background-color: ${GlobalStyles.accentOrange};
            color: ${GlobalStyles.white};
            width: 48%;
            border-radius: 0%;
            &:hover {
              color: ${GlobalStyles.navyBlue};
              background-color: ${GlobalStyles.accentOrange};
            }
            height: 32px;
          `}
        >
          {isLoading ? (
            <CircularProgress
              size="28px"
              css={css`
                .MuiCircularProgress-colorPrimary {
                  color: ${GlobalStyles.navyBlue};
                }
              `}
            />
          ) : (
            `${buttonTwoText}`
          )}
        </Button>
      </div>
    </Fragment>
  );
};

export default TeamPageButtons;
