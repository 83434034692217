/** @jsx jsx */
import { jsx, css } from '@emotion/core';
import { useEffect, useRef } from 'react';
import {
  GET_PATIENT_HOSPITAL_PLAN,
  GET_PATIENT_NON_HOSPITAL_PLAN,
} from 'utils/queries';
import { useQuery } from '@apollo/client';
import { Grid, useMediaQuery } from '@material-ui/core';
import GlobalStyles from 'assets/GlobalStyles';
import BlueCardWithButton from 'components/PatientView/BlueCardWithButton';
import MobileAppHeader from 'components/PatientView/MobileAppHeader';
import BlueCardNoData from 'components/PatientView/BlueCardNoData.js';
import BlueCard from './BlueCard';

function Download({ patient }) {
  const isMobile = useMediaQuery(
    `(max-width:${GlobalStyles.mobileBreakpoint})`
  );
  const { data: hospitalPlanData, loading: hospitalPlanLoading } = useQuery(
    GET_PATIENT_HOSPITAL_PLAN,
    {
      variables: { id: patient.id },
    }
  );

  const { data: nonHospitalPlanData } = useQuery(
    GET_PATIENT_NON_HOSPITAL_PLAN,
    {
      variables: { id: patient.id },
    }
  );

  //this section scrolls nicely to the download part of the plan if they've come from the sent e-mail link
  const downloadRef = useRef(null);
  const executeScroll = () => {
    const element = downloadRef.current;
    const yOffset = -200;
    const y =
      element.getBoundingClientRect().top + window.pageYOffset + yOffset;
    return window.scrollTo({ top: y, behavior: 'smooth' });
  };

  useEffect(() => {
    const url = window.location.href;
    const lastPart = url.substr(url.lastIndexOf('/') + 1);
    if (lastPart === 'download-plan' && downloadRef.current !== null) {
      executeScroll();
    }
  }, [downloadRef, hospitalPlanLoading]);

  //Checking if there is Hospital Plan or not

  const checkForEpilepticusPlan = () => {
    if (!hospitalPlanData) {
      return true;
    } else if (!hospitalPlanData.actionPlans[0]) {
      return true;
    } else if (!hospitalPlanData.actionPlans[0].show_epilepticus_plan) {
      return false;
    } else if (hospitalPlanData.actionPlans[0].show_epilepticus_plan) {
      return true;
    }
  };

  //Checking if there is Out-of-Hospital Plan or not
  const checkForNonHospitalPlan = () => {
    if (!nonHospitalPlanData) {
      return false;
    } else if (!nonHospitalPlanData.actionPlans[0]) {
      return false;
    } else {
      return true;
    }
  };

  return (
    <div
      ref={downloadRef}
      className="PatientViewDownload"
      css={css`
        margin-top: 10%;
        padding: 0 6%;
        @media (max-width: ${GlobalStyles.mobileBreakpoint}) {
          padding: 3% 0 5% 10%;
          margin-top: 0;
        }
      `}
    >
      {isMobile && <MobileAppHeader patient={patient} />}
      <div
        css={css`
          font-family: 'roboto-bold';
          font-size: 30px;
          line-height: 35px;
          color: ${GlobalStyles.darkBlue};
          margin-bottom: 3%;
          text-align: center;
          @media (max-width: ${GlobalStyles.mobileBreakpoint}) {
            margin-left: 20%;
            text-align: left;
            margin-bottom: 10%;
            font-size: 18px;
            line-height: 21px;
          }
        `}
      >
        {isMobile ? (
          `Share/Download ${patient.nickname}'s Plans`
        ) : (
          <p>
            <span
              css={css`
                color: ${GlobalStyles.midBlue};
              `}
            >
              Share/Download
            </span>{' '}
            {patient.nickname}'s{' '}
            <span
              css={css`
                color: ${GlobalStyles.midBlue};
              `}
            >
              Plans
            </span>
          </p>
        )}
      </div>

      {isMobile ? (
        <Grid
          container
          className="MobileDownload"
          alignItems="center"
          justify="space-between"
        >
          <Grid
            item
            xs={12}
            css={css`
              margin-top: 5%;
              overflow: hidden;
            `}
          >
            <BlueCardWithButton
              title={'OUT-OF-HOSPITAL PLAN'}
              buttonLabel={'Download PDF'}
              buttonMedia={'pdf'}
              buttonLabel2={'Download PNG'}
              buttonMedia2={'png'}
              patient={patient}
              isMobile={isMobile}
            />
          </Grid>

          {checkForEpilepticusPlan() && (
            <Grid
              item
              xs={12}
              css={css`
                margin-top: 3%;
                overflow: hidden;
              `}
            >
              <BlueCardWithButton
                title={'HOSPITAL PLAN'}
                buttonLabel={
                  <a
                    href={'/files/hospital_plan.pdf'}
                    download="Status-epilepticus-plan.pdf"
                    css={css`
                      text-decoration: none;
                      :visited {
                        color: ${GlobalStyles.whiteButton};
                      }
                    `}
                  >
                    Download Plan
                    <br />
                    <span
                      css={css`
                        font-size: 14px;
                      `}
                    >
                      (Rev. Mar 2017 - CHBC)
                    </span>
                  </a>
                }
                isMobile={isMobile}
              />
            </Grid>
          )}

          <Grid
            item
            xs={12}
            css={css`
              margin-top: 3%;
              overflow: hidden;
            `}
          >
            <BlueCard
              title={'SHARE THIS PLAN'}
              isMobile={isMobile}
              QRcode
              text={'Scan this QR code to access this plan'}
            />
          </Grid>

          <Grid
            item
            xs={12}
            css={css`
              margin-top: 3%;
              overflow: hidden;
            `}
          >
            <BlueCardWithButton
              patient={patient}
              title={'EMAIL THIS PLAN'}
              buttonLabel={'EMAIL THIS PLAN'}
              isMobile={isMobile}
            />
          </Grid>
        </Grid>
      ) : (
        //DESKTOP
        <Grid
          container
          direction="row"
          justify="space-between"
          alignItems="center"
        >
          {checkForNonHospitalPlan() ? (
            <BlueCardWithButton
              title={'OUT-OF-HOSPITAL PLAN'}
              buttonLabel={'Download PDF'}
              buttonMedia={'pdf'}
              buttonLabel2={'Download PNG'}
              buttonMedia2={'png'}
              patient={patient}
            />
          ) : (
            <BlueCardNoData
              nickname={patient.nickname}
              title={'NO OUT-OF-HOSPITAL PLAN'}
              plan={'an out-of-hospital plan'}
            />
          )}

          {checkForEpilepticusPlan() ? (
            <BlueCardWithButton
              title={'HOSPITAL PLAN'}
              buttonLabel={
                <a
                  href={'/files/hospital_plan.pdf'}
                  download="Status-epilepticus-plan.pdf"
                  css={css`
                    text-decoration: none;
                    :visited {
                      color: ${GlobalStyles.whiteButton};
                    }
                  `}
                >
                  Download Plan
                  <br />
                  <span
                    css={css`
                      font-size: 14px;
                    `}
                  >
                    (Rev. Mar 2017 - CHBC)
                  </span>
                </a>
              }
            />
          ) : (
            <BlueCardNoData
              nickname={patient.nickname}
              title={'HOSPITAL PLAN'}
              plan={'a downloadable hospital plan'}
            />
          )}

          <BlueCard
            title={'SHARE THIS PLAN'}
            QRcode
            text={'Scan this QR code to access this plan'}
          />

          <BlueCardWithButton
            patient={patient}
            title={'EMAIL THIS PLAN'}
            buttonLabel={'EMAIL THIS PLAN'}
          />
        </Grid>
      )}
    </div>
  );
}

export default Download;
