/** @jsx jsx */
import { jsx, css } from '@emotion/core';
import GlobalStyles from 'assets/GlobalStyles';

const CMSWarningPage = () => {
  return (
    <div
      css={css`
        display: flex;
        justify-content: center;
      `}
    >
      <div
        css={css`
          background-color: ${GlobalStyles.white};
          color: ${GlobalStyles.textBlue};
          width: 70vw;
          border: 5px solid ${GlobalStyles.babyBlue};
          padding: 5px;
        `}
      >
        <p>
          <b>Please Note:</b>
        </p>
        <p>
          The Seizure Action Plan CMS is best experienced on a computer on
          Google Chrome.
        </p>

        <p>Please visit the Seizure Action Plan CMS on a computer.</p>
      </div>
    </div>
  );
};

export default CMSWarningPage;
