import { Link } from 'react-router-dom';
import { useState, useEffect } from 'react';
import { useQuery } from '@apollo/client';
/** @jsx jsx */
import { css, jsx } from '@emotion/core';
import Button from '@material-ui/core/Button';
import GlobalStyles from 'assets/GlobalStyles';
import Grid from '@material-ui/core/Grid';
import { GET_PATIENTS_LIST } from 'utils/queries';
import SearchIcon from '@material-ui/icons/Search';
// have to rename Link as name already exists for react-router-dom
import { Link as ScrollLink } from 'react-scroll';

//Loading Skeleton
import DatabaseSkeleton from '../components/Skeletons/DatabaseSkeleton';

const containerWithScrollbarStyling = css`
  height: 50vh;
  overflow-y: scroll;
  padding: 0 20px 20px 0;

  /* Scrollbar for Firefox */
  scrollbar-color: ${GlobalStyles.babyBlue} ${GlobalStyles.backgroundBlue};

  &::-webkit-scrollbar {
    width: 20px;
  }
  &::-webkit-scrollbar-thumb {
    height: 25%;
    border-radius: 10px;
    background-color: ${GlobalStyles.babyBlue};
    box-shadow: inset 0 0 10px 10px ${GlobalStyles.babyBlue};
    border: 5px solid ${GlobalStyles.backgroundBlue};
  }
  &::-webkit-scrollbar-track {
    border-radius: 30px;
    background-color: ${GlobalStyles.backgroundBlue};
  }
`;

const patientLinkStyling = css`
  justify-items: start;
  padding-top: 15px;
  font-size: 14px;
  font-weight: medium;
  color: ${GlobalStyles.navyBlue};

  &:hover {
    font-weight: bolder;
  }
  .patient-list__container {
    display: flex;
    align-items: baseline;
    flex-direction: row;
  }
  .patient-list__last_name {
    padding: 0 2px 0 2px;
  }
  .patient-list__nickname {
    padding: 0 10px 0 10px;
  }
  .patient-list__dots {
    flex: 1;
    border: transparent;
    overflow-x: hidden !important;
    :after {
      letter-spacing: 8px;
      content: '...........................................................................................................................................';
    }
  }
`;

const searchIconStyling = css`
  height: 31px;
  padding-left: 1%;
  border: ${GlobalStyles.formInputYellow};
  background-color: ${GlobalStyles.formInputYellow};
`;

const searchBarStyling = css`
  display: flex;
  border: 0;
  padding: 4px;
  width: 100%;
`;

const searchInputStyling = css`
  border: ${GlobalStyles.formInputYellow};
  background-color: ${GlobalStyles.formInputYellow};
  height: 31px;
  margin-right: 20px;
  width: 100%;
  position: relative;
  ::placeholder {
    color: ${GlobalStyles.navyBlue};
    position: relative;
    font-weight: 900;
    opacity: 1;
  }
  :focus {
    outline: none;
  }
  :focus::placeholder {
    color: transparent;
  }
`;

const addNewButtonStyling = css`
  font-weight: bolder;
  line-height: 1.75;
  background-color: ${GlobalStyles.accentOrange};
  color: ${GlobalStyles.white};
  cursor: pointer;
  text-decoration: none;
  border-radius: 0;
  height: 31px;
  &:hover {
    color: ${GlobalStyles.navyBlue};
    background-color: ${GlobalStyles.accentOrange};
  }
`;

const noResults = css`
  font-size: 21px;
  font-weight: lighter;
  color: ${GlobalStyles.textBlue};
  font-family: roboto;
`;

const boldedSpan = css`
  font-weight: bold;
  font-family: roboto-bold;
`;

const selectButtonStyling = css`
  background-color: ${GlobalStyles.accentOrange};
  width: 95.97px;
  height: 19px;
  color: ${GlobalStyles.white};
  font-family: 'roboto-medium';
  text-align: center;
  border-radius: 0px;
  font-size: 12px;
  cursor: pointer;
  &:hover {
    color: ${GlobalStyles.navyBlue};
    background-color: ${GlobalStyles.accentOrange};
  }
`;

function Patient(patient) {
  const {
    lastNameAlphabeticalGroup,
    id,
    first_name,
    last_name,
    nickname,
  } = patient.props;
  const firstLetterofLastName = last_name[0]
    ? last_name[0].toUpperCase()
    : null;

  return (
    <div
      key={id}
      id={lastNameAlphabeticalGroup ? `${firstLetterofLastName}-first` : id}
    >
      {lastNameAlphabeticalGroup && (
        <div
          css={css`
            display: flex;
            width: 100%;
          `}
        >
          <span
            css={css`
              display: flex;
              justify-content: center;
              align-items: center;
              width: 32px;
              height: 26px;
              padding-bottom: 2px;
              background-color: ${GlobalStyles.navyBlue};
              color: ${GlobalStyles.white};
              font-family: 'roboto-black';
              font-size: 14px;
              margin-top: 10px;
            `}
          >
            {firstLetterofLastName}{' '}
          </span>
          <span
            className="underline"
            css={css`
              width: 100%;
              border-bottom: 5px solid ${GlobalStyles.navyBlue};
            `}
          ></span>
        </div>
      )}

      <Link
        to={`/patient/${id}/details`}
        style={{ textDecoration: 'none' }}
        css={patientLinkStyling}
      >
        <div className="patient-list__container">
          <div className="patient-list__last_name">{last_name},</div>
          <div className="patient-list__first_name">{first_name}</div>
          <hr className="patient-list__dots"></hr>
          <div className="patient-list__nickname">
            {nickname ? nickname : first_name}
          </div>

          <div>
            <Button
              className="patient-list__select-medication-button"
              css={selectButtonStyling}
            >
              SELECT
            </Button>
          </div>
        </div>
      </Link>
    </div>
  );
}

function PatientList({ role }) {
  const [inputText, setInputText] = useState('');
  const { data, loading } = useQuery(GET_PATIENTS_LIST);

  // Search function
  const doSearch = (event) => {
    setInputText(event.target.value);
  };

  // Patient list filtered by search
  const [filteredPatients, setFilteredPatients] = useState([]);

  // Alphabet Timeline on the side
  const [availableLetters, setAvailableLetters] = useState(new Set());

  const updateLetterTimeline = (alphabets) => {
    const newAlphabets = new Set();

    alphabets.forEach((alphabet) => {
      if (alphabet.last_name[0]) {
        newAlphabets.add(alphabet.last_name[0].toUpperCase());
      }
    });

    setAvailableLetters(newAlphabets);
  };

  const AlphabetTimeline = ({ alphabets }) => {
    // adjust space between letters based on if user is filtering content by searching
    let spacing;
    inputText.length > 0
      ? (spacing = 'flex-start')
      : (spacing = 'space-between');
    return (
      <div
        css={css`
          background-color: ${GlobalStyles.midBlue};
          color: ${GlobalStyles.white};
          font-size: 14px;
          font-weight: bold;
          padding-top: 7px;
          padding-bottom: 7px;
          width: 34px;
          display: flex;
          flex-direction: column;
          text-align: center;
          justify-content: ${spacing};
        `}
      >
        {Array.from(alphabets).map((alphabet) => {
          return (
            <ScrollLink
              id={`${alphabet}-timeLineLetter`}
              css={css`
                padding: 3px;
                cursor: pointer;
              `}
              to={`${alphabet}-first`}
              containerId="container"
              hashSpy={true}
              spy={true}
              isDynamic={true}
              className="timeLineLetter"
              key={alphabet}
              aria-hidden={true}
              offset={-6}
              onSetActive={() => setActiveHighlight(alphabet)}
              onClick={() => {
                setActiveHighlight(alphabet);
              }}
            >
              {alphabet}
            </ScrollLink>
          );
        })}
      </div>
    );
  };

  const setActiveHighlight = (letter) => {
    document.querySelectorAll('.timeLineLetter').forEach((timeLineLetter) => {
      if (letter.startsWith(timeLineLetter.id.split('-')[0])) {
        timeLineLetter.style.backgroundColor = `${GlobalStyles.white}`;
        timeLineLetter.style.color = `${GlobalStyles.midBlue}`;
      } else {
        timeLineLetter.style.backgroundColor = `${GlobalStyles.midBlue}`;
        timeLineLetter.style.color = `${GlobalStyles.white}`;
      }
    });
  };

  useEffect(() => {
    const seenPatients = new Set();
    if (!data || !data.patients) return;

    const newlyFilteredPats = data.patients.filter((patient) => {
      const lastNameSearch = patient.last_name ? patient.last_name : null;
      const firstNameSearch = patient.first_name ? patient.first_name : null;
      const nickNameSearch = patient.nickname;

      if (
        (lastNameSearch &&
          lastNameSearch.toLowerCase().includes(inputText.toLowerCase())) ||
        (firstNameSearch &&
          firstNameSearch.toLowerCase().includes(inputText.toLowerCase())) ||
        nickNameSearch.toLowerCase().includes(inputText.toLowerCase())
      ) {
        return patient;
      } else {
        return null;
      }
    });

    //needed for sorting case-insensitively
    const collator = new Intl.Collator(['en', 'fr-ca'], {
      numeric: true,
      sensitivity: 'base',
    });

    /// needed to copy for Apollo
    const filteredPatientCopy = newlyFilteredPats.map((patient) => {
      return Object.assign({}, patient);
    });

    filteredPatientCopy
      .sort(
        (a, b) =>
          collator.compare(a.last_name, b.last_name) ||
          collator.compare(a.first_name, b.first_name)
      )
      .forEach((patient) => {
        if (
          patient.last_name &&
          !seenPatients.has(patient.last_name[0].toLowerCase())
        ) {
          patient.lastNameAlphabeticalGroup = true;
          seenPatients.add(patient.last_name[0].toLowerCase());
        }
      });
    setFilteredPatients(filteredPatientCopy);
    updateLetterTimeline(filteredPatientCopy);
  }, [data, inputText]);

  if (loading) {
    return <DatabaseSkeleton />;
  } else if (!role || !data) {
    return <p>Higher permissions needed to view this page</p>;
  } else {
    return (
      <Grid role="main" className="patient-list">
        <div
          className="patient-list__label"
          css={css`
            padding: 7px 18px 7px 18px;
            background-color: ${GlobalStyles.midBlue};
            color: ${GlobalStyles.white};
            font-size: 20px;
            display: inline-block;
            font-family: 'roboto-black';
          `}
        >
          PATIENT DATABASE
        </div>

        <div
          className="patient-list_column_and_body"
          css={css`
            display: grid;
            grid-template-columns: auto 1fr;
          `}
        >
          {/* Quick Label Searching Column */}

          <AlphabetTimeline
            id="alphabetTimeline"
            alphabets={availableLetters}
          />

          <div
            className="patient-list__body"
            css={css`
              padding: 40px 40px 40px 60px;
              border-top: ${GlobalStyles.outerContainerBorder};
              border-right: ${GlobalStyles.outerContainerBorder};
              border-bottom: ${GlobalStyles.outerContainerBorder};
              background-color: ${GlobalStyles.white};
              overflow-y: hidden;
            `}
          >
            <div
              className="containerForSearchBarAndAddNewButton"
              css={css`
                display: flex;
                flex-direction: row;
                margin-bottom: 20px;
                align-items: center;
                padding-right: 40px;
              `}
            >
              <div className="searchBarContainer" css={searchBarStyling}>
                <SearchIcon css={searchIconStyling} />
                <input
                  css={searchInputStyling}
                  type="search"
                  placeholder="search"
                  value={inputText}
                  onChange={doSearch}
                  aria-hidden="false"
                />
              </div>

              <Grid className="add-new-button" item container sm={3}>
                <Button
                  className="patient-list__add-medication-button"
                  component={Link}
                  to={`/patient/new/details/edit`}
                  fullWidth
                  css={addNewButtonStyling}
                >
                  ADD NEW
                </Button>
              </Grid>
            </div>

            {filteredPatients.length < 1 && inputText.length > 0 && (
              <p css={noResults}>
                No results found for <span css={boldedSpan}>{inputText}</span>.
              </p>
            )}

            <Grid id="container" css={containerWithScrollbarStyling}>
              {filteredPatients.map((patient) => {
                const { id } = patient;

                return <Patient key={id} props={patient} />;
              })}
            </Grid>
          </div>
        </div>
      </Grid>
    );
  }
}

export default PatientList;
