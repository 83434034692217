/** @jsx jsx */
import { jsx, css } from '@emotion/core';
import Skeleton from '@material-ui/lab/Skeleton';
import Grid from '@material-ui/core/Grid';
import GlobalStyles from 'assets/GlobalStyles';

const PatientSkeleton = () => {
  return (
    <Grid className="patient-skeleton">
      <div
        css={css`
          display: grid;
          grid-template-columns: auto 1fr;
        `}
      >
        <div
          className="patient-skeleton-body"
          css={css`
            padding: 40px;
            border: ${GlobalStyles.outerContainerBorder};
            background-color: white;
            overflow-y: scroll;
          `}
        >
          <div
            css={css`
              min-width: 77vw;
            `}
          ></div>
          <Grid
            container
            className="patient-skeleton"
            direction="row"
            justify="space-around"
          >
            {Array(1)
              .fill()
              .map((item, index) => (
                <Skeleton height={120} width={'91%'} key={index} />
              ))}
            <Grid item xs={5}>
              {Array(2)
                .fill()
                .map((item, index) => (
                  <Skeleton height={100} width={'100%'} key={index} />
                ))}
            </Grid>
            <Grid item xs={5}>
              {Array(2)
                .fill()
                .map((item, index) => (
                  <Skeleton height={100} width={'100%'} key={index} />
                ))}
            </Grid>
            {Array(6)
              .fill()
              .map((item, index) => (
                <Skeleton height={100} width={'91%'} key={index} />
              ))}
          </Grid>
        </div>
      </div>
    </Grid>
  );
};

export default PatientSkeleton;
