/** @jsx jsx */
import { jsx, css } from '@emotion/core';
import styled from '@emotion/styled';
import GlobalStyles from 'assets/GlobalStyles';
import Skeleton from '@material-ui/lab/Skeleton';

const skeletonPageContainerStyling = css`
  background-color: ${GlobalStyles.white};
  padding: 15px 0;
  min-height: 100vh;
`;

const MenuSkeleton = styled(Skeleton)`
  &.MuiSkeleton-root {
    border-top-right-radius: 40px;
    border-bottom-right-radius: 40px;
    height: 80px;
    width: 22%;
  }
`;

const pageTitleStyle = css`
  margin-left: 5%;
  margin-right: 10%;
  width: 78%;
  height: 80px;
`;

const CardSkeleton = styled(Skeleton)`
  &.MuiSkeleton-root {
    border-top-left-radius: 30px;
    border-bottom-left-radius: 30px;
    margin: 25px 0 25px 10%;
    height: 200px;
  }
`;

const CallCardSkeleton = styled(Skeleton)`
  &.MuiSkeleton-root {
    border-top-left-radius: 30px;
    border-bottom-left-radius: 30px;
    margin: 25px 0 25px 10%;
    height: 330px;
  }
`;

const MobileCall911Skeleton = () => {
  return (
    <div css={skeletonPageContainerStyling}>
      <div
        css={css`
          display: flex;
          margin-bottom: 50px;
        `}
      >
        <MenuSkeleton variant="rect" />
        <Skeleton css={pageTitleStyle} />
      </div>
      <div>
        <CallCardSkeleton variant="rect" />
        <CardSkeleton variant="rect" />
      </div>
    </div>
  );
};

export default MobileCall911Skeleton;
