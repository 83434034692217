/** @jsx jsx */
import { Fragment, useState } from 'react';
import { useApolloClient } from '@apollo/client';
import { jsx, css } from '@emotion/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import { useParams, Link, useHistory } from 'react-router-dom';
import Skeleton from '@material-ui/lab/Skeleton';
import TextField from '@material-ui/core/TextField';
import { Button } from '@material-ui/core';
import OpenInNew from '@material-ui/icons/OpenInNew';
import GlobalStyles from 'assets/GlobalStyles';
import { useMutation, useQuery } from '@apollo/client';
import {
  GET_PATIENT,
  GET_PATIENT_LOG_DETAILS,
  GET_PATIENT_HOSPITAL_PLAN,
} from 'utils/queries';
import {
  UPDATE_PATIENT_PLAN_STEPS,
  UPDATE_ACTION_PLAN,
  CREATE_ACTION_PLAN_STEP,
  CREATE_ACTION_PLAN,
} from 'utils/mutations';
import { ACTION_PLAN_STEP, ACTION_PLAN } from 'utils/fragments';

function HospitalPlanStep({
  step,
  stepNumber,
  isEditing,
  currentStep,
  isErrorMessageDisplayed,
  setIsErrorMessageDisplayed,
}) {
  const client = useApolloClient();
  const { id: patientId } = useParams();
  const history = useHistory();
  const { actionPlans } = client.cache.readQuery({
    query: GET_PATIENT_HOSPITAL_PLAN,
    variables: { id: patientId },
  });
  let isPlanShowing = actionPlans[0]
    ? actionPlans[0].show_epilepticus_plan
    : true;

  //states
  const [formFields, setFormFields] = useState({
    text_field: step ? step.text_field : '',
  });
  const { text_field } = formFields;
  const [loadingSubmit, setLoadingSubmit] = useState(false);

  //Updating pdf and replacing the old pdf to new pdf
  // const [file, setFile] = useState([{ file: null }]);
  // const updateFile = async (event) => {
  //   if (event.target.files) {
  //     const files = event.target.files;

  //     setFile(files);
  //   }
  // };

  //queries

  const { data: patientData, loading: patientLoading } = useQuery(GET_PATIENT, {
    variables: { id: patientId },
  });

  const [updateActionPlanStep] = useMutation(UPDATE_PATIENT_PLAN_STEPS, {
    update(cache, { data: { updateActionPlanStep } }) {
      const { actionPlanStep } = updateActionPlanStep;
      cache.writeFragment({
        id: cache.identify(actionPlanStep),
        fragment: ACTION_PLAN_STEP,
        data: actionPlanStep,
        fragmentName: 'ActionPlanStepFields',
      });
    },
    refetchQueries: [
      { query: GET_PATIENT_LOG_DETAILS, variables: { id: patientId } },
    ],
    awaitRefetchQueries: true,
  });

  const [updateActionPlan, { loading: isUpdatingActionPlan }] = useMutation(
    UPDATE_ACTION_PLAN,
    {
      update(cache, { data: { updateActionPlan } }) {
        const { actionPlan } = updateActionPlan;
        cache.writeFragment({
          id: cache.identify(actionPlan),
          fragment: ACTION_PLAN,
          data: actionPlan,
          fragmentName: 'ActionPlanFields',
        });
      },
      refetchQueries: [
        { query: GET_PATIENT_LOG_DETAILS, variables: { id: patientId } },
      ],
      awaitRefetchQueries: true,
    }
  );

  const [createActionPlanStep] = useMutation(CREATE_ACTION_PLAN_STEP, {
    refetchQueries: [
      { query: GET_PATIENT_HOSPITAL_PLAN, variables: { id: patientId } },
      { query: GET_PATIENT_LOG_DETAILS, variables: { id: patientId } },
    ],
    awaitRefetchQueries: true,
  });
  const [createActionPlan] = useMutation(CREATE_ACTION_PLAN, {
    refetchQueries: [
      { query: GET_PATIENT_HOSPITAL_PLAN, variables: { id: patientId } },
      { query: GET_PATIENT, variables: { id: patientId } },
      { query: GET_PATIENT_LOG_DETAILS, variables: { id: patientId } },
    ],
    awaitRefetchQueries: true,
  });

  const isEditingCurrentStep = () => {
    if (isEditing && currentStep === stepNumber.toString()) {
      return true;
    } else {
      return false;
    }
  };

  const handleChange = (key) => ({ target: { value } }) => {
    if (key === 'text_field' && value === '' && !isPlanShowing) {
      setIsErrorMessageDisplayed(true);
    }
    setFormFields({ ...formFields, [key]: value });
    setIsErrorMessageDisplayed(false);
  };

  const handleHidePlan = async () => {
    if (isPlanShowing) {
      const { actionPlans } = client.cache.readQuery({
        query: GET_PATIENT_HOSPITAL_PLAN,
        variables: { id: patientId },
      });

      if (actionPlans[0] && actionPlans[0].action_plan_steps) {
        actionPlans[0].action_plan_steps.forEach((step) => {
          if (step.text_field === '') {
            //if text_field is an empty string an error message still needs to display
            setIsErrorMessageDisplayed(true);
          } else if (step.text_field) {
            const updatedPlanData = { show_epilepticus_plan: false };

            updateActionPlan({
              variables: { id: actionPlans[0].id, data: updatedPlanData },
            })
              .then(async (response) => {
                const updatedEpilepticusPlanStatus =
                  response.data.updateActionPlan.actionPlan
                    .show_epilepticus_plan;

                isPlanShowing = updatedEpilepticusPlanStatus;
              })
              .catch((error) => {
                console.log('ERROR', error);
              });
          }
        });
      } else {
        setIsErrorMessageDisplayed(true);
      }
    } else {
      const updatedPlanData = { show_epilepticus_plan: true };

      updateActionPlan({
        variables: { id: actionPlans[0].id, data: updatedPlanData },
      })
        .then(async (response) => {
          const updatedEpilepticusPlanStatus =
            response.data.updateActionPlan.actionPlan.show_epilepticus_plan;
          isPlanShowing = updatedEpilepticusPlanStatus;
        })
        .catch((error) => {
          console.log('ERROR', error);
        });
    }
  };

  const createNewActionPlan = async () => {
    setLoadingSubmit(true);
    const newPlanData = {
      patient: patientId,
      category: 'hospital',
    };
    createActionPlan({
      variables: { data: newPlanData },
    })
      .then(async (response) => {
        formFields['action_plan'] =
          response.data.createActionPlan.actionPlan.id;

        try {
          await createActionPlanStep({
            variables: { data: formFields },
          });
        } catch (error) {
          console.log('error', error);
        }
      })
      .then(() => {
        setLoadingSubmit(false);
      })
      .catch((error) => {
        console.log('error', error);
      });
  };

  const handleCreateSubmit = async (e) => {
    e.preventDefault();

    formFields['step_number'] = stepNumber;
    if (patientData.patient.action_plans.length) {
      patientData.patient.action_plans.forEach(async (actionPlan) => {
        if (actionPlan.category === 'hospital') {
          setLoadingSubmit(true);
          formFields['action_plan'] = actionPlan.id;

          await createActionPlanStep({
            variables: { data: formFields },
          })
            .then((response) => {
              const updatedTextField =
                response.data.updateActionPlanStep.actionPlanStep.text_field;

              if (updatedTextField === '' && !isPlanShowing) {
                setIsErrorMessageDisplayed(true);
              } else {
                setIsErrorMessageDisplayed(false);
              }
            })
            .catch((error) => {
              console.log('error', error);
            });
        } else {
          createNewActionPlan();
        }
      });
    } else {
      createNewActionPlan();
    }
    setLoadingSubmit(false);
    history.push(`/patient/${patientId}/plan/hospital`);
  };

  const handleUpdateSubmit = async (e) => {
    e.preventDefault();
    setLoadingSubmit(true);

    await updateActionPlanStep({
      variables: { id: step.id, data: formFields },
    })
      .then(async (response) => {
        const updatedTextField =
          response.data.updateActionPlanStep.actionPlanStep.text_field;

        if (updatedTextField === '' && !isPlanShowing) {
          setIsErrorMessageDisplayed(true);
        } else {
          setIsErrorMessageDisplayed(false);
        }
      })
      .catch((error) => {
        console.log('ERROR', error);
      });
    setLoadingSubmit(false);
    history.push(`/patient/${patientId}/plan/hospital`);
  };

  const handleCancel = async () => {
    await setFormFields({
      text_field: step ? step.text_field : '',
    });
    setIsErrorMessageDisplayed(false);
    history.push(`/patient/${patientId}/plan/hospital`);
  };

  const displayInputColor = () => {
    let backgroundColor;

    if (isEditingCurrentStep()) {
      backgroundColor = GlobalStyles.formInputYellow;
    } else if (isErrorMessageDisplayed) {
      backgroundColor = GlobalStyles.formInputRed;
    } else {
      backgroundColor = GlobalStyles.formInputGrey;
    }
    return backgroundColor;
  };

  if (patientLoading) {
    return <p>Loading</p>;
  } else {
    return (
      <Fragment>
        <div
          className="HospitalPlanStep"
          css={css`
            grid-column: 1 / span 6;
            margin-top: 1.33em;
            margin-bottom: 1.33em;
            .MuiInputBase-input {
              color: ${GlobalStyles.navyBlue};
              padding: 6px 0 7px 10px;
              font-weight: bold;
            }
          `}
        >
          {stepNumber === 1 ? (
            <Fragment>
              {isUpdatingActionPlan ? (
                <Skeleton variant="text" />
              ) : (
                <Fragment>
                  <Link
                    to={'/files/hospital_plan.pdf'}
                    disabled={isPlanShowing ? true : false}
                    target="_blank"
                    css={css`
                      font-family: 'roboto-black';
                      font-size: 16px;
                      text-decoration: none;
                      color: ${isPlanShowing
                        ? GlobalStyles.navyBlue
                        : GlobalStyles.disabledGrey};
                      cursor: ${isPlanShowing ? 'pointer' : 'auto'};
                      display: flex;
                      align-items: end;
                    `}
                  >
                    STATUS EPILEPTICUS PLAN
                    <OpenInNew
                      style={{
                        color: 'GlobalStyles.accentOrange',
                        marginLeft: '4px',
                      }}
                    />
                  </Link>
                </Fragment>
              )}
            </Fragment>
          ) : (
            <Fragment>
              <h4
                css={css`
                  font-family: 'roboto-black';
                  font-size: 16px;
                  margin-top: auto;
                `}
              >
                {isErrorMessageDisplayed && (
                  <span
                    css={css`
                      color: ${GlobalStyles.red};
                      font-family: 'roboto-thin';
                      font-size: 12px;
                      letter-spacing: 0.1em;
                      margin-left: 4px;
                    `}
                  >
                    To hide plan, alternative text must be provided
                  </span>
                )}
              </h4>

              {(isEditing && step && currentStep === stepNumber.toString()) ||
              !step ||
              !text_field ? (
                <Fragment>
                  <TextField
                    multiline
                    rows={3}
                    type="text"
                    css={css`
                      width: 100%;
                      background-color: ${displayInputColor()};
                      font-family: 'roboto-black';
                      font-size: 16px;
                      margin-top: 0px;
                    `}
                    disabled={!step && !isEditingCurrentStep()}
                    onChange={handleChange('text_field')}
                    value={text_field}
                  />
                </Fragment>
              ) : (
                <Fragment>
                  <p
                    css={css`
                      margin: auto;
                      font-family: 'roboto-light';
                      font-size: 21px;
                      white-space: pre-wrap;
                    `}
                  >
                    {text_field ? text_field : ''}
                  </p>
                </Fragment>
              )}
            </Fragment>
          )}
        </div>

        <div
          className="hospitalPlanStep__ButtonsContainer"
          css={css`
            grid-column: 7 / span 2;
            display: grid;
            grid-template-columns: 1;
          `}
        >
          {stepNumber === 1 ? (
            <Button
              onClick={handleHidePlan}
              css={css`
                background: ${GlobalStyles.accentOrange};
                color: ${GlobalStyles.white};
                width: 75%;
                height: 30px;
                border-radius: 0%;
                align-self: center;
                justify-self: right;
                font-family: 'roboto-black';
                font-size: 12px;
                :hover {
                  color: ${GlobalStyles.navyBlue};
                  background: ${GlobalStyles.accentOrange};
                }
              `}
            >
              {isUpdatingActionPlan ? (
                <CircularProgress
                  size="28px"
                  css={css`
                    .MuiCircularProgress-colorPrimary {
                      color: ${GlobalStyles.navyBlue};
                    }
                  `}
                />
              ) : isPlanShowing ? (
                'HIDE PLAN'
              ) : (
                'INCLUDE PLAN'
              )}
            </Button>
          ) : isEditing && currentStep === stepNumber.toString() ? (
            <Fragment>
              <Button
                onClick={step ? handleUpdateSubmit : handleCreateSubmit}
                disabled={isPlanShowing ? false : text_field ? false : true}
                css={css`
                  background: ${GlobalStyles.accentOrange};
                  color: ${GlobalStyles.white};
                  width: 75%;
                  height: 30px;
                  border-radius: 0%;
                  align-self: center;
                  justify-self: right;
                  font-family: 'roboto-black';
                  font-size: 12px;
                  :hover {
                    color: ${GlobalStyles.navyBlue};
                    background: ${GlobalStyles.accentOrange};
                  }
                `}
              >
                {loadingSubmit ? (
                  <CircularProgress
                    size="28px"
                    css={css`
                      .MuiCircularProgress-colorPrimary {
                        color: ${GlobalStyles.navyBlue};
                      }
                    `}
                  />
                ) : (
                  'SAVE'
                )}
              </Button>
              <Button
                onClick={handleCancel}
                css={css`
                  background-color: ${GlobalStyles.red};
                  color: ${GlobalStyles.white};
                  width: 75%;
                  height: 30px;
                  border-radius: 0%;
                  justify-self: right;
                  font-family: 'roboto-black';
                  font-size: 12px;
                  :hover {
                    color: ${GlobalStyles.navyBlue};
                    background-color: ${GlobalStyles.red};
                  }
                `}
              >
                CANCEL
              </Button>
            </Fragment>
          ) : (
            <Button
              component={Link}
              to={`/patient/${patientId}/plan/hospital/edit/${stepNumber}`}
              css={css`
                background: ${GlobalStyles.accentOrange};
                color: ${GlobalStyles.white};
                width: 75%;
                height: 30px;
                border-radius: 0%;
                align-self: center;
                justify-self: right;
                font-family: 'roboto-black';
                font-size: 12px;
                :hover {
                  color: ${GlobalStyles.navyBlue};
                  background: ${GlobalStyles.accentOrange};
                }
              `}
            >
              EDIT TEXT
            </Button>
          )}
        </div>
      </Fragment>
    );
  }
}

export default HospitalPlanStep;
