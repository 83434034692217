import gql from 'graphql-tag';
import {
  PATIENT_FULL,
  PATIENT_NAMES,
  PATIENT_DETAILS,
  PATIENT_ACTION_PLANS_IDS,
  PATIENT_LOG_FULL,
  PATIENT_LOG_DETAILS,
  PATIENT_TEAM_MEMBERS,
  MEDICATION_FULL,
  MEDICATION_NAMES,
  MEDICATION_DETAILS,
  TEAM_MEMBER_WITHOUT_PATIENT_LIST,
  TEAM_MEMBER_WITH_PATIENT_LIST,
  ACTION_PLAN,
  TEAM_ROLES,
} from './fragments';

export const CURRENT_USER = gql`
  query CurrentUser {
    role @client
  }
`;

//  ===== PATIENT QUERIES =====
//Only get patient that have not been "soft" deleted
export const GET_PATIENTS_LIST = gql`
  ${PATIENT_NAMES}
  query GetPatientList {
    patients(where: { deleted_at_null: true }, sort: "last_name:asc") {
      ...PatientNames
    }
  }
`;

export const GET_PATIENTS = gql`
  ${PATIENT_FULL}
  query GetPatients {
    patients(where: { deleted_at_null: true }, sort: "last_name:asc") {
      ...PatientFull
    }
  }
`;

export const GET_PATIENT = gql`
  ${PATIENT_FULL}
  query GetPatient($id: ID!) {
    patient(id: $id) {
      ...PatientFull
    }
  }
`;

export const GET_PATIENT_NAMES = gql`
  ${PATIENT_NAMES}
  query GetPatientNames($id: ID!) {
    patient(id: $id) {
      ...PatientNames
    }
  }
`;

export const GET_PATIENT_DETAILS = gql`
  ${PATIENT_DETAILS}
  ${PATIENT_ACTION_PLANS_IDS}
  query GetPatientDetails($id: ID!) {
    patient(id: $id) {
      ...PatientDetails
      ...PatientActionPlanIds
    }
  }
`;

export const GET_PATIENT_HOSPITAL_PLAN = gql`
  ${ACTION_PLAN}
  query GetPatientHospitalPlan($id: ID!) {
    actionPlans(where: { patient_eq: $id, category_eq: "hospital" }) {
      ...ActionPlanFields
    }
  }
`;

export const GET_PATIENT_NON_HOSPITAL_PLAN = gql`
  ${ACTION_PLAN}
  query GetPatientNonHospitalPlan($id: ID!) {
    actionPlans(where: { patient_eq: $id, category_eq: "nonHospital" }) {
      ...ActionPlanFields
    }
  }
`;

export const GET_PATIENT_LOG_FULL = gql`
  ${PATIENT_LOG_FULL}
  query GetPatientLog($id: ID!) {
    patientLogs(where: { patient_eq: $id }, sort: "published_at:desc") {
      ...PatientLogFull
    }
  }
`;

export const GET_PATIENT_LOG_DETAILS = gql`
  ${PATIENT_LOG_DETAILS}
  query GetPatientLog($id: ID!) {
    patientLogs(where: { patient_eq: $id }, sort: "published_at:desc") {
      ...PatientLogDetails
    }
  }
`;

export const GET_PATIENT_UPDATED_WEIGHT_LOG = gql`
  ${PATIENT_LOG_DETAILS}
  query GetPatientUpdatedWeightLog($id: ID!) {
    patientLogs(
      where: {
        field_name: "weight"
        field_operation: "Changed"
        patient_eq: $id
      }
      sort: "published_at:desc"
      limit: 1
    ) {
      ...PatientLogDetails
    }
  }
`;

export const GET_ADMINISTERED_DOSE_LOG = gql`
  query($id: ID!) {
    patientLogs(
      sort: "updatedAt:desc"
      where: {
        _or: [
          { field_name: "administered_dose" }
          { field_name: "medication" }
          { field_operation: "Changed" }
          { field_operation: "Added" }
        ]
        patient_eq: $id
      }
      publicationState: LIVE
    ) {
      updatedAt
      field_name
      old_entry
      new_entry
      model_id
      field_operation
      patient {
        first_name
        last_name
        action_plans {
          id
          category
          action_plan_steps {
            id
            step_number
            medication {
              generic_name
            }
          }
        }
      }
    }
  }
`;

export const GET_PATIENT_TEAM_MEMBERS = gql`
  ${PATIENT_TEAM_MEMBERS}
  query GetPatientTeamMembers($id: ID!) {
    patient(id: $id) {
      ...PatientTeamMembers
    }
  }
`;

//  ===== MEDICATIONS QUERIES =====
export const GET_MEDICATIONS_NAMES_LIST = gql`
  ${MEDICATION_NAMES}
  query GetMedicationsNamesList {
    medications(sort: "generic_name:asc") {
      ...MedicationNames
    }
  }
`;

export const GET_MEDICATIONS_DETAILS_LIST = gql`
  ${MEDICATION_DETAILS}
  query GetMedicationsDetailsList {
    medications(sort: "generic_name:asc") {
      ...MedicationDetails
    }
  }
`;

export const GET_MEDICATIONS_LIST = gql`
  ${MEDICATION_FULL}
  query GetMedicationsList {
    medications(sort: "generic_name:asc") {
      ...MedicationFull
    }
  }
`;

export const GET_MEDICATION = gql`
  ${MEDICATION_FULL}
  query GetMedication($id: ID!) {
    medication(id: $id) {
      ...MedicationFull
    }
  }
`;

//  ===== TEAM MEMBERS QUERIES =====
//Only get patient's team members that have not been "soft" deleted
export const GET_TEAM_WITH_PATIENT = gql`
  ${TEAM_MEMBER_WITH_PATIENT_LIST}
  query GetPatientsTeam($id: ID!) {
    patient(id: $id) {
      id
      users(where: { deleted_at_null: true }) {
        ...TeamMemberWithPatientList
      }
    }
  }
`;

export const GET_ALL_MEDICAL_TEAM_MEMBERS = gql`
  ${TEAM_MEMBER_WITHOUT_PATIENT_LIST}
  query GetTeamMembers {
    users(where: { deleted_at_null: true }) {
      ...TeamMemberWithoutPatientList
    }
  }
`;

export const GET_TEAM_MEMBER = gql`
  ${TEAM_MEMBER_WITHOUT_PATIENT_LIST}
  query GetTeamMember($id: ID!) {
    user(id: $id) {
      ...TeamMemberWithoutPatientList
    }
  }
`;

//  ===== MISC. QUERIES =====
export const GET_FORMULATION_OPTIONS = gql`
  query {
    __type(name: "ENUM_MEDICATION_FORMULATION") {
      name
      enumValues {
        name
      }
    }
  }
`;

export const GET_UNIT_OF_DOSAGE_OPTIONS = gql`
  query {
    __type(name: "ENUM_MEDICATION_UNIT_OF_DOSAGE") {
      name
      enumValues {
        name
      }
    }
  }
`;

export const GET_ROLES = gql`
  ${TEAM_ROLES}
  query GetRoles {
    roles {
      ...RolesFields
    }
  }
`;
