/** @jsx jsx */
import { jsx, css } from '@emotion/core';
import { useState, useContext } from 'react';
import GlobalStyles from 'assets/GlobalStyles';
import Modal from '@material-ui/core/Modal';
import CloseTwoToneIcon from '@material-ui/icons/CloseTwoTone';
import IconButton from '@material-ui/core/IconButton';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import StepContext from 'components/Steps/StepContext';

const highlightDataStyling = css`
  font-weight: bold;
`;

const MaximumDosageModal = ({ cancelNewDosage, confirmNewDosage }) => {
  const { stepState } = useContext(StepContext);
  const [confirmDosageInModal, setConfirmDosageInModal] = useState('');
  const comparisonString = `${stepState.currentMedication.generic_name} ${stepState.tempMedicationDisplay}mg`;
  const isConfirmButtonEnabled = confirmDosageInModal === comparisonString;

  const handleChange = ({ target: { value } }) => {
    setConfirmDosageInModal(value);
  };

  const handleCancel = (e) => {
    e.preventDefault();
    setConfirmDosageInModal('');
    cancelNewDosage();
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    setConfirmDosageInModal('');
    confirmNewDosage();
  };
  return (
    <Modal
      open={stepState.openMaxDosageModal}
      onClose={cancelNewDosage}
      aria-labelledby="max-dosage-modal-title"
      aria-describedby="max-dosagemodal-description"
      className="max-dosage-modal"
      BackdropProps={{
        style: {
          boxShadow: 'none',
          cursor: 'pointer',
        },
      }}
      css={css`
        display: 'flex';
        align-items: 'center';
        justify-content: 'center';
        width: 50%;
        margin: auto;
        margin-top: 100px;
        border-radius: 0px;
      `}
    >
      <div
        className="max-dosage-modal__paper"
        css={css`
          background-color: ${GlobalStyles.white};
          border: solid 7px ${GlobalStyles.navyBlue};
          .MuiInputBase-input {
            color: ${GlobalStyles.navyBlue};
            padding: 6px 6px 0px 6px;
          }
        `}
      >
        <IconButton
          onClick={cancelNewDosage}
          aria-label="close"
          css={css`
            color: ${GlobalStyles.white};
            background-color: ${GlobalStyles.navyBlue};
            border-radius: 0px;
            float: right;
          `}
        >
          <CloseTwoToneIcon />
        </IconButton>
        <div
          className="max-dosage-modal__paper"
          css={css`
            margin: 50px;
          `}
        >
          <div
            className="max-dosage-modal__paper-title-container"
            css={css`
              display: grid;
              grid-template-columns: repeat(6, 1fr);
              column-gap: ${GlobalStyles.columnGap};
              border-bottom: 5px solid ${GlobalStyles.red};
            `}
          >
            <div
              className="max-dosage-modal__paper-title"
              css={css`
                grid-column: 1 / span 4;
                background-color: ${GlobalStyles.red};
                color: ${GlobalStyles.white};
                font-weight: bolder;
                padding: 2px 2px 0px 15px;
                font-size: 20px;
                margin-top: 50px;
                margin-bottom: -0.5%;
              `}
            >
              CAUTION: MAXIMUM DOSAGE EXCEEDED
            </div>
          </div>

          <p
            css={css`
              color: ${GlobalStyles.navyBlue};
              font-size: 21px;
              overflow-wrap: break-word;
            `}
          >
            <span css={highlightDataStyling}>
              {stepState.currentMedication.generic_name}
            </span>{' '}
            has a maximum dosage of{' '}
            <span css={highlightDataStyling}>
              {stepState.currentMedication.maximum_dosage}mg
            </span>{' '}
            . You are attempting to prescribe:{' '}
            <span css={highlightDataStyling}>
              {stepState.currentMedication.generic_name}{' '}
              {stepState.tempMedicationDisplay}mg
            </span>
            . If you are certain that you would like to override this maximum,
            please type{' '}
            <span css={highlightDataStyling}>
              {stepState.currentMedication.generic_name}{' '}
              {stepState.tempMedicationDisplay}mg
            </span>{' '}
            into the box below to confirm:
          </p>

          <TextField
            type="text"
            value={confirmDosageInModal}
            css={css`
              background-color: ${GlobalStyles.formInputYellow};
              color: ${GlobalStyles.navyBlue};
              font-size: 14px;
              width: 100%;
              margin-top: 0px;
              font-family: inherit;
            `}
            onChange={handleChange}
            margin="normal"
          />
          <div
            className="max-dosage-modal__buttons-container"
            css={css`
              display: grid;
              grid-template-columns: repeat(2, 1fr);
            `}
          >
            <div
              css={css`
                line-height: 1.75;
                height: 32px;
                margin: 10px 10px 10px 0px;
              `}
            >
              <Button
                type="button"
                css={css`
                  font-weight: bolder;
                  align-self: center;
                  background-color: ${GlobalStyles.red};
                  color: ${GlobalStyles.white};
                  cursor: pointer;
                  width: 100%;
                  text-decoration: none;
                  border-radius: 0% !important;
                  &:hover {
                    color: ${GlobalStyles.navyBlue};
                    background-color: ${GlobalStyles.red};
                  }
                  height: 32px;
                `}
                onClick={handleCancel}
              >
                CANCEL
              </Button>
            </div>
            <div
              css={css`
                line-height: 1.75;
                height: 32px;
                margin: 10px 0px 10px 10px;
              `}
            >
              <Button
                disabled={!isConfirmButtonEnabled}
                type="button"
                css={css`
                  font-weight: bolder;
                  align-self: center;
                  background-color: ${GlobalStyles.accentOrange};
                  color: ${GlobalStyles.navyBlue};
                  cursor: pointer;
                  width: 100%;
                  text-decoration: none;
                  border-radius: 0% !important;
                  &:hover {
                    color: ${GlobalStyles.navyBlue};
                    background-color: ${GlobalStyles.accentOrange};
                  }
                  height: 32px;
                `}
                onClick={handleSubmit}
              >
                CONFIRM
              </Button>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default MaximumDosageModal;
