import * as Sentry from '@sentry/react';

// Initializes Sentry via init() method IF environment is prod
export const initSentry = () => {
  if (process.env.NODE_ENV === 'production') {
    Sentry.init({
      dsn: process.env.REACT_APP_SENTRY_DSN,
    });
  }
};
