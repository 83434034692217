/** @jsx jsx */
import { jsx, css } from '@emotion/core';
import GlobalStyles from 'assets/GlobalStyles';
import BlueCard from 'components/PatientView/BlueCard.js';
import Grid from '@material-ui/core/Grid';
import MobileAppHeader from 'components/PatientView/MobileAppHeader';
import BlueCardNoData from 'components/PatientView/BlueCardNoData.js';
import { Fragment } from 'react';

function PPClinicalTeam({ patient, isMobile }) {
  return (
    <div
      className="PPClinicalTeam"
      css={css`
        margin-top: 10%;
        padding: 0 6%;
        @media (max-width: ${GlobalStyles.mobileBreakpoint}) {
          padding: 0 0 0 10%;
          margin-top: 0;
        }
      `}
    >
      {/* Add a SAP title if Mobile */}
      {isMobile && <MobileAppHeader patient={patient} />}
      <div
        css={css`
          font-family: 'roboto-bold';
          font-size: 30px;
          line-height: 35px;
          color: ${GlobalStyles.darkBlue};
          margin-bottom: 3%;
          text-align: center;
          @media (max-width: ${GlobalStyles.mobileBreakpoint}) {
            margin-left: 20%;
            text-align: left;
            margin-bottom: 5%;
            font-size: 18px;
            line-height: 21px;
            margin-bottom: 10%;
          }
        `}
      >
        {isMobile ? (
          'Clinical Care Team'
        ) : (
          <p>
            {patient.nickname}'s{' '}
            <span
              css={css`
                color: ${GlobalStyles.midBlue};
              `}
            >
              Clinical Care Team
            </span>
          </p>
        )}
      </div>
      {!patient.users.length && (
        <Fragment>
          <div
            css={css`
              display: flex;
              justify-content: center;
              overflow: hidden;
            `}
          >
            <BlueCardNoData
              nickname={patient.nickname}
              title={'NO CLINICAL CARE TEAM MEMBERS'}
              plan={'Clinical Care Team'}
            />
          </div>
        </Fragment>
      )}
      <div
        className="patientTeam__listContainer"
        css={css`
          @media (max-width: ${GlobalStyles.mobileBreakpoint}) {
            padding-top: 3%;
          }
        `}
      >
        {/* Mobile styling for the Patient Team Cards*/}
        {isMobile ? (
          <Grid container direction="column">
            {patient.users.map((member) => {
              const {
                id,
                first_name,
                last_name,
                prefix,
                position,
                contact_info,
              } = member;
              //sending one text into the blue card

              //preventing from prefix saying 'null' on the cards
              const prefixNonNull = prefix !== null ? prefix : '';

              const cardText =
                contact_info && !isMobile
                  ? prefixNonNull +
                    ' ' +
                    first_name +
                    ' ' +
                    last_name +
                    ': ' +
                    contact_info
                  : prefixNonNull + ' ' + first_name + ' ' + last_name;
              return (
                <div
                  className="patientTeam_container"
                  key={id}
                  css={css`
                    overflow: hidden;
                    margin-top: 5%;
                  `}
                >
                  <Grid item xs={12}>
                    <BlueCard
                      title={position}
                      text={cardText}
                      contactInfo={contact_info}
                      isMobile={isMobile}
                    />
                  </Grid>
                </div>
              );
            })}
          </Grid>
        ) : (
          //Desktop styling for the Patient Team Cards
          <Grid
            container
            direction="row"
            justify="space-between"
            alignItems="center"
          >
            {patient.users.map((member) => {
              const {
                id,
                first_name,
                last_name,
                prefix,
                position,
                contact_info,
              } = member;

              //preventing from prefix saying 'null' on the cards
              const prefixNonNull = prefix !== null ? prefix : '';
              //sending one text into the blue card
              const cardText = contact_info
                ? prefixNonNull +
                  ' ' +
                  first_name +
                  ' ' +
                  last_name +
                  ': ' +
                  contact_info
                : prefixNonNull + ' ' + first_name + ' ' + last_name;

              return (
                <BlueCard
                  key={id}
                  title={position}
                  text={cardText}
                  isMobile={isMobile}
                />
              );
            })}
          </Grid>
        )}
      </div>
    </div>
  );
}

export default PPClinicalTeam;
