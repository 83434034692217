/** @jsx jsx */
import { jsx, css } from '@emotion/core';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import GlobalStyles from 'assets/GlobalStyles';

const useStyles = makeStyles(() => ({
  root: {
    maxWidth: '41vw',
    minHeight: '40vh',
    backgroundColor: `${GlobalStyles.paleBlueCardBackground}`,
    borderRadius: '30px',
    marginBottom: '3%',

    '@media (max-width: 960px)': {
      maxWidth: '100vw',
      minHeight: '0',
      marginRight: '-10%',
      display: 'grid',
      padding: '9px 5px 5% 33px',
    },
  },
}));

export default function BlueCardNoData({ title, nickname, plan }) {
  const classes = useStyles();

  return (
    <Card className={classes.root}>
      <div
        css={css`
          margin-left: 7.5%;
          /* padding right is so the blue line always has space to show */
          padding-right: 15%;
          @media (max-width: ${GlobalStyles.mobileBreakpoint}) {
            margin-left: 0;
          }
        `}
      >
        <h3
          css={css`
            color: ${GlobalStyles.midBlue};
            font-family: 'poppins-extra-bold';
            letter-spacing: 1px;
            font-size: 24px;
            @media (max-width: ${GlobalStyles.mobileBreakpoint}) {
              font-size: 16px;
            }
            /* The styling below is for the horizontal line on the right side of the title */
            ::after {
              flex: 1 1;
              background-color: ${GlobalStyles.midBlue};
              content: '';
              display: inline-block;
              height: 3px;
              position: relative;
              vertical-align: middle;
              margin-left: 10px;
              width: 102%;
              margin-right: -100%;
            }
          `}
        >
          {title && title.toUpperCase()}
        </h3>
      </div>

      <p
        css={css`
          color: ${GlobalStyles.darkBlue};
          margin-left: 7.5%;
          margin-right: 6%;
          font-family: 'poppins-medium';
          font-size: 20px;
          padding-bottom: 3%;
          @media (max-width: ${GlobalStyles.mobileBreakpoint}) {
            padding-top: 1%;
            font-size: 14px;
            line-height: 21px;
            text-align: left;
            margin-left: 0;
            padding-right: 10px;
          }
        `}
      >
        {`${nickname} does not have ${plan} created yet. Please contact ${nickname}'s primary neurologist for next steps.`}
      </p>
    </Card>
  );
}
