/** @jsx jsx */
import { jsx, css } from '@emotion/core';
import { useState, Fragment } from 'react';
import GlobalStyles from 'assets/GlobalStyles';
import moment from 'moment';
import {
  Dialog,
  Grid,
  IconButton,
  TextField,
  Button,
  CircularProgress,
} from '@material-ui/core/';
import CloseTwoToneIcon from '@material-ui/icons/CloseTwoTone';

const boldedSpan = css`
  font-weight: bold;
  font-family: roboto-bold;
`;

const modalContainerWithScrollbar = css`
  background-color: ${GlobalStyles.white};
  border: solid 7px ${GlobalStyles.navyBlue};
  overflow-y: scroll;
  /* Scrollbar for Firefox */
  scrollbar-color: ${GlobalStyles.babyBlue} ${GlobalStyles.backgroundBlue};

  &::-webkit-scrollbar {
    width: 15px;
  }
  &::-webkit-scrollbar-thumb {
    height: 10%;
    border-radius: 10px;
    background-color: ${GlobalStyles.babyBlue};
    box-shadow: inset 0 0 10px 10px ${GlobalStyles.babyBlue};
    border: 3px solid ${GlobalStyles.backgroundBlue};
  }
  &::-webkit-scrollbar-track {
    margin-top: 45px;
    border-radius: 10px;
    background-color: ${GlobalStyles.backgroundBlue};
  }
  .MuiInputBase-input {
    color: ${GlobalStyles.navyBlue};
    padding: 6px 6px 0px 6px;
  }
`;

const addNotesTitleStyling = css`
  background-color: ${GlobalStyles.midBlue};
  color: ${GlobalStyles.white};
  font-family: 'roboto-black';
  width: max-content;
  padding: 2px 5px;
  font-size: 20px;
`;

const updateNotesTitleStyling = css`
  background-color: ${GlobalStyles.navyBlue};
  color: ${GlobalStyles.white};
  font-family: 'roboto-black';
  width: max-content;
  padding: 2px 5px;
  font-size: 14px;
  margin-top: 15px;
`;

const textFieldStyling = css`
  background-color: ${GlobalStyles.formInputYellow};
  color: ${GlobalStyles.navyBlue};
  font-size: 14px;
  width: 100%;
  height: 200px;
  margin-top: 0px;
  border-top: 5px solid ${GlobalStyles.navyBlue};
  font-family: inherit;
  /* remove materialUI focused styling */
  .MuiInput-underline:hover:not(.Mui-disabled):before {
    border-bottom: none;
  }
  .MuiInput-underline:after {
    border-bottom: none;
  }
`;

const buttonStyling = css`
  font-family: 'poppins-medium';
  font-size: 18px;
  height: 32px;
  background-color: ${GlobalStyles.accentOrange};
  color: ${GlobalStyles.white};
  width: 100%;
  border-radius: 0%;
  &:hover {
    color: ${GlobalStyles.navyBlue};
    background-color: ${GlobalStyles.accentOrange};
  }
`;

const dataChanged = css`
  font-size: 18px;
  font-weight: lighter;
  color: ${GlobalStyles.textBlue};
  font-family: roboto;
  letter-spacing: 1.88px;
  line-height: 24px;
  margin: 0;
  overflow-wrap: break-word;
  white-space: pre-wrap;
`;

// Function similar to patientLogPage.js
function PatientLogs({ log, nickname, formatValue, formatFieldName }) {
  const actionPlanStepID = log.model_id;

  const stepNumber = () => {
    let stepInfo = { stepNumber: 0, category: '' };

    if (log.patient.action_plans.length) {
      log.patient.action_plans.forEach((action_plan) => {
        let currentCategory = '';
        if (action_plan.category === 'nonHospital') {
          currentCategory = 'Out-of-Hospital';
        } else {
          currentCategory =
            action_plan.category.charAt(0).toUpperCase() +
            action_plan.category.slice(1);
        }
        action_plan.action_plan_steps.forEach((step) => {
          if (step.id === actionPlanStepID) {
            stepInfo = {
              stepNumber: step.step_number,
              category: currentCategory,
            };
          }
        });
      });
    }

    return (
      <span>
        {stepInfo.stepNumber === 0 ? (
          ''
        ) : (
          <span className="boldedText" css={boldedSpan}>
            Step {stepInfo.stepNumber}
          </span>
        )}
      </span>
    );
  };

  // finding Step Number and Category: Hosital vs. Non-hospital
  const findStepInfo = () => {
    // Status epilepticus plan logic (hospital step 1, this logic needs to come first)
    if (
      log.model_name === 'action-plan' &&
      log.field_name === 'show_epilepticus_plan'
    ) {
      if (log.field_operation === 'Removed') {
        return (
          <span>
            <span className="boldedText" css={boldedSpan}>
              {' '}
              Status Epilepticus Plan
            </span>{' '}
            from{' '}
            <span className="boldedText" css={boldedSpan}>
              {log.patient.nickname}&apos;s Hospital Plan
            </span>
          </span>
        );
      } else if (log.field_operation === 'Added') {
        return (
          <span>
            <span className="boldedText" css={boldedSpan}>
              Status Epilepticus Plan
            </span>{' '}
            to{' '}
            <span className="boldedText" css={boldedSpan}>
              {log.patient.nickname}&apos;s Hospital Plan
            </span>
          </span>
        );
      }
    }

    // adding step 2 of Hospital Plan. || needed as on very first add field name is patient's hospital plan but changes to text_field after deleting step 2 and adding it again
    if (
      log.model_name === 'action-plan-step' &&
      (log.field_name === "patient's hospital plan" ||
        log.field_name === 'text_field')
    ) {
      if (log.field_operation === 'Removed') {
        return (
          <span css={dataChanged}>
            <span className="boldedText" css={boldedSpan}>
              Step 2{' '}
            </span>
            of{' '}
            <span className="boldedText" css={boldedSpan}>
              {log.patient.nickname}&apos;s Hospital Plan
            </span>{' '}
            <br />
            <span className="boldedText" css={boldedSpan}>
              Previous:
            </span>
            {log.text_field}
            {formatValue(log.field_name, log.old_entry)} <br />
            <span className="boldedText" css={boldedSpan}>
              Current:
            </span>
            {log.text_field}
          </span>
        );
      } else if (
        log.field_operation === 'Added' &&
        log.field_name === "patient's hospital plan"
      ) {
        return (
          <span css={dataChanged}>
            <span className="boldedText" css={boldedSpan}>
              Step 2{' '}
            </span>
            of{' '}
            <span className="boldedText" css={boldedSpan}>
              {log.patient.nickname}&apos;s Hospital Plan
            </span>{' '}
            <br />
            <span className="boldedText" css={boldedSpan}>
              Previous:{' '}
            </span>
            N/A
            {formatValue(log.field_name, log.old_entry)} <br />
            <span className="boldedText" css={boldedSpan}>
              Current:{' '}
            </span>
            {log.text_field}
          </span>
        );
      } else if (
        log.field_operation === 'Added' &&
        log.field_name === 'text_field'
      ) {
        return (
          <span css={dataChanged}>
            <span className="boldedText" css={boldedSpan}>
              Step 2{' '}
            </span>
            of{' '}
            <span className="boldedText" css={boldedSpan}>
              {log.patient.nickname}&apos;s Hospital Plan
            </span>{' '}
            <br />
            <span className="boldedText" css={boldedSpan}>
              Previous:{' '}
            </span>
            N/A
            {formatValue(log.field_name, log.old_entry)} <br />
            <span className="boldedText" css={boldedSpan}>
              Current:{' '}
            </span>
            {log.new_entry}
          </span>
        );
      }
    }

    if (log.model_name === 'patient' && log.field_operation === 'Removed') {
      return (
        <span>
          <span className="boldedText" css={boldedSpan}>
            {clinicalTeam}
          </span>{' '}
          from{' '}
          <span className="boldedText" css={boldedSpan}>
            {log.patient.nickname}&apos;s Clinical Team
          </span>
        </span>
      );
    }

    let stepInfo = { stepNumber: 0, category: '' };

    if (log.patient.action_plans.length) {
      log.patient.action_plans.forEach((action_plan) => {
        let currentCategory = '';
        if (action_plan.category === 'nonHospital') {
          currentCategory = 'Out-of-Hospital';
        } else {
          currentCategory =
            action_plan.category.charAt(0).toUpperCase() +
            action_plan.category.slice(1);
        }
        action_plan.action_plan_steps.forEach((step) => {
          if (step.id === actionPlanStepID) {
            stepInfo = {
              stepNumber: step.step_number,
              category: currentCategory,
            };
          }
        });
      });
    }
    // ONLY for Patient Details & Team Members
    if (stepInfo.stepNumber === 0) {
      return '';
    }

    return (
      <span>
        {/* this shows which step of hospital/out-of-hosp plan was changed */}
        <span className="boldedText" css={boldedSpan}>
          Step {stepInfo.stepNumber}
        </span>{' '}
        of{' '}
        <span className="boldedText" css={boldedSpan}>
          {log.patient.nickname}&apos;s{' '}
          {stepInfo.category.charAt(0).toUpperCase() +
            stepInfo.category.slice(1)}{' '}
          Plan
        </span>
      </span>
    );
    // All changes in Patient's Plan - hospital and out of hospital
  };

  let clinicalTeam = ' ';
  if (log.field_name === 'users') {
    clinicalTeam = log.new_entry
      .join(', ')
      .replace(/, ((?:.(?!, ))+)$/, ' and $1');
  }

  return (
    <div>
      {/* Logs for deleting information     */}
      <Grid className="ChangedData" item>
        {log.field_operation === 'Deleted' && log.model_name === 'patient' ? (
          <p css={dataChanged}>
            {/* Deleting patient form information */}
            Deleted{' '}
            <span className="boldedText" css={boldedSpan}>
              {formatFieldName(log.field_name)}
            </span>{' '}
            {findStepInfo()} <br />
            <span className="boldedText" css={boldedSpan}>
              Previous:{' '}
            </span>
            {formatValue(log.field_name, log.old_entry)}
            <br />
            <span className="boldedText" css={boldedSpan}>
              Current:{' '}
            </span>
            N/A
          </p>
        ) : log.field_operation === 'Deleted' &&
          (log.field_name === "patient's hospital plan" ||
            log.field_name === 'text_field') ? (
          <p css={dataChanged}>
            {/* Deleting hospital plan step 2 info  */}
            Deleted
            <span className="boldedText" css={boldedSpan}>
              {formatFieldName(log.field_name)}
            </span>{' '}
            {findStepInfo()}
            <br />
            <span className="boldedText" css={boldedSpan}>
              Previous:{' '}
            </span>
            {formatValue(log.field_name, log.old_entry)}
            <br />
            <span className="boldedText" css={boldedSpan}>
              Current:{' '}
            </span>
            N/A
          </p>
        ) : log.field_operation === 'Changed' && log.field_name === 'weight' ? (
          <p css={dataChanged}>
            {/* Updates to patient weight  */}
            Updated{' '}
            <span className="boldedText" css={boldedSpan}>
              {formatFieldName('weight')}
            </span>{' '}
            <br />
            <span className="boldedText" css={boldedSpan}>
              Previous:{' '}
            </span>
            {formatValue(log.field_name, log.old_entry)} kg
            <br />
            <span className="boldedText" css={boldedSpan}>
              Current:{' '}
            </span>
            {formatValue(log.field_name, log.new_entry)} kg
          </p>
        ) : log.field_operation === 'Changed' && log.field_name === 'notes' ? (
          /* Updating notes in Step 1 of Out of Hospital  */
          <p css={dataChanged}>
            Updated{' '}
            <span className="boldedText" css={boldedSpan}>
              {findStepInfo()}
            </span>{' '}
            <br />
            <span className="boldedText" css={boldedSpan}>
              Previous:{' '}
            </span>
            {formatValue(log.field_name, log.old_entry)} <br />
            <span className="boldedText" css={boldedSpan}>
              Current:{' '}
            </span>
            {formatValue(log.field_name, log.new_entry)}{' '}
          </p>
        ) : log.field_operation === 'Changed' &&
          log.field_name !== 'action_plan' ? (
          <p css={dataChanged}>
            {/* This is for changes to the patient details form */}
            {/* Also for updating Step2 and 3 of Out-of-hospital-plan */}
            Updated{' '}
            <span className="boldedText" css={boldedSpan}>
              {formatFieldName(log.field_name)} {stepNumber(nickname)}
              {log.model_name === 'patient'
                ? ''
                : log.field_name !== 'show_epilepticus_plan' &&
                  log.field_name !== 'text_field'
                ? ` of ${log.patient.nickname}'s Out-of-Hospital Plan`
                : ` of ${log.patient.nickname}'s Hospital Plan`}
            </span>{' '}
            <br />
            <span className="boldedText" css={boldedSpan}>
              Previous:{' '}
            </span>
            {formatValue(log.field_name, log.old_entry)} <br />
            <span className="boldedText" css={boldedSpan}>
              Current:{' '}
            </span>
            {formatValue(log.field_name, log.new_entry)}
          </p>
        ) : log.field_operation === 'Removed' &&
          log.field_name !== 'action_plan' ? (
          <p css={dataChanged}>
            {/* Removing steps*/}
            Removed {findStepInfo()}
          </p>
        ) : log.field_operation === 'Added' && log.model_name === 'patient' ? (
          <p css={dataChanged}>
            {/* Adding patient form info */}
            Added{' '}
            <span className="boldedText" css={boldedSpan}>
              {formatFieldName(log.field_name)}
            </span>{' '}
            {findStepInfo()} <br />
            <span className="boldedText" css={boldedSpan}>
              Previous:{' '}
            </span>
            N/A
            <br />
            <span className="boldedText" css={boldedSpan}>
              Current:{' '}
            </span>
            {formatValue(log.field_name, log.new_entry)}
          </p>
        ) : log.field_operation === 'Added' &&
          log.new_entry === 'step number 1' ? (
          <p css={dataChanged}>
            {/* Adding step 1 of out-of-hospital-plan for the first time */}
            <Fragment>
              Added{' '}
              <span className="boldedText" css={boldedSpan}>
                Step 1 of {log.patient.nickname}&apos;s Out-of-Hospital Plan
              </span>
              <br />
              <span className="boldedText" css={boldedSpan}>
                Previous:{' '}
              </span>
              N/A <br />
              <span className="boldedText" css={boldedSpan}>
                Current:{' '}
              </span>{' '}
              {log.text_field}
            </Fragment>
          </p>
        ) : log.field_operation === 'Added' &&
          log.new_entry === 'step number 2' &&
          log.field_name === "patient's nonHospital plan" ? (
          <p css={dataChanged}>
            {/* Adding step 2 of out-of-hospital-plan for the first time */}
            <Fragment>
              Added{' '}
              <span className="boldedText" css={boldedSpan}>
                Step 2 of {log.patient.nickname}&apos;s Out-of-Hospital Plan
              </span>
            </Fragment>
          </p>
        ) : log.field_operation === 'Added' &&
          log.field_name !== 'action_plan' ? (
          <p css={dataChanged}>
            {/* Adding in hospital plan steps 1 & 2 AND steps 2,3 out-of-hosp plans */}
            Added {findStepInfo()}
          </p>
        ) : (
          <p css={dataChanged}>
            {/* Adding step 2 and 3 of out-of-hospital-plan for the first time */}
            {(log.new_entry === 'step number 2' ||
              log.new_entry === 'step number 3') && (
              <Fragment>
                Added{' '}
                <span className="boldedText" css={boldedSpan}>
                  {formatFieldName(log.new_entry)} to Out-of-Hospital Plan
                </span>
                <br />
              </Fragment>
            )}
            {/* Adding patient details for the first time */}
            {log.new_entry !== 'step number 1' &&
            log.new_entry !== 'step number 2' &&
            log.new_entry !== 'step number 3' &&
            log.field_name !== 'action_plan' &&
            log.field_name !== 'show_epilepticus_plan' ? (
              <Fragment>
                Added{' '}
                <span className="boldedText" css={boldedSpan}>
                  {formatFieldName(log.field_name)}
                </span>
                <br />
                <span className="boldedText" css={boldedSpan}>
                  Previous:{' '}
                </span>
                N/A <br />
                <span className="boldedText" css={boldedSpan}>
                  Current:{' '}
                </span>{' '}
                {formatValue(log.field_name, log.new_entry)}
              </Fragment>
            ) : null}{' '}
          </p>
        )}
      </Grid>
    </div>
  );
}

const UpdateNotesModal = ({
  handleClose,
  open,
  currentLog,
  updatedNotes,
  setUpdatedNotes,
  UpdatePatientLogNotes,
  formatFieldName,
  formatValue,
}) => {
  const handleChange = ({ target: { value } }) => {
    setUpdatedNotes(value);
  };

  const [loadingSubmit, setLoadingSubmit] = useState(false);

  const handleSubmit = async (e) => {
    setLoadingSubmit(true);
    e.preventDefault();
    await UpdatePatientLogNotes({
      variables: { id: currentLog.id, data: updatedNotes },
    });
    setLoadingSubmit(false);
    handleClose();
  };
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="log-modal-title"
      aria-describedby="log-modal-description"
      className="notesModel__modal"
      scroll="paper"
      BackdropProps={{
        style: {
          boxShadow: 'none',
          cursor: 'pointer',
        },
      }}
      css={css`
        display: 'flex';
        align-items: 'center';
        justify-content: 'center';
        margin: 6rem auto;
        border-radius: 0px;
        .MuiDialog-paperWidthSm {
          width: 50vw;
        }
      `}
    >
      <div css={modalContainerWithScrollbar}>
        <IconButton
          onClick={handleClose}
          aria-label="close"
          css={css`
            color: ${GlobalStyles.white};
            background-color: ${GlobalStyles.navyBlue};
            border-radius: 0px;
            position: absolute;
            top: 0;
            right: 0;
          `}
        >
          <CloseTwoToneIcon />
        </IconButton>
        <div
          className="notesModel__paper"
          css={css`
            margin: 50px;
          `}
        >
          <div css={addNotesTitleStyling}>ADD NOTES</div>

          <div
            className="notesModel__info-container"
            css={css`
              display: flex;
              justify-content: space-between;
              border-top: 5px solid ${GlobalStyles.midBlue};
              border-bottom: 5px solid ${GlobalStyles.midBlue};
              margin-bottom: 15px;
              color: ${GlobalStyles.midBlue};
              font-size: 12px;
            `}
          >
            <div
              css={css`
                display: flex;
                align-self: center;
                font-family: 'roboto-bold';
              `}
            >
              {moment(currentLog.published_at).format('MMM D, YYYY | h:mm A')}
            </div>
            <div className="teamInfoContainer">
              <p
                className="teamMemberName"
                css={css`
                  font-family: 'roboto-bold-italic';
                `}
              >
                {currentLog.updated_by_user
                  ? `${currentLog.updated_by_user.prefix} ${currentLog.updated_by_user.first_name} ${currentLog.updated_by_user.last_name}`
                  : null}
              </p>
              <p
                className="teamMemberPosition"
                css={css`
                  font-family: 'roboto-medium';
                  font-size: 8px;
                  text-align: right;
                `}
              >
                {currentLog.updated_by_user
                  ? currentLog.updated_by_user.position.toUpperCase()
                  : null}
              </p>
            </div>
          </div>

          {/* LOG INFORMATION - props from PatientLogPage.js  */}
          <PatientLogs
            log={currentLog}
            formatFieldName={formatFieldName}
            formatValue={formatValue}
          />

          <div css={updateNotesTitleStyling}>UPDATE NOTES</div>
          <TextField
            type="text"
            multiline
            rows={10}
            value={updatedNotes}
            css={textFieldStyling}
            onChange={handleChange}
            margin="normal"
          />

          <Button css={buttonStyling} onClick={handleSubmit}>
            {loadingSubmit ? (
              <CircularProgress
                size="28px"
                css={css`
                  .MuiCircularProgress-colorPrimary {
                    color: ${GlobalStyles.navyBlue};
                  }
                `}
              />
            ) : (
              'SAVE'
            )}
          </Button>
        </div>
      </div>
    </Dialog>
  );
};

export default UpdateNotesModal;
