/** @jsx jsx */
import { jsx, css } from '@emotion/core';
import { useState, Fragment } from 'react';
import { useHistory } from 'react-router-dom';
import GlobalStyles from 'assets/GlobalStyles';
import TextField from '@material-ui/core/TextField';
import { Button } from '@material-ui/core';
import axios from 'axios';

function ResetPassword() {
  const history = useHistory();
  const [formFields, setFormFields] = useState({
    password: '',
    passwordConfirmation: '',
  });
  const handleChange = (key) => ({ target: { value } }) => {
    setFormFields({ ...formFields, [key]: value });
  };
  const [passwordStatus, setPasswordStatus] = useState('');
  const [error, setError] = useState();

  //get reset code from URL params
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const code = urlParams.get('code');

  const resetPassword = async (e) => {
    e.preventDefault();

    axios
      .post(`${process.env.REACT_APP_API_URL}/auth/reset-password`, {
        code: code,
        password: formFields.password,
        passwordConfirmation: formFields.passwordConfirmation,
      })
      .then(() => {
        setPasswordStatus('success');
        console.log("Your user's password has been reset.");
      })
      .catch((error) => {
        setError(error.response.data.message[0].messages[0].message);
        console.log('An error occurred:', error.response);
      });
  };

  if (passwordStatus === 'success') {
    return (
      <Fragment>
        <p>
          Success! Your password has been reset. Follow the link below to login
          with your new password.
        </p>
        <Button
          className="appBar__button-login"
          onClick={() => {
            history.push('/login');
          }}
          css={css`
            display: grid;
            grid-column-start: 5;
            font-weight: bolder;
            align-self: center;
            background-color: ${GlobalStyles.accentOrange};
            color: ${GlobalStyles.navyBlue};
            cursor: pointer;
            text-decoration: none;
            &:hover {
              color: ${GlobalStyles.navyBlue};
              background-color: ${GlobalStyles.accentOrange};
            }
          `}
        >
          Login
        </Button>
      </Fragment>
    );
  } else {
    return (
      <div>
        <form
          onSubmit={resetPassword}
          css={css`
            padding-left: 50px;
            padding-right: 50px;
            margin: auto;
            display: grid;
            justify-content: 'space-between';
          `}
        >
          <TextField
            error={error ? true : false}
            type="password"
            label="New Password"
            onChange={handleChange('password')}
          />
          <TextField
            error={error ? true : false}
            helperText={error ? error : ''}
            type="password"
            label="Confirm New Password"
            onChange={handleChange('passwordConfirmation')}
          />
          <Button
            type="submit"
            css={css`
              background-color: ${GlobalStyles.accentOrange};
              color: ${GlobalStyles.navyBlue};

              &:hover {
                color: ${GlobalStyles.navyBlue};
                background-color: ${GlobalStyles.accentOrange};
              }
            `}
          >
            Reset Password
          </Button>
        </form>
      </div>
    );
  }
}

export default ResetPassword;
