/** @jsx jsx */
import { jsx } from '@emotion/core';
import { createContext, useState } from 'react';

const defaultStepState = {
  openMaxDosageModal: false,
  openStep2UpdatedModal: false,
  rescueMedicationNameIsUpdating: false,
  currentMedication: {},
  tempMedicationDisplay: null,
  isExceedingMaxDoseConfirmed: false,
  formFields: {
    step_number: '',
    notes: '',
    total_time_in_minutes: null,
    administered_dose: null,
    estimated_weight_dose_per_kg: null,
    calculated_dose: null,
    medication: null,
    length_of_current_seizure_time_in_minutes: null,
    total_number_of_seizures: null,
    based_on_patients_weight: null,
    step_weight_last_updated: null,
  },
  step: null,
  stepNumber: null,
  isEditing: false,
  currentStep: null,
  medicationData: {},
  patientData: {},
  actionPlanStepLoading: false,
};
const StepContext = createContext();

const StepProvider = ({ children }) => {
  const [stepState, setStepState] = useState(defaultStepState);

  return (
    <StepContext.Provider value={{ stepState, setStepState }}>
      {children}
    </StepContext.Provider>
  );
};

export { StepContext as default, StepProvider };
