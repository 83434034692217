import { Fragment, useState } from 'react';
import { useParams, NavLink } from 'react-router-dom';
import { useQuery } from '@apollo/client';
/** @jsx jsx */
import { jsx, css } from '@emotion/core';
import GlobalStyles from 'assets/GlobalStyles';
import {
  GET_PATIENT_HOSPITAL_PLAN,
  GET_PATIENT_NON_HOSPITAL_PLAN,
} from 'utils/queries';
import PatientTabs from 'components/PatientTabs';
import PlanContainer from 'components/PlanContainer';
import PatientViewLink from 'components/PatientViewLink';
//Loading Skeleton
import PatientTabSkeleton from '../components/Skeletons/PatientTabSkeleton';

function PatientPlanPage({ isEditing }) {
  const { id: patientId } = useParams();

  const [activeTab, setActiveTab] = useState('non-hospital');

  const { data: hospitalPlanData, loading: hospitalPlanLoading } = useQuery(
    GET_PATIENT_HOSPITAL_PLAN,
    {
      variables: { id: patientId },
    }
  );
  const {
    data: nonHospitalPlanData,
    loading: nonHospitalPlanLoading,
  } = useQuery(GET_PATIENT_NON_HOSPITAL_PLAN, {
    variables: { id: patientId },
  });

  const checkIfSteps = (planType) => {
    if (planType === 'hospital') {
      if (
        hospitalPlanData &&
        hospitalPlanData.actionPlans[0] &&
        hospitalPlanData.actionPlans[0].action_plan_steps
      ) {
        return hospitalPlanData.actionPlans[0].action_plan_steps;
      } else {
        return null;
      }
    } else if (planType === 'non hospital') {
      if (
        nonHospitalPlanData &&
        nonHospitalPlanData.actionPlans[0] &&
        nonHospitalPlanData.actionPlans[0].action_plan_steps
      ) {
        return nonHospitalPlanData.actionPlans[0].action_plan_steps;
      } else {
        return null;
      }
    }
  };

  if (hospitalPlanLoading || nonHospitalPlanLoading) {
    return <PatientTabSkeleton />;
  } else {
    return (
      <Fragment>
        <PatientTabs id={patientId} activeTab="plan"></PatientTabs>
        <div
          className="patientPlan"
          css={css`
            border: ${GlobalStyles.outerContainerBorder};
            background-color: white;
            display: grid;
            grid-template-columns: repeat(10, 1fr);
            column-gap: ${GlobalStyles.columnGap};
            column-gap: 10px;
            padding-bottom: 80px;
          `}
        >
          <div
            className="patientPlan__container"
            css={css`
              grid-column: 2 / span 8;
              padding-top: 40px;
              height: 700px;
              overflow-y: scroll;
              overflow-x: hidden;
              width: 105%;
              margin-left: -2%;

              &::-webkit-scrollbar {
                width: 20px;
              }
              &::-webkit-scrollbar-thumb {
                height: 10%;
                border-radius: 10px;
                background-color: ${GlobalStyles.babyBlue};
                box-shadow: inset 0 0 10px 10px ${GlobalStyles.babyBlue};
                border: 3px solid ${GlobalStyles.backgroundBlue};
              }
              &::-webkit-scrollbar-track {
                margin-top: 75px;
                border-radius: 10px;
                height: 70%;
                background-color: ${GlobalStyles.backgroundBlue};
              }
            `}
          >
            <PatientViewLink
              id={patientId}
              hospitalPlanSteps={checkIfSteps('hospital')}
              nonHospitalPlanSteps={checkIfSteps('non hospital')}
              activeTab={activeTab}
            />
            <div
              className="patientPlan__tabContainer"
              css={css`
                height: 2.5rem;
                display: grid;
                grid-template-columns: repeat(2, 1fr);
                border-bottom: 5px solid ${GlobalStyles.navyBlue};
                margin-top: 50px;
                margin-right: 30px;
              `}
            >
              <NavLink
                to={`/patient/${patientId}/plan/nonhospital`}
                className="patientPlan__tabNonHospital"
                onClick={() => setActiveTab('non-hospital')}
                onKeyPress={() => setActiveTab('non-hospital')}
                role="button"
                tabIndex="0"
                css={css`
                  height: 100%;
                  display: grid;
                  justify-content: center;
                  font-family: 'poppins-medium';
                  font-size: 20px;
                  align-content: center;
                  text-decoration: none;
                  margin-bottom: 11px;
                  color: ${activeTab === 'non-hospital'
                    ? GlobalStyles.white
                    : GlobalStyles.navyBlue};
                  background-color: ${activeTab === 'non-hospital'
                    ? GlobalStyles.navyBlue
                    : null};
                `}
              >
                OUT-OF-HOSPITAL PLAN
              </NavLink>
              <NavLink
                to={`/patient/${patientId}/plan/hospital`}
                className="patientPlan__tabHospital"
                onClick={() => setActiveTab('hospital')}
                onKeyPress={() => setActiveTab('hospital')}
                role="button"
                tabIndex="0"
                css={css`
                  height: 100%;
                  display: grid;
                  justify-content: center;
                  align-content: center;
                  text-decoration: none;
                  font-family: 'poppins-medium';
                  font-size: 20px;
                  color: ${activeTab === 'hospital'
                    ? GlobalStyles.white
                    : GlobalStyles.navyBlue};
                  background-color: ${activeTab === 'hospital'
                    ? GlobalStyles.navyBlue
                    : null};
                `}
              >
                HOSPITAL PLAN
              </NavLink>
            </div>
            {activeTab === 'non-hospital' ? (
              <PlanContainer
                planSteps={checkIfSteps('non hospital')}
                isEditing={isEditing}
                planType="non-hospital"
              />
            ) : (
              <PlanContainer
                planSteps={checkIfSteps('hospital')}
                isEditing={isEditing}
                planType="hospital"
              />
            )}
          </div>
        </div>
      </Fragment>
    );
  }
}

export default PatientPlanPage;
