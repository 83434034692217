import { useState, Fragment } from 'react';
import { Link, useParams } from 'react-router-dom';
import { useQuery } from '@apollo/client';
/** @jsx jsx */
import { jsx, css } from '@emotion/core';
import Button from '@material-ui/core/Button';
import GlobalStyles from 'assets/GlobalStyles';
import {
  GET_PATIENT_TEAM_MEMBERS,
  GET_ALL_MEDICAL_TEAM_MEMBERS,
} from 'utils/queries';
import PatientTabs from 'components/PatientTabs';
import TeamContainer from 'components/TeamContainer';

//Loading Skeleton
import PatientTabSkeleton from '../components/Skeletons/PatientTabSkeleton';

function PatientTeam({ isEditing }) {
  const { id: patientId } = useParams();
  const [teamMemberIdsChecked] = useState([]);
  const [addingTeamMembers, setAddingTeamMembers] = useState(false);
  const [teamMemberToDelete] = useState([]);

  const { data: clinicalTeamMembers } = useQuery(GET_ALL_MEDICAL_TEAM_MEMBERS);

  const { data, loading } = useQuery(GET_PATIENT_TEAM_MEMBERS, {
    variables: { id: patientId },
  });

  if (loading) {
    return <PatientTabSkeleton />;
  } else {
    return (
      <Fragment>
        <PatientTabs id={patientId}></PatientTabs>
        <div
          css={css`
            border: ${GlobalStyles.outerContainerBorder};
            background-color: ${GlobalStyles.white};
          `}
        >
          <div
            className="patientTeam__listContainer"
            css={css`
              height: 50vh;
              overflow-y: ${data.patient.users.length ||
              (addingTeamMembers && clinicalTeamMembers.users.length)
                ? 'auto'
                : 'hidden'};
              margin: 80px 53px 80px 53px;
              /* Scrollbar for Firefox */
              /* prettier-ignore */
              scrollbar-color: ${GlobalStyles.babyBlue} ${GlobalStyles.backgroundBlue};

              &::-webkit-scrollbar {
                width: 15px;
              }
              &::-webkit-scrollbar-thumb {
                height: 40%;
                border-radius: 10px;
                background-color: ${GlobalStyles.babyBlue};
                box-shadow: inset 0 0 10px 10px ${GlobalStyles.babyBlue};
                border: 3px solid ${GlobalStyles.backgroundBlue};
              }
              &::-webkit-scrollbar-track {
                border-radius: 10px;
                background-color: ${GlobalStyles.backgroundBlue};
              }
            `}
          >
            {!data.patient.users.length && !addingTeamMembers && (
              <div
                css={css`
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  flex-direction: column;
                  width: 100%;
                  height: 100%;
                `}
              >
                <p>This patient does not yet have any medical team members</p>
              </div>
            )}
            {data.patient.users.length
              ? data.patient.users.map((member) => {
                  return (
                    <div
                      className="patientTeam__container"
                      key={member.id}
                      css={css`
                        display: grid;
                        justify-items: start;
                        grid-template-columns: 100px 1fr;
                        margin: 0px 40px 48px 40px;
                      `}
                    >
                      <TeamContainer
                        member={member}
                        isEditing={isEditing}
                        teamMemberIdsChecked={teamMemberIdsChecked}
                        teamMemberToDelete={teamMemberToDelete}
                        addingTeamMembers={addingTeamMembers}
                      />
                    </div>
                  );
                })
              : null}
          </div>
          <div
            css={css`
              display: flex;
              justify-content: space-between;
              margin: 0 7.5% 67px 10%;
            `}
          >
            <Button
              className="patientTeam__add-member-button"
              fullWidth
              component={Link}
              to={`/patient/${patientId}/team/add`}
              onClick={() => setAddingTeamMembers(true)}
              css={css`
                background-color: ${GlobalStyles.accentOrange};
                font-weight: bolder;
                color: ${GlobalStyles.white};
                width: 423px;
                border-radius: 0%;
                margin-bottom: 2rem;
                margin-right: 1rem;
                height: 32px;
                &:hover {
                  color: ${GlobalStyles.navyBlue};
                  background-color: ${GlobalStyles.accentOrange};
                }
              `}
            >
              Add
            </Button>
            <Button
              component={Link}
              to={`/patient/${patientId}/team/edit`}
              css={css`
                background-color: ${GlobalStyles.accentOrange};
                font-weight: bolder;
                color: ${GlobalStyles.white};
                width: 423px;
                border-radius: 0%;
                margin-bottom: 2rem;
                margin-left: 1rem;
                height: 32px;
                &:hover {
                  color: ${GlobalStyles.navyBlue};
                  background-color: ${GlobalStyles.accentOrange};
                }
              `}
            >
              Edit
            </Button>
          </div>
        </div>
      </Fragment>
    );
  }
}

export default PatientTeam;
