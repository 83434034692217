/** @jsx jsx */
import { jsx, css } from '@emotion/core';
import { Fragment, useContext, useEffect, useState } from 'react';
import moment from 'moment';
import { Button } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import GlobalStyles from 'assets/GlobalStyles';
import StepContext from 'components/Steps/StepContext';

const boldTextStyling = css`
  font-weight: bolder;
`;

const calculatorButtonStyling = css`
  background-color: ${GlobalStyles.accentOrange};
  color: ${GlobalStyles.white};
  font-family: 'roboto-black';
  font-size: 12px;
  text-align: center;
  border-radius: 0px;
  font-size: 12px;
  cursor: pointer;
  display: block;
  margin: 15px 0px 15px;
  padding: 0px 8px;
  &:hover {
    color: ${GlobalStyles.navyBlue};
    background-color: ${GlobalStyles.accentOrange};
  }
`;

const CalculationsforStepTwoAndThree = ({
  displayInputColor,
  isEditingCurrentStep,
  handleDosageChange,
}) => {
  const { stepState, setStepState } = useContext(StepContext);
  const { stepNumber, currentStep, step, formFields, patientData } = stepState;
  const [showCalculator, setShowCalculator] = useState(false);

  const handleToggleCalculator = () => {
    formFields.estimated_weight_dose_per_kg =
      formFields.calculated_dose / formFields.based_on_patients_weight;
    setShowCalculator(!showCalculator);
  };

  function handleInput(e) {
    if (e.key === 'e' || e.key === '-' || e.key === '+') {
      e.preventDefault();
    }
  }

  useEffect(() => {
    if (patientData && patientData.patient && patientData.patient.weight) {
      //if no weight yet set in formFields - set these with patientData info
      if (!formFields.based_on_patients_weight) {
        setStepState((currentState) => ({
          ...currentState,
          formFields: {
            ...currentState.formFields,
            based_on_patients_weight: patientData.patient.weight,
            step_weight_last_updated: patientData.patient.weight_last_updated,
          },
        }));
      }
      //if there has been update in patient's weight update where relevant
      if (patientData.patient.weight !== formFields.based_on_patients_weight) {
        setStepState((currentState) => ({
          ...currentState,
          formFields: {
            ...currentState.formFields,
            based_on_patients_weight: patientData.patient.weight,
            step_weight_last_updated: patientData.patient.weight_last_updated,
          },
        }));
      }
    }
  }, [patientData, formFields, setStepState]);

  return (
    <div
      className="calculations"
      css={css`
        font-family: 'roboto-light';
        font-size: 21px;
        min-width: 100px;
        padding: 5px 10px 0px 0px;
        margin: 0;
        display: block;
      `}
    >
      Based on{' '}
      <span css={boldTextStyling}>{`${
        patientData && patientData.patient && patientData.patient.nickname
      }'s`}</span>{' '}
      weight
      {formFields.based_on_patients_weight && (
        <Fragment>
          {' '}
          of{' '}
          <span css={boldTextStyling}>
            {formFields.based_on_patients_weight} kg
          </span>{' '}
          {`(on
${moment(formFields.step_weight_last_updated).format('MMM D, YYYY')})`}{' '}
        </Fragment>
      )}
      {isEditingCurrentStep &&
        stepNumber &&
        currentStep === stepNumber.toString() && (
          <Fragment>
            <Button
              data-cy="advanced-calculator-btn"
              css={calculatorButtonStyling}
              onClick={handleToggleCalculator}
            >
              {showCalculator ? 'HIDE CALCULATOR' : 'ADVANCED CALCULATOR'}
            </Button>

            {showCalculator && (
              <Fragment>
                <div
                  className="calculations-body"
                  css={css`
                    display: inline-block;
                    font-family: 'roboto-light';
                    font-size: 21px;
                    min-width: 100px;
                    padding: 5px 10px 5px 0px;
                    margin: 0;
                  `}
                >
                  {' '}
                  and the estimated weight dosage of
                  <TextField
                    data-cy={`edit-est-dose-step-${stepNumber}`}
                    css={css`
                      background-color: ${displayInputColor(
                        'estimated_weight_dose_per_kg'
                      )};
                      width: 70px;
                      margin: 0 5px;
                    `}
                    disabled={
                      (!step && !isEditingCurrentStep()) ||
                      (isEditingCurrentStep() && !formFields.medication)
                    }
                    type="number"
                    onKeyDown={handleInput}
                    onChange={handleDosageChange(
                      'estimated_weight_dose_per_kg'
                    )}
                    //leave as null, changing to '' as per console warning clears inputs of 0 && .0
                    value={
                      formFields.estimated_weight_dose_per_kg
                        ? formFields.estimated_weight_dose_per_kg
                        : null
                    }
                  />
                  mg/kg
                  <br />
                  <div
                    css={css`
                      margin: 10px 0;
                    `}
                  >
                    the calculated dose would be{' '}
                    <TextField
                      data-cy={`edit-calc-dose-step-${stepNumber}`}
                      css={css`
                        width: 70px;
                        max-height: -webkit-fill-available;
                        color: ${GlobalStyles.navyBlue};
                        margin: 0 5px;
                        background-color: ${displayInputColor(
                          'calculated_dose'
                        )};
                      `}
                      disabled={
                        (!step && !isEditingCurrentStep()) ||
                        (isEditingCurrentStep() && !formFields.medication)
                      }
                      onChange={handleDosageChange('calculated_dose')}
                      //leave as null, changing to '' as per console warning clears inputs of 0 && .0
                      type="number"
                      onKeyDown={handleInput}
                      value={
                        formFields.calculated_dose
                          ? formFields.calculated_dose
                          : null
                      }
                    />{' '}
                    mg
                  </div>
                </div>
              </Fragment>
            )}
          </Fragment>
        )}
    </div>
  );
};

export default CalculationsforStepTwoAndThree;
