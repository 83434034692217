/** @jsx jsx */
import { jsx, css } from '@emotion/core';
import { Route, useRouteMatch, Redirect } from 'react-router-dom';
import { Fragment, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { GET_PATIENT } from 'utils/queries';
import GlobalStyles from 'assets/GlobalStyles';
import PatientViewNavBar from 'components/PatientView/NavBar';
import PatientViewDownload from 'components/PatientView/Download';
import PatientViewNonHospitalPlan from 'components/PatientView/NonHospitalPlan';
import PatientViewHospitalPlan from 'components/PatientView/HospitalPlan';
import PPClinicalTeam from 'components/PatientView/ClinicalTeam';
import PPSeizureFirstAid from 'components/PatientView/SeizureFirstAid';
import PatientDetails from 'components/PatientView/PatientInformation';
import MobileNavbar from 'components/PatientView/MobileNavbar';
import swirlBackground from 'assets/images/swirl-background-new.svg';
import useMediaQuery from '@material-ui/core/useMediaQuery';
//Loading Skeleton
import PatientViewSkeleton from '../components/Skeletons/PatientViewSkeleton';
import MobileOutOfHospitalSkeleton from '../components/Skeletons/MobileOutOfHospitalSkeleton';
import MobileHospitalSkeleton from '../components/Skeletons/MobileHospitalSkeleton';
import MobileClinicalCareTeamSkeleton from '../components/Skeletons/MobileClinicalCareTeamSkeleton';
import MobileShareDownloadSkeleton from '../components/Skeletons/MobileShareDownloadSkeleton';
import MobileCall911Skeleton from '../components/Skeletons/MobileCall911Skeleton';

const mobileBreakpoint = 960;

const location = window.location.href;

const pageContainerStyling = css`
  background-color: ${GlobalStyles.white} !important;
  background: linear-gradient(
      ${GlobalStyles.backgroundYellowPatientView},
      ${GlobalStyles.backgroundYellowPatientView}
    ),
    url(${swirlBackground});
  background-position: center;
  background-size: cover;
  background-repeat: repeat-y;
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-template-rows: 100px 1fr auto; //100px height of navbar, auto for footer
  column-gap: ${GlobalStyles.columnGap};
  @media (max-width: ${mobileBreakpoint}px) {
    background: linear-gradient(
        ${GlobalStyles.backgroundYellowPatientView},
        ${GlobalStyles.backgroundYellowPatientView}
      ),
      url(${swirlBackground});
    background-position: center;
    background-attachment: fixed;
    background-size: cover;
    :before {
      content: '';
      position: fixed;
      display: block;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: -1;
      background-color: ${GlobalStyles.white} !important;
      background-repeat: no-repeat;
      background-position: center;
      background-attachment: fixed;
      background-size: cover;
      background: linear-gradient(
          ${GlobalStyles.backgroundYellowPatientView},
          ${GlobalStyles.backgroundYellowPatientView}
        ),
        url(${swirlBackground});
    }
  }
`;

function PatientViewPage() {
  const [mobileNavOpen, setMobileNavOpen] = useState(false);
  const { id: patientId } = useParams();
  const { data, loading } = useQuery(GET_PATIENT, {
    variables: { id: patientId },
  });
  let { path, url } = useRouteMatch();

  const isMobile = useMediaQuery(
    `(max-width:${GlobalStyles.mobileBreakpoint})`
  );

  const checkIfSteps = (planType) => {
    const actionPlans = data.patient.action_plans
      ? data.patient.action_plans
      : null;
    let planSteps = null;
    if (actionPlans) {
      actionPlans.map((actionPlan) => {
        if (actionPlan.category === planType) {
          planSteps = actionPlan.action_plan_steps;
        }
        return planSteps;
      });
      return planSteps;
    }
  };

  if (isMobile && loading && location.includes('out-of-hospital-plan')) {
    return <MobileOutOfHospitalSkeleton />;
  } else if (isMobile && loading && location.includes('details')) {
    return <MobileClinicalCareTeamSkeleton />;
  } else if (isMobile && loading && location.includes('hospital-plan')) {
    return <MobileHospitalSkeleton />;
  } else if (isMobile && loading && location.includes('clinical-team')) {
    return <MobileClinicalCareTeamSkeleton />;
  } else if (isMobile && loading && location.includes('download-plan')) {
    return <MobileShareDownloadSkeleton />;
  } else if (isMobile && loading && location.includes('first-aid')) {
    return <MobileCall911Skeleton />;
  } else if (loading) {
    return <PatientViewSkeleton />;
  } else {
    return (
      <div className="patientPlan" css={pageContainerStyling}>
        {isMobile ? (
          <Fragment>
            <MobileNavbar
              mobileNavOpen={mobileNavOpen}
              setMobileNavOpen={setMobileNavOpen}
              patient={data.patient}
            />

            {/* patient-view mobile routes */}
            <div
              role="main"
              css={css`
                grid-column: 1 / span 12;
                grid-row: 1 / span 12;
                height: 100vh;
                margin: 0;
                margin-top: 15px;
              `}
            >
              {/* First redirect is for minimizing from desktop to mobile with no specific path, others all have redirect so they stay on their route on refresh */}
              <Route path={path}>
                <Redirect to={`${url}/out-of-hospital-plan/1`} />
              </Route>

              <Route path={`${path}/out-of-hospital-plan/:step`}>
                <PatientViewNonHospitalPlan
                  patient={data.patient}
                  planSteps={checkIfSteps('nonHospital')}
                />
              </Route>
              <Route path={`${path}/hospital-plan`}>
                <PatientViewHospitalPlan
                  patient={data.patient}
                  planSteps={checkIfSteps('hospital')}
                />
                <Redirect to={`${url}/hospital-plan`} />
              </Route>
              <Route path={`${path}/clinical-team`}>
                <PPClinicalTeam
                  patient={data.patient}
                  isMobile={isMobile}
                  pageLoading={loading}
                />
                <Redirect to={`${url}/clinical-team`} />
              </Route>
              <Route path={`${path}/download-plan`}>
                <PatientViewDownload patient={data.patient} />
                <Redirect to={`${url}/download-plan`} />
              </Route>
              <Route path={`${path}/first-aid`}>
                <PPSeizureFirstAid
                  patient={data.patient}
                  firstAidLoading={loading}
                />
                <Redirect to={`${url}/first-aid`} />
              </Route>
              <Route path={`${path}/details`}>
                <PatientDetails patient={data.patient} />
                <Redirect to={`${url}/details`} />
              </Route>
            </div>
          </Fragment>
        ) : (
          <Fragment>
            <PatientViewNavBar patient={data.patient} />
            <div
              role="main"
              className="patientViewPage__desktopBody"
              css={css`
                grid-column: 1 / span 12;
              `}
            >
              {/* this is for accessibility test (need an h1 on page, css is for screenreader only) */}
              <h1
                css={css`
                  position: absolute;
                  left: -10000px;
                  top: auto;
                  width: 1px;
                  height: 1px;
                  overflow: hidden;
                `}
              >
                Seizure
              </h1>
              <span id="PVDetails">
                <PatientDetails patient={data.patient} />
              </span>
              <span id="PVNonHospitalPlan">
                <PatientViewNonHospitalPlan
                  patient={data.patient}
                  planSteps={checkIfSteps('nonHospital')}
                />
              </span>
              <span id="PVHospitalPlan">
                <PatientViewHospitalPlan
                  patient={data.patient}
                  planSteps={checkIfSteps('hospital')}
                />
              </span>
              <span id="PPClinicalTeam">
                <PPClinicalTeam patient={data.patient} isMobile={isMobile} />
              </span>
              <span id="PatientViewDownload">
                <PatientViewDownload patient={data.patient} />
              </span>
              <span id="SeizureFirstAid">
                <PPSeizureFirstAid />
              </span>
            </div>
          </Fragment>
        )}
      </div>
    );
  }
}

export default PatientViewPage;
