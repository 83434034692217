import React, { useEffect, useState } from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { useParams, useHistory } from 'react-router-dom';
import Stepper from '@material-ui/core/Stepper';
import StepConnector from '@material-ui/core/StepConnector';
import Step from '@material-ui/core/Step';
import StepButton from '@material-ui/core/StepButton';
import { useSwipeable } from 'react-swipeable';
import GlobalStyles from 'assets/GlobalStyles';

const useStyles = makeStyles({
  root: {
    width: '100%',
    position: 'fixed',
    left: 0,
    bottom: 0,

    //overriding materialUI stepper styles
    '& .MuiStepLabel-label.MuiStepLabel-alternativeLabel': {
      color: GlobalStyles.midBlue,
      fontFamily: 'roboto-black',
      fontSize: 14,
      marginTop: -70,
    },
    '& .MuiStepper-alternativeLabel': {
      paddingTop: '12%',
    },

    '& .MuiStepIcon-root': {
      color: GlobalStyles.midBlue,
      opacity: 0.5,
      width: 35,
      height: 35,
      zIndex: 1,
    },
    '& .MuiStepIcon-text': {
      color: 'white',
      fontFamily: 'roboto-black',
      fontSize: 12,
    },

    '& .MuiStepIcon-root.MuiStepIcon-active': {
      opacity: 1,
    },
    '& .MuiStepConnector-alternativeLabel': {
      backgroundColor: GlobalStyles.mutedOrange,
      left: 'calc(-50% + 17px)',
      right: 'calc(50% + 17px)',
      height: 8,
    },
  },
});

const ColorlibConnector = withStyles({
  line: {
    border: 0,
  },
})(StepConnector);

function getSteps(planSteps) {
  const lengthsArray = planSteps.map((step) => {
    if (step.length_of_current_seizure_time_in_minutes === null) {
      if (step.step_number === 4) {
        return 'Notes';
      }
      return '0 MIN';
    } else {
      return `${step.length_of_current_seizure_time_in_minutes} MIN`;
    }
  });

  return lengthsArray;
}

export default function MobileStepper({ planSteps }) {
  const classes = useStyles();
  const { id: patientId, step: stepNumber } = useParams();
  const history = useHistory();
  const [activeStep, setActiveStep] = useState(parseInt(stepNumber));
  const steps = getSteps(planSteps);

  const handleClick = (step) => () => {
    setActiveStep(step);
    history.push(`/patient-view/${patientId}/out-of-hospital-plan/${step + 1}`);
  };

  useEffect(() => {
    setActiveStep(parseInt(stepNumber - 1));
  }, [stepNumber]);

  const swipeHandlers = useSwipeable({
    onSwipedLeft: async (eventData) => {
      if (eventData.event.view.location.pathname.includes('/3')) {
        return;
      } else {
        await setActiveStep(activeStep - 1);
        history.push(
          `/patient-view/${patientId}/out-of-hospital-plan/${activeStep + 2}`
        );
      }
    },

    onSwipedRight: async (eventData) => {
      if (eventData.event.view.location.pathname.includes('/1')) {
        return;
      } else {
        await setActiveStep(activeStep + 1);
        history.push(
          `/patient-view/${patientId}/out-of-hospital-plan/${activeStep}`
        );
      }
    },
  });

  return (
    <div className={classes.root}>
      <Stepper
        connector={<ColorlibConnector />}
        alternativeLabel
        nonLinear
        activeStep={activeStep}
        {...swipeHandlers}
      >
        {steps.map((label, index) => {
          return (
            <Step key={index}>
              <StepButton onClick={handleClick(index)}>{label}</StepButton>
            </Step>
          );
        })}
      </Stepper>
    </div>
  );
}
