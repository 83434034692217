/** @jsx jsx */
import { jsx } from '@emotion/core';
import { Fragment, useContext } from 'react';
import { ValidatorForm } from 'react-material-ui-form-validator';
import ViewStepTwoAndThree from 'components/Steps/ViewStepTwoAndThree';
import EditStepTwoAndThree from 'components/Steps/EditStepTwoAndThree';
import StepContext from 'components/Steps/StepContext';

function NonHospitalPlanStepTwoAndThree({
  handleUpdateSubmit,
  handleCreateSubmit,
  displayInputColor,
  isEditingCurrentStep,
  handleChange,
  isExceedingMaxDoseConfirmed,
  handleDosageChange,
  displayAdministeredDosage,
  handleOpen,
}) {
  const { stepState } = useContext(StepContext);
  const { isEditing, step, currentStep, stepNumber } = stepState;
  return (
    <Fragment>
      {(isEditing && step && currentStep === stepNumber.toString()) ||
      (isEditing && !step) ||
      (!isEditing && !step) ? (
        <ValidatorForm
          onSubmit={step ? handleUpdateSubmit : handleCreateSubmit}
          onError={(errors) => console.log(errors)}
        >
          <EditStepTwoAndThree
            displayInputColor={displayInputColor}
            isEditingCurrentStep={isEditingCurrentStep}
            handleChange={handleChange}
            isExceedingMaxDoseConfirmed={isExceedingMaxDoseConfirmed}
            handleDosageChange={handleDosageChange}
            displayAdministeredDosage={displayAdministeredDosage}
            handleOpen={handleOpen}
          />
        </ValidatorForm>
      ) : (
        <ViewStepTwoAndThree />
      )}
    </Fragment>
  );
}

export default NonHospitalPlanStepTwoAndThree;
