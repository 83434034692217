/** @jsx jsx */
import { jsx, css } from '@emotion/core';
import GlobalStyles from 'assets/GlobalStyles';
import MenuIcon from '@material-ui/icons/Menu';
import MobileNavMenu from './MobileNavMenu';

function MobileNavbar({ mobileNavOpen, setMobileNavOpen, patient }) {
  const setOpenToggle = () => {
    setMobileNavOpen(!mobileNavOpen);
  };

  return mobileNavOpen ? (
    <MobileNavMenu
      setOpenToggle={setOpenToggle}
      patient={patient}
      mobileNavOpen={mobileNavOpen}
    />
  ) : (
    <div
      css={css`
        background-color: ${GlobalStyles.midBlue};
        grid-column: 1 / span 3;
        grid-row: 1;
        height: 80px;
        width: 90%;
        border-top-right-radius: 40%;
        border-bottom-right-radius: 40%;
        margin-top: 15px;
        color: ${GlobalStyles.white};
        z-index: 0;
      `}
    >
      <MenuIcon
        fontSize={'large'}
        css={css`
          height: 100%;
          width: 50%;
          margin-left: 20%;
        `}
        onClick={setOpenToggle}
      />
    </div>
  );
}

export default MobileNavbar;
