/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable react/no-unescaped-entities */
/** @jsx jsx */
import { jsx, css } from '@emotion/core';
import GlobalStyles from 'assets/GlobalStyles';
import { makeStyles } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import MobileAppHeader from 'components/PatientView/MobileAppHeader';

const useStyles = makeStyles(() => ({
  root: {
    minWidth: '41vw',
    minHeight: '40vh',
    backgroundColor: `${GlobalStyles.paleBlueCardBackground}`,
    borderRadius: '30px',
    paddingTop: '3%',
    paddingBottom: '5%',

    '@media (max-width: 1250px)': {
      minHeight: '55vh',
    },
    '@media (max-width: 960px)': {
      minHeight: '13vh',
      marginRight: '-20%',
      paddingLeft: '33px',
      paddingTop: '24px',
      paddingBottom: '10%',
    },
  },
}));

const textArray = [
  'Your child has difficulty breathing.',
  'Your child does not stop seizing despite giving rescue medications.',
  'Your child takes longer than usual to become responsive after the seizure.',
  'Your child is injured during the seizure.',
  'You are otherwise concerned about your child during or after the seizure.',
];

const linkOne = '/files/first_aid_poster.pdf';
const linkTwo =
  'https://www.youtube.com/watch?v=uq5PBzorBm8&ab_channel=BCEpilepsySociety';

const titleStyling = css`
  color: ${GlobalStyles.midBlue};
  font-family: 'poppins-bold';
  font-size: 24px;
  line-height: 16px;
  width: 100%;
  text-align: left;
  border-bottom: 3px solid ${GlobalStyles.midBlue};
  line-height: 0.1em;
  margin: 10px 0 20px;

  @media (max-width: ${GlobalStyles.mobileBreakpoint}) {
    font-size: 16px;
  }

  span {
    background: ${GlobalStyles.paleBlueCardBackground};
    padding: 0 10px 0 0;
  }
`;

const textStyling = css`
  color: ${GlobalStyles.darkBlue};
  font-family: 'poppins-medium';
  font-size: 20px;
  line-height: 31px;
  margin-left: 7.5%;
  padding: 3% 10px 5% 5px;
  max-width: 40vw;
  margin-block-start: 0;
  margin-block-end: 0;
  @media (max-width: ${GlobalStyles.mobileBreakpoint}) {
    max-width: 74vw;
    font-size: 14px;
    margin-left: 0;
    padding: 0;
    padding-right: 15px;
  }
  span {
    content: '\\00B7';
  }
  a {
    margin: 1em 0 1em 0;
  }
`;

function BlueCard1({ title, array }) {
  const classes = useStyles();

  return (
    <Card className={classes.root}>
      <div
        className="blueCard2__title-container"
        css={css`
          margin-left: 7.5%;
          @media (max-width: ${GlobalStyles.mobileBreakpoint}) {
            margin-left: 0;
          }
        `}
      >
        <h3 css={titleStyling}>
          <span>{title}</span>
        </h3>
      </div>
      <div css={textStyling}>
        {array.map((string) => {
          return (
            <p
              key={string}
              css={css`
                margin-block-start: 0;
                margin-block-end: 0;
              `}
            >
              <span>&#183; </span> {string} <br />
            </p>
          );
        })}
      </div>
    </Card>
  );
}

function BlueCard2({ title, link1, link2 }) {
  const classes = useStyles();

  return (
    <Card className={classes.root}>
      <div
        className="blueCard2__title-container"
        css={css`
          margin-left: 7.5%;
          @media (max-width: ${GlobalStyles.mobileBreakpoint}) {
            margin-left: 0;
          }
        `}
      >
        <h3 css={titleStyling}>
          <span>{title}</span>
        </h3>
      </div>
      <div
        css={css`
          display: flex;
          flex-direction: column;
        `}
      >
        <p>
          <a css={textStyling} href={linkOne} download="First-aid-poster.pdf">
            {link1}
          </a>
        </p>
        <a css={textStyling} href={linkTwo} target="_blank">
          {link2}
        </a>
      </div>
    </Card>
  );
}

function SeizureFirstAid({ patient }) {
  const isMobile = useMediaQuery(
    `(max-width:${GlobalStyles.mobileBreakpoint})`
  );

  return (
    <div
      className="SeizureFirstAid"
      css={css`
        margin-top: 10%;
        @media (max-width: ${GlobalStyles.mobileBreakpoint}) {
          padding: 3% 0 3% 10%;
          margin-top: 0;
        }
      `}
    >
      {/* Add a SAP title if Mobile */}
      {isMobile && <MobileAppHeader patient={patient} />}
      <div
        css={css`
          font-family: 'Roboto-bold';
          font-size: 30px;
          line-height: 35px;
          color: ${GlobalStyles.darkBlue};
          margin-bottom: 3%;
          text-align: center;
          @media (max-width: ${GlobalStyles.mobileBreakpoint}) {
            margin-left: 20%;
            text-align: left;
            margin-bottom: 5%;
            font-size: 18px;
            line-height: 21px;
          }
        `}
      >
        {isMobile ? (
          'When to Call 9-1-1'
        ) : (
          <p
            css={css`
              color: ${GlobalStyles.midBlue};
            `}
          >
            When to Call 9-1-1
          </p>
        )}
      </div>

      <div
        className="SeizureFirstAid__Card-container"
        css={css`
          margin-bottom: 15%;
          overflow: hidden;
          padding: 0 6% 3%;
        `}
      >
        {isMobile ? (
          <Grid container justify="space-between">
            <Grid
              item
              xs={12}
              css={css`
                margin-top: 5%;
              `}
            >
              <BlueCard1 title={`CALL 9-1-1 IF: `} array={textArray} />
            </Grid>
            <Grid
              item
              xs={12}
              css={css`
                margin-top: 5%;
              `}
            >
              <BlueCard2
                title={`FIRST AID RESOURCES`}
                link1={`First Aid Poster`}
                link2={`Seizure First Aid Video`}
              />
            </Grid>
          </Grid>
        ) : (
          //DESKTOP
          <Grid container justify="space-between">
            <BlueCard1 title={`CALL 9-1-1 IF: `} array={textArray} />

            <BlueCard2
              title={`FIRST AID RESOURCES`}
              link1={`First Aid Poster`}
              link2={`Seizure First Aid Video`}
            />
          </Grid>
        )}
      </div>
    </div>
  );
}

export default SeizureFirstAid;
