/** @jsx jsx */
import { jsx, css } from '@emotion/core';
import { Fragment } from 'react';
import { client } from 'ApolloClient';
import GlobalStyles from 'assets/GlobalStyles';
import PersonOutlineIcon from '@material-ui/icons/PersonOutline';
import { NavLink, useHistory } from 'react-router-dom';
import { Button } from '@material-ui/core';
import AppBar from '@material-ui/core/AppBar';
import Logo from 'assets/images/SAP-logo.png';
import Grid from '@material-ui/core/Grid';

function NavBar({ role }) {
  const history = useHistory();
  const localStorageId = localStorage.getItem('user_id');
  const id = localStorageId;
  const ADMIN = 'super_admin';
  return (
    <AppBar
      className="navBar"
      position="static"
      css={css`
        grid-column: 1 / span 12;
        box-shadow: none;
        background-color: ${GlobalStyles.backgroundBlue};
        display: grid;
        grid-template-columns: repeat(12, 1fr);
        padding-top: 40px;
        top: 0;
        z-index: 1;
        text-align: center;
      `}
    >
      <img
        src={Logo}
        alt="navBar-logo"
        css={css`
          grid-column: 2;
          height: 60px;
        `}
      />

      {role && (
        <Fragment>
          <NavLink
            className="navBar__button-patient"
            to="/patient-list"
            activeStyle={{
              textDecoration: 'underline',
              textUnderlinePosition: 'under',
            }}
            css={css`
              grid-column: ${role !== ADMIN ? '5 / 8' : '5 / 7'};
              font-family: 'poppins-medium';
              font-size: 20px;
              color: ${GlobalStyles.navyBlue};
              cursor: pointer;
              text-decoration: none;
              &:hover {
                text-decoration: underline;
                text-underline-position: under;
              }
            `}
          >
            PATIENT DATABASE
          </NavLink>
          <NavLink
            className="navBar__button-medication"
            activeStyle={{
              textDecoration: 'underline',
              textUnderlinePosition: 'under',
            }}
            to="/medication-list"
            css={css`
              grid-column: ${role !== ADMIN ? '8 / 11' : '7 / 9'};
              font-family: 'poppins-medium';
              font-size: 20px;
              color: ${GlobalStyles.navyBlue};
              cursor: pointer;
              text-decoration: none;
              &:hover {
                text-decoration: underline;
                text-underline-position: under;
              }
            `}
          >
            MEDICATION DATABASE
          </NavLink>

          {role === ADMIN && (
            <NavLink
              className="navBar__button-care"
              activeStyle={{
                textDecoration: 'underline',
                textUnderlinePosition: 'under',
              }}
              to="/team"
              css={css`
                grid-column: 9 / 11;
                font-family: 'poppins-medium';
                font-size: 20px;
                color: ${GlobalStyles.navyBlue};
                cursor: pointer;
                text-decoration: none;
                &:hover {
                  text-decoration: underline;
                  text-underline-position: under;
                }
              `}
            >
              CLINICAL CARE TEAM
            </NavLink>
          )}
          <div
            className="navBar_button_and_icon_container"
            css={css`
              display: flex;
            `}
          >
            <NavLink
              aria-label="close"
              className="navBar__profile-icon"
              to={`/profile/${id}`}
              css={css`
                padding-right: ${GlobalStyles.columnGap};
                margin-top: 2px;
              `}
            >
              <PersonOutlineIcon style={{ color: GlobalStyles.navyBlue }} />
            </NavLink>
            <Grid className="add-new-button" item container sm={12}>
              <div
                css={css`
                  line-height: 1.75;
                  width: 150px;
                  height: 32px;
                  margin: 0 5px 0 10px;
                `}
              >
                <Button
                  className="navBar__button-logout"
                  onClick={() => {
                    // call your auth logout code then reset store
                    localStorage.clear();
                    client.resetStore();
                    history.push('/login');
                  }}
                  css={css`
                    font-weight: bolder;
                    align-self: center;
                    background-color: ${GlobalStyles.accentOrange};
                    color: ${GlobalStyles.white};
                    cursor: pointer;
                    width: 100%;
                    text-decoration: none;
                    border-radius: 0% !important;
                    &:hover {
                      color: ${GlobalStyles.navyBlue};
                      background-color: ${GlobalStyles.accentOrange};
                    }
                    height: 32px;
                  `}
                >
                  Logout
                </Button>
              </div>
            </Grid>
          </div>
        </Fragment>
      )}
    </AppBar>
  );
}

export default NavBar;
