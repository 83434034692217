/** @jsx jsx */
import { jsx, css } from '@emotion/core';
import Skeleton from '@material-ui/lab/Skeleton';
import Grid from '@material-ui/core/Grid';
import PatientLogSkeleton from './PatientLogSkeleton';
import PatientTeamSkeleton from './PatientTeamSkeleton';
import PatientSkeleton from './PatientSkeleton';

const PatientTabSkeleton = () => {
  const location = window.location.href;

  return (
    <Grid className="patient-tab-skeleton">
      {Array(4)
        .fill()
        .map((item, index) => (
          <div
            className="patient-tab-skeleton-title"
            css={css`
              padding: 1% 4%;
              display: inline-block;
            `}
            key={index}
          >
            <Skeleton height={50} width={160} />
          </div>
        ))}

      {location.includes('log') || location.includes('plan') ? (
        <PatientLogSkeleton />
      ) : location.includes('team') ? (
        <PatientTeamSkeleton />
      ) : location.includes('details') ? (
        <PatientSkeleton />
      ) : null}
    </Grid>
  );
};

export default PatientTabSkeleton;
