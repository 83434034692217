/** @jsx jsx */
import { jsx, css } from '@emotion/core';
import GlobalStyles from 'assets/GlobalStyles';
import BottomNavigation from '@material-ui/core/BottomNavigation';
import PrivacyPolicyLink from './PrivacyPolicyLink';

function Footer() {
  return (
    <BottomNavigation
      css={css`
        background-color: ${GlobalStyles.footerGreen};
        grid-column: 1 / span 12;
      `}
    >
      <PrivacyPolicyLink />
    </BottomNavigation>
  );
}

export default Footer;
