import { Fragment, useState, useRef, useEffect, useCallback } from 'react';
import { useQuery, useMutation } from '@apollo/client';
import { useParams } from 'react-router-dom';
import moment from 'moment';
/** @jsx jsx */
import { jsx, css } from '@emotion/core';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import SearchIcon from '@material-ui/icons/Search';
import TextField from '@material-ui/core/TextField';
import GlobalStyles from 'assets/GlobalStyles';
import { DebounceInput } from 'react-debounce-input';
import Pagination from '@material-ui/lab/Pagination';

//Components
import PatientTabs from 'components/PatientTabs';
import { GET_PATIENT_LOG_DETAILS } from 'utils/queries';
import { UPDATE_PATIENT_LOG_NOTES } from 'utils/mutations';
import UpdateNotesModal from 'components/UpdateNotesModal';

//Using tab skeleton until all skeletons get refactored
import PatientTabSkeleton from '../components/Skeletons/PatientTabSkeleton';

// Get medication names for patient
import { GET_MEDICATIONS_NAMES_LIST } from 'utils/queries';

// Format how values are displayed on the frontend
function formatValue(fieldName, value, fieldOperation, medicationNamesData) {
  if (fieldName === 'DOB') {
    const birthDate = moment(value).format('MM/DD/YYYY');

    return birthDate.toLocaleString([], {
      year: 'numeric',
      month: 'numeric',
      day: 'numeric',
    });
  }
  if (
    fieldName === 'length_of_current_seizure_time_in_minutes' ||
    fieldName === 'total_time_in_minutes'
  ) {
    return `${value} mins`;
  }
  if (fieldName === 'administered_dose') {
    return `${value} mg`;
  }
  if (fieldName === 'seizure_types') {
    //adding new seizure type
    if (fieldOperation === 'Added') {
      let seizureTypeName = '';
      [...value][0].name.forEach((name, index) => {
        index === 0
          ? (seizureTypeName += name)
          : index === 1
          ? (seizureTypeName += ' | ' + name)
          : index === 2 && (seizureTypeName += ' | ' + name);
      });

      let seizureTypeActive = [...value][0].checkboxActive;
      if (seizureTypeActive) {
        return `${seizureTypeName} (Active)`;
      }
      return `${seizureTypeName}`;
    } else if (fieldOperation === 'Changed' || fieldOperation === 'Deleted') {
      let nameToDisplay = '';

      value.forEach((v) => {
        let namesArray = v.name;

        namesArray.forEach((name, index) => {
          index === 0
            ? (nameToDisplay += `\n• ${name}`)
            : index === 1
            ? (nameToDisplay += ' | ' + name)
            : index === 2 && (nameToDisplay += ' | ' + name);
        });
        if (v.checkboxActive) nameToDisplay += ' (Active)';
      });
      return nameToDisplay;
    }
  } else if (fieldName === 'dosage') {
    let nameToDisplay = '';
    let medValueToFormat = {};
    let finalArr = [];
    value !== null && value !== undefined && (medValueToFormat = value);
    for (const [key, val] of Object.entries(medValueToFormat)) {
      //remove the quotations around the id so it can be compared
      let dosageMedId = key.slice(1, -1);
      medicationNamesData !== undefined &&
        Object.entries(medicationNamesData).forEach((item) => {
          let medNamesArray = item[1];
          medNamesArray.forEach((med) => {
            if (dosageMedId === med.id) {
              finalArr.push(med.generic_name + ` ${val}`);
            }
          });
        });
    }
    finalArr.forEach((displayItem) => {
      nameToDisplay += `\n• ${displayItem}`;
    });
    return nameToDisplay;
  } else {
    return value
      .toLocaleString(undefined, { maximumFractionDigits: 2 })
      .trim()
      .replace(/^\s*\n/gm, '');
  }
}

//Format the field names are displayed on the frontend
function formatFieldName(fieldName) {
  if (fieldName === 'first_name') {
    return 'Patient First Name';
  }
  if (fieldName === 'last_name') {
    return 'Patient Last Name';
  }
  if (fieldName === 'nickname') {
    return 'Preferred Name/Nickname';
  }
  if (fieldName === 'DOB') {
    return 'Date of Birth';
  }
  if (fieldName === 'caregiver_name') {
    return 'Parent/Guardian Name';
  }
  if (fieldName === 'caregiver_email') {
    return 'Parent/Guardian Email';
  }
  if (fieldName === 'weight') {
    return 'Patient Weight';
  }

  if (fieldName === 'diagnoses') {
    return 'Diagnoses';
  }
  if (fieldName === 'medications') {
    return 'Anti-Seizures Medications';
  }
  if (fieldName === 'dosage') {
    return 'Anti-Seizures Medications';
  }
  if (fieldName === 'seizure_types') {
    return 'Seizure Types';
  }
  if (fieldName === 'allergies') {
    return 'Allergies';
  }
  if (fieldName === 'patient_notes') {
    return 'Patient Notes';
  }
  if (fieldName === 'step number 2') {
    return 'Step 2';
  }
  if (fieldName === 'step number 3') {
    return 'Step 3';
  }
  if (fieldName === 'step number 4') {
    return 'Step 4';
  }
  if (fieldName === 'length_of_current_seizure_time_in_minutes') {
    return `Length of Current Seizure Time in Minutes in `;
  }
  if (fieldName === 'total_number_of_seizures') {
    return `Total Number of Seizures in `;
  }
  if (fieldName === 'total_time_in_minutes') {
    return `Total Time in Minutes in `;
  }
  if (fieldName === 'medication') {
    return `Medication in `;
  }
  if (fieldName === 'administered_dose') {
    return `Administered Dose in `;
  } else {
    return;
  }
}

const dateStamp = css`
  color: ${GlobalStyles.babyBlue};
  font-family: roboto-bold;
  font-size: 12px;
  margin: 0;
  padding-bottom: 10px;
`;

const dataChanged = css`
  font-size: 21px;
  font-weight: lighter;
  color: ${GlobalStyles.textBlue};
  font-family: roboto;
  letter-spacing: 1.88px;
  line-height: 24px;
  margin: 0;
  width: 50vw;
  overflow-wrap: break-word;
  white-space: pre-wrap;
`;

const boldedSpan = css`
  font-weight: bold;
  font-family: roboto-bold;
`;

const updatedBy = css`
  text-align: right;
  color: ${GlobalStyles.babyBlue};
  font-family: roboto-bold-italic;
  font-size: 12px;
  margin: 0;
  padding-bottom: 5px;
`;

const profession = css`
  text-align: right;
  color: ${GlobalStyles.babyBlue};
  font-family: roboto-medium;
  font-size: 8px;
  padding-bottom: 5px;
  margin: 0;
`;

const buttonStyling = css`
  font-family: 'roboto-black';
  font-size: 12px;
  align-self: center;
  background-color: ${GlobalStyles.accentOrange};
  color: ${GlobalStyles.white};
  cursor: pointer;
  text-decoration: none;
  border-radius: 0%;
  &:hover {
    color: ${GlobalStyles.navyBlue};
    background-color: ${GlobalStyles.accentOrange};
  }
`;

const noteStyling = css`
  color: ${GlobalStyles.navyBlue};
  font-size: 14px;
  font-family: 'roboto-black';
  margin-bottom: 0;
`;

const noteDetailsStyling = css`
  color: ${GlobalStyles.navyBlue};
  font-size: 14px;
  font-weight: lighter;
  margin: 0;
  margin-bottom: 10px;
  .MuiInputBase-input.Mui-disabled {
    color: ${GlobalStyles.navyBlue2};
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 1.25px;
  }
  .MuiInput-underline.Mui-disabled:before {
    border-bottom-style: hidden;
  }
`;

const containerWithScrollbarStyling = css`
  height: 50vh;
  overflow-y: scroll;
  overflow-x: hidden;
  margin-top: 10px;
  margin-right: 20px;
  padding: 0px 50px 0px 0px;
  /*Scrollbar for Firefox*/
  scrollbar-color: ${GlobalStyles.babyBlue} ${GlobalStyles.backgroundBlue};

  &::-webkit-scrollbar {
    width: 15px;
  }
  &::-webkit-scrollbar-thumb {
    height: 40%;
    border-radius: 10px;
    background-color: ${GlobalStyles.babyBlue};
    box-shadow: inset 0 0 10px 10px ${GlobalStyles.babyBlue};
    border: 3px solid ${GlobalStyles.backgroundBlue};
  }
  &::-webkit-scrollbar-track {
    border-radius: 10px;
    background-color: ${GlobalStyles.backgroundBlue};
  }
`;

const yearSectionLabel = css`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  padding: 2px 10px;
  background-color: ${GlobalStyles.midBlue};
  color: ${GlobalStyles.white};
  font-family: 'roboto-black';
  font-size: 18px;
  margin: 0 0 10px 0;
`;

const yearSectionLabelUnderline = css`
  width: 100%;
  border-bottom: 5px solid ${GlobalStyles.midBlue};
  margin: 0 0 10px 0;
`;

const beginningOfYearGroupStyle = css`
  padding-top: 30px;
  border-bottom: 2px solid ${GlobalStyles.blueBorderBottom};
`;

const showingEntriesStyle = css`
  color: gray;
  font-size: 14px;
`;

const resultsFoundStyle = css`
  color: gray;
  font-size: 12px;
`;

const paginationContainerStyle = css`
  display: flex;
  justify-content: flex-end;
`;

function PatientLogs({ log, publishDate, year, nickname }) {
  const ref = useRef();
  const actionPlanStepID = log.model_id;

  const { data: medicationNamesData } = useQuery(GET_MEDICATIONS_NAMES_LIST);

  const stepNumber = () => {
    let stepInfo = { stepNumber: 0, category: '' };

    if (log.patient.action_plans.length) {
      log.patient.action_plans.forEach((action_plan) => {
        let currentCategory = '';
        if (action_plan.category === 'nonHospital') {
          currentCategory = 'Out-of-Hospital';
        } else {
          currentCategory =
            action_plan.category.charAt(0).toUpperCase() +
            action_plan.category.slice(1);
        }
        action_plan.action_plan_steps.forEach((step) => {
          if (step.id === actionPlanStepID) {
            stepInfo = {
              stepNumber: step.step_number,
              category: currentCategory,
            };
          }
        });
      });
    }

    return (
      <span>
        {stepInfo.stepNumber === 0 ||
        (stepInfo.category !== 'nonHospital' && stepInfo.stepNumber === 2) ? (
          ''
        ) : (
          <span className="boldedText" css={boldedSpan}>
            Step {stepInfo.stepNumber}
          </span>
        )}
      </span>
    );
  };

  // finding Step Number and Category: Hosital vs. Non-hospital
  const findStepInfo = () => {
    // Status epilepticus plan logic (hospital step 1, this logic needs to come first)
    if (
      log.model_name === 'action-plan' &&
      log.field_name === 'show_epilepticus_plan'
    ) {
      if (log.field_operation === 'Removed') {
        return (
          <span>
            <span className="boldedText" css={boldedSpan}>
              {' '}
              Status Epilepticus Plan
            </span>{' '}
            from{' '}
            <span className="boldedText" css={boldedSpan}>
              {log.patient.nickname}&apos;s Hospital Plan
            </span>
          </span>
        );
      } else if (log.field_operation === 'Added') {
        return (
          <span>
            <span className="boldedText" css={boldedSpan}>
              Status Epilepticus Plan
            </span>{' '}
            to{' '}
            <span className="boldedText" css={boldedSpan}>
              {log.patient.nickname}&apos;s Hospital Plan
            </span>
          </span>
        );
      }
    }

    // adding step 2 of Hospital Plan. || needed as on very first add field name is patient's hospital plan but changes to text_field after deleting step 2 and adding it again
    if (
      log.model_name === 'action-plan-step' &&
      (log.field_name === "patient's hospital plan" ||
        log.field_name === 'text_field')
    ) {
      if (log.field_operation === 'Removed') {
        return (
          <span css={dataChanged}>
            <span className="boldedText" css={boldedSpan}>
              (Optional) Text Field{' '}
            </span>
            of{' '}
            <span className="boldedText" css={boldedSpan}>
              {log.patient.nickname}&apos;s Hospital Plan
            </span>{' '}
            <br />
            <span className="boldedText" css={boldedSpan}>
              Previous:
            </span>
            {log.text_field}
            {formatValue(log.field_name, log.old_entry)} <br />
            <span className="boldedText" css={boldedSpan}>
              Current:
            </span>
            {log.text_field}
          </span>
        );
      } else if (
        log.field_operation === 'Added' &&
        log.field_name === "patient's hospital plan"
      ) {
        return (
          <span css={dataChanged}>
            <span className="boldedText" css={boldedSpan}>
              (Optional) Text Field{' '}
            </span>
            of{' '}
            <span className="boldedText" css={boldedSpan}>
              {log.patient.nickname}&apos;s Hospital Plan
            </span>{' '}
            <br />
            <span className="boldedText" css={boldedSpan}>
              Previous:{' '}
            </span>
            N/A
            {formatValue(log.field_name, log.old_entry)} <br />
            <span className="boldedText" css={boldedSpan}>
              Current:{' '}
            </span>
            {log.text_field}
          </span>
        );
      } else if (
        log.field_operation === 'Added' &&
        (log.field_name === 'text_field' || log.field_name === 'text_field')
      ) {
        return (
          <span css={dataChanged}>
            <span className="boldedText" css={boldedSpan}>
              (Optional) Text Field{' '}
            </span>
            of{' '}
            <span className="boldedText" css={boldedSpan}>
              {log.patient.nickname}&apos;s Hospital Plan
            </span>{' '}
            <br />
            <span className="boldedText" css={boldedSpan}>
              Previous:{' '}
            </span>
            N/A
            {formatValue(log.field_name, log.old_entry)} <br />
            <span className="boldedText" css={boldedSpan}>
              Current:{' '}
            </span>
            {log.new_entry}
          </span>
        );
      }
    }

    if (log.model_name === 'patient' && log.field_operation === 'Removed') {
      return (
        <span>
          <span className="boldedText" css={boldedSpan}>
            {clinicalTeam}
          </span>{' '}
          from{' '}
          <span className="boldedText" css={boldedSpan}>
            {log.patient.nickname}&apos;s Clinical Team
          </span>
        </span>
      );
    }

    let stepInfo = { stepNumber: 0, category: '' };

    if (log.patient.action_plans.length) {
      log.patient.action_plans.forEach((action_plan) => {
        let currentCategory = '';
        if (action_plan.category === 'nonHospital') {
          currentCategory = 'Out-of-Hospital';
        } else {
          currentCategory =
            action_plan.category.charAt(0).toUpperCase() +
            action_plan.category.slice(1);
        }
        action_plan.action_plan_steps.forEach((step) => {
          if (step.id === actionPlanStepID) {
            stepInfo = {
              stepNumber: step.step_number,
              category: currentCategory,
            };
          }
        });
      });
    }
    // ONLY for Patient Details & Team Members
    if (stepInfo.stepNumber === 0) {
      return '';
    }
    return (
      <span>
        {/* this shows which step of hospital/out-of-hosp plan was changed */}
        <span className="boldedText" css={boldedSpan}>
          {stepInfo.stepNumber === 4
            ? '(Optional) Text Field'
            : stepInfo.category !== 'nonHospital'
            ? null
            : `Step ${stepInfo.stepNumber}`}
        </span>{' '}
        of{' '}
        <span className="boldedText" css={boldedSpan}>
          {log.patient.nickname}&apos;s{' '}
          {stepInfo.category.charAt(0).toUpperCase() +
            stepInfo.category.slice(1)}{' '}
          Plan
        </span>
      </span>
    );
    // All changes in Patient's Plan - hospital and out of hospital
  };

  let clinicalTeam = ' ';
  if (log.field_name === 'users') {
    clinicalTeam = log.new_entry
      .join(', ')
      .replace(/, ((?:.(?!, ))+)$/, ' and $1');
  }
  return (
    <div ref={ref} id={log.first ? `${year}-first` : year}>
      <Grid className="Date" item>
        <p css={dateStamp}>
          {moment(publishDate).format('MMM D, YYYY | h:mm A')}
        </p>
      </Grid>

      {/* Logs for deleting information     */}
      <Grid className="ChangedData" item>
        {log.field_operation === 'Deleted' && log.model_name === 'patient' ? (
          <p css={dataChanged}>
            {/* Deleting patient form information */}
            Deleted{' '}
            <span className="boldedText" css={boldedSpan}>
              {formatFieldName(log.field_name)}
            </span>{' '}
            {findStepInfo()} <br />
            <span className="boldedText" css={boldedSpan}>
              Previous:{' '}
            </span>
            {formatValue(
              log.field_name,
              log.old_entry,
              log.field_operation,
              medicationNamesData
            )}
            <br />
            <span className="boldedText" css={boldedSpan}>
              Current:{' '}
            </span>
            {log.field_name === 'seizure_types' &&
            log.new_entry &&
            log.new_entry.length
              ? formatValue(log.field_name, log.new_entry, log.field_operation)
              : 'N/A'}
          </p>
        ) : log.field_operation === 'Deleted' && log.field_name === 'notes' ? (
          <p css={dataChanged}>
            {/* Deleting non hospital plan step 2 */}
            Deleted {findStepInfo()}
            <br />
            <span className="boldedText" css={boldedSpan}>
              Previous:{' '}
            </span>
            {formatValue(log.field_name, log.old_entry)}
            <br />
            <span className="boldedText" css={boldedSpan}>
              Current:{' '}
            </span>
            N/A
          </p>
        ) : log.field_operation === 'Deleted' &&
          (log.field_name === "patient's hospital plan" ||
            log.field_name === 'text_field') ? (
          <p css={dataChanged}>
            {/* Deleting hospital plan step 2 info  */}
            Deleted{' '}
            <span className="boldedText" css={boldedSpan}>
              (Optional) Text Field
            </span>
            {findStepInfo()}
            <br />
            <span className="boldedText" css={boldedSpan}>
              Previous:{' '}
            </span>
            {formatValue(log.field_name, log.old_entry)}
            <br />
            <span className="boldedText" css={boldedSpan}>
              Current:{' '}
            </span>
            N/A
          </p>
        ) : log.field_operation === 'Changed' && log.field_name === 'weight' ? (
          <p css={dataChanged}>
            {/* Updates to patient weight in addOrEditPatientForm  */}
            Updated{' '}
            <span className="boldedText" css={boldedSpan}>
              {formatFieldName('weight')}
            </span>{' '}
            <br />
            <span className="boldedText" css={boldedSpan}>
              Previous:{' '}
            </span>
            {formatValue(log.field_name, log.old_entry)} kg
            <br />
            <span className="boldedText" css={boldedSpan}>
              Current:{' '}
            </span>
            {formatValue(log.field_name, log.new_entry)} kg
          </p>
        ) : log.field_operation === 'Changed' && log.field_name === 'notes' ? (
          /* Updating notes in Step 1 a&& step 4 of Out of Hospital Plan */
          <p css={dataChanged}>
            Updated{' '}
            <span className="boldedText" css={boldedSpan}>
              {findStepInfo()}
            </span>{' '}
            <br />
            <span className="boldedText" css={boldedSpan}>
              Previous:{' '}
            </span>
            {formatValue(log.field_name, log.old_entry)} <br />
            <span className="boldedText" css={boldedSpan}>
              Current:{' '}
            </span>
            {formatValue(log.field_name, log.new_entry)}{' '}
          </p>
        ) : log.field_operation === 'Changed' &&
          log.field_name !== 'action_plan' ? (
          <p css={dataChanged}>
            {/* This is for changes to the patient details form */}
            {/* Also for updating step2 of HOSPITAL plan and Step2 and 3 of Out-of-hospital-plan */}
            Updated{' '}
            <span className="boldedText" css={boldedSpan}>
              {log.model_name === 'action-plan-step' &&
              log.field_name === 'text_field'
                ? '(Optional) Text Field'
                : formatFieldName(log.field_name)}
              {stepNumber(nickname)}
              {log.model_name === 'patient'
                ? ''
                : log.field_name !== 'show_epilepticus_plan' &&
                  log.field_name !== 'text_field'
                ? ` of ${log.patient.nickname}'s Out-of-Hospital Plan`
                : ` of ${log.patient.nickname}'s Hospital Plan`}
            </span>{' '}
            <br />
            <span className="boldedText" css={boldedSpan}>
              Previous:{' '}
            </span>
            {formatValue(
              log.field_name,
              log.old_entry,
              log.field_operation,
              medicationNamesData
            )}{' '}
            <br />
            <span className="boldedText" css={boldedSpan}>
              Current:{' '}
            </span>
            {formatValue(
              log.field_name,
              log.new_entry,
              log.field_operation,
              medicationNamesData
            )}
          </p>
        ) : log.field_operation === 'Removed' &&
          log.field_name !== 'action_plan' ? (
          <p css={dataChanged}>
            {/* Removing steps*/}
            Removed {findStepInfo()}
          </p>
        ) : log.field_operation === 'Added' && log.model_name === 'patient' ? (
          <p css={dataChanged}>
            {/* Adding patient form info */}
            Added{' '}
            <span className="boldedText" css={boldedSpan}>
              {formatFieldName(log.field_name)}
            </span>{' '}
            {findStepInfo()} <br />
            <span className="boldedText" css={boldedSpan}>
              Previous:{' '}
            </span>
            N/A
            <br />
            <span className="boldedText" css={boldedSpan}>
              Current:{' '}
            </span>
            {formatValue(
              log.field_name,
              log.new_entry,
              log.field_operation,
              medicationNamesData
            )}
          </p>
        ) : log.field_operation === 'Added' &&
          log.new_entry === 'step number 4' &&
          log.field_name === "patient's nonHospital plan" ? (
          <p css={dataChanged}>
            {/* Adding step 4 of out-of-hospital-plan for THE FIRST TIME */}
            <Fragment>
              Added{' '}
              <span className="boldedText" css={boldedSpan}>
                (Optional) Text Field of {log.patient.nickname}&apos;s
                Out-of-Hospital Plan
              </span>
              <br />
              <span className="boldedText" css={boldedSpan}>
                Previous:{' '}
              </span>
              N/A <br />
              <span className="boldedText" css={boldedSpan}>
                Current:{' '}
              </span>{' '}
              {log.new_entry === 'step number 4'
                ? log.text_field
                : log.new_entry}
            </Fragment>
          </p>
        ) : log.field_operation === 'Added' &&
          log.model_name === 'action-plan-step' &&
          log.field_name === 'notes' ? (
          <p css={dataChanged}>
            {/* Adding step 4 of out-of-hospital-plan*/}
            <Fragment>
              Added{' '}
              <span className="boldedText" css={boldedSpan}>
                (Optional) Text Field of {log.patient.nickname}&apos;s
                Out-of-Hospital Plan
              </span>
              <br />
              <span className="boldedText" css={boldedSpan}>
                Previous:{' '}
              </span>
              N/A <br />
              <span className="boldedText" css={boldedSpan}>
                Current:{' '}
              </span>{' '}
              {log.new_entry}
            </Fragment>
          </p>
        ) : log.field_operation === 'Added' &&
          log.new_entry === 'step number 1' ? (
          <p css={dataChanged}>
            {/* Adding step 1 of out-of-hospital-plan for the first time */}
            <Fragment>
              Added{' '}
              <span className="boldedText" css={boldedSpan}>
                Step 1 of {log.patient.nickname}&apos;s Out-of-Hospital Plan
              </span>
              <br />
              <span className="boldedText" css={boldedSpan}>
                Previous:{' '}
              </span>
              N/A <br />
              <span className="boldedText" css={boldedSpan}>
                Current:{' '}
              </span>{' '}
              {log.text_field}
            </Fragment>
          </p>
        ) : log.field_operation === 'Added' &&
          log.new_entry === 'step number 2' &&
          log.field_name === "patient's hospital plan" ? (
          <p css={dataChanged}>
            {/* Adding step 2 of hospital-plan for the first time */}
            <Fragment>
              Added{' '}
              <span className="boldedText" css={boldedSpan}>
                (Optional) Text Field of {log.patient.nickname}&apos;s Hospital
                Plan
              </span>
              <br />
              <span className="boldedText" css={boldedSpan}>
                Previous:{' '}
              </span>
              N/A <br />
              <span className="boldedText" css={boldedSpan}>
                Current:{' '}
              </span>{' '}
              {log.text_field}
            </Fragment>
          </p>
        ) : log.field_operation === 'Added' &&
          log.new_entry === 'step number 2' &&
          log.field_name === "patient's nonHospital plan" ? (
          <p css={dataChanged}>
            {/* Adding step 2 of out-of-hospital-plan for the first time */}
            <Fragment>
              Added{' '}
              <span className="boldedText" css={boldedSpan}>
                Step 2 of {log.patient.nickname}&apos;s Out-of-Hospital Plan
              </span>
            </Fragment>
          </p>
        ) : log.field_operation === 'Added' &&
          log.field_name !== 'action_plan' &&
          log.new_entry !== 'step number 2' &&
          log.new_entry !== 'step number 3' ? (
          <p css={dataChanged}>
            {/* Adding in hospital plan steps 1 & 2 AND steps 2,3 out-of-hosp plans */}
            Added {findStepInfo()}
          </p>
        ) : (
          <p css={dataChanged}>
            {/* Adding step 2 and 3 of out-of-hospital-plan for the first time */}
            {(log.new_entry === 'step number 2' ||
              log.new_entry === 'step number 3') && (
              <Fragment>
                Added{' '}
                <span className="boldedText" css={boldedSpan}>
                  {formatFieldName(log.new_entry)} to Out-of-Hospital Plan
                </span>
                <br />
              </Fragment>
            )}
            {/* Adding patient details for the first time */}
            {log.new_entry !== 'step number 1' &&
            log.new_entry !== 'step number 2' &&
            log.new_entry !== 'step number 3' &&
            log.field_name !== 'action_plan' &&
            log.field_name !== 'show_epilepticus_plan' ? (
              <Fragment>
                Added{' '}
                <span className="boldedText" css={boldedSpan}>
                  {formatFieldName(log.field_name)}
                </span>
                <br />
                <span className="boldedText" css={boldedSpan}>
                  Previous:{' '}
                </span>
                N/A <br />
                <span className="boldedText" css={boldedSpan}>
                  Current:{' '}
                </span>{' '}
                {formatValue(log.field_name, log.new_entry)}
              </Fragment>
            ) : null}{' '}
          </p>
        )}
      </Grid>
    </div>
  );
}

function PatientLogPage() {
  const { id } = useParams();
  const [inputText, setInputText] = useState('');

  const [open, setOpen] = useState(false);
  const [updatedNotes, setUpdatedNotes] = useState('');
  const [currentLog, setCurrentLog] = useState({});

  const { data, loading } = useQuery(GET_PATIENT_LOG_DETAILS, {
    variables: { id },
  });

  const [UpdatePatientLogNotes] = useMutation(UPDATE_PATIENT_LOG_NOTES);

  const handleOpen = (log) => () => {
    setCurrentLog(log);
    setUpdatedNotes(log.notes || '');
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const [filteredLogs, setFilteredLogs] = useState([]);
  const logsPerPage = 20;
  const [startCursor, setStartCursor] = useState(0);
  const [endCursor, setEndCursor] = useState(logsPerPage);
  const [currentPage, setCurrentPage] = useState(1);

  const doSearch = (event) => {
    setInputText(event.target.value);
    setStartCursor(0);
    setEndCursor(logsPerPage);
  };

  const filterLogs = useCallback(
    (logs) => {
      const publishDate = new Date(logs.published_at);
      let clinicalTeam = ' ';
      if (logs.field_name === 'users') {
        clinicalTeam = logs.new_entry.join(', ');
      }

      // excluding weight related logs triggered by editing out-of-hospital step 2or3 after updating a patient's weight
      if (
        logs.field_name === 'step_weight_last_updated' ||
        logs.field_name === 'based_on_patients_weight'
      ) {
        return null;
      }

      // searching dates in either long (16 june) or short form (12 dec)
      if (
        (publishDate &&
          publishDate
            .toLocaleString([], {
              year: 'numeric',
              month: 'long',
              day: 'numeric',
              hour: '2-digit',
              minute: '2-digit',
            })
            .toLowerCase()
            .includes(inputText.toLowerCase())) ||
        publishDate
          .toLocaleString([], {
            year: 'numeric',
            month: 'short',
            day: 'numeric',
            hour: '2-digit',
            minute: '2-digit',
          })
          .toLowerCase()
          .includes(inputText.toLowerCase())
      ) {
        return logs;
      }

      const searchLogs =
        logs.field_operation === 'Deleted'
          ? 'Deleted ' + logs.field_name.replace(/_/g, ' ')
          : logs.field_operation === 'Changed'
          ? 'Changed ' +
            logs.field_name.replace(/_/g, ' ') +
            ' from ' +
            formatValue(logs.field_name, logs.old_entry) +
            ' to ' +
            formatValue(logs.field_name, logs.new_entry)
          : logs.field_operation === 'Removed'
          ? 'Removed ' + clinicalTeam + ' from the Clinical Team'
          : logs.field_operation === 'Added'
          ? 'Added ' +
            formatValue(logs.field_name, logs.new_entry) +
            ' to ' +
            logs.field_name.replace(/_/g, ' ')
          : '';

      if (
        searchLogs &&
        searchLogs.toLowerCase().includes(inputText.toLowerCase())
      ) {
        return logs;
      }

      let userFullName = '';

      if (logs.updated_by_user) {
        userFullName =
          logs.updated_by_user.position +
          ' ' +
          logs.updated_by_user.prefix +
          ' ' +
          logs.updated_by_user.first_name +
          ' ' +
          logs.updated_by_user.last_name;
      }

      if (
        userFullName &&
        userFullName.toLowerCase().includes(inputText.toLowerCase())
      ) {
        return logs;
      }

      return null;
    },
    [inputText]
  );

  const generatePaginatedButtons = () => {
    const numPages = Math.ceil(filteredLogs.length / logsPerPage);

    const handleChange = (event, value) => {
      setStartCursor((value - 1) * logsPerPage);
      setEndCursor(value * logsPerPage);
      setCurrentPage(value);
    };

    return (
      <div>
        <p css={showingEntriesStyle}>
          Showing entries:{' '}
          {filteredLogs.length === 0
            ? 0
            : startCursor === 0
            ? 1
            : startCursor + 1}{' '}
          - {endCursor > filteredLogs.length ? filteredLogs.length : endCursor}
        </p>
        <p css={resultsFoundStyle}>{filteredLogs.length} entries found</p>

        <div css={paginationContainerStyle}>
          <Pagination
            count={numPages}
            page={currentPage}
            onChange={handleChange}
            color="primary"
          />
        </div>
      </div>
    );
  };

  useEffect(() => {
    if (!data) {
      return;
    } else if (!data.patientLogs) {
      return;
    }

    const filtered = data.patientLogs.filter((log) => {
      const filteredLogs = filterLogs(log);
      if (filteredLogs === null) {
        return false;
      }
      return true;
    });

    setFilteredLogs(filtered);
  }, [inputText, data, filterLogs]);

  if (loading) {
    return <PatientTabSkeleton />;
  } else {
    return (
      <Fragment>
        <PatientTabs id={id}>
          <div
            className="patientLog"
            css={css`
              border: ${GlobalStyles.outerContainerBorder};
              background-color: ${GlobalStyles.white};
              display: grid;
              grid-template-columns: repeat(10, 1fr);
              column-gap: ${GlobalStyles.columnGap};
              column-gap: 10px;
              padding-bottom: 80px;
            `}
          >
            <div
              className="logPageContainer"
              css={css`
                grid-column: 2 / span 8;
                padding-top: 40px;
                height: 700px;
                overflow: hidden;
                width: 105%;
                margin-left: -2%;
              `}
            >
              {/* Vertical Label Searching */}

              <div
                className="patientLogSection"
                css={css`
                  overflow: hidden;
                `}
              >
                {/* HEADER */}
                <div
                  className="searchSection"
                  css={css`
                    padding-right: 70px;
                  `}
                >
                  <div
                    className="searchBarContainer"
                    css={css`
                      display: flex;
                      border: 0;
                      width: 100%;
                      padding-top: 10px;
                      margin-bottom: 40px;
                    `}
                  >
                    <SearchIcon
                      css={css`
                        height: 31px;
                        padding-left: 1%;
                        border: ${GlobalStyles.formInputYellow};
                        background-color: ${GlobalStyles.formInputYellow};
                      `}
                    />
                    <DebounceInput
                      css={css`
                        border: ${GlobalStyles.formInputYellow};
                        background-color: ${GlobalStyles.formInputYellow};
                        height: 31px;
                        width: 100%;
                        ::placeholder {
                          color: ${GlobalStyles.navyBlue};
                          position: relative;
                          font-weight: 900;
                          opacity: 1;
                        }
                        :focus {
                          outline: none;
                        }
                        :focus::placeholder {
                          color: transparent;
                        }
                      `}
                      type="search"
                      placeholder="search"
                      value={inputText}
                      onChange={doSearch}
                      aria-hidden="false"
                      debounceTimeout={300}
                    />
                  </div>
                  {generatePaginatedButtons()}
                </div>

                {filteredLogs.length < 1 && inputText.length > 0 && (
                  <p css={dataChanged}>
                    No results found for{' '}
                    <span css={boldedSpan}>{inputText}</span>.
                  </p>
                )}

                {/* MAIN CONTAINER */}
                <div id="log-container" css={containerWithScrollbarStyling}>
                  {filteredLogs &&
                    filteredLogs.map((log, i) => {
                      // pagination - return early to avoid rendering not-shown logs
                      if (i < startCursor || i >= endCursor) return null;

                      const nickname = log.patient.nickname;
                      const publishDate = new Date(log.published_at);
                      const year = moment(log.published_at).format('YYYY');
                      const beginningOfYearGroup = log.first;
                      const id = log.id;

                      return (
                        <div
                          key={id}
                          id={beginningOfYearGroup ? `${year}-first` : id}
                          className="patient-log-individual-container"
                          css={beginningOfYearGroupStyle}
                        >
                          {beginningOfYearGroup && (
                            <div
                              css={css`
                                display: flex;
                                width: 100%;
                              `}
                            >
                              <span className="year" css={yearSectionLabel}>
                                {year}
                              </span>
                              <span
                                className="underline"
                                css={yearSectionLabelUnderline}
                              ></span>
                            </div>
                          )}

                          <Grid container direction="row">
                            <Grid container item xs={9} direction="column">
                              <div
                                id={id}
                                css={css`
                                  background-color: lime;
                                `}
                              ></div>
                              <PatientLogs
                                nickname={nickname}
                                log={log}
                                publishDate={publishDate}
                                year={year}
                              />

                              <Grid className="Notes" item>
                                <h5 css={noteStyling}>
                                  {log.notes ? `NOTES` : null}
                                </h5>
                                <TextField
                                  fullWidth
                                  multiline
                                  rows={2}
                                  value={log.notes ? log.notes : ''}
                                  disabled
                                  css={noteDetailsStyling}
                                />
                              </Grid>
                            </Grid>

                            <Grid
                              className="AddProfession"
                              container
                              item
                              xs={3}
                              direction="column"
                            >
                              <Grid item>
                                <p css={updatedBy}>
                                  {log.updated_by_user
                                    ? log.updated_by_user.prefix +
                                      ' ' +
                                      log.updated_by_user.first_name +
                                      ' ' +
                                      log.updated_by_user.last_name
                                    : null}
                                </p>
                              </Grid>
                              <Grid item>
                                <p css={profession}>
                                  {log.updated_by_user
                                    ? log.updated_by_user.position
                                      ? log.updated_by_user.position.toUpperCase()
                                      : null
                                    : null}
                                </p>
                              </Grid>

                              <Grid
                                item
                                className="AddNoteButton"
                                css={css`
                                  grid-column: 7 / span 2;
                                  display: flex;
                                  justify-content: flex-end;
                                `}
                              >
                                <Button
                                  type="button"
                                  onClick={handleOpen(log)}
                                  css={buttonStyling}
                                >
                                  ADD NOTE
                                </Button>
                              </Grid>
                            </Grid>
                          </Grid>
                        </div>
                      );
                    })}
                </div>
                <UpdateNotesModal
                  handleClose={handleClose}
                  open={open}
                  updatedNotes={updatedNotes}
                  setUpdatedNotes={setUpdatedNotes}
                  currentLog={currentLog}
                  UpdatePatientLogNotes={UpdatePatientLogNotes}
                  formatValue={formatValue}
                  formatFieldName={formatFieldName}
                />
              </div>
            </div>
          </div>
        </PatientTabs>
      </Fragment>
    );
  }
}

export default PatientLogPage;
