/** @jsx jsx */
import { jsx, css } from '@emotion/core';
import Skeleton from '@material-ui/lab/Skeleton';
import Grid from '@material-ui/core/Grid';
import GlobalStyles from 'assets/GlobalStyles';

const PatientLogSkeleton = () => {
  return (
    <Grid className="patient-log-skeleton">
      <div
        css={css`
          display: grid;
          grid-template-columns: auto 1fr;
        `}
      >
        <div
          className="patient-log-skeleton-body"
          css={css`
            padding: 40px;
            border-top: ${GlobalStyles.outerContainerBorder};
            border-right: ${GlobalStyles.outerContainerBorder};
            border-bottom: ${GlobalStyles.outerContainerBorder};
            border-left: ${GlobalStyles.outerContainerBorder};
            background-color: ${GlobalStyles.white};
            overflow-y: scroll;
          `}
        >
          <div
            css={css`
              min-width: 77vw;
            `}
          ></div>
          <Grid
            container
            className="patient-log-skeleton"
            direction="row"
            justify="space-around"
          >
            {Array(2)
              .fill()
              .map((item, index) => (
                <Skeleton height={80} width={'91%'} key={index} />
              ))}
            {Array(6)
              .fill()
              .map((item, index) => (
                <Skeleton height={100} width={'91%'} key={index} />
              ))}
          </Grid>
        </div>
      </div>
    </Grid>
  );
};

export default PatientLogSkeleton;
