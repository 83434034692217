/** @jsx jsx */
import { jsx, css } from '@emotion/core';
import GlobalStyles from 'assets/GlobalStyles';
import moment from 'moment';
import Modal from '@material-ui/core/Modal';
import CloseTwoToneIcon from '@material-ui/icons/CloseTwoTone';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';

const boldedSpan = css`
  font-weight: bold;
  font-family: roboto-bold;
`;

const UpdateWeightModal = ({
  handleClose,
  open,
  name,
  weight,
  setFormFields,
  handleChange,
}) => {
  const currentTime = moment().format().toString();
  const handleConfirm = () => {
    setFormFields((prev) => ({
      ...prev,
      weight_last_updated: currentTime,
    }));
    handleChange('weight');
    handleClose();
  };
  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="weight-modal-title"
      aria-describedby="weight-modal-description"
      BackdropProps={{
        style: {
          boxShadow: 'none',
          cursor: 'pointer',
        },
      }}
      css={css`
        display: 'flex';
        align-items: 'center';
        justify-content: 'center';
        width: 50%;
        margin: auto;
        margin-top: 100px;
        border-radius: 0px;
      `}
    >
      <div
        className="weightModel__paper-container"
        css={css`
          background-color: ${GlobalStyles.white};
          border: solid 7px ${GlobalStyles.navyBlue};
          margin-right: -2px;
          .MuiInputBase-input {
            color: ${GlobalStyles.navyBlue};
            padding: 6px 6px 0px 6px;
          }
        `}
      >
        <IconButton
          onClick={handleClose}
          aria-label="close"
          css={css`
            color: ${GlobalStyles.white};
            background-color: ${GlobalStyles.navyBlue};
            border-radius: 0px;
            float: right;
            margin-right: -2px;
          `}
        >
          <CloseTwoToneIcon />
        </IconButton>
        <div
          className="weightModel__paper"
          css={css`
            margin: 50px;
          `}
        >
          <div
            className="weightModel__paper-title-container"
            css={css`
              display: grid;
              grid-template-columns: repeat(5, 1fr);
              column-gap: ${GlobalStyles.columnGap};
              border-bottom: 5px solid ${GlobalStyles.warningOrange};
            `}
          >
            {name && (
              <div
                className="weightModel__paper-title"
                css={css`
                  background-color: ${GlobalStyles.warningOrange};
                  color: ${GlobalStyles.white};
                  font-family: 'roboto-black';
                  font-size: 20px;
                  padding: 2px 20px 0px 20px;
                  font-size: 20px;
                  margin-bottom: -2px;
                  white-space: nowrap;
                `}
              >
                CONFIRM {name.toUpperCase()}'S WEIGHT
              </div>
            )}
          </div>
          <div
            css={css`
              color: ${GlobalStyles.navyBlue};
              font-size: 21px;
              overflow-wrap: break-word;
            `}
          >
            <p>
              Please confirm that{' '}
              <span className="boldedText" css={boldedSpan}>
                {name}
              </span>
              's weight is{' '}
              <span className="boldedText" css={boldedSpan}>
                {weight}kg
              </span>
              .
            </p>
            <p>
              Please note: The patient's weight is used to calculate the
              recommended dosage in their seizure plan.
            </p>
          </div>
          <div
            className="max-dosage-modal__buttons-container"
            css={css`
              display: grid;
              grid-template-columns: repeat(2, 1fr);
            `}
          >
            <div
              css={css`
                line-height: 1.75;
                height: 32px;
                margin: 10px 10px 10px 0px;
              `}
            >
              <Button
                type="button"
                css={css`
                  font-weight: bolder;
                  align-self: center;
                  background-color: ${GlobalStyles.red};
                  color: ${GlobalStyles.white};
                  cursor: pointer;
                  width: 100%;
                  text-decoration: none;
                  border-radius: 0% !important;
                  &:hover {
                    color: ${GlobalStyles.navyBlue};
                    background-color: ${GlobalStyles.red};
                  }
                  height: 32px;
                `}
                onClick={handleClose}
              >
                CANCEL
              </Button>
            </div>
            <div
              css={css`
                line-height: 1.75;
                height: 32px;
                margin: 10px 0px 10px 10px;
              `}
            >
              <Button
                type="submit"
                css={css`
                  font-weight: bolder;
                  align-self: center;
                  background-color: ${GlobalStyles.accentOrange};
                  color: ${GlobalStyles.navyBlue};
                  cursor: pointer;
                  width: 100%;
                  text-decoration: none;
                  border-radius: 0% !important;
                  &:hover {
                    color: ${GlobalStyles.navyBlue};
                    background-color: ${GlobalStyles.accentOrange};
                  }
                  height: 32px;
                `}
                onClick={handleConfirm}
              >
                CONFIRM
              </Button>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default UpdateWeightModal;
