import { Fragment } from 'react';
import { useParams, Link } from 'react-router-dom';
/** @jsx jsx */
import { jsx, css } from '@emotion/core';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import GridList from '@material-ui/core/GridList';
import GridListTile from '@material-ui/core/GridListTile';
import PlaceholderImage from 'assets/images/medication_placeholder.jpg';
import GlobalStyles from 'assets/GlobalStyles';
import { Button } from '@material-ui/core';

const view__images = css`
  height: 90%;
  margin-top: 10px;
  padding: 0 30px 0 30px;
  width: 200px;
  overflow-y: auto;
  align-content: 'space-between';
  /* Scrollbar for Firefox */
  scrollbar-color: ${GlobalStyles.babyBlue} ${GlobalStyles.backgroundBlue};

  &::-webkit-scrollbar {
    width: 15px;
  }
  &::-webkit-scrollbar-thumb {
    height: 10%;
    border-radius: 10px;
    background-color: ${GlobalStyles.babyBlue};
    box-shadow: inset 0 0 10px 10px ${GlobalStyles.babyBlue};
    border: 3px solid ${GlobalStyles.backgroundBlue};
  }
  &::-webkit-scrollbar-track {
    border-radius: 10px;
    height: 70%;
    background-color: ${GlobalStyles.backgroundBlue};
  }
`;

const inputFieldTitle = css`
  background-color: ${GlobalStyles.navyBlue2};
  color: ${GlobalStyles.white};
  font-size: 14px;
  font-weight: 800;
  letter-spacing: 1px;
  padding: 0.5% 1%;
  margin-bottom: -0.2%;
  height: min-content;
  width: max-content;
`;

const textFieldStyle = css`
  margin: 0px;
  padding-left: 1%;
  padding-bottom: 2%;
  width: 100%;
  border-top: 5px solid ${GlobalStyles.navyBlue2};
  input.Mui-disabled {
    color: ${GlobalStyles.navyBlue2};
    font-size: ${GlobalStyles.inputFontSize};
    font-weight: 500;
    letter-spacing: 1.25px;
  }
  .MuiInputBase-input.Mui-disabled {
    color: ${GlobalStyles.navyBlue2};
    -webkit-text-fill-color: ${GlobalStyles.navyBlue2};
    font-size: ${GlobalStyles.inputFontSize};
    font-weight: 500;
    letter-spacing: 1.25px;
  }
  .MuiInput-underline:before {
    border-bottom: 0px;
  }
`;

function CustomTextField(props) {
  return (
    <TextField
      InputLabelProps={{
        shrink: true,
      }}
      css={css`
        input.Mui-disabled {
          color: ${GlobalStyles.navyBlue2};
          -webkit-text-fill-color: ${GlobalStyles.navyBlue2};
          font-size: ${GlobalStyles.inputFontSize};
          letter-spacing: 1.25px;
          font-weight: 500;
          width: 100%;
          padding: 5px 0px 20px 5px;
          margin-right: 5px;
        }
        .MuiInput-underline.Mui-disabled:before {
          border-bottom-style: hidden;
        }
      `}
      {...props}
    />
  );
}

function MedicationView({ medication }) {
  const { id } = useParams();

  const {
    generic_name,
    trade_names,
    type_of_medication,
    images,
    maximum_dosage,
    notes,
  } = medication;

  const disabled = true;

  return (
    <Fragment>
      <Grid container xs={12} justify="space-between">
        <Grid
          className="titleBlock"
          item
          container
          css={css`
            font-size: ${GlobalStyles.pageTitleSize};
            font-weight: bolder;
            padding: 10px;
            background-color: ${GlobalStyles.midBlue};
            color: ${GlobalStyles.white};
            width: max-content;
            text-align: left;
            letter-spacing: 1px;
          `}
        >
          {generic_name.toUpperCase()}
        </Grid>

        <Button
          className="editButton"
          component={Link}
          to={`/medication/${id}/edit`}
          css={css`
            font-weight: bolder;
            background-color: ${GlobalStyles.accentOrange};
            color: ${GlobalStyles.white};
            width: 180px;
            text-decoration: none;
            border-radius: 0%;
            height: 32px;
            &:hover {
              color: ${GlobalStyles.navyBlue};
              background-color: ${GlobalStyles.accentOrange};
            }
          `}
        >
          Edit
        </Button>
      </Grid>

      <Grid
        container
        xs={12}
        className="medication-view"
        key={id}
        css={css`
          padding: 50px 80px;
          border: ${GlobalStyles.outerContainerBorder};
          background-color: ${GlobalStyles.white};
        `}
      >
        <Grid
          className="image-column"
          container
          item
          css={css`
            max-height: 50vh;
            width: max-content;
          `}
        >
          <GridList
            cellHeight={160}
            className="medication-view__images"
            cols={1}
            css={view__images}
          >
            {/* uploaded images < 157px height will be null in thumbnail, display non-optimized for such cases but use optimized thumbnails if larger */}
            {images &&
              images.map((image, index) => (
                <GridListTile key={index} cols={image.cols || 1}>
                  <img
                    src={
                      image && image.formats === null
                        ? image.url
                        : image.formats.thumbnail.url
                    }
                    alt={image.generic_name}
                  />
                </GridListTile>
              ))}
            {!medication.images.length && (
              <img
                style={{
                  height: '150px',
                  width: '150px',
                }}
                src={PlaceholderImage}
                alt="Placeholder"
              />
            )}
          </GridList>
        </Grid>

        <Grid
          className="medicationForm-column"
          container
          item
          xs
          css={css`
            margin-left: 50px;
          `}
        >
          <Grid item container>
            <Grid item container css={inputFieldTitle}>
              GENERIC NAME
            </Grid>
            <CustomTextField
              value={generic_name ? generic_name : ''}
              disabled={disabled}
              css={textFieldStyle}
            />
          </Grid>

          <Grid item container>
            <Grid item container css={inputFieldTitle}>
              TRADE NAME
            </Grid>
            <Grid item container>
              <CustomTextField
                value={trade_names ? trade_names : ''}
                disabled={disabled}
                css={textFieldStyle}
              />
            </Grid>
          </Grid>

          <Grid item container>
            <Grid item container css={inputFieldTitle}>
              TYPE
            </Grid>
            <Grid item container>
              <CustomTextField
                value={type_of_medication ? type_of_medication : ''}
                disabled={disabled}
                css={textFieldStyle}
              />
            </Grid>
          </Grid>

          <Grid item container>
            <Grid item container css={inputFieldTitle}>
              MAXIMUM DOSAGE
            </Grid>
            <Grid item container>
              <CustomTextField
                css={textFieldStyle}
                value={
                  maximum_dosage > 0
                    ? Number(maximum_dosage).toFixed(2) + '   mg'
                    : ''
                }
                disabled={disabled}
              />
            </Grid>
          </Grid>

          <Grid item container>
            <Grid item container css={inputFieldTitle}>
              NOTES
            </Grid>
            <Grid item container>
              <CustomTextField
                multiline
                rows={5}
                value={notes ? notes : ''}
                disabled={disabled}
                css={textFieldStyle}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Fragment>
  );
}

export default MedicationView;
