/** @jsx jsx */
import { jsx, css } from '@emotion/core';
import DeleteIcon from '@material-ui/icons/Delete';
import { Grid, MenuItem, Select } from '@material-ui/core';
import GlobalStyles from 'assets/GlobalStyles';
import { TextValidator } from 'react-material-ui-form-validator';

const textValidatorStyle = css`
  width: 100%;
  background-color: ${GlobalStyles.paleOrange};
  height: max-content;

  .MuiInputBase-input {
    color: ${GlobalStyles.navyBlue2};
    font-size: 20px;
    font-weight: 500;
    letter-spacing: 1.25px;
    margin-left: 10px;
  }

  .MuiInputBase-input.Mui-disabled {
    background-color: ${GlobalStyles.disabledGrey};
    width: 100%;
    margin-left: 0;
  }
  .MuiFormHelperText-root.Mui-error {
    background-color: ${GlobalStyles.white};
    margin-bottom: -5px;
  }
`;

const NonRescueMedication = ({
  medications,
  handleChange,
  textFieldStyle2,
  dosageValue,
  medicationData,
  handleDelete,
  isLoading,
  patient,
  disableDosage,
}) => {
  const nonRescueMedications = () => {
    if (medicationData && medicationData.medications) {
      return medicationData.medications.filter(
        (m) =>
          !m.generic_name.includes('Lorazepam') &&
          !m.generic_name.includes('Midazolam')
      );
    } else {
      return null;
    }
  };

  const medicationList = nonRescueMedications() && [...nonRescueMedications()];

  /// Get medication names list that our patient uses.
  const patientMedicationList = patient
    ? patient.medications.map((m) => m.generic_name)
    : [];

  /// Filter medications that patient uses from all others to prevent adding twice.
  const medicationListToRender =
    patient && medicationList
      ? medicationList.filter(
          (m) => !patientMedicationList.includes(m.generic_name)
        )
      : [];

  return (
    <div
      css={css`
        display: flex;
        justify-content: space-between;
        width: 100%;
        padding-bottom: 10px;
      `}
    >
      <Grid container>
        <Grid item xs={7}>
          <Select
            defaultValue={''}
            css={textValidatorStyle}
            value={Array.isArray(medications) ? '' : medications}
            onChange={handleChange('medications')}
            disabled={patient === null}
          >
            {medicationData &&
              medicationData.medications &&
              medicationListToRender.map((medication) => {
                return (
                  <MenuItem
                    key={medication.id}
                    value={
                      medication.generic_name && !isLoading
                        ? medication.generic_name
                        : ''
                    }
                    css={css`
                      color: ${GlobalStyles.navyBlue};
                    `}
                  >
                    {medication.generic_name}
                  </MenuItem>
                );
              })}
          </Select>
        </Grid>
        <Grid item xs={5}>
          <div
            css={css`
              display: flex;
              justify-content: flex-end;
            `}
          >
            <label
              css={css`
                color: ${GlobalStyles.navyBlue};
                -webkit-text-fill-color: ${GlobalStyles.navyBlue2};
                font-size: 20px;
                font-weight: 500;
                letter-spacing: 1.25px;
                margin: 0px;
                margin-right: 10px;
                padding: 10px 0px 10px 2%;
                cursor: default;
              `}
            >
              Dose:{' '}
            </label>
            <div
              css={css`
                background-color: ${GlobalStyles.paleOrange};
                height: max-content;
                width: 30%;
              `}
            >
              <TextValidator
                type="text"
                validators={disableDosage ? [] : ['required']}
                errorMessages={['Please enter a dosage or clear medication']}
                css={textFieldStyle2}
                value={dosageValue && !isLoading ? dosageValue : ''}
                onChange={handleChange('dosage')}
                disabled={patient === null || disableDosage}
              />
            </div>
            <DeleteIcon
              onClick={handleDelete}
              variant="contained"
              color="secondary"
              style={{
                color:
                  patient === null
                    ? `${GlobalStyles.disabledGrey}`
                    : `${GlobalStyles.navyBlue}`,
                cursor: patient === null ? 'auto' : 'pointer',
                alignSelf: 'center',
                marginLeft: '10px',
              }}
            />
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

export default NonRescueMedication;
