/** @jsx jsx */
import { jsx, css } from '@emotion/core';
import { Fragment } from 'react';
import { useHistory } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { useSwipeable } from 'react-swipeable';
import GlobalStyles from 'assets/GlobalStyles';
import Button from '@material-ui/core/Button';
import ArrowLeft from 'assets/images/arrow-left.png';
import ArrowRight from 'assets/images/arrow-right.png';

const xsMobile = 400;
const MobilePlanButton = ({ buttonlabel, to, contentdirection }) => {
  let direction = contentdirection;
  const history = useHistory();
  const leftarrow = ArrowLeft;
  const rightarrow = ArrowRight;

  const swipeHandlers = useSwipeable({
    onSwiped: async () => history.push(to),
  });

  return (
    <Button
      contentdirection={direction}
      {...swipeHandlers}
      css={css`
        font-family: poppins-extra-bold;
        font-size: 18px;
        letter-spacing: 1.43px;
        text-transform: uppercase;
        color: ${GlobalStyles.darkBlue};
        /* !important to override materialUI clicked color of transparent */
        background-color: ${GlobalStyles.paleBlueCardBackground} !important;
        padding: 18px 18px 18px 10%;
        border-radius: 30px;
        min-width: fit-content;
        width: 100%;
        justify-content: ${direction};
        white-space: nowrap;
        @media (max-width: ${xsMobile}px) {
          font-size: 16px;
        }
      `}
      component={Link}
      to={to}
    >
      {direction === 'left' && (
        <Fragment>
          <img
            src={leftarrow}
            alt="Orange arrow pointing left"
            css={css`
              padding: 0 8px;
              width: fit-content;
            `}
          />
          {buttonlabel}
        </Fragment>
      )}

      {direction === 'right' && (
        <Fragment>
          {buttonlabel}
          <img
            src={rightarrow}
            alt="Orange arrow pointing right"
            css={css`
              padding: 0 8px;
              width: 60px;
              @media (max-width: ${xsMobile}px) {
                width: 50px;
              }
            `}
          />
        </Fragment>
      )}
    </Button>
  );
};

export default MobilePlanButton;
