import { useState, useRef, Fragment } from 'react';
import { useLazyQuery } from '@apollo/client';
import { useHistory, Link } from 'react-router-dom';
import { useMutation, useQuery } from '@apollo/client';
/** @jsx jsx */
import { jsx, css } from '@emotion/core';
import {
  TextField,
  Button,
  Grid,
  CircularProgress,
  MenuItem,
  Snackbar,
  IconButton,
  GridList,
  GridListTile,
  GridListTileBar,
} from '@material-ui/core/';
import DeleteIcon from '@material-ui/icons/Delete';
import GlobalStyles from 'assets/GlobalStyles';
import { useTheme } from '@material-ui/core/styles';
import {
  ValidatorForm,
  TextValidator,
  SelectValidator,
} from 'react-material-ui-form-validator';
import CloseIcon from '@material-ui/icons/Close';
import { CREATE_MEDICATION } from 'utils/mutations';
import {
  GET_MEDICATIONS_LIST,
  GET_UNIT_OF_DOSAGE_OPTIONS,
} from 'utils/queries';
import PlaceholderImage from 'assets/images/medication_placeholder.jpg';
//Loading Skeleton
import EditProfileSkeleton from '../components/Skeletons/EditProfileSkeleton';
import { GET_MEDICATION } from 'utils/queries';

// CSS
const viewImages = css`
  background: ${GlobalStyles.paleBlue};
  max-height: -webkit-fill-available;
  margin-top: 0;
  padding: 0 25px 10px 30px;
  width: 200px;
  max-height: 500px;
  overflow-y: auto;

  /* Scrollbar for Firefox */
  scrollbar-color: ${GlobalStyles.babyBlue} ${GlobalStyles.backgroundBlue};

  &::-webkit-scrollbar {
    width: 20px;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: ${GlobalStyles.babyBlue};
    background-clip: content-box;
    border: 4px solid transparent;
  }
  &::-webkit-scrollbar-track {
    border-radius: 10px;
    height: 70%;
    background-color: ${GlobalStyles.backgroundBlue};
    margin: 0.5rem;
    border: 1px solid ${GlobalStyles.babyBlue};
  }
`;

const sharedButtonStyle = css`
  font-weight: bolder;
  color: ${GlobalStyles.white};
  cursor: pointer;
  width: 96%;
  text-decoration: none;
  border-radius: 0% !important;
  height: 35px;
`;

const leftButtonStyle = css`
  background-color: ${GlobalStyles.red};
  &:hover {
    color: ${GlobalStyles.navyBlue};
    background-color: ${GlobalStyles.red};
  }
`;

const rightButtonStyle = css`
  background-color: ${GlobalStyles.accentOrange};
  &:hover {
    color: ${GlobalStyles.navyBlue};
    background-color: ${GlobalStyles.accentOrange};
  }
`;

const inputFieldTitle = css`
  background-color: ${GlobalStyles.navyBlue2};
  color: ${GlobalStyles.white};
  font-size: 14px;
  font-weight: 800;
  letter-spacing: 1px;
  padding: 0.5% 1%;
  margin-bottom: -0.2%;
  height: min-content;
  width: max-content;
`;

const inputFieldStyle = css`
  height: min-content;
  background-color: ${GlobalStyles.paleOrange};
  border-top: 5px solid ${GlobalStyles.navyBlue2};
`;

const textFieldStyle = css`
  width: 100%;
  .MuiInputBase-input {
    margin-left: 3%;
    color: ${GlobalStyles.navyBlue2};
    font-size: ${GlobalStyles.inputFontSize};
    font-weight: 500;
    letter-spacing: 1.25px;
    padding: 0px 0 5px 0px;
  }
  .MuiInput-underline:before {
    border-bottom: 0px;
  }
  .MuiInputBase-input.Mui-disabled {
    color: ${GlobalStyles.navyBlue2};
    margin-left: 10%;
  }
  .MuiFormHelperText-root.Mui-error {
    background-color: ${GlobalStyles.white};
    margin-bottom: -10px;
  }
`;

const selectValidatorStyle = css`
  width: 100%;
  background-color: ${GlobalStyles.paleOrange};
  margin-bottom: ${GlobalStyles.singleInputMarginBottom};
  border-top: 5px solid ${GlobalStyles.navyBlue};
  .MuiInputBase-input {
    color: ${GlobalStyles.navyBlue2};
    font-size: 20px;
    font-weight: 500;
    letter-spacing: 1.25px;
    margin-left: 10px;
  }

  .MuiInput-underline:before {
    border-bottom: 0px;
  }

  .MuiInputBase-input.Mui-disabled {
    color: ${GlobalStyles.navyBlue2};
  }
  .MuiFormHelperText-root.Mui-error {
    background-color: ${GlobalStyles.white};
    margin-bottom: -5px;
  }
`;

const imageUploadFormStyle = css`
  min-height: 173px;
  display: grid;
  align-content: 'space-between';
`;

const imageUploadBtnStyle = css`
  font-family: 'roboto-black';
  font-size: 14px;
  letter-spacing: 1px;
  background-color: ${GlobalStyles.accentOrange};
  color: ${GlobalStyles.white};
  cursor: pointer;
  text-align: center;
  height: 28px;
  padding-top: 10px;
  &:hover {
    color: ${GlobalStyles.navyBlue};
    background-color: ${GlobalStyles.accentOrange};
  }
`;

const deleteIconContainerStyle = css`
  height: 30px;
`;

function CustomTextField(props) {
  const theme = useTheme();

  return (
    <TextField
      fullWidth
      type="text"
      margin="normal"
      InputLabelProps={{
        shrink: true,
      }}
      css={css`
        input.Mui-disabled {
          color: ${theme.palette.text.primary};
        }
      `}
      {...props}
    />
  );
}

function AddMedicationForm({ title }) {
  const history = useHistory();
  const imageFormElAdd = useRef(null);

  const { loading: loadingUnitOfDosageOptions } = useQuery(
    GET_UNIT_OF_DOSAGE_OPTIONS
  );
  const [imagePreview, setImagePreview] = useState([{ image: null }]);
  const [open, setOpen] = useState(false);
  const [loadingSubmit, setLoadingSubmit] = useState(false);
  const {
    refetch: refetchMedicationList,
    loading: loadingMedicationList,
  } = useQuery(GET_MEDICATIONS_LIST);
  const [createMedication, addingMedication] = useMutation(CREATE_MEDICATION, {
    update(cache, { data: { createMedication } }) {
      const { medications } = cache.readQuery({ query: GET_MEDICATIONS_LIST });
      cache.writeQuery({
        query: GET_MEDICATIONS_LIST,
        data: {
          medications: medications.concat([createMedication.medication]),
        },
      });
    },
  });
  const [getMedication] = useLazyQuery(GET_MEDICATION);

  const [formFields, setFormFields] = useState({
    generic_name: '',
    trade_names: '',
    type_of_medication: '',
    maximum_dosage: '',
    unit_of_dosage: '',
    notes: '',
  });

  const listOfMedicationTypes = ['Rescue', 'Maintenance'];
  const { maximum_dosage } = formFields;

  const handleImagePreview = async (event) => {
    if (event.target.files && event.target.files[0]) {
      const files = event.target.files;

      const imageArr = [];

      for (let i = 0; i < files.length; i++) {
        imageArr.push({ image: URL.createObjectURL(files[i]) });
      }

      if (imageArr[0].image === null) {
        imageArr.shift();
      }

      setImagePreview(imageArr);
    }
  };

  const handleDelete = (key) => {
    const newImageArr = Array.from(imagePreview);

    if (newImageArr.length === 1) {
      newImageArr.splice(key, 1);
      newImageArr.push({ image: null });
    } else {
      newImageArr.splice(key, 1);
    }

    setImagePreview(newImageArr);
  };

  // Function for focusing on next input field on enter
  // Multiline inputs not targeted as enter must go to next line.
  // e.preventDefault necessary in each input as form button must stay type="submit" for validation to work
  const input1 = useRef(),
    input2 = useRef(),
    input3 = useRef(),
    input4 = useRef(),
    input5 = useRef();

  const handleEnter = (e) => {
    if (e.keyCode === 13) {
      switch (e.target.id) {
        case 'inputId1':
          e.preventDefault();
          input2.current.focus();
          break;
        case 'inputId2':
          e.preventDefault();
          input3.current.focus();
          break;
        case 'inputId3':
          e.preventDefault();
          input4.current.focus();
          break;
        case 'inputId4':
          e.preventDefault();
          input5.current.focus();
          break;

        default:
          break;
      }
    }
  };

  // Max Dosage form validation
  const [errorHelperText, setErrorHelperText] = useState('');

  const handleChange = (key) => ({ target: { value } }) => {
    console.log(formFields);
    const newFormFields = { ...formFields };
    newFormFields[key] = value;
    const validNum = /^-?[0-9]*\.?[0-9]*$/.test(value);

    if (key === 'maximum_dosage') {
      if (value < 0 || value === '-') {
        setErrorHelperText('This field cannot be negative');
      } else if (value >= 0) {
        setErrorHelperText('');
      } else if (!validNum) {
        return;
      } else {
        setErrorHelperText('');
      }
    }
    if (key === 'type_of_medication') {
      console.log(key, value);
    } else {
      newFormFields[key] = value;
    }
    // console.log(newFormFields);
    setFormFields(newFormFields);
  };

  const handleSubmitNewImage = async (formElement, newMedicationRecord) => {
    if (!formElement.files.value) {
      return;
    }

    const formData = new FormData(formElement);
    const newMemberRecordId = newMedicationRecord.id;
    formData.append('refId', newMemberRecordId);
    const options = {
      method: 'POST',
      body: formData,
    };

    return fetch(`${process.env.REACT_APP_API_URL}/upload`, options)
      .then((response) => response.json())
      .then(async () => {
        await getMedication({
          variables: { id: newMedicationRecord.id },
        });
      })
      .catch((error) => {
        console.error('Error:', error);
        setOpen(true);
      });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoadingSubmit(true);

    if (formFields.maximum_dosage >= 0) {
      let max_dosage_float = parseFloat(formFields.maximum_dosage);
      formFields.maximum_dosage = max_dosage_float;
    } else if (formFields.maximum_dosage === '.') {
      setErrorHelperText('Please enter a number or leave blank');
    }

    const formElement = imageFormElAdd.current;

    if (!formFields.unit_of_dosage) {
      formFields.unit_of_dosage = 'mg';
    }

    try {
      const response = await createMedication({
        variables: { data: formFields },
      });

      const newMedicationRecord = response.data.createMedication.medication;
      const newlyCreatedMedicationID =
        response.data.createMedication.medication.id;
      await handleSubmitNewImage(formElement, newMedicationRecord);
      await refetchMedicationList();

      history.push(`/medication/${newlyCreatedMedicationID}`);
    } catch (error) {
      console.log('Error', error);
      setLoadingSubmit(false);
    }
  };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };
  const isLoading =
    loadingUnitOfDosageOptions ||
    loadingSubmit ||
    loadingMedicationList ||
    addingMedication.loading;

  if (loadingUnitOfDosageOptions || loadingMedicationList)
    return <EditProfileSkeleton />;
  else {
    return (
      <Fragment>
        <div
          className="titleBlock"
          css={css`
            font-size: ${GlobalStyles.pageTitleSize};
            font-weight: bolder;
            padding: 10px;
            background-color: ${GlobalStyles.midBlue};
            color: ${GlobalStyles.white};
            width: max-content;
            text-align: left;
            letter-spacing: 1px;
          `}
        >
          {title.toUpperCase()}
        </div>

        <div
          className="add-medication"
          css={css`
            padding: 40px 80px;
            border: ${GlobalStyles.outerContainerBorder};
            overflow-y: hidden;
            background-color: ${GlobalStyles.white};
          `}
        >
          <Grid container>
            <Grid item>
              <form ref={imageFormElAdd} css={imageUploadFormStyle}>
                <label css={imageUploadBtnStyle} htmlFor="image-input">
                  ADD IMAGE
                  <input
                    type="file"
                    id="image-input"
                    name="files"
                    multiple
                    required
                    accept="image/png, image/jpg, image/jpeg"
                    onChange={handleImagePreview}
                    css={css`
                      display: none;
                    `}
                  />
                </label>

                <GridList
                  // Style tag is needed to override negative margin from mui
                  style={{ margin: 0 }}
                  cellHeight={150}
                  cols={1}
                  css={viewImages}
                >
                  {imagePreview[0].image !== null &&
                    imagePreview.map((imageItem, index) => (
                      <GridListTile
                        key={index}
                        cols={imageItem.cols || 1}
                        rows={1}
                        css={css`
                          margin: 5px 0;
                        `}
                      >
                        <img
                          src={imageItem.image !== null && imageItem.image}
                          alt={imageItem.generic_name}
                        />
                        ;
                        <GridListTileBar
                          css={deleteIconContainerStyle}
                          title={imageItem.generic_name}
                          actionIcon={
                            <DeleteIcon
                              onClick={() => handleDelete(index)}
                              style={{
                                color: `${GlobalStyles.accentOrange}`,
                                cursor: 'pointer',
                              }}
                            />
                          }
                        />
                      </GridListTile>
                    ))}
                  {imagePreview[0].image === null && (
                    <img
                      style={{
                        width: '150px',
                        margin: 'auto',
                        padding: '10px 0',
                      }}
                      src={PlaceholderImage}
                      alt="placeholder"
                    />
                  )}
                </GridList>
                {/* Below fields are needed to send images to Strapi */}
                <input type="hidden" name="ref" value="medication" />
                <input type="hidden" name="field" value="images" />
              </form>
            </Grid>

            <Grid
              className="medicationForm-column"
              container
              item
              xs
              css={css`
                padding-left: 10px;
              `}
            >
              <ValidatorForm
                onSubmit={handleSubmit}
                onError={(errors) => console.log(errors)}
                css={css`
                  margin-left: 50px;
                  width: 100%;
                `}
              >
                <Grid
                  item
                  container
                  css={css`
                    margin-bottom: ${GlobalStyles.singleInputMarginBottom};
                  `}
                >
                  <Grid
                    item
                    container
                    className="required"
                    css={inputFieldTitle}
                  >
                    GENERIC NAME*
                  </Grid>
                  <Grid item container direction="column" css={inputFieldStyle}>
                    <TextValidator
                      css={textFieldStyle}
                      onChange={handleChange('generic_name')}
                      value={formFields.generic_name}
                      validators={['required']}
                      errorMessages={['This field is required']}
                      margin="normal"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      id={'inputId1'}
                      inputRef={input1}
                      onKeyDown={handleEnter}
                    />
                  </Grid>
                </Grid>

                <Grid
                  item
                  container
                  css={css`
                    margin-bottom: ${GlobalStyles.singleInputMarginBottom};
                  `}
                >
                  <Grid item container css={inputFieldTitle}>
                    TRADE NAME
                  </Grid>

                  <Grid item container css={inputFieldStyle}>
                    <TextField
                      css={textFieldStyle}
                      value={formFields.trade_names}
                      onChange={handleChange('trade_names')}
                      margin="normal"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      id={'inputId2'}
                      inputRef={input2}
                      onKeyDown={handleEnter}
                    />
                  </Grid>
                </Grid>

                <Grid item container css={inputFieldTitle}>
                  TYPE*
                </Grid>

                <SelectValidator
                  css={selectValidatorStyle}
                  value={formFields.type_of_medication || ''}
                  onChange={handleChange('type_of_medication')}
                  validators={['required']}
                  errorMessages={['This field is required']}
                  id={'inputId3'}
                  inputRef={input3}
                  onKeyDown={handleEnter}
                >
                  {listOfMedicationTypes.map((type) => (
                    <MenuItem value={type} key={type}>
                      {type}
                    </MenuItem>
                  ))}
                </SelectValidator>

                <Grid
                  className="medicationView-maxDosageFields"
                  item
                  container
                  css={css`
                    margin-bottom: ${GlobalStyles.singleInputMarginBottom};
                  `}
                >
                  <Grid
                    item
                    container
                    // style differs from other input fields as width is less
                    css={css`
                      border-bottom: 5px solid ${GlobalStyles.navyBlue2};
                      height: fit-content;
                    `}
                  >
                    <div css={inputFieldTitle}>MAXIMUM DOSAGE (OPTIONAL)</div>
                  </Grid>

                  <Grid container justify="space-between">
                    <Grid
                      item
                      container
                      xs={9}
                      css={css`
                        background-color: ${GlobalStyles.paleOrange};
                        padding: 0;
                      `}
                    >
                      <TextField
                        css={textFieldStyle}
                        onChange={handleChange('maximum_dosage')}
                        value={maximum_dosage}
                        margin="normal"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        error={errorHelperText.length !== 0}
                        helperText={errorHelperText}
                        id={'inputId4'}
                        inputRef={input4}
                        onKeyDown={handleEnter}
                      />
                    </Grid>
                    <Grid item container sm={2} direction="column">
                      <CustomTextField
                        css={textFieldStyle}
                        disabled={formFields.id === 'new' ? false : true}
                        value={
                          formFields.unit_of_dosage
                            ? formFields.unit_of_dosage
                            : 'mg'
                        }
                      />
                    </Grid>
                  </Grid>
                </Grid>

                <Grid
                  item
                  container
                  css={css`
                    margin-bottom: 50px;
                  `}
                >
                  <Grid item container css={inputFieldTitle}>
                    NOTES
                  </Grid>
                  <Grid item container css={inputFieldStyle}>
                    <TextField
                      css={textFieldStyle}
                      multiline
                      rows={3}
                      value={formFields.notes ? formFields.notes : ''}
                      onChange={handleChange('notes')}
                      margin="normal"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      id={'inputId5'}
                      inputRef={input5}
                      onKeyDown={handleEnter}
                    />
                  </Grid>
                </Grid>

                <Snackbar
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                  }}
                  open={open}
                  autoHideDuration={6000}
                  onClose={handleClose}
                  message={'Sorry something went wrong'}
                  action={
                    <Fragment>
                      <IconButton
                        size="small"
                        aria-label="close"
                        color="inherit"
                        onClick={handleClose}
                      >
                        <CloseIcon fontSize="small" />
                      </IconButton>
                    </Fragment>
                  }
                />

                <Grid item container xs={12}>
                  <Grid item container xs={6} justify="flex-start">
                    <Button
                      component={Link}
                      to={`/medication-list`}
                      css={[leftButtonStyle, sharedButtonStyle]}
                    >
                      Cancel
                    </Button>
                  </Grid>
                  <Grid item container xs={6} justify="flex-end">
                    <Button
                      type="submit"
                      css={[rightButtonStyle, sharedButtonStyle]}
                    >
                      {!isLoading ? (
                        'SAVE'
                      ) : (
                        <CircularProgress
                          size="28px"
                          css={css`
                            .MuiCircularProgress-colorPrimary {
                              color: ${GlobalStyles.navyBlue};
                            }
                          `}
                        />
                      )}
                    </Button>
                  </Grid>
                </Grid>
              </ValidatorForm>
            </Grid>
          </Grid>
        </div>
      </Fragment>
    );
  }
}

export default AddMedicationForm;
