import { useState, useEffect, useMemo, Fragment } from 'react';
import { useQuery } from '@apollo/client';
import { useParams } from 'react-router-dom';
/** @jsx jsx */
import { jsx, css } from '@emotion/core';
import PatientDetails from 'components/PatientDetails';
import AddOrEditPatientForm from 'components/AddOrEditPatientForm';
import PatientTabs from 'components/PatientTabs';
import { GET_PATIENT_DETAILS, GET_MEDICATIONS_LIST } from 'utils/queries';
import GlobalStyles from 'assets/GlobalStyles';
import PatientTabSkeleton from '../components/Skeletons/PatientTabSkeleton';

function PatientDetailsPage({ isEditing }) {
  const { id } = useParams();

  const { data, loading } = useQuery(GET_PATIENT_DETAILS, {
    variables: { id },
  });

  const { data: medicationData } = useQuery(GET_MEDICATIONS_LIST);

  const patient = useMemo(() => (data ? data.patient : {}), [data]);
  const [weightModal, setWeightModal] = useState(false);
  const [updatedWeightModal, setUpdatedWeightModal] = useState(false);
  const [medicationNames, setMedicationNames] = useState([]);

  useEffect(() => {
    const medicationNameArr = [];

    if (patient && patient.medications && patient.dosage) {
      for (let key in patient.dosage) {
        const medicationFind = patient.medications.find(({ id }) => {
          return id === JSON.parse(key);
        }).generic_name;

        medicationNameArr.push(medicationFind);
      }
    }

    setMedicationNames(medicationNameArr);
  }, [patient]);

  return (
    <Fragment>
      {loading ? (
        <PatientTabSkeleton id={id} />
      ) : (
        <PatientTabs id={id}>
          <div
            css={css`
              border: ${GlobalStyles.outerContainerBorder};
            `}
          >
            {isEditing ? (
              <AddOrEditPatientForm
                patient={patient}
                weightModal={weightModal}
                setWeightModal={setWeightModal}
                setUpdatedWeightModal={setUpdatedWeightModal}
                medicationData={medicationData}
                medicationNames={medicationNames}
              />
            ) : (
              <PatientDetails
                patient={patient}
                setWeightModal={setWeightModal}
                updatedWeightModal={updatedWeightModal}
                setUpdatedWeightModal={setUpdatedWeightModal}
                isEditing={isEditing}
                medicationNames={medicationNames}
              />
            )}
          </div>
        </PatientTabs>
      )}
    </Fragment>
  );
}

export default PatientDetailsPage;
