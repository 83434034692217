/** @jsx jsx */
import { jsx, css } from '@emotion/core';
import styled from '@emotion/styled';
import Skeleton from '@material-ui/lab/Skeleton';
import GlobalStyles from 'assets/GlobalStyles';
import Grid from '@material-ui/core/Grid';
import AppBar from '@material-ui/core/AppBar';

const StyledSkeleton = styled(Skeleton)`
  &.MuiSkeleton-root {
    border-radius: 20px;
    margin: 3%;
  }
`;

const PatientViewSkeleton = () => {
  return (
    <div
      css={css`
        background-color: ${GlobalStyles.white};
      `}
    >
      <AppBar
        position="static"
        css={css`
          grid-column: 1 / span 12;
          box-shadow: none;
          background-color: ${GlobalStyles.midBlue};
          display: grid;
          grid-template-columns: repeat(12, 1fr);
          column-gap: 4%;
          padding: 20px 20px 20px 100px;
        `}
      >
        {Array(6)
          .fill()
          .map((item, index) => (
            <Skeleton
              height={60}
              width={160}
              css={css`
                padding-left: 10px;
              `}
              key={index}
            />
          ))}
      </AppBar>
      <Grid className="clinical-team-skeleton">
        <div
          css={css`
            display: grid;
          `}
        >
          <div
            css={css`
              display: flex;
              align-items: center;
              flex-direction: column;
            `}
          >
            <Skeleton height={120} width={500} />
          </div>
          <div
            className="clinical-team-skeleton-body"
            css={css`
              padding: 0 10% 40px 10%;
              background-color: ${GlobalStyles.white};
              overflow-y: scroll;
            `}
          >
            <Grid
              container
              className="edit-profile-skeleton"
              direction="row"
              justify="space-between"
            >
              <Grid item xs={12} md={4}>
                <StyledSkeleton height={600} />
              </Grid>
              <Grid item xs={12} md={4}>
                <StyledSkeleton height={600} />
              </Grid>
              <Grid item xs={12} md={4}>
                <StyledSkeleton height={600} />
              </Grid>
            </Grid>
            <Grid
              container
              className="edit-profile-skeleton"
              direction="row"
              justify="space-between"
            >
              <Grid item xs={12} md={6}>
                <StyledSkeleton height={600} />
              </Grid>
              <Grid item xs={12} md={6}>
                <StyledSkeleton height={600} />
              </Grid>
            </Grid>
          </div>
        </div>
      </Grid>
    </div>
  );
};

export default PatientViewSkeleton;
