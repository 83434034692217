/** @jsx jsx */
import { jsx, css } from '@emotion/core';
import { Fragment, useContext, useEffect, useState } from 'react';
import { useParams, useHistory, Link } from 'react-router-dom';
import TextField from '@material-ui/core/TextField';
import { Button } from '@material-ui/core';
import styled from '@emotion/styled';
import CircularProgress from '@material-ui/core/CircularProgress';
import { useMutation, useQuery } from '@apollo/client';
import {
  UPDATE_PATIENT_PLAN_STEPS,
  CREATE_ACTION_PLAN_STEP,
  CREATE_ACTION_PLAN,
} from 'utils/mutations';
import {
  GET_MEDICATIONS_DETAILS_LIST,
  GET_PATIENT,
  GET_PATIENT_NON_HOSPITAL_PLAN,
  GET_PATIENT_LOG_DETAILS,
  GET_ADMINISTERED_DOSE_LOG,
} from 'utils/queries';
import { ACTION_PLAN_STEP } from 'utils/fragments';
import GlobalStyles from 'assets/GlobalStyles';
import MaximumDosageModal from 'components/MaximumDosageModal';
import Step2UpdatedModal from 'components/Step2UpdatedModal';
import NonHospitalPlanStepTwoAndThree from 'components/Steps/NonHospitalPlanStepTwoAndThree';
import { DEFAULT_VALUES } from 'components/Steps/constants';
import StepContext from 'components/Steps/StepContext';

function NonHospitalPlanStep({ step, stepNumber, isEditing, currentStep }) {
  const { id: patientId } = useParams();
  const history = useHistory();

  //state
  const { stepState, setStepState } = useContext(StepContext);
  const [
    generatingActionPlanStepLoading,
    setGeneratingActionPlanStepLoading,
  ] = useState(false);

  //queries/mutations
  const { data: medicationData, loading: medicationLoading } = useQuery(
    GET_MEDICATIONS_DETAILS_LIST
  );
  const { data: patientData, loading: patientLoading } = useQuery(GET_PATIENT, {
    variables: { id: patientId },
  });
  const [updateActionPlanStep] = useMutation(UPDATE_PATIENT_PLAN_STEPS, {
    update(cache, { data: { updateActionPlanStep } }) {
      const { actionPlanStep } = updateActionPlanStep;
      cache.writeFragment({
        id: cache.identify(actionPlanStep),
        fragment: ACTION_PLAN_STEP,
        data: actionPlanStep,
        fragmentName: 'ActionPlanStepFields',
      });
    },
    refetchQueries: [
      { query: GET_PATIENT_LOG_DETAILS, variables: { id: patientId } },
      { query: GET_ADMINISTERED_DOSE_LOG, variables: { id: patientId } },
    ],
    awaitRefetchQueries: true,
  });
  const [createActionPlanStep] = useMutation(CREATE_ACTION_PLAN_STEP, {
    refetchQueries: [
      { query: GET_PATIENT_NON_HOSPITAL_PLAN, variables: { id: patientId } },
      { query: GET_PATIENT_LOG_DETAILS, variables: { id: patientId } },
    ],
    awaitRefetchQueries: true,
  });
  const [createActionPlan] = useMutation(CREATE_ACTION_PLAN, {
    refetchQueries: [
      { query: GET_PATIENT_NON_HOSPITAL_PLAN, variables: { id: patientId } },
      { query: GET_PATIENT, variables: { id: patientId } },
      { query: GET_PATIENT_LOG_DETAILS, variables: { id: patientId } },
    ],
    awaitRefetchQueries: true,
  });

  const { data: nonHospitalPlan } = useQuery(GET_PATIENT_NON_HOSPITAL_PLAN, {
    variables: { id: patientId },
  });

  let step1Exists = false;
  let step2Exists = false;
  let step3Exists = false;
  let step4Exists = false;
  let step1Data;
  let step2Data;
  let step3Data;
  let step4Data;

  if (nonHospitalPlan.actionPlans.length) {
    nonHospitalPlan.actionPlans[0].action_plan_steps.map((planStep) => {
      if (planStep.step_number === 1) {
        step1Exists = true;
        step1Data = planStep;
      }
      if (planStep.step_number === 2) {
        step2Exists = true;
        step2Data = planStep;
      }
      if (planStep.step_number === 3) {
        step3Exists = true;
        step3Data = planStep;
      }
      if (planStep.step_number === 4) {
        step4Exists = true;
        step4Data = planStep;
      }
      return step2Data;
    });
  }

  useEffect(() => {
    let administered_dose = null;
    let calculated_dose = null;

    if (step) {
      if (
        patientData &&
        step.step_weight_last_updated < patientData.patient.weight_last_updated
      ) {
        calculated_dose =
          patientData.patient.weight * step.estimated_weight_dose_per_kg;
      } else {
        administered_dose = step.administered_dose;
        calculated_dose = step.calculated_dose;
      }
    }

    setStepState((currentState) => ({
      ...currentState,
      step: step,
      stepNumber: stepNumber,
      isEditing: isEditing,
      currentStep: currentStep,
      formFields: {
        step_number: step ? step.step_number : '',
        notes: step ? step.notes : '',
        total_time_in_minutes: step ? step.total_time_in_minutes : null,
        administered_dose,
        estimated_weight_dose_per_kg: step
          ? step.estimated_weight_dose_per_kg
          : null,
        calculated_dose,
        medication: step ? (step.medication ? step.medication.id : null) : null,
        length_of_current_seizure_time_in_minutes: step
          ? step.length_of_current_seizure_time_in_minutes
          : null,
        total_number_of_seizures: step ? step.total_number_of_seizures : null,
        based_on_patients_weight: step ? step.based_on_patients_weight : null,
        step_weight_last_updated: step ? step.step_weight_last_updated : null,
      },

      medicationData: medicationData,
      patientData: patientData,
    }));

    if (step2Exists && stepNumber === 3 && !step3Exists) {
      setStepState((currentState) => ({
        ...currentState,
        formFields: {
          ...currentState.formFields,
          length_of_current_seizure_time_in_minutes:
            step2Data.length_of_current_seizure_time_in_minutes,
          medication: step2Data.medication.id,
          administered_dose: step2Data.administered_dose,
        },
      }));
    }
  }, [
    setStepState,
    step,
    stepNumber,
    isEditing,
    currentStep,
    medicationData,
    patientData,
    step1Exists,
    step2Exists,
    step3Exists,
    step4Exists,
    step1Data,
    step2Data,
    step3Data,
    step4Data,
  ]);

  const {
    notes,
    total_time_in_minutes,
    administered_dose,
    length_of_current_seizure_time_in_minutes,
    total_number_of_seizures,
    based_on_patients_weight,
  } = stepState.formFields;

  const isEditingCurrentStep = () => {
    if (isEditing && currentStep === stepNumber.toString()) {
      return true;
    } else {
      return false;
    }
  };

  const handleCancel = async () => {
    await setStepState((currentState) => ({
      ...currentState,
      tempMedicationDisplay: null,
      formFields: {
        step_number: step ? step.step_number : '',
        notes: step ? step.notes : '',
        total_time_in_minutes: step ? step.total_time_in_minutes : null,
        administered_dose: step ? step.administered_dose : null,
        estimated_weight_dose_per_kg: step
          ? step.estimated_weight_dose_per_kg
          : null,
        calculated_dose: step ? step.calculated_dose : null,
        medication: step ? (step.medication ? step.medication.id : ' ') : '',
        length_of_current_seizure_time_in_minutes: step
          ? step.length_of_current_seizure_time_in_minutes
          : null,
        total_number_of_seizures: step ? step.total_number_of_seizures : null,
        based_on_patients_weight: step ? step.based_on_patients_weight : null,
        step_weight_last_updated: step ? step.step_weight_last_updated : null,
      },
    }));

    if (currentStep !== '1' && currentStep !== '4') {
      if (
        !checkIfCurrentValueExceedsMaxDosage(
          stepState.formFields.administered_dose
        )
      ) {
        await setStepState((currentState) => ({
          ...currentState,
          isExceedingMaxDoseConfirmed: false,
        }));
      }
    }
    history.push(`/patient/${patientId}/plan/nonhospital`);
  };

  const displayAdministeredDosage = () => {
    let displayValue;
    const tempMedicationDisplay = stepState.tempMedicationDisplay;
    if (tempMedicationDisplay || administered_dose) {
      if (tempMedicationDisplay) {
        displayValue = tempMedicationDisplay;
      } else if (administered_dose) displayValue = administered_dose;
    } else {
      displayValue = '';
    }
    return displayValue;
  };

  const displayInputColor = (fieldName) => {
    let backgroundColor;

    if (
      fieldName === 'administered_dose' &&
      stepState.isExceedingMaxDoseConfirmed
    ) {
      backgroundColor = GlobalStyles.formInputRed;
    } else if (
      (fieldName === 'administered_dose' ||
        fieldName === 'estimated_weight_dose_per_kg' ||
        fieldName === 'calculated_dose') &&
      isEditingCurrentStep() &&
      !stepState.formFields.medication
    ) {
      //administered dose and calulated dosage are disabled and disaply at gray untill a medication is selected
      backgroundColor = GlobalStyles.formInputGrey;
    } else {
      isEditingCurrentStep()
        ? (backgroundColor = GlobalStyles.formInputYellow)
        : (backgroundColor = GlobalStyles.formInputGrey);
    }
    return backgroundColor;
  };
  const findMaxDosage = (currentMedicationId) => {
    let object;
    medicationData.medications.map((medication) => {
      if (medication.id === currentMedicationId) {
        object = medication;
      }
      return object;
    });
    return object;
  };

  const dosageCalculations = (fieldChanged, value) => {
    const dosageCalcFormFields = { ...stepState.formFields };
    const patientsWeight = based_on_patients_weight;

    const clearAdminDoseField = () => {
      dosageCalcFormFields.administered_dose = null;
      setStepState((currentState) => ({
        ...currentState,
        isExceedingMaxDoseConfirmed: false,
        tempMedicationDisplay: null,
      }));
    };

    //convert value from string to num;
    let valueNum = +value;

    if (fieldChanged === 'administered_dose') {
      dosageCalcFormFields[fieldChanged] = valueNum;
      dosageCalcFormFields.calculated_dose = valueNum;
      dosageCalcFormFields.estimated_weight_dose_per_kg =
        valueNum / patientsWeight;
    } else if (fieldChanged === 'calculated_dose') {
      clearAdminDoseField();
      dosageCalcFormFields.calculated_dose = valueNum;
      dosageCalcFormFields.estimated_weight_dose_per_kg =
        valueNum / patientsWeight;
    } else {
      clearAdminDoseField();
      dosageCalcFormFields.estimated_weight_dose_per_kg = valueNum;
      dosageCalcFormFields.calculated_dose = valueNum * patientsWeight;
    }

    setStepState((currentState) => ({
      ...currentState,
      formFields: dosageCalcFormFields,
    }));
  };

  // Validation for dosage inputs
  const handleDosageChange = (key) => ({ target: { value } }) => {
    // Valid positive numbers incl decimals
    const validNum = /^[0-9]*\.?[0-9]*$/.test(value);
    if (!validNum) {
      return;
    }

    if (value.match(/[a-z]/i)) {
      stepState.formFields[key] = null;
      return;
    }

    if (key === 'administered_dose') {
      setStepState((currentState) => ({
        ...currentState,
        isExceedingMaxDoseConfirmed: false,
        tempMedicationDisplay: value,
      }));

      const currentMedicationId = stepState.formFields.medication;
      let currentMedObject = findMaxDosage(currentMedicationId);

      if (
        !currentMedObject.maximum_dosage ||
        currentMedObject.maximum_dosage >= value
      ) {
        dosageCalculations(key, value);
      } else {
        setStepState((currentState) => ({
          ...currentState,
          tempMedicationDisplay: value,
        }));
        dosageCalculations(key, value);
      }
    } else {
      dosageCalculations(key, value);
    }
  };

  const handleChange = (key) => ({ target: { value } }) => {
    let newFormFields = { ...stepState.formFields };

    if (key.includes('time_in_minutes') || key.includes('number')) {
      const valueNumber = value ? parseInt(value) : null;
      if (valueNumber < 0 || value === '-') {
        newFormFields[key] = null;
        return;
      }
      newFormFields[key] = valueNumber;
    } else {
      newFormFields[key] = value;
    }

    if (key === 'medication') {
      if (stepNumber === 2) {
        setStepState((currentState) => ({
          ...currentState,
          rescueMedicationNameIsUpdating: true,
        }));
      } else {
        setStepState((currentState) => ({
          ...currentState,
          rescueMedicationNameIsUpdating: false,
        }));
      }
      newFormFields.administered_dose = null;
      newFormFields.estimated_weight_dose_per_kg = null;
      newFormFields.calculated_dose = null;
      setStepState((currentState) => ({
        ...currentState,
        isExceedingMaxDoseConfirmed: false,
        tempMedicationDisplay: '',
      }));
    }
    setStepState((currentState) => ({
      ...currentState,
      formFields: newFormFields,
    }));
  };

  const checkAndSetDefaults = () => {
    if (!notes || notes === '') {
      if (currentStep === '1') {
        stepState.formFields.notes = DEFAULT_VALUES.STEP_1_TEXT;
      }
      if (currentStep === '4') {
        stepState.formFields.notes = DEFAULT_VALUES.STEP_4_TEXT;
      }
    }

    if (!total_number_of_seizures || total_number_of_seizures === '') {
      stepState.formFields.total_number_of_seizures =
        DEFAULT_VALUES.TOTAL_NUMBER_OF_SEIZURES;
    }

    if (!total_time_in_minutes || total_time_in_minutes === '') {
      stepState.formFields.total_time_in_minutes = DEFAULT_VALUES.TOTAL_TIME;
    }

    if (length_of_current_seizure_time_in_minutes === 0 && stepNumber === 2) {
      stepState.formFields.length_of_current_seizure_time_in_minutes = 0;
      return;
    }

    if (
      !length_of_current_seizure_time_in_minutes ||
      length_of_current_seizure_time_in_minutes === ''
    ) {
      if (stepNumber === 2) {
        stepState.formFields.length_of_current_seizure_time_in_minutes =
          DEFAULT_VALUES.LENGTH_OF_SEIZURE_STEP_2;
      } else if (stepNumber === 3) {
        stepState.formFields.length_of_current_seizure_time_in_minutes =
          DEFAULT_VALUES.LENGTH_OF_SEIZURE_STEP_3;
      }
    }
  };

  const handleUpdateSubmit = async (e) => {
    checkAndSetDefaults();
    console.log(nonHospitalPlan.actionPlans[0]);
    e.preventDefault();
    await setStepState((currentState) => ({
      ...currentState,
      actionPlanStepLoading: true,
    }));

    await updateActionPlanStep({
      variables: { id: step.id, data: stepState.formFields },
    })
      .then(async () => {
        if (currentStep === '2' && step3Exists) {
          const step3NewData = stepState.formFields;
          step3NewData.step_number = 3;
          await updateActionPlanStep({
            variables: {
              id: step3Data.id,
              data: step3NewData,
            },
          });
        }
      })
      .then(async () => {
        await setStepState((currentState) => ({
          ...currentState,
          actionPlanStepLoading: false,
          tempMedicationDisplay: null,
        }));

        history.push(`/patient/${patientId}/plan/nonhospital`);
        if (currentStep === '2' && step3Exists) {
          await setStepState((currentState) => ({
            ...currentState,
            openStep2UpdatedModal: true,
          }));
        }
      })
      .catch((error) => {
        console.log('ERROR', error);
      });
  };

  const createNewActionPlan = async () => {
    const newPlanData = {
      patient: patientId,
      category: 'nonHospital',
    };
    await setStepState((currentState) => ({
      ...currentState,
      actionPlanStepLoading: true,
    }));
    createActionPlan({
      variables: { data: newPlanData },
    })
      .then(async (response) => {
        stepState.formFields['action_plan'] =
          response.data.createActionPlan.actionPlan.id;
        // create step 1 for case if step 2 is created and there is no step 1 yet.
        // default text is from constants.js

        const generatedStep1 = {
          step_number: 1,
          notes: DEFAULT_VALUES.STEP_1_TEXT,
          action_plan: response.data.createActionPlan.actionPlan.id,
        };
        setGeneratingActionPlanStepLoading(true);

        if (currentStep === '2') {
          console.log('create2');
          try {
            await setStepState((currentState) => ({
              ...currentState,
              actionPlanStepLoading: true,
            }));
            await createActionPlanStep({
              variables: { data: generatedStep1 },
            }).then(async () => {
              await setStepState((currentState) => ({
                ...currentState,
                actionPlanStepLoading: false,
              }));
              history.push(`/patient/${patientId}/plan/nonhospital`);
            });
          } catch (error) {
            console.log('error', error);
          }
        }
        try {
          await setStepState((currentState) => ({
            ...currentState,
            actionPlanStepLoading: true,
          }));
          await createActionPlanStep({
            variables: { data: stepState.formFields },
          }).then(async () => {
            await setStepState((currentState) => ({
              ...currentState,
              actionPlanStepLoading: false,
            }));
            history.push(`/patient/${patientId}/plan/nonhospital`);
          });
        } catch (error) {
          console.log('error', error);
        }
      })
      .then(() => setGeneratingActionPlanStepLoading(false))
      .catch((error) => {
        console.log('error', error);
      });
  };

  const handleCreateSubmit = async (e) => {
    checkAndSetDefaults();

    e.preventDefault();
    stepState.formFields['step_number'] = stepNumber;

    if (patientData.patient.action_plans.length) {
      patientData.patient.action_plans.forEach(async (actionPlan) => {
        if (actionPlan.category === 'nonHospital') {
          stepState.formFields['action_plan'] = actionPlan.id;

          await setStepState((currentState) => ({
            ...currentState,
            actionPlanStepLoading: true,
          }));

          // if action plan exists with only step 4, when generating step 2 we add step one
          if (currentStep === '2' && step4Exists && !step1Exists) {
            const generatedStep1 = {
              step_number: 1,
              notes: DEFAULT_VALUES.STEP_1_TEXT,
              action_plan: nonHospitalPlan.actionPlans[0].id,
            };
            await createActionPlanStep({
              variables: { data: generatedStep1 },
            });
          }
          await createActionPlanStep({
            variables: { data: stepState.formFields },
          })
            .then(async () => {
              if (stepNumber)
                await setStepState((currentState) => ({
                  ...currentState,
                  actionPlanStepLoading: false,
                }));
              history.push(`/patient/${patientId}/plan/nonhospital`);
            })
            .catch((error) => {
              console.log('error', error);
            });
        } else {
          createNewActionPlan();
        }
      });
    } else {
      createNewActionPlan();
    }
  };

  const isLoading = stepState.actionPlanStepLoading;

  const checkIfCurrentValueExceedsMaxDosage = (value) => {
    const currentMedicationId = stepState.formFields.medication;
    let currentMedObject = findMaxDosage(currentMedicationId);

    if (!currentMedObject) {
      return false;
    } else {
      if (
        !currentMedObject.maximum_dosage ||
        currentMedObject.maximum_dosage >= value
      ) {
        return false;
      } else {
        return true;
      }
    }
  };

  const handleConfirmOfExceededDosageInModel = () => {
    dosageCalculations('administered_dose', stepState.tempMedicationDisplay);
    setStepState((currentState) => ({
      ...currentState,
      isExceedingMaxDoseConfirmed: true,
      openMaxDosageModal: false,
      tempMedicationDisplay: null,
    }));
  };

  const handleCancelOfExceededDosageInModel = () => {
    setStepState((currentState) => ({
      ...currentState,
      tempMedicationDisplay: null,
    }));
    setStepState((currentState) => ({
      ...currentState,
      formFields: {
        ...currentState.formFields,
        administered_dose: null,
        calculated_dose: null,
        estimated_weight_dose_per_kg: null,
      },
    }));

    //check if original dose reverting to exceededMaxDosage and disaply correct styling if so
    if (step) {
      if (checkIfCurrentValueExceedsMaxDosage(step.administered_dose)) {
        setStepState((currentState) => ({
          ...currentState,
          isExceedingMaxDoseConfirmed: true,
        }));
      }
      setStepState((currentState) => ({
        ...currentState,
        openMaxDosageModal: false,
      }));
    } else {
      setStepState((currentState) => ({
        ...currentState,
        openMaxDosageModal: false,
      }));
    }
  };

  const handleMaxDosageModal = async (e) => {
    if (e.relatedTarget && e.relatedTarget.id === 'cancel-step') {
      return;
    } else {
      const currentMedicationId = stepState.formFields.medication;
      let currentMedObject = findMaxDosage(currentMedicationId);

      //handle click to admin_dosage but don't update input
      if (!stepState.tempMedicationDisplay) {
        return;
      }

      if (
        checkIfCurrentValueExceedsMaxDosage(
          stepState.formFields.administered_dose
        )
      ) {
        await setStepState((currentState) => ({
          ...currentState,
          currentMedication: currentMedObject,
          openMaxDosageModal: true,
        }));
      }
    }
  };

  // step2Updated Modal
  const handleCloseOfStep2UpdatedModal = async () => {
    await setStepState((currentState) => ({
      ...currentState,
      openStep2UpdatedModal: false,
    }));
  };

  // CSS
  const sharedButtonStyling = css`
    width: 75%;
    border-radius: 0%;
    align-self: center;
    justify-self: right;
    font-family: 'roboto-black';
    font-size: 12px;
    height: 30px;
  `;

  const editStepButtonStyling = css`
    background-color: ${GlobalStyles.accentOrange};
    color: ${GlobalStyles.white};
    :hover {
      color: ${GlobalStyles.navyBlue};
      background-color: ${GlobalStyles.accentOrange};
    }
  `;

  const saveButtonStyling = css`
    background-color: ${GlobalStyles.accentOrange};
    color: ${!administered_dose && currentStep !== '1' && currentStep !== '4'
      ? GlobalStyles.disabledOrangeButtonText
      : GlobalStyles.white};

    ${currentStep === '1' || currentStep === '4' ? `align-self: end;` : null}
    :hover {
      color: ${!administered_dose && currentStep !== '1' && currentStep !== '4'
        ? GlobalStyles.disabledOrangeButtonText
        : GlobalStyles.navyBlue};
      background-color: ${GlobalStyles.accentOrange};
    }
  `;

  const cancelButtonStyling = css`
    background-color: ${GlobalStyles.red};
    color: ${GlobalStyles.white};
    ${currentStep === '1' || currentStep === '4' ? `align-self: end;` : null}
    :hover {
      color: ${GlobalStyles.navyBlue};
      background-color: ${GlobalStyles.red};
    }
  `;

  const duplicateStep2ButtonStyling = css`
    background-color: ${GlobalStyles.accentOrange};
    color: ${GlobalStyles.white};
    align-self: flex-start;
    margin: 20px 0;
    width: 40%;
    :hover {
      color: ${GlobalStyles.navyBlue};
      background-color: ${GlobalStyles.accentOrange};
    }
  `;

  const StyledCircularProgress = styled(CircularProgress)`
    .MuiCircularProgress-colorPrimary {
      color: ${GlobalStyles.navyBlue};
    }
  `;

  if (medicationLoading || patientLoading) {
    return <p>Loading...</p>;
  } else {
    return (
      <Fragment>
        <div
          className="NonHospitalPlanStep"
          css={css`
            grid-column: 1 / span 6;

            .MuiInputBase-input {
              color: ${GlobalStyles.navyBlue};
              padding: 6px 0 7px 10px;
              font-weight: bold;
            }
          `}
        >
          {stepNumber === 3 && step === undefined && (
            <Fragment>
              <Button
                onClick={handleCreateSubmit}
                css={[sharedButtonStyling, duplicateStep2ButtonStyling]}
                disabled={!step2Exists}
              >
                {isLoading ? (
                  <StyledCircularProgress size="24px" />
                ) : (
                  `DUPLICATE STEP 2`
                )}
              </Button>
            </Fragment>
          )}
          {stepNumber === 1 || stepNumber === 4 ? (
            <Fragment>
              <h4
                css={css`
                  margin-bottom: 12px;
                  font-family: 'roboto-black';
                  font-size: 16px;
                  min-width: 100px;
                `}
              >
                {stepNumber === 4 ? null : 'START OF SEIZURE'}
              </h4>
              {(isEditing && step && currentStep === stepNumber.toString()) ||
              !step ||
              !step.notes ? (
                <Fragment>
                  <TextField
                    data-cy={`edit-text-input-step-${stepNumber}`}
                    type="text"
                    placeholder={
                      isEditingCurrentStep()
                        ? stepNumber === 1
                          ? DEFAULT_VALUES.STEP_1_TEXT
                          : DEFAULT_VALUES.STEP_4_TEXT
                        : null
                    }
                    multiline
                    rows={3}
                    css={css`
                      width: 100%;
                      background-color: ${displayInputColor('notes')};
                      font-family: 'roboto-black';
                      font-size: 16px;
                      margin-top: 0px;
                    `}
                    disabled={
                      step
                        ? !step.notes && !isEditingCurrentStep()
                        : !step && !isEditingCurrentStep()
                    }
                    onChange={handleChange('notes')}
                    value={notes}
                  />
                </Fragment>
              ) : (
                <Fragment>
                  <p
                    data-cy={`read-text-input-step-${stepNumber}`}
                    css={css`
                      margin-top: 0px;
                      font-family: 'roboto-light';
                      font-size: 21px;
                    `}
                  >
                    {notes ? notes : ''}
                  </p>
                </Fragment>
              )}
            </Fragment>
          ) : (
            !(stepNumber === 3 && step === undefined) && (
              <NonHospitalPlanStepTwoAndThree
                handleUpdateSubmit={handleUpdateSubmit}
                handleCreateSubmit={handleCreateSubmit}
                displayInputColor={displayInputColor}
                isEditingCurrentStep={isEditingCurrentStep}
                handleChange={handleChange}
                handleDosageChange={handleDosageChange}
                displayAdministeredDosage={displayAdministeredDosage}
                handleOpen={handleMaxDosageModal}
              />
            )
          )}
        </div>

        <div
          className="NonHospitalPlanStep__ButtonsContainer"
          css={css`
            grid-column: 7 / span 2;
            display: grid;
            grid-template-columns: 1;
          `}
        >
          {isEditing && currentStep === stepNumber.toString() ? (
            <Fragment>
              <Button
                disabled={
                  administered_dose >= 0 &&
                  administered_dose < 0.005 &&
                  currentStep !== '1' &&
                  currentStep !== '4'
                }
                onClick={step ? handleUpdateSubmit : handleCreateSubmit}
                data-cy={`save-step-${stepNumber}`}
                css={[sharedButtonStyling, saveButtonStyling]}
              >
                {!isLoading ? 'Save' : <StyledCircularProgress size="24px" />}
              </Button>
              <Button
                onClick={handleCancel}
                id={'cancel-step'}
                data-cy={`cancel-step-${stepNumber}`}
                css={[sharedButtonStyling, cancelButtonStyling]}
              >
                CANCEL
              </Button>
            </Fragment>
          ) : (
            !(stepNumber === 3 && step === undefined) && (
              <Button
                component={Link}
                to={`/patient/${patientId}/plan/nonhospital/edit/${stepNumber}`}
                data-cy={`edit-step-${stepNumber}`}
                css={[sharedButtonStyling, editStepButtonStyling]}
              >
                {generatingActionPlanStepLoading ? (
                  <StyledCircularProgress size="24px" />
                ) : stepNumber === 4 ? (
                  `EDIT TEXT`
                ) : (
                  `EDIT STEP ${stepNumber}`
                )}
              </Button>
            )
          )}
        </div>
        <MaximumDosageModal
          cancelNewDosage={handleCancelOfExceededDosageInModel}
          confirmNewDosage={handleConfirmOfExceededDosageInModel}
        />

        <Step2UpdatedModal handleClose={handleCloseOfStep2UpdatedModal} />
      </Fragment>
    );
  }
}

export default NonHospitalPlanStep;
