/** @jsx jsx */
import { jsx, css } from '@emotion/core';
import styled from '@emotion/styled';
import { useState, Fragment, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import GlobalStyles from 'assets/GlobalStyles';
import { Button, TextField, CircularProgress, Link } from '@material-ui/core';
import { useApolloClient } from '@apollo/client';
import axios from 'axios';
import { CURRENT_USER } from 'utils/queries';
import { ValidatorForm } from 'react-material-ui-form-validator';

function LoginPage() {
  const client = useApolloClient();
  const history = useHistory();
  const [formFields, setFormFields] = useState({
    identifier: '',
    password: '',
  });
  const handleChange = (key) => ({ target: { value } }) => {
    setFormFields({ ...formFields, [key]: value });
  };
  const [error, setError] = useState();
  const [showLoginForm] = useState(true);
  const [success, setSuccess] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loginLoading, setLoginLoading] = useState(false);
  const [resettingPassword, setResettingPassword] = useState(false);
  const [passwordResetError, setPasswordResetError] = useState(false);
  const [helperText, setHelperText] = useState('');
  const [resettingPasswordForm, setResettingPasswordForm] = useState({
    email: '',
  });

  useEffect(() => {
    const timeout = setTimeout(() => {
      setHelperText('');
    }, 5000);

    return () => clearTimeout(timeout);
  }, [helperText]);

  const formStyling = css`
    display: flex;
    flex-direction: column;
    background-color: ${GlobalStyles.white};
    border: ${GlobalStyles.outerContainerBorder};
    padding: 70px 60px;
  `;

  const formTitleContainerStyling = css`
    border-bottom: 5px solid ${GlobalStyles.midBlue};
  `;

  const formTitleStyling = css`
    background-color: ${GlobalStyles.midBlue};
    padding: 5px 15px 0;
    width: fit-content;
    color: ${GlobalStyles.white};
    font-family: 'roboto-black';
    font-size: 22px;
  `;

  const inputTitleStyling = css`
    background-color: ${GlobalStyles.navyBlue2};
    padding: 5px 10px 0;
    width: fit-content;
    color: ${GlobalStyles.white};
    font-family: 'roboto-black';
    font-size: 14px;
    margin-top: 20px;
  `;

  const inputFieldStyling = css`
    width: 100%;
    border-top: 5px solid ${GlobalStyles.navyBlue2};

    background-color: ${GlobalStyles.paleOrange};
    .MuiInputBase-input {
      color: ${GlobalStyles.textBlue};
      font-size: 20px;
      font-weight: 500;
      letter-spacing: 1.25px;
      margin-left: 1%;
      margin-top: 7px;
    }
    .MuiFormHelperText-root.Mui-error {
      background-color: ${GlobalStyles.white};
      margin-top: 0;
    }
  `;

  const buttonStyling = css`
    background-color: ${GlobalStyles.accentOrange};
    color: ${GlobalStyles.white};
    font-weight: bolder;
    border-radius: 0px;
    font-size: 14px;
    letter-spacing: 1.25px;
    width: 25%;

    &:hover {
      color: ${GlobalStyles.navyBlue};
      background-color: ${GlobalStyles.accentOrange};
    }
  `;

  const successMsgStyling = css`
    font-size: 21px;
    font-weight: lighter;
    color: ${GlobalStyles.textBlue};
    font-family: roboto;
  `;

  const StyledCircularProgress = styled(CircularProgress)`
    .MuiCircularProgress-colorPrimary {
      color: ${GlobalStyles.navyBlue};
    }
  `;

  const handleBack = () => {
    window.location.reload();
  };

  const handleResetPassword = (key) => ({ target: { value } }) => {
    setResettingPasswordForm({ ...resettingPasswordForm, [key]: value });
  };

  const handleForgotPassword = async (e) => {
    e.preventDefault();
    setLoading(true);

    axios
      .post(`${process.env.REACT_APP_API_URL}/auth/forgot-password`, {
        email: resettingPasswordForm.email,
      })
      .then(() => {
        setSuccess(true);
        setLoading(false);
        console.log('Your user received an email');
      })
      .catch((error) => {
        if (
          error.response.data.message[0].messages[0].message !==
          'This email does not exist.'
        ) {
          setSuccess(false);
          setLoading(false);
          setHelperText('Email is not valid');
          console.log('An error occurred:', error.response);
          setPasswordResetError(
            error.response.data.message[0].messages[0].message
          );
        } else {
          setSuccess(true);
          setLoading(false);
          console.log('Your user received an email');
        }
      });
  };

  const handleLogin = async (e) => {
    e.preventDefault();
    setLoginLoading(true);

    axios
      .post(`${process.env.REACT_APP_API_URL}/auth/local`, {
        identifier: formFields.identifier,
        password: formFields.password,
      })
      .then((response) => {
        const token = response.data.jwt;
        const role = response.data.user.role.type;
        const user_id = response.data.user.id;
        //set token and role in localStorage to persist after refresh
        localStorage.setItem('token', token);
        localStorage.setItem('role', role);
        localStorage.setItem('user_id', user_id);
        //write query to set info in cache on first login (which does not appear when set local storage only - both are needed)
        client.cache.writeQuery({
          query: CURRENT_USER,
          data: {
            role: response.data.user.role.type,
          },
        });
        setLoginLoading(false);
        history.push('/patient-list');
      })
      .catch((error) => {
        setLoginLoading(false);
        console.log(
          'Error message',
          error.response.data.message[0].messages[0].message
        );
        setError(error.response.data.message[0].messages[0].message);
      });
  };

  return (
    <ValidatorForm onSubmit={handleLogin} css={formStyling}>
      <div css={formTitleContainerStyling}>
        <div css={formTitleStyling}>
          {resettingPassword || success
            ? 'FORGOT PASSWORD'
            : showLoginForm && 'LOGIN'}
        </div>
      </div>

      {/* form content  */}
      {resettingPassword ? (
        <Fragment>
          {success ? (
            <p css={successMsgStyling}>
              Success! If your email is registered you will receive an email
              shortly.
            </p>
          ) : (
            <Fragment>
              <div className="inputTitle" css={inputTitleStyling}>
                EMAIL*
              </div>
              <TextField
                onChange={handleResetPassword('email')}
                error={passwordResetError && !success}
                helperText={helperText}
                css={inputFieldStyling}
              />
            </Fragment>
          )}
          {
            <div
              css={css`
                margin-top: 50px;
                display: flex;
                justify-content: flex-end;
              `}
            >
              {success ? (
                <Button onClick={handleBack} css={buttonStyling}>
                  {!loading ? 'Back' : <StyledCircularProgress size="28px" />}
                </Button>
              ) : (
                <Button onClick={handleForgotPassword} css={buttonStyling}>
                  {!loading ? (
                    'Send reset password link'
                  ) : (
                    <StyledCircularProgress size="28px" />
                  )}
                </Button>
              )}{' '}
            </div>
          }
        </Fragment>
      ) : (
        showLoginForm && (
          <Fragment>
            <div className="inputTitle" css={inputTitleStyling}>
              USERNAME / EMAIL*
            </div>
            <TextField
              type="text"
              css={inputFieldStyling}
              data-cy="login__username"
              onChange={handleChange('identifier')}
              error={error && true}
              helperText={error ? 'Your password or username is incorrect' : ''}
            />

            <div className="inputTitle" css={inputTitleStyling}>
              PASSWORD*
            </div>
            <TextField
              css={inputFieldStyling}
              data-cy="login__password"
              type="password"
              onChange={handleChange('password')}
              error={error && true}
              helperText={error ? 'Your password or username is incorrect' : ''}
            />

            <div
              css={css`
                display: flex;
                margin-top: 50px;
                justify-content: space-between;
                align-items: center;
              `}
            >
              <Link
                onClick={() => setResettingPassword(!resettingPassword)}
                css={css`
                  color: ${GlobalStyles.navyBlue};
                  text-decoration: underline;
                  &:hover {
                    cursor: pointer;
                  }
                `}
              >
                Forgot Password
              </Link>

              <Button
                data-cy="login__login-button"
                type="submit"
                css={buttonStyling}
              >
                {!loginLoading ? (
                  'Login'
                ) : (
                  <StyledCircularProgress size="28px" />
                )}
              </Button>
            </div>
          </Fragment>
        )
      )}
    </ValidatorForm>
  );
}

export default LoginPage;
