/** @jsx jsx */
import { jsx, css } from '@emotion/core';
import { useApolloClient } from '@apollo/client';
import { useParams } from 'react-router-dom';
import GlobalStyles from 'assets/GlobalStyles';
import { GET_PATIENT_HOSPITAL_PLAN } from 'utils/queries';
import NonHospitalPlanStep from 'components/Steps/NonHospitalPlanStep';
import HospitalPlanStep from 'components/Steps/HospitalPlanStep';
import { StepProvider } from 'components/Steps/StepContext';

function PlanStepContainer({
  step,
  isEditing,
  stepNumber,
  planType,
  isErrorMessageDisplayed,
  setIsErrorMessageDisplayed,
}) {
  const client = useApolloClient();
  const { id: patientId, currentStep } = useParams();

  const { actionPlans: hospitalSteps } = client.cache.readQuery({
    query: GET_PATIENT_HOSPITAL_PLAN,
    variables: { id: patientId },
  });
  let isPlanShowing = hospitalSteps[0]
    ? hospitalSteps[0].show_epilepticus_plan
    : true;

  return (
    <StepProvider>
      <div
        className="planStepContainer"
        key={stepNumber}
        css={css`
          margin-top: 40px;
          padding: 0px 50px 0px 0px;
        `}
      >
        <div
          className="planStepContainer__stepTitle"
          css={css`
            display: flex;
            border-bottom: 5px solid ${GlobalStyles.midBlue};
            margin-right: 6%;
          `}
        >
          {planType === 'hospital' && (
            <div
              className="planStepContainer__stepTitle"
              css={css`
                width: fit-content;
                background-color: ${GlobalStyles.midBlue};
                color: ${GlobalStyles.white};
                font-family: 'roboto-black';
                font-size: 14px;
                padding: 5px 10px;
                margin-bottom: -1px;
                margin-right: 5px;
              `}
            >
              {stepNumber === 2
                ? `(OPTIONAL) TEXT FIELD`
                : `STEP ${stepNumber}`}
            </div>
          )}
          {planType === 'non-hospital' && (
            <div
              className="planStepContainer__stepTitle"
              css={css`
                width: fit-content;
                background-color: ${GlobalStyles.midBlue};
                color: ${GlobalStyles.white};
                font-family: 'roboto-black';
                font-size: 14px;
                padding: 5px 10px;
                margin-bottom: -1px;
                margin-right: 5px;
              `}
            >
              {stepNumber === 3 && !step && '(OPTIONAL) '}{' '}
              {stepNumber === 4
                ? `(OPTIONAL) TEXT FIELD`
                : `STEP ${stepNumber}`}
            </div>
          )}
          {!isPlanShowing && stepNumber === 1 && planType === 'hospital' && (
            <p
              css={css`
                color: ${GlobalStyles.disabledGrey};
                margin: unset;
                font-family: 'roboto-black';
                font-size: 14px;
              `}
            >{`(NOT DISPLAYED ON PATIENT'S PLAN)`}</p>
          )}
        </div>

        <div
          className="planStepContainer__stepBody"
          css={css`
            display: grid;
            grid-template-columns: repeat(8, 1fr);
            grid-template-rows: 1;
            column-gap: ${GlobalStyles.columnGap};
            margin-right: 6%;
          `}
        >
          {planType === 'non-hospital' ? (
            step ? (
              <NonHospitalPlanStep
                key={stepNumber}
                step={step}
                stepNumber={stepNumber}
                isEditing={isEditing}
                currentStep={currentStep}
              />
            ) : (
              <NonHospitalPlanStep
                key={stepNumber}
                stepNumber={stepNumber}
                isEditing={isEditing}
                currentStep={currentStep}
              />
            )
          ) : step ? (
            <HospitalPlanStep
              key={stepNumber}
              step={step}
              stepNumber={stepNumber}
              isEditing={isEditing}
              currentStep={currentStep}
              isErrorMessageDisplayed={isErrorMessageDisplayed}
              setIsErrorMessageDisplayed={setIsErrorMessageDisplayed}
            />
          ) : (
            <HospitalPlanStep
              key={stepNumber}
              stepNumber={stepNumber}
              isEditing={isEditing}
              currentStep={currentStep}
              isErrorMessageDisplayed={isErrorMessageDisplayed}
              setIsErrorMessageDisplayed={setIsErrorMessageDisplayed}
            />
          )}
        </div>
      </div>
    </StepProvider>
  );
}

export default PlanStepContainer;
