/** @jsx jsx */
import { jsx, css } from '@emotion/core';
import { useState } from 'react';
import {
  makeStyles,
  Button,
  Card,
  Box,
  Grid,
  CircularProgress,
} from '@material-ui/core';
import GlobalStyles from 'assets/GlobalStyles';
import handleDownload from 'components/PatientView/utils/handleDownload.js';

const textFieldStyle = css`
  color: ${GlobalStyles.black};
  font-family: 'Poppins-Medium';
  font-size: 14px;
  letter-spacing: 1.25px;
  line-height: 21px;
  margin-left: 7.5%;
  margin-block-start: 0;
  @media (max-width: ${GlobalStyles.mobileBreakpoint}) {
    margin-left: 0;
  }
`;

const textStyle = css`
  color: ${GlobalStyles.darkBlue};
  font-family: 'poppins-medium';
  font-size: 20px;
  line-height: 30px;
  margin-block-start: 0;
  margin-block-end: 0;
  padding-top: 1%;
  padding-bottom: 3%;
  margin-left: 7.5%;
  text-align: left;
  @media (max-width: ${GlobalStyles.mobileBreakpoint}) {
    font-size: 14px;
    line-height: 21px;
    text-align: left;
    margin-left: 0;
  }
`;

//ROOT
const useStyles = makeStyles(() => ({
  root: {
    width: '41vw',
    minHeight: '40vh',
    backgroundColor: `${GlobalStyles.paleBlueCardBackground}`,
    borderRadius: '30px',
    paddingTop: '3%',
    marginBottom: '3%',

    '@media (max-width: 960px)': {
      minWidth: '90vw',
      minHeight: '15vh',
      marginRight: '-10%',
      paddingLeft: '33px',
      paddingTop: '24px',
      paddingBottom: '5%',
    },
  },
  whiteButton: {
    color: `${GlobalStyles.whiteButton}`,
    border: `2px solid ${GlobalStyles.accentOrange}`,
    fontFamily: 'poppins-semi-bold',
    fontSize: '18px',
    padding: '2% 5%',
    borderRadius: '12px',
    backgroundColor: `${GlobalStyles.white}`,
    display: 'flex',
    margin: 'auto 17px',
    marginBottom: '1rem',
    minWidth: '50%',
    '@media (max-width: 960px)': {
      minWidth: '90%',
      fontSize: '16px',
      marginRight: '0%',
      marginLeft: '0%',
      marginBottom: '3%',
      maxWidth: '90%',
    },
  },
}));

export default function BlueCardWithButton({
  title,
  buttonLabel,
  buttonMedia,
  buttonLabel2,
  buttonMedia2,
  isMobile,
  textField,
  patient,
  text,
  openHospPDF,
}) {
  const [pngLoading, setPNGLoading] = useState(false);
  const [pdfLoading, setPDFLoading] = useState(false);

  const handleMail = () => {
    return (window.location.href =
      'mailto:' +
      encodeURIComponent('') +
      '?subject=' +
      encodeURIComponent(`${patient.nickname}'s Seizure Action Plan`) +
      '&body=' +
      encodeURIComponent(
        `You can find ${patient.nickname}'s Seizure Action Plan here:\nhttps://sap.bcchdigital.ca/patient-view/${patient.id}\n\nIf you require offline access to ${patient.nickname}'s Seizure Action Plan, you can also download a PDF or PNG here:\nhttp://sap.bcchdigital.ca/patient-view/${patient.id}/download-plan`
      ));
  };

  const classes = useStyles();
  return (
    <Card className={classes.root}>
      <div
        className="BlueCardWithButton__title-container"
        css={css`
          margin-left: 7.5%;
          @media (max-width: ${GlobalStyles.mobileBreakpoint}) {
            margin-left: 0;
          }
        `}
      >
        <h3
          css={css`
            color: ${GlobalStyles.midBlue};
            font-family: 'poppins-bold';
            font-size: 24px;
            line-height: 16px;
            margin-block-start: 0;
            margin-block-end: 0;
            @media (max-width: ${GlobalStyles.mobileBreakpoint}) {
              font-size: 16px;
            }

            /* The styling below is for the horizontal line on the right side of the title */

            width: 100%;
            text-align: left;
            border-bottom: 3px solid ${GlobalStyles.midBlue};
            line-height: 0.1em;
            margin: 10px 0 20px;

            span {
              background: ${GlobalStyles.paleBlueCardBackground};
              padding: 0 10px 0 0;
            }
          `}
        >
          <span>{title.toUpperCase()}</span>
        </h3>
      </div>
      {isMobile ? (
        <Grid
          container
          item
          css={css`
            padding-right: 5%;
          `}
        >
          {/* need to point hrefs to valid values */}
          {text ? <div css={textStyle}>{text}</div> : null}
          <Button
            className={classes.whiteButton}
            onClick={
              title === 'OUT-OF-HOSPITAL PLAN'
                ? handleDownload(
                    buttonMedia,
                    patient,
                    setPDFLoading,
                    setPNGLoading
                  )
                : title === 'EMAIL THIS PLAN'
                ? () => handleMail()
                : null
            }
            // this is mobile downloads
            href={title === 'HOSPITAL PLAN' ? openHospPDF : ''}
            target="_blank"
          >
            {!pdfLoading ? (
              buttonLabel
            ) : (
              <CircularProgress
                size="28px"
                css={css`
                  .MuiCircularProgress-colorPrimary {
                    color: ${GlobalStyles.navyBlue};
                  }
                `}
              />
            )}
          </Button>
          <Grid container item>
            {textField ? <p css={textFieldStyle}>{textField}</p> : null}
          </Grid>
          {buttonLabel2 && (
            <Button
              href={title === 'HOSPITAL PLAN' ? openHospPDF : ''}
              target="_blank"
              className={classes.whiteButton}
              onClick={
                title === 'OUT-OF-HOSPITAL PLAN'
                  ? handleDownload(
                      buttonMedia2,
                      patient,
                      setPDFLoading,
                      setPNGLoading
                    )
                  : null
              }
            >
              {!pngLoading ? (
                buttonLabel2
              ) : (
                <CircularProgress
                  size="28px"
                  css={css`
                    .MuiCircularProgress-colorPrimary {
                      color: ${GlobalStyles.navyBlue};
                    }
                  `}
                />
              )}
            </Button>
          )}
        </Grid>
      ) : (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          minHeight="25vh"
        >
          <Grid
            container
            item
            justify="center"
            alignItems="center"
            css={css`
              min-height: 16vh;
            `}
          >
            {text ? <div css={textStyle}>{text}</div> : null}
            {textField ? (
              <div
                css={css`
                  display: flex;
                  flex-direction: column;
                  width: 100%;
                  margin-top: 2.5%;
                `}
              >
                <Button
                  css={css`
                    text-align: center;
                    margin: auto;
                    width: fit-content;
                  `}
                  className={classes.whiteButton}
                >
                  {buttonLabel}
                </Button>
                <p
                  css={css`
                    color: ${GlobalStyles.darkBlue};
                    margin-left: 7.5%;
                    font-family: 'poppins-medium';
                    font-size: 20px;
                    line-height: 30px;
                    margin-block-start: 0;
                    margin-block-end: 0;
                    padding-top: 10%;
                    margin-left: 7.5%;
                    text-align: left;
                    @media (max-width: ${GlobalStyles.mobileBreakpoint}) {
                      margin-left: 0;
                      padding-top: 1%;
                      font-size: 14px;
                      line-height: 21px;
                    }
                  `}
                >
                  {textField}
                </p>
              </div>
            ) : (
              <Button
                className={classes.whiteButton}
                css={css`
                  ${title === 'OUT-OF-HOSPITAL PLAN' ? 'min-width: 35%;' : ''}
                `}
                onClick={
                  title === 'OUT-OF-HOSPITAL PLAN'
                    ? handleDownload(
                        buttonMedia,
                        patient,
                        setPDFLoading,
                        setPNGLoading
                      )
                    : title === 'EMAIL THIS PLAN'
                    ? () => handleMail()
                    : null
                }
              >
                {!pdfLoading ? (
                  buttonLabel
                ) : (
                  <CircularProgress
                    size="28px"
                    css={css`
                      .MuiCircularProgress-colorPrimary {
                        color: ${GlobalStyles.navyBlue};
                      }
                    `}
                  />
                )}
              </Button>
            )}

            {buttonLabel2 ? (
              <Button
                className={classes.whiteButton}
                css={css`
                  ${title === 'OUT-OF-HOSPITAL PLAN' ? 'min-width: 35%;' : ''}
                `}
                href={title === 'HOSPITAL PLAN' ? openHospPDF : ''}
                target="_blank"
                onClick={
                  title === 'OUT-OF-HOSPITAL PLAN'
                    ? handleDownload(
                        buttonMedia2,
                        patient,
                        setPDFLoading,
                        setPNGLoading
                      )
                    : null
                }
              >
                {!pngLoading ? (
                  buttonLabel2
                ) : (
                  <CircularProgress
                    size="28px"
                    css={css`
                      .MuiCircularProgress-colorPrimary {
                        color: ${GlobalStyles.navyBlue};
                      }
                    `}
                  />
                )}
              </Button>
            ) : null}
          </Grid>
        </Box>
      )}
    </Card>
  );
}
