/** @jsx jsx */
import { jsx, css } from '@emotion/core';
import Skeleton from '@material-ui/lab/Skeleton';
import GlobalStyles from 'assets/GlobalStyles';
import Grid from '@material-ui/core/Grid';

const DatabaseSkeleton = () => {
  return (
    <Grid className="database-skeleton">
      <div
        className="database-skeleton-title"
        css={css`
          padding: 7px 18px 7px 18px;
          background-color: ${GlobalStyles.midBlue};
          display: inline-block;
        `}
      >
        <Skeleton height={30} width={200} />
      </div>
      <div
        css={css`
          display: grid;
          grid-template-columns: auto 1fr;
        `}
      >
        <div
          className="database-skeleton-body"
          css={css`
            height: 50vh;
            padding: 40px 40px 40px 40px;
            border-top: ${GlobalStyles.outerContainerBorder};
            border-right: ${GlobalStyles.outerContainerBorder};
            border-bottom: ${GlobalStyles.outerContainerBorder};
            border-left: ${GlobalStyles.outerContainerBorder};

            background-color: ${GlobalStyles.white};
            overflow-y: scroll;
          `}
        >
          <Skeleton height={40} width={'77vw'} />
          {Array(7)
            .fill()
            .map((item, index) => (
              <Skeleton height={118} width={'77vw'} key={index} />
            ))}
        </div>
      </div>
    </Grid>
  );
};

export default DatabaseSkeleton;
