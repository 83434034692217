/** @jsx jsx */
import { jsx, css } from '@emotion/core';
import GlobalStyles from 'assets/GlobalStyles';
import BlueCard from 'components/PatientView/BlueCard.js';
import BlueCardWithButton from 'components/PatientView/BlueCardWithButton.js';
import { useMediaQuery, Grid, Button, makeStyles } from '@material-ui/core';
import { useQuery } from '@apollo/client';
import { GET_PATIENT_HOSPITAL_PLAN } from 'utils/queries';
import MobileAppHeader from 'components/PatientView/MobileAppHeader';
import MobilePlanButton from 'components/PatientView/MobilePlanButton';
import { useParams } from 'react-router-dom';

// yellow button styling
const useStyles = makeStyles(() => ({
  yellowButton: {
    color: `${GlobalStyles.whiteButton}`,
    backgroundColor: `${GlobalStyles.accentOrange}`,
    border: ` 2px solid ${GlobalStyles.accentOrange}`,
    fontFamily: 'poppins-semi-bold',
    fontSize: '18px',
    padding: '1% 4%',
    borderRadius: '12px',
    '&:hover': {
      background: `${GlobalStyles.mutedOrange}`,
    },
    minWidth: '26vw',
  },
}));

function HospitalPlan({ patient }) {
  const isMobile = useMediaQuery(
    `(max-width:${GlobalStyles.mobileBreakpoint})`
  );

  // patient id
  const { id: patientId } = useParams();

  const classes = useStyles();
  const { data: hospitalPlanData } = useQuery(GET_PATIENT_HOSPITAL_PLAN, {
    variables: { id: patient.id },
  });

  const checkForHospitalPlan = () => {
    if (!hospitalPlanData) {
      return 'no plan';
    } else if (!hospitalPlanData.actionPlans[0]) {
      return 'no plan';
    } else if (!hospitalPlanData.actionPlans[0].show_epilepticus_plan) {
      return 'no epilepticus plan';
    } else if (hospitalPlanData.actionPlans[0].show_epilepticus_plan) {
      return 'epilepticus plan';
    }
  };

  return (
    <div
      className="HospitalPlan"
      css={css`
        margin-top: 10%;
        padding: 0 6%;
        overflow: hidden;

        @media (max-width: ${GlobalStyles.mobileBreakpoint}) {
          padding: 0 0 3% 10%;
          margin-top: 0;
        }
      `}
    >
      {isMobile && <MobileAppHeader patient={patient} />}
      <div
        css={css`
          font-family: 'roboto-bold';
          font-size: 30px;
          line-height: 35px;
          color: ${GlobalStyles.darkBlue};
          margin-bottom: 3%;
          text-align: center;
          @media (max-width: ${GlobalStyles.mobileBreakpoint}) {
            margin-left: 20%;
            text-align: left;
            margin-bottom: 5%;
            font-size: 18px;
            line-height: 21px;
            position: relative;
            z-index: 0;
          }
        `}
      >
        {isMobile ? (
          'Hospital Plan'
        ) : (
          <p>
            {patient.nickname}'s{' '}
            <span
              css={css`
                color: ${GlobalStyles.midBlue};
              `}
            >
              Hospital Plan
            </span>
          </p>
        )}
      </div>
      {isMobile ? (
        //MOBILE
        <Grid container justify="space-between">
          <Grid
            item
            xs={12}
            css={css`
              margin-top: 10%;
              overflow: hidden;
              position: relative;
              z-index: 0;
            `}
          >
            {checkForHospitalPlan() === 'no plan' && (
              <BlueCardWithButton
                title={'HOSPITAL PLAN'}
                buttonLabel={
                  <a
                    href={'/files/hospital_plan.pdf'}
                    download="Status-epilepticus-plan.pdf"
                    css={css`
                      text-decoration: none;
                      :visited {
                        color: ${GlobalStyles.whiteButton};
                      }
                    `}
                  >
                    Download Plan
                    <br />
                    <span
                      css={css`
                        font-size: 14px;
                      `}
                    >
                      (Rev. Mar 2017 - CHBC)
                    </span>
                  </a>
                }
                isMobile={isMobile}
              />
            )}
            {checkForHospitalPlan() === 'no epilepticus plan' && (
              <BlueCard
                title={'HOSPITAL PLAN'}
                isMobile={isMobile}
                text={
                  hospitalPlanData.actionPlans[0].action_plan_steps[0]
                    .text_field
                }
              />
            )}
            {checkForHospitalPlan() === 'epilepticus plan' && (
              <BlueCardWithButton
                title={'HOSPITAL PLAN'}
                buttonLabel={
                  <a
                    href={'/files/hospital_plan.pdf'}
                    download="Status-epilepticus-plan.pdf"
                    css={css`
                      text-decoration: none;
                      :visited {
                        color: ${GlobalStyles.whiteButton};
                      }
                    `}
                  >
                    Download Plan
                    <br />
                    <span
                      css={css`
                        font-size: 14px;
                      `}
                    >
                      (Rev. Mar 2017 - CHBC)
                    </span>
                  </a>
                }
                isMobile={isMobile}
                textField={
                  hospitalPlanData.actionPlans[0].action_plan_steps[0]
                    .text_field
                }
              />
            )}
          </Grid>
          <Grid
            item
            xs={12}
            css={css`
              margin-top: 5%;
            `}
          >
            <BlueCard
              title={'Allergies'}
              text={patient.allergies ? patient.allergies : 'NONE'}
              isMobile={isMobile}
            />
            <div
              css={css`
                margin: 10% -22% 0 0;
                padding-left: 15%;
                /* for iPhone text and arrow to fit */
                @media (max-width: ${GlobalStyles.xsMobileBreakpoint}) {
                  padding-left: 12%;
                }
              `}
            >
              <MobilePlanButton
                to={`/patient-view/${patientId}/download-plan`}
                buttonlabel={'share this plan'}
                title={'share-hospital-plan-button'}
                contentdirection={'right'}
              />
            </div>
          </Grid>
        </Grid>
      ) : (
        //DESKTOP
        <Grid container justify="space-between">
          {checkForHospitalPlan() === 'no plan' && (
            <BlueCardWithButton
              title={'HOSPITAL PLAN'}
              buttonLabel={
                <a
                  href={'/files/hospital_plan.pdf'}
                  download="Status-epilepticus-plan.pdf"
                  css={css`
                    text-decoration: none;
                    :visited {
                      color: ${GlobalStyles.whiteButton};
                    }
                  `}
                >
                  Download Plan
                  <br />
                  <span
                    css={css`
                      font-size: 14px;
                    `}
                  >
                    (Rev. Mar 2017 - CHBC)
                  </span>
                </a>
              }
            />
          )}
          {checkForHospitalPlan() === 'epilepticus plan' && (
            <BlueCardWithButton
              title={'HOSPITAL PLAN'}
              buttonLabel={
                <a
                  href={'/files/hospital_plan.pdf'}
                  download="Status-epilepticus-plan.pdf"
                  css={css`
                    text-decoration: none;
                    :visited {
                      color: ${GlobalStyles.whiteButton};
                    }
                  `}
                >
                  Download Plan
                  <br />
                  <span
                    css={css`
                      font-size: 14px;
                    `}
                  >
                    (Rev. Mar 2017 - CHBC)
                  </span>
                </a>
              }
              textField={
                hospitalPlanData.actionPlans[0].action_plan_steps[0].text_field
              }
            />
          )}
          {checkForHospitalPlan() === 'no epilepticus plan' && (
            <BlueCard
              title={'HOSPITAL PLAN'}
              text={
                hospitalPlanData.actionPlans[0].action_plan_steps[0].text_field
              }
            />
          )}

          <BlueCard
            title={'Allergies'}
            text={patient.allergies ? patient.allergies : 'NONE'}
            isMobile={isMobile}
          />
        </Grid>
      )}
      {!isMobile && (
        <Grid
          container
          css={css`
            padding: 4% 0;
          `}
          justify="flex-end"
        >
          <a
            href="#patientViewNonHospitalPlan"
            css={css`
              text-decoration: none;
            `}
          >
            <Button className={classes.yellowButton}>
              {`Go to ${patient.nickname}'s Out-Of-Hospital Plan`}
            </Button>
          </a>
        </Grid>
      )}
    </div>
  );
}

export default HospitalPlan;
