/** @jsx jsx */
import { css, jsx } from '@emotion/core';
import { Fragment } from 'react';
import GlobalStyles from 'assets/GlobalStyles';
import { NavLink } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { GET_PATIENT_NAMES } from 'utils/queries';

//Loading Skeleton
import PatientTabSkeleton from '../components/Skeletons/PatientTabSkeleton';

const patientTabs__tabTitleStyling = css`
  display: grid;
  align-self: center;
`;

const patientTabs__navLinkStyling = css`
  display: flex;
  cursor: pointer;
  text-decoration: none;
  justify-content: center;
  font-family: 'poppins-medium';
  color: ${GlobalStyles.midBlue};
  font-size: 20px;
`;

const activeTabStyle = {
  backgroundColor: `${GlobalStyles.white}`,
  height: '50px',
  borderTop: `${GlobalStyles.outerContainerBorder}`,
  borderLeft: `${GlobalStyles.outerContainerBorder}`,
  borderRight: `${GlobalStyles.outerContainerBorder}`,
  borderBottom: `${GlobalStyles.tabBottomBorder}`,
  borderWidth: '7px',
  color: `${GlobalStyles.navyBlue2}`,
  marginTop: '5px',
  paddingBottom: '12px',
};

function PatientTabs({ id, children }) {
  const location = window.location.href;

  const { data: patientData, loading: patientLoading } = useQuery(
    GET_PATIENT_NAMES,
    {
      variables: { id },
    }
  );

  if (patientLoading) {
    return <PatientTabSkeleton />;
  }
  return (
    <div className="PatientTabs">
      <div
        css={css`
          display: grid;
          grid-template-columns: repeat(4, 1fr);
          column-gap: ${GlobalStyles.columnGap};
          text-align: center;
          align-content: center;
          color: ${GlobalStyles.midBlue};
          text-decoration: none;
          margin: 0 auto;
          height: 60px;
        `}
      >
        <NavLink
          className="PatientTabs__tab"
          to={id === 'new' ? '#' : `/patient/${id}/details`}
          activeStyle={activeTabStyle}
          css={patientTabs__navLinkStyling}
        >
          <span css={patientTabs__tabTitleStyling}>
            {patientData
              ? `${patientData.patient.nickname.toUpperCase()}'S DETAILS`
              : "PATIENT'S DETAILS"}
          </span>
        </NavLink>
        {!location.includes('new') && (
          <Fragment>
            <NavLink
              className="PatientTabs__tab"
              to={id === 'new' ? '#' : `/patient/${id}/plan/nonhospital`}
              isActive={(match, location) => {
                return match || location.pathname.includes('plan');
              }}
              activeStyle={activeTabStyle}
              css={patientTabs__navLinkStyling}
            >
              <span css={patientTabs__tabTitleStyling}>
                {`${patientData.patient.nickname.toUpperCase()}'S PLAN`}
              </span>
            </NavLink>
            <NavLink
              className="PatientTabs__tab"
              to={id === 'new' ? '#' : `/patient/${id}/log`}
              isActive={(match) => {
                if (match) {
                  return true;
                }
              }}
              activeStyle={activeTabStyle}
              css={patientTabs__navLinkStyling}
            >
              <span css={patientTabs__tabTitleStyling}>
                {`${patientData.patient.nickname.toUpperCase()}'S UPDATE LOG`}
              </span>
            </NavLink>
            <NavLink
              className="PatientTabs__tab"
              to={id === 'new' ? '#' : `/patient/${id}/team`}
              isActive={(match) => {
                if (match) {
                  return true;
                }
              }}
              activeStyle={activeTabStyle}
              css={patientTabs__navLinkStyling}
            >
              <span
                css={patientTabs__tabTitleStyling}
              >{`${patientData.patient.nickname.toUpperCase()}'S TEAM`}</span>
            </NavLink>
          </Fragment>
        )}
      </div>
      <div
        css={css`
          grid-column-start: span 4;
        `}
      >
        {children}
      </div>
    </div>
  );
}

export default PatientTabs;
