const showFile = (blob, fileType, patient) => {
  // It is necessary to create a new blob object with mime-type explicitly set
  // otherwise only Chrome works like it should
  const newBlob = new Blob([blob], {
    type: fileType === 'png' ? 'image/png' : 'application/pdf',
  });

  // IE doesn't allow using a blob object directly as link href
  // instead it is necessary to use msSaveOrOpenBlob
  if (window.navigator && window.navigator.msSaveOrOpenBlob) {
    window.navigator.msSaveOrOpenBlob(newBlob);
    return;
  }

  // For other browsers:
  // Create a link pointing to the ObjectURL containing the blob.
  const data = window.URL.createObjectURL(newBlob);
  const link = document.createElement('a');
  link.href = data;
  link.download = `Out-of-hospital-plan-${patient.nickname}.${fileType}`;
  link.click();
  setTimeout(function () {
    // For Firefox it is necessary to delay revoking the ObjectURL
    window.URL.revokeObjectURL(data);
  }, 100);
};

const handleDownload = (
  key,
  patient,
  setPDFLoading,
  setPNGLoading
) => async () => {
  try {
    if (key === 'png') {
      setPNGLoading(true);
    } else {
      setPDFLoading(true);
    }

    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/${key}/${patient.id}`
    );

    const blob = await response.blob();

    showFile(blob, key, patient);
    if (key === 'png') {
      setPNGLoading(false);
    } else {
      setPDFLoading(false);
    }
  } catch (error) {
    if (key === 'png') {
      setPNGLoading(false);
    } else {
      setPDFLoading(false);
    }
    console.log('An error occurred:', error);
  }
};

export default handleDownload;
