const SeizureTypesList = {
  focalOnset: {
    name: 'Focal Onset',
    checked: false,
    level: 1,
    aware: {
      name: 'Aware',
      checked: false,
      level: 2,
    },
    impairedAwareness: {
      name: 'Impaired Awareness',
      checked: false,
      level: 2,
    },
    motorOnset: {
      name: 'Motor Onset',
      checked: false,
      level: 2,
      automatisms: {
        name: 'Automatisms',
        checked: false,
        level: 3,
      },
      atonic: {
        name: 'Atonic',
        checked: false,
        level: 3,
      },
      clonic: {
        name: 'Clonic',
        checked: false,
        level: 3,
      },
      epilepticSpasms: {
        name: 'Epileptic Spasms',
        checked: false,
        level: 3,
      },
      hyperkinetic: {
        name: 'Hyperkinetic',
        checked: false,
        level: 3,
      },
      myoclonic: {
        name: 'Myoclonic',
        checked: false,
        level: 3,
      },
      tonic: {
        name: 'Tonic',
        checked: false,
        level: 3,
      },
    },
    nonMotorOnset: {
      name: 'Non-Motor Onset',
      checked: false,
      level: 2,
      autonomic: {
        name: 'Autonomic',
        checked: false,
        level: 3,
      },
      behaviorArrest: {
        name: 'Behavior Arrest',
        checked: false,
        level: 3,
      },
      cognitive: {
        name: 'Cognitive',
        checked: false,
        level: 3,
      },
      emotional: {
        name: 'Emotional',
        checked: false,
        level: 3,
      },
      sensory: {
        name: 'Sensory',
        checked: false,
        level: 3,
      },
    },
  },
  generalizedOnset: {
    name: 'Generalized Onset',
    checked: false,
    level: 1,
    motor: {
      name: 'Motor',
      checked: false,
      level: 2,
      atonic: {
        name: 'Atonic',
        checked: false,
        level: 3,
      },
      clonic: {
        name: 'Clonic',
        checked: false,
        level: 3,
      },
      epilepticSpasms: {
        name: 'Epileptic Spasms',
        checked: false,
        level: 3,
      },
      myoclonic: {
        name: 'Myoclonic',
        checked: false,
        level: 3,
      },
      myoclonicAtonic: {
        name: 'Myoclonic-atonic',
        checked: false,
        level: 3,
      },
      myoclonicTonicClonic: {
        name: 'Myoclonic-tonic-clonic',
        checked: false,
        level: 3,
      },
      tonic: {
        name: 'Tonic',
        checked: false,
        level: 3,
      },
      tonicClonic: {
        name: 'Tonic-clonic',
        checked: false,
        level: 3,
      },
    },
    nonMotor: {
      name: 'Non-motor',
      checked: false,
      level: 2,
      atypical: {
        name: 'Atypical',
        checked: false,
        level: 3,
      },
      eyelidMyoclonia: {
        name: 'Eyelid Myoclonia',
        checked: false,
        level: 3,
      },
      myoclonic: {
        name: 'Myoclonic',
        checked: false,
        level: 3,
      },
      typical: {
        name: 'Typical',
        checked: false,
        level: 3,
      },
    },
  },
  unKnownOnset: {
    name: 'Unknown Onset',
    checked: false,
    level: 1,
    motor: {
      name: 'Motor',
      checked: false,
      level: 2,
      tonicClonic: {
        name: 'Tonic-clonic',
        checked: false,
        level: 3,
      },
      epilepticSpasms: {
        name: 'Epileptic Spasms',
        checked: false,
        level: 3,
      },
    },
    nonMotor: {
      name: 'Non-motor',
      checked: false,
      level: 2,
      behaviorArrest: {
        name: 'Behaviour Arrest',
        checked: false,
        level: 3,
      },
    },
  },
  unclassified: {
    name: 'Unclassified',
    checked: false,
    level: 1,
  },
};

export default SeizureTypesList;
