/** @jsx jsx */
import { jsx, css } from '@emotion/core';
import { ApolloProvider } from '@apollo/client';
import { client } from './ApolloClient';
import GlobalStyles from 'assets/GlobalStyles';
import AppContainer from './AppContainer';

function App() {
  return (
    <ApolloProvider className="App" client={client}>
      <AppContainer
        css={css`
          background-color: ${GlobalStyles.backgroundBlue};
        `}
      />
    </ApolloProvider>
  );
}

export default App;
