/** @jsx jsx */
import { jsx, css } from '@emotion/core';

import { makeStyles } from '@material-ui/core/styles';
import { useParams, useLocation, Link } from 'react-router-dom';
import QRCode from 'qrcode.react';
import Card from '@material-ui/core/Card';
import GlobalStyles from 'assets/GlobalStyles';

//ROOT
const useStyles = makeStyles(() => ({
  root: {
    minHeight: '40vh',
    minWidth: '41vw',
    backgroundColor: `${GlobalStyles.paleBlueCardBackground}`,
    borderRadius: '30px',
    paddingTop: '3%',
    marginBottom: '3%',

    '@media(max-width: 960px)': {
      minHeight: 'initial',
      marginRight: '-10%',
      paddingLeft: '33px',
      paddingTop: '20px',
    },
  },
}));

const whenToCall911Styling = css`
  color: ${GlobalStyles.darkBlue};
  font-family: 'poppins-medium';
  font-size: 20px;
  line-height: 1.5;
  letter-spacing: 1px;
  margin-left: 7.5%;
  overflow-wrap: break-word;

  @media (max-width: ${GlobalStyles.mobileBreakpoint}) {
    font-size: 14px;
    margin-left: 0;
    letter-spacing: 0px;
  }
`;

const WhenToCall911 = ({ patientId }) => {
  return (
    <Link
      className="whenToCall911"
      css={whenToCall911Styling}
      to={`/patient-view/${patientId}/first-aid`}
    >
      {'When to Call 911'}
    </Link>
  );
};

const sharedStyling = css`
  color: ${GlobalStyles.darkBlue};
  font-family: 'poppins-medium';
  font-size: 20px;
  line-height: 1.5;
  padding-top: 5%;
  padding-right: 5%;
  padding-bottom: 3%;
  margin-left: 7.5%;
  overflow-wrap: break-word;
  max-width: 41vw;
  white-space: pre-wrap;
  @media (max-width: ${GlobalStyles.mobileBreakpoint}) {
    max-width: 100vw;
    padding-top: 1%;
    padding-right: 14%;
    padding-bottom: 20px;
    font-size: 14px;
    margin-left: 0;
  }
`;

export default function BlueCard({
  title,
  text,
  isMobile,
  contactInfo,
  QRcode,
  planSteps,
}) {
  const classes = useStyles();
  const { id: patientId, step } = useParams();
  const isAllergies = title === 'Allergies' ? true : false;

  const location = useLocation();

  return (
    <Card
      className={classes.root}
      css={css`
        @media (max-width: ${GlobalStyles.mobileBreakpoint}) {
          /* min height for hospital plan mobile cards */
          min-height: ${!step &&
          !location.pathname.includes('/details') &&
          '20vh'};
        }
      `}
    >
      <div
        css={css`
          margin-left: 7.5%;
          @media (max-width: ${GlobalStyles.mobileBreakpoint}) {
            margin-left: 0%;
          }
        `}
      >
        <h2
          css={css`
            color: ${GlobalStyles.midBlue};
            font-family: 'poppins-bold';
            font-size: 24px;
            line-height: 16px;
            margin-block-start: 0;
            margin-block-end: 0;
            @media (max-width: ${GlobalStyles.mobileBreakpoint}) {
              font-size: 16px;
            }

            /* The styling below is for the horizontal line on the right side of the title */

            width: 100%;
            text-align: left;
            border-bottom: 3px solid ${GlobalStyles.midBlue};
            line-height: 0.1em;
            margin: 10px 0 20px;

            span {
              background: ${GlobalStyles.paleBlueCardBackground};
              padding: 0 10px 0 0;
            }
          `}
        >
          <span>{title && title.toUpperCase()}</span>
        </h2>
      </div>
      {isAllergies ? (
        <div css={sharedStyling}>{text}</div>
      ) : (
        <div css={sharedStyling}>
          {QRcode ? (
            <div
              css={css`
                display: flex;
                flex-direction: column;
                ${isMobile ? 'width: fit-content' : null};
                margin-right: 5%;
                min-height: 30vh;
                justify-content: center;
                @media (max-width: ${GlobalStyles.mobileBreakpoint}) {
                  min-height: 20vh;
                  padding-top: 4%;
                } ;
              `}
            >
              <div
                css={css`
                  text-align: center;
                  padding-right: 7.5%;
                `}
              >
                <QRCode
                  value={`https://sap.bcchdigital.ca/patient-view/${patientId}`}
                  bgColor={GlobalStyles.paleBlueCardBackground}
                />
              </div>
              <div
                css={css`
                  margin-top: 5%;
                  margin-bottom: 2%;
                  padding-right: 10px;
                  ${isMobile ? null : 'margin-right: 6%'}
                `}
              >
                {text}
              </div>
            </div>
          ) : (
            <div>{text}</div>
          )}
          {isMobile && planSteps ? (
            <WhenToCall911 patientId={patientId} />
          ) : null}
        </div>
      )}

      {isMobile && contactInfo && (
        <a
          href="tel:{contactInfo}"
          css={css`
            color: ${GlobalStyles.darkBlue};
            font-family: 'poppins-medium';
            font-size: 14px;
            line-height: 21px;
            margin-block-start: 0;
            margin-block-end: 0;
            padding-bottom: 1%;
            margin-left: 7.5%;
            @media (max-width: ${GlobalStyles.mobileBreakpoint}) {
              margin-left: 0;
            }
          `}
        >
          {contactInfo}
        </a>
      )}
    </Card>
  );
}
