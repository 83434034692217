/** @jsx jsx */
import { jsx, css } from '@emotion/core';
import { useState } from 'react';
import { Grid, Button } from '@material-ui/core';
import GlobalStyles from 'assets/GlobalStyles';
import PlaceholderImage from 'assets/images/Square.png';

// Styles
const contactInfoStyle = css`
  font-family: 'Roboto-Light';
  font-size: 16px;
  text-align: left;
  line-height: 19px;
  color: ${GlobalStyles.navyBlue2};
`;

const nameStyle = css`
  font-family: 'Roboto-Bold';
  font-size: 21px;
  text-align: left;
  line-height: 24px;
  padding: 10px 0px 5px 0px;
  color: ${GlobalStyles.navyBlue2};
`;

const TeamContainer = ({
  member,
  isEditing,
  teamMemberIdsChecked,
  setTeamMemberIdsChecked,
  addingTeamMembers,
}) => {
  const {
    lastNameAlphabeticalGroup,
    id,
    first_name,
    last_name,
    image,
    email,
    prefix,
    position,
    contact_info,
  } = member;

  const [aboutToBeAdded, setAboutToBeAdded] = useState(false);
  const [aboutToBeRemoved, setAboutToBeRemoved] = useState(false);
  const [hovering, setHovering] = useState(false);

  const buttonText = () => {
    if (addingTeamMembers) {
      if (aboutToBeAdded) {
        if (hovering) {
          return 'Undo add';
        }
        return 'Added';
      } else if (!aboutToBeAdded) {
        return 'Add Member';
      }
    } else {
      if (aboutToBeRemoved) {
        if (hovering) {
          return 'Undo remove';
        }
        return 'Removed';
      } else if (!aboutToBeRemoved) {
        return 'Remove Member';
      }
    }
  };

  const addOrRemoveTeamMembers = (id) => {
    const newCheckedArray = [...teamMemberIdsChecked];

    if (newCheckedArray.includes(id)) {
      const index = newCheckedArray.indexOf(id);
      newCheckedArray.splice(index, 1);
      setAboutToBeAdded(false);
      setAboutToBeRemoved(false);
    } else {
      setAboutToBeAdded(true);
      setAboutToBeRemoved(true);
      newCheckedArray.push(id);
    }

    setTeamMemberIdsChecked(newCheckedArray);
  };

  const firstLetterofLastName = last_name[0]
    ? last_name[0].toUpperCase()
    : null;

  return (
    <div
      key={id}
      id={lastNameAlphabeticalGroup ? `${firstLetterofLastName}-first` : id}
      css={css`
        width: 55vw;

        @media (min-width: ${GlobalStyles.mobileBreakpoint}) {
          width: 65vw;
        }
      `}
    >
      {lastNameAlphabeticalGroup && (
        <div
          css={css`
            display: flex;
            width: 100%;
          `}
        >
          <span
            css={css`
              display: flex;
              justify-content: center;
              align-items: center;
              width: 32px;
              height: 26px;
              padding-bottom: 2px;
              background-color: ${GlobalStyles.navyBlue};
              color: ${GlobalStyles.white};
              font-family: 'roboto-black';
              font-size: 14px;
              margin-top: 10px;
            `}
          >
            {firstLetterofLastName}{' '}
          </span>
          <span
            className="underline"
            css={css`
              width: 100%;
              border-bottom: 5px solid ${GlobalStyles.navyBlue};
            `}
          ></span>
        </div>
      )}
      <div
        css={css`
          display: flex;
          margin-top: 1rem;
        `}
      >
        <div
          className="patientTeam__image_container"
          css={css`
            padding-right: 10px;
            height: 100px;
            width: 100px;
            display: flex;
            justify-content: center;
            overflow: hidden;
          `}
        >
          {/* uploaded images < 157px height will be null in thumbnail, display non-optimized for such cases but use optimized thumbnails if larger */}
          <img
            className="patientTeam__image"
            src={
              image && image.formats === null
                ? image.url
                : image && image.formats !== null
                ? image.formats.thumbnail.url
                : PlaceholderImage
            }
            alt={`${prefix} ${first_name} ${last_name}`}
            css={css`
              min-width: 100%;
              min-height: 100%;
              align-self: center;
              object-fit: cover;
            `}
          />
        </div>
        <div
          className="patientTeam__teamInfo_container"
          css={css`
            display: grid;
            width: 100%;
            padding-left: 28px;
          `}
        >
          <div
            className="patientTeam__occupation_title_header_container"
            css={css`
              grid-column-start: span 2;
            `}
          >
            <Grid
              item
              container
              css={css`
                border-bottom: 5px solid
                  ${isEditing ? GlobalStyles.babyBlue : GlobalStyles.navyBlue};
                width: 100%;
              `}
            >
              <span
                css={css`
                  display: inline-block;
                  color: ${GlobalStyles.white};
                  font-family: 'Roboto-Black';
                  left: 0;
                  width: auto;
                  background-color: ${isEditing
                    ? GlobalStyles.babyBlue
                    : GlobalStyles.navyBlue};
                  font-size: 12px;
                  line-height: 15px;
                  letter-spacing: 1.07px;
                  text-align: left;
                  height: 12px;
                  padding: 4px;
                  margin-bottom: -0.2%;
                `}
              >
                {position && `${position.toUpperCase()}`}
              </span>
            </Grid>
          </div>
          <div>
            {prefix ? (
              <div
                css={nameStyle}
              >{`${prefix} ${first_name} ${last_name}`}</div>
            ) : (
              <div css={nameStyle}>{`${first_name} ${last_name}`}</div>
            )}

            {!contact_info || contact_info === '' ? null : (
              <div css={contactInfoStyle}>{contact_info}</div>
            )}

            {!email || email === '' ? (
              <div css={contactInfoStyle}> No Email Info </div>
            ) : (
              <div css={contactInfoStyle}>{email}</div>
            )}
          </div>
          {isEditing ? (
            <div
              css={css`
                display: flex;
                justify-content: flex-end;
                height: 50%;
                align-items: center;
                margin-top: 1rem;
              `}
            >
              <Button
                onClick={() => addOrRemoveTeamMembers(id)}
                onMouseEnter={() => setHovering(!hovering)}
                onMouseLeave={() => setHovering(!hovering)}
                css={css`
                  background: ${GlobalStyles.accentOrange};
                  color: ${GlobalStyles.white};
                  width: 10rem;
                  height: 30px;
                  border-radius: 0%;
                  align-self: center;
                  justify-self: right;
                  font-family: 'roboto-black';
                  font-size: 12px;
                  opacity: ${(aboutToBeAdded || aboutToBeRemoved) && !hovering
                    ? '50%'
                    : '100%'};
                  :hover {
                    background: ${GlobalStyles.accentOrange};
                  }
                `}
              >
                <span>{buttonText()}</span>
              </Button>
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default TeamContainer;
